import React, { useState } from "react";
import { ROLE_ID, STATUS_REJECTED } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkEmail, checkPhNo, checkUserName, checkZoomLink } from "../../../helper/Validator";
import Loader from "../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { dateAPIFormate, defaultTimeZone } from "../../../helper/dateFormates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

function UpsertZoomMeeting(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.AGENT
            ? "agent"
            : profileData.roleId == ROLE_ID.AGENCY
            ? "agency"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.MANAGER
            ? "manager"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const { t } = useTranslation();

    const handleAdd = async () => {
        if (!props.inputValue.email || props.inputValue.email === "" || props.inputValue.email === null) {
            toast.error(t(`Please enter email`));
        } else if (props.inputValue.email && checkEmail(props.inputValue.email) == false) {
            toast.error(t(`Please enter valid email`));
        } else if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter name`));
        } else if (!props.inputValue.userName || props.inputValue.userName === "" || props.inputValue.userName === null) {
            toast.error(t(`Please enter username`));
        } else if (props.inputValue.userName && checkUserName(props.inputValue.userName) == false) {
            toast.error(t(`Please enter valid username`));
        } else if (
            (profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE) &&
            (!props.inputValue.officeId || props.inputValue.officeId === "" || props.inputValue.officeId === null)
        ) {
            toast.error(t(`Please select office`));
        } else if (
            (profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE) &&
            (!props.inputValue.doctorId || props.inputValue.doctorId === "" || props.inputValue.doctorId === null)
        ) {
            toast.error(t(`Please select doctor`));
        } else if (!props.inputValue.phone || props.inputValue.phone === "" || props.inputValue.phone === null) {
            toast.error(t(`Please enter phone`));
        } else if (props.inputValue.phone && checkPhNo(props.inputValue.phone) == false) {
            toast.error(t(`Please enter valid phone`));
        } else if (!props.inputValue.requestedDate || props.inputValue.requestedDate === "" || props.inputValue.requestedDate === null) {
            toast.error(t(`Please enter date`));
        } else if (!props.inputValue.requestedTime || props.inputValue.requestedTime === "" || props.inputValue.requestedTime === null) {
            toast.error(t(`Please enter time`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/request-zoom`,
                    {
                        name: props.inputValue.name,
                        email: props.inputValue.email,
                        phone: props.inputValue.phone,
                        statusId: STATUS_REJECTED.Requested,
                        userName: props.inputValue.userName,
                        requestedDate: dateAPIFormate(props.inputValue.requestedDate),
                        requestedTime: props.inputValue.requestedTime,
                        notes: props.inputValue.notes,
                        officeId: profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ? props.inputValue.officeId : "",
                        doctorId: profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ? props.inputValue.doctorId : "",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchZoomMeetingdata();
                    props.handleClose();
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleApproveOrReject = async (statusId) => {
        if (!props.inputValue.link || props.inputValue.link === "" || props.inputValue.link === null) {
            toast.error(t(`Please enter zoom link`));
        } else if (props.inputValue.link && checkZoomLink(props.inputValue.link) == false) {
            toast.error(t(`Please enter valid zoom-meeting link`));
        } else if (!props.inputValue.requestedDate || props.inputValue.requestedDate === "" || props.inputValue.requestedDate === null) {
            toast.error(t(`Please enter date`));
        } else if (!props.inputValue.requestedTime || props.inputValue.requestedTime === "" || props.inputValue.requestedTime === null) {
            toast.error(t(`Please enter time`));
        } else {
            Swal.fire({
                title: t(`Are you sure you want to ${statusId == STATUS_REJECTED.Approved ? "approve" : "reject"} this request ?`),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t(`Yes, ${statusId == STATUS_REJECTED.Approved ? "approve" : "reject"} it!`),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        setIsLoading(true);
                        await axios
                            .post(
                                `${baseURL}/api/v1/admin/zoom-meeting/update`,
                                {
                                    id: props.zoomMeetingId,
                                    scheduleDate: dateAPIFormate(props.inputValue.requestedDate),
                                    scheduleTime: props.inputValue.requestedTime,
                                    link: props.inputValue.link,
                                    statusId: statusId,
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: token,
                                        lang: languageShortName,
                                    },
                                }
                            )
                            .then((res) => {
                                Swal.fire(res.data.message, " ", "success");
                                props.fetchZoomMeetingdata();
                                props.handleClose();
                                setIsLoading(false);
                            })
                            .catch((err) => {
                                if (err.response) {
                                    setIsLoading(false);
                                    if (err.response.status == 500 || err.response.status == 401) {
                                        localStorage.clear();
                                        sessionStorage.clear();
                                        navigate("/login");
                                    } else {
                                        toast.error(err.response.data.message);
                                    }
                                }
                            });
                    }
                }
            });
        }
    };

    return (
        <>
            <div className="col-xs-12 ">
                <div
                    className={`add-header-wrapper gradient-${
                        profileData.roleId == ROLE_ID.ADMIN
                            ? "blue"
                            : profileData.roleId == ROLE_ID.DOCTOR
                            ? "violet"
                            : profileData.roleId == ROLE_ID.PATIENT
                            ? "orange"
                            : profileData.roleId == ROLE_ID.VERIFY
                            ? "pink"
                            : profileData.roleId == ROLE_ID.AGENCY
                            ? "green"
                            : profileData.roleId == ROLE_ID.AGENT
                            ? "green1"
                            : profileData.roleId == ROLE_ID.MANAGER
                            ? "blue3"
                            : "grep"
                    } curved-section text-center`}
                >
                    <h2 className="uppercase bold w-text">
                        {props.isEdit ? t("Update") : props.isView ? t("View") : t("Request")} {t("Zoom-Meeting")}
                    </h2>
                    <div className="before-text">
                        {props.isEdit ? t("Update") : props.isView ? t("View") : t("Request")} {t("Zoom-Meeting")}
                    </div>
                </div>

                <div className="bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        {profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ? (
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Office")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="officeList"
                                                                    value={props.officeOptions && props.officeOptions.filter(({ value }) => value == props.inputValue.officeId)}
                                                                    options={props.officeOptions}
                                                                    onChange={props.onOfficeChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={profileData.roleId == ROLE_ID.ADMIN ? "false" : "true"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Doctor")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="doctorList"
                                                                    value={props.doctorOptions && props.doctorOptions.filter(({ value }) => value == props.inputValue.doctorId)}
                                                                    options={props.doctorOptions}
                                                                    onChange={props.onDoctorChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.inputValue.officeId ? false : true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="col-xs-12">
                                            <div className="form-group row">
                                                <label className="control-label col-12">
                                                    {t("E-mail")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-12">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        value={props.inputValue.email}
                                                        onChange={
                                                            profileData.roleId == ROLE_ID.DOCTOR ||
                                                            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                                                            profileData.roleId == ROLE_ID.PATIENT ||
                                                            profileData.roleId == ROLE_ID.AGENT ||
                                                            profileData.roleId == ROLE_ID.AGENCY ||
                                                            profileData.roleId == ROLE_ID.MANAGER ||
                                                            profileData.roleId == ROLE_ID.VERIFY ||
                                                            profileData.roleId == ROLE_ID.ADMIN ||
                                                            props.isView
                                                                ? () => {}
                                                                : props.onChangeValue
                                                        }
                                                        disabled={
                                                            profileData.roleId == ROLE_ID.DOCTOR ||
                                                            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                                                            profileData.roleId == ROLE_ID.PATIENT ||
                                                            profileData.roleId == ROLE_ID.AGENT ||
                                                            profileData.roleId == ROLE_ID.AGENCY ||
                                                            profileData.roleId == ROLE_ID.MANAGER ||
                                                            profileData.roleId == ROLE_ID.VERIFY ||
                                                            profileData.roleId == ROLE_ID.ADMIN ||
                                                            props.isView
                                                                ? "disabled"
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Username")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="userName"
                                                        value={props.inputValue.userName}
                                                        onChange={
                                                            profileData.roleId == ROLE_ID.DOCTOR ||
                                                            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                                                            profileData.roleId == ROLE_ID.PATIENT ||
                                                            profileData.roleId == ROLE_ID.AGENT ||
                                                            profileData.roleId == ROLE_ID.AGENCY ||
                                                            profileData.roleId == ROLE_ID.MANAGER ||
                                                            profileData.roleId == ROLE_ID.VERIFY ||
                                                            profileData.roleId == ROLE_ID.ADMIN ||
                                                            props.isView
                                                                ? () => {}
                                                                : props.onChangeValue
                                                        }
                                                        disabled={
                                                            profileData.roleId == ROLE_ID.DOCTOR ||
                                                            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                                                            profileData.roleId == ROLE_ID.PATIENT ||
                                                            profileData.roleId == ROLE_ID.AGENT ||
                                                            profileData.roleId == ROLE_ID.AGENCY ||
                                                            profileData.roleId == ROLE_ID.MANAGER ||
                                                            profileData.roleId == ROLE_ID.VERIFY ||
                                                            profileData.roleId == ROLE_ID.ADMIN ||
                                                            props.isView
                                                                ? "disabled"
                                                                : ""
                                                        }
                                                    />
                                                    <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                        *{t("At least 5 characters : start and end with character or number, can have special character")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Name")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                value={props.inputValue.name}
                                                                onChange={
                                                                    profileData.roleId == ROLE_ID.DOCTOR ||
                                                                    profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                                                                    profileData.roleId == ROLE_ID.PATIENT ||
                                                                    profileData.roleId == ROLE_ID.AGENT ||
                                                                    profileData.roleId == ROLE_ID.AGENCY ||
                                                                    profileData.roleId == ROLE_ID.MANAGER ||
                                                                    profileData.roleId == ROLE_ID.VERIFY ||
                                                                    profileData.roleId == ROLE_ID.ADMIN ||
                                                                    props.isView
                                                                        ? () => {}
                                                                        : props.onChangeValue
                                                                }
                                                                disabled={
                                                                    profileData.roleId == ROLE_ID.DOCTOR ||
                                                                    profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                                                                    profileData.roleId == ROLE_ID.PATIENT ||
                                                                    profileData.roleId == ROLE_ID.AGENT ||
                                                                    profileData.roleId == ROLE_ID.AGENCY ||
                                                                    profileData.roleId == ROLE_ID.MANAGER ||
                                                                    profileData.roleId == ROLE_ID.VERIFY ||
                                                                    profileData.roleId == ROLE_ID.ADMIN ||
                                                                    props.isView
                                                                        ? "disabled"
                                                                        : ""
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Phone")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="number"
                                                                className="form-control"
                                                                name="phone"
                                                                value={props.inputValue.phone}
                                                                onChange={profileData.roleId == ROLE_ID.ADMIN || props.isView ? () => {} : props.onChangeValue}
                                                                disabled={profileData.roleId == ROLE_ID.ADMIN || props.isView ? "disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Date")} <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-12">
                                                            <DatePicker
                                                                className="form-control input-height mr-sm-2"
                                                                placeholderText={"Enter date"}
                                                                minDate={defaultTimeZone(new Date())}
                                                                scrollableMonthDropdown={true}
                                                                selected={props.inputValue.requestedDate && defaultTimeZone(props.inputValue.requestedDate)}
                                                                onChange={
                                                                    profileData.roleId == ROLE_ID.ADMIN || props.isView
                                                                        ? () => {}
                                                                        : (date) => props.setInputValue({ ...props.inputValue, requestedDate: date })
                                                                }
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                disabled={profileData.roleId == ROLE_ID.ADMIN || props.isView ? "disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Time")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                name="requestedTime"
                                                                value={props.inputValue.requestedTime}
                                                                onChange={profileData.roleId == ROLE_ID.ADMIN || props.isView ? () => {} : props.onChangeValue}
                                                                disabled={profileData.roleId == ROLE_ID.ADMIN || props.isView ? "disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("Notes")}</label>

                                                <div className="col-md-12">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="notes"
                                                        rows={5}
                                                        cols={12}
                                                        value={props.inputValue.notes}
                                                        onChange={profileData.roleId == ROLE_ID.ADMIN || props.isView ? () => {} : props.onChangeValue}
                                                        disabled={profileData.roleId == ROLE_ID.ADMIN || props.isView ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {profileData.roleId == ROLE_ID.ADMIN && !props.isView ? (
                                            <>
                                                <div className="col-12">
                                                    <hr className="my-1" style={{ border: "0.5px solid" }} />
                                                </div>
                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Zoom Link")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <input type="text" className="form-control" name="link" value={props.inputValue.link} onChange={props.onChangeValue} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Date")} <span className="required"> * </span>
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <DatePicker
                                                                        className="form-control input-height mr-sm-2"
                                                                        placeholderText={"Enter date"}
                                                                        minDate={defaultTimeZone(new Date())}
                                                                        scrollableMonthDropdown={true}
                                                                        selected={props.inputValue.requestedDate && defaultTimeZone(props.inputValue.requestedDate)}
                                                                        onChange={(date) => props.setInputValue({ ...props.inputValue, requestedDate: date })}
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Time")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="time"
                                                                        className="form-control"
                                                                        name="requestedTime"
                                                                        value={props.inputValue.requestedTime}
                                                                        onChange={props.onChangeValue}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Comment")}</label>

                                                        <div className="col-md-12">
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                name="comment"
                                                                rows={5}
                                                                cols={12}
                                                                value={props.inputValue.comment}
                                                                onChange={props.onChangeValue}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <div className="padding-bottom-30 d-flex justify-content-center">
                                            <div className="text-left">
                                                <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                    {t("Close")}
                                                </button>
                                                {props.isView ? (
                                                    ""
                                                ) : profileData.roleId == ROLE_ID.ADMIN ? (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-success mx-2`}
                                                            onClick={isLoading ? () => {} : () => handleApproveOrReject(STATUS_REJECTED.Approved)}
                                                            disabled={isLoading ? "disabled" : ""}
                                                        >
                                                            {t("Approve")}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-danger mx-2`}
                                                            onClick={isLoading ? () => {} : () => handleApproveOrReject(STATUS_REJECTED.Rejected)}
                                                            disabled={isLoading ? "disabled" : ""}
                                                        >
                                                            {t("Reject")}
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className={`btn btn-primary gradient-${
                                                            profileData.roleId == ROLE_ID.ADMIN
                                                                ? "blue"
                                                                : profileData.roleId == ROLE_ID.DOCTOR
                                                                ? "violet"
                                                                : profileData.roleId == ROLE_ID.PATIENT
                                                                ? "orange"
                                                                : profileData.roleId == ROLE_ID.VERIFY
                                                                ? "pink"
                                                                : profileData.roleId == ROLE_ID.AGENCY
                                                                ? "green"
                                                                : profileData.roleId == ROLE_ID.AGENT
                                                                ? "green1"
                                                                : profileData.roleId == ROLE_ID.MANAGER
                                                                ? "blue3"
                                                                : "grep"
                                                        }  mx-2`}
                                                        onClick={handleAdd}
                                                    >
                                                        {t("Request")}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertZoomMeeting;
