import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

export default function FAQ() {
    const navigate = useNavigate();

    return (
        <>
            <style jsx>{`
                .search-need-help-custom {
                    border-right: 0 !important;
                    height: auto !important;
                    border-radius: 20px !important;
                    padding: 6px 30px 6px 10px !important;
                }
                .search-icon-need-custom {
                    position: relative;
                    right: 26px;
                    background: #a1cbc9;
                    border-radius: 20px;
                }
                .user-icon-custom {
                    border-radius: 50%;
                    padding: 7% 5%;
                    background: #d9d9d9;

                    font-weight: 800;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                    color: #115278;
                }
                .need-text-custom {
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #115278;
                }
                .need-card-bg-custom {
                    background: #f6f8f9;
                    box-shadow: -2px 1px 4px 2px rgb(0 0 0 / 10%);
                    border-radius: 13px;
                }
                .list-style-custom {
                    list-style-type: disc;
                }
                .need-text-header-custom {
                    color: #115278;
                    font-weight: 700;
                    font-size: 12px;
                }
                .text-desc-review-custom {
                    font-size: 12px;
                    line-height: 16px;
                }
                ol li::marker {
                    color: #115278;
                    font-weight: 700;
                    font-size: 12px;
                }
                .list-style-custom::marker {
                    color: #898989;
                    font-weight: 700;
                    font-size: 12px;
                }
            `}</style>

            <Header />
            <section className="enrollment-hero-custom">
                <div className="hero-get-in-toch-custom icons-height-custom">
                    <div className="icons-img-custom" data-aos="fade-left">
                        <img src="/assets/images/home/img-1.png" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 text-title-sm-custom" data-aos="zoom-in">
                        <h3 className="bold pb-2">Need Help?</h3>
                        <button className="btn btn-outline text-white join-now-custom btn-arrow-width-custom" onClick={() => navigate("/get-in-touch")}>
                            Contact Me
                            <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="icons-img-custom" data-aos="fade-right">
                        <img src="/assets/images/home/img-2.png" alt="" />
                    </div>
                </div>
            </section>
            {/* <section className="pt-5 d-flex justify-content-center">
                <div className="container">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                        <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12 aff-cov-text-custom">
                            <p className="text-black mt-2 mb-0">How can we help you?</p>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-12 aff-cov-text-custom">
                            <div className="d-flex mb-3">
                                <input type="text" className="form-control search-need-help-custom" placeholder="search..." aria-label="search" aria-describedby="basic-addon2" />
                                <span className="input-group-text ps-3 pe-3 search-icon-need-custom" id="basic-addon2">
                                    <i className="fa fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="mt-2">
                <div className="container">
                    <div className="col-lg-12 col-sm-12 mt-4 pt-2">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-left">
                            <div className="card need-card-bg-custom">
                                <div className="text-center">
                                    <p className="mt-40 mb-50">
                                        <img src="/assets/images/home/services/coverd-services-1.png" alt="" height="50px" className="w-auto" />
                                    </p>
                                    <p className="d-flex justify-content-center well-sm mb-4">
                                        <span className="need-text-custom">Am I eligible for a GDenti membership?</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-up">
                            <div className="card need-card-bg-custom">
                                <div className="text-center">
                                    <p className="mt-40 mb-50">
                                        <img src="/assets/images/home/services/coverd-services-2.png" alt="" height="50px" className="w-auto" />
                                    </p>
                                    <p className="d-flex justify-content-center well-sm mb-4">
                                        <span className="need-text-custom">How does the GDenti membership work?</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-right">
                            <div className="card need-card-bg-custom">
                                <div className="text-center">
                                    <p className="mt-40 mb-50">
                                        <img src="/assets/images/home/services/coverd-services-3.png" alt="" height="50px" className="w-auto" />
                                    </p>
                                    <p className="d-flex justify-content-center well-sm ">
                                        <span className="need-text-custom">Can I use my GDenti membership in conjunction with Medicare?</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-left">
                            <div className="card need-card-bg-custom">
                                <div className="text-center">
                                    <p className="mt-40 mb-50">
                                        <img src="/assets/images/home/services/coverd-services-4.png" alt="" height="50px" className="w-auto" />
                                    </p>
                                    <p className="d-flex justify-content-center well-sm mb-40">
                                        <span className="need-text-custom">How do I sign up?</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-up">
                        <div className="card need-card-bg-custom">
                            <div className="text-center">
                                <p className="mt-40 mb-50">
                                    <img src="/assets/images/home/services/coverd-services-5.png" alt="" height="50px" className="w-auto" />
                                </p>
                                <p className="d-flex justify-content-center well-sm mb-4">
                                    <span className="need-text-custom">What is included in my GDenti plan? </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-right">
                        <div className="card need-card-bg-custom">
                            <div className="text-center">
                                <p className="mt-40 mb-50">
                                    <img src="/assets/images/home/services/coverd-services-6.png" alt="" height="50px" className="w-auto" />
                                </p>
                                <p className="d-flex justify-content-center well-sm mb-4">
                                    <span className="need-text-custom">How soon can I start receiving dental treatments after I sign up? </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-left">
                        <div className="card need-card-bg-custom">
                            <div className="text-center">
                                <p className="mt-40 mb-50">
                                    <img src="/assets/images/home/services/coverd-services-7.png" alt="" height="50px" className="w-auto" />
                                </p>
                                <p className="d-flex justify-content-center well-sm mb-4">
                                    <span className="need-text-custom">Are dental professionals licensed and qualified?</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="fade-up">
                        <div className="card need-card-bg-custom">
                            <div className="text-center">
                                <p className="mt-40 mb-50">
                                    <img src="/assets/images/home/services/coverd-services-8.png" alt="" height="50px" className="w-auto" />
                                </p>
                                <p className="d-flex justify-content-center well-sm mb-4">
                                    <span className="need-text-custom">Am I limited to one facility for all my oral treatments?</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-4">
                <div className="container">
                    <ol>
                        <li className="border-bottom pb-2">
                            <p className="need-text-header-custom fw-bold">Memberships</p>
                            <ul className="ps-0 text-desc-review-custom">
                                <li className="list-style-custom fw-bold">What is a Gdenti membership?</li>
                                <p>
                                    GDenti membership is a dental membership plan for everyone. Anyone can join regardless of age or gender. You can get discounts and protect yourself from
                                    overtreatment within the GDenti network.
                                </p>

                                <li className="list-style-custom fw-bold">Am I eligible for a GDenti membership? </li>
                                <p>Anyone can enroll to become a GDenti member, even if already covered by a traditional insurance policy.</p>

                                <li className="list-style-custom fw-bold">How does the Gdenti membership work?</li>
                                <p>
                                    It works just like a dental HMO plan. After you sign up, you can visit our nearby Dentists around you within our network. Please visit:&nbsp;
                                    <a href="https://gdenti.com/services"><u>https://gdenti.com/services</u></a> for more information.
                                </p>

                                <li className="list-style-custom fw-bold">What do I need to get started?</li>
                                <p>
                                    Please visit: <a href="https://gdenti.com/signup"><u>https://gdenti.com/signup</u></a> for more information.
                                </p>

                                <li className="list-style-custom fw-bold">Are there any age restrictions to the GDenti membership?</li>
                                <p>Our GDenti membership is open to all ages.</p>

                                <li className="list-style-custom fw-bold">Does GDenti offer annual membership plans?</li>
                                <p>Yes.</p>

                                <li className="list-style-custom fw-bold">Are there any discounts for multiple month memberships?</li>
                                <p>Currently we only provide 1 (one) membership per person.</p>

                                <li className="list-style-custom fw-bold">Can I use my GDenti membership in conjunction with Medicare?</li>
                                <p>Yes.</p>

                                <li className="list-style-custom fw-bold">How will I be billed for my membership?</li>
                                <p>
                                    You can pay for your membership at: <a href="https://gdenti.com"><u>https://gdenti.com/</u></a> after signing in to your account. GPoint and debit/credit cards are accepted. Make sure to check your
                                    renewal date.
                                </p>

                                <li className="list-style-custom fw-bold">How do I renew my membership?</li>
                                <p>You can set up an automatic renewal subscription or pay manually every month.</p>

                                <li className="list-style-custom fw-bold">How do I cancel my membership?</li>
                                <p>
                                    You can cancel your membership at: <a href="https://gdenti.com"><u>https://gdenti.com/</u></a>
                                </p>

                                <li className="list-style-custom fw-bold">If I cancel my membership early, do my benefits remain until the end of my payment cycle?</li>
                                <p>If you cancel your GDenti membership, you can still use it until the end of the month of your current pay cycle</p>

                                <li className="list-style-custom fw-bold">What if I miss my membership payment?</li>
                                <p>If you miss your payment, your Gdenti membership will be deactivated and canceled.</p>
                            </ul>
                        </li>

                        <li className="border-bottom pt-2 pb-2">
                            <p className="need-text-header-custom fw-bold">Account</p>
                            <ul className="ps-0 text-desc-review-custom">
                                <li className="list-style-custom fw-bold">How do I sign up?</li>
                                <p>
                                    To sign up for GDenti membership visit <a href="https://gdenti.com/signup"><u>https://gdenti.com/signup</u></a>
                                </p>

                                <li className="list-style-custom fw-bold">What should I do if I forget my login information?</li>
                                <p>
                                    You can recover your login information by selecting the Forgot Password on the login page and input the email address you used when signing up. Log in with the
                                    temporary password you received via email and reset it to your desired password by selecting the Change Password page located in the top-right corner.
                                </p>

                                <li className="list-style-custom fw-bold">How can I view my membership details?</li>
                                <p>You can view your membership details through the GDenti plan menu after logging in.</p>

                                <li className="list-style-custom fw-bold">How can I see my billing history?</li>
                                <p>You can see your billing history by selecting the Billing List page after logging in.</p>

                                <li className="list-style-custom fw-bold">How do I manage my account and update my personal information?</li>
                                <p>You can manage and update your account through the Edit Profile menu located in the right-upper part after logging in.</p>

                                <li className="list-style-custom fw-bold">How can I update my billing and membership information?</li>
                                <p>
                                    You can view your membership status first through the GDenti plan menu and then you can update your Membership information through the Join or Change button.
                                </p>

                                <li className="list-style-custom fw-bold">How can I update my payment information or add a new card?</li>
                                <p>You can update your payment information through the Manage Payment page after logging in.</p>

                                <li className="list-style-custom fw-bold">How do I know if my membership payment was successful?</li>
                                <p> You can know it through your membership status. If your membership payment is successful, your membership status is changed to Enrolled.</p>
                            </ul>
                        </li>

                        <li className="border-bottom pt-2 pb-2">
                            <p className="need-text-header-custom fw-bold">My Plans</p>
                            <ul className="ps-0 text-desc-review-custom">
                                <li className="list-style-custom fw-bold">What is included in my GDenti plan?</li>
                                <p>
                                    Besides a 10-50% savings in dental care treatments, you can access special rebates/promotions, unlimited covered services, and full transparency on treatment costs,
                                    including X-Ray inspection services!
                                </p>

                                <li className="list-style-custom fw-bold">Can I pause or change my membership plan?</li>
                                <p>
                                    Currently we only offer 1 membership plan. Memberships can not be paused. If you need to deactivate or cancel your membership, you can do so through your dashboard.
                                </p>
                            </ul>
                        </li>

                        <li className="border-bottom pt-2 pb-2">
                            <p className="need-text-header-custom fw-bold">Services and Treatments</p>
                            <ul className="ps-0 text-desc-review-custom">
                                <li className="list-style-custom fw-bold">What types of dental memberships and treatments are included in the membership?</li>
                                <p>
                                    Each dental office provides different services with different treatment prices. Please visit: <a href="https://gdenti.com/near-me"><u>https://gdenti.com/near-me</u></a> to see which services your dental
                                    practitioner offers.
                                </p>
                                <li className="list-style-custom fw-bold">Are there additional fees for certain services or treatments?</li>
                                <p>Each service fee is different depending on dental location. Contact the dental office before visiting.</p>

                                <li className="list-style-custom fw-bold">How soon can I start receiving dental treatments after I sign up?</li>
                                <p>You can schedule your dental services as soon as you are enrolled.</p>

                                <li className="list-style-custom fw-bold">Where can I use my GDenti membership for dental treatments?</li>
                                <p>As of Now, our Network operates in New Jersey and New York. Find a participating dental office near you by clicking Find a Dentist at the top of the page.</p>

                                <li className="list-style-custom fw-bold">How can I schedule appointments with my membership?</li>
                                <p>Contact your desired dental office and let them know you're a GDenti member so they can apply your membership perks to your visits.</p>

                                <li className="list-style-custom fw-bold">Are dental professionals licensed and qualified?</li>
                                <p>All of the dental professionals within our GDenti network are licensed professionals.</p>

                                <li className="list-style-custom fw-bold">What happens if I miss a scheduled appointment?</li>
                                <p>Please contact the dental office you’ve scheduled the appointment with to reschedule your appointment.</p>

                                <li className="list-style-custom fw-bold"> Am I limited to one facility for all my oral treatments?</li>
                                <p>No, you can visit any dental offices within our GDenti network.</p>
                            </ul>
                        </li>
                    </ol>
                </div>
            </section>

            <Footer />
        </>
    );
}
