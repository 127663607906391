import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { baseURL, treatmentTypeId } from "../../helper/Config";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

export default function Services() {
    const navigate = useNavigate();

    const divRef = useRef(null);
    const [clipPath, setClipPath] = useState("");

    const { t } = useTranslation();
    const languageShortName = localStorage.getItem("languageShortName");

    const [linePosition, setLinePosition] = useState([]); //Initial Line position
    const [lineHeight, setLineHeight] = useState([]); //Initial Line Height
    const [lineWidth, setLineWidth] = useState([]); //Initial Line Width

    let verticalHeight = null;
    let verticalWidth = null;
    let verticalPosition = null;

    function handleVerticalImageLoad() {
        const divWidth = divRef.current.offsetWidth;

        verticalHeight = divWidth * 0.235;
        verticalWidth = divWidth * 0.015;
        verticalPosition = divWidth * 0.5;

        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setLinePosition(verticalPosition);
        setClipPath(`polygon(0 0, ${verticalPosition}px 0, ${verticalPosition}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    }

    const handleMouseMove = (event) => {
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const x = event.clientX - rect.left;
        setLinePosition(x);
        verticalHeight = rect.width * 0.235;
        verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // prevent scrolling on touch devices
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const touch = event.touches[0];
        const x = touch.clientX - rect.left;
        setLinePosition(x);
        const verticalHeight = rect.width * 0.235;
        const verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    const [showMore, setShowMore] = useState(false);
    const [showMoreYearly, setShowMoreYearly] = useState(false);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleShowMoreYearly = () => {
        setShowMoreYearly(!showMoreYearly);
    };

    const [treatmentGroup, setTreatmentGRoup] = useState([]);

    const fetchTreatmentTypeGroupdata = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/treatment-type-group/list?isPagination=false`,
                {
                    treatmentTypeId: treatmentTypeId,
                    limited: 100,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setTreatmentGRoup(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    useEffect(() => {
        fetchTreatmentTypeGroupdata();
    }, []);

    const [info, setInfo] = useState([]);

    const fetchTreatmentTypeData = async (groupname) => {
        await axios
            .post(
                `${baseURL}/api/v1/treatment/list?isPagination=false`,
                {
                    isListAllRecord: false,
                    treatmentTypeId: treatmentTypeId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                let treatmentTypeList = res.data.data;
                const groupbydata = _.chain(treatmentTypeList)
                    .groupBy("treatmentTypeGroup")
                    .map(function (value, key) {
                        return {
                            treatmentTypeGroup: key,
                            data: value,
                        };
                    })
                    .value();
                groupbydata.map((item, i) => {
                    if (item.treatmentTypeGroup == groupname) {
                        return setInfo(item.data);
                    }
                });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleMoreInfo = async (groupname, isOpen, i) => {
        setInfo([]);
        await fetchTreatmentTypeData(groupname);
        if (isOpen) {
            setInfo([]);
            setOpenIndex(-1);
        } else {
            setOpenIndex(i);
        }
    };
    const [openIndex, setOpenIndex] = useState(-1);
    return (
        <>
            <Header />

            <section>
                <div
                    ref={divRef}
                    className="hero-get-in-toch-custom"
                    style={{ cursor: "grab", position: "relative", width: "100%", height: `${lineHeight}px` }}
                    onMouseMove={handleMouseMove}
                    onTouchStart={handleTouchMove}
                    onTouchMove={handleTouchMove}
                >
                    <img src="/assets/images/home/home_service_left.png" alt="before" style={{ position: "absolute", left: 0, top: 0, zIndex: 2, width: "100%", height: "auto", clipPath }} />
                    <img src="/assets/images/home/home_service_right.png" alt="after" style={{ position: "absolute", left: 0, top: 0, zIndex: 1, width: "100%", height: "auto" }} />
                    <img
                        src="/assets/images/home/circle_line_icon.png"
                        alt="Vertical Line"
                        style={{ position: "absolute", top: 0, left: `${linePosition}px`, transform: "translateX(-50%)", width: `${lineWidth}px`, height: `${lineHeight}px`, zIndex: 3 }}
                        onLoad={handleVerticalImageLoad}
                    />
                </div>
            </section>
            <br />

            {/* Service */}
            <section className="pt-10 d-flex justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-7 text-title-sm-custom text-center" data-aos="zoom-in">
                    <h3 className="bold pb-2">{t("Our Services")}</h3>
                    <button className="btn btn-outline text-white join-now-custom btn-arrow-width-custom" onClick={() => navigate("/get-in-touch")}>
                        {t("Contact Me")}
                        <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                    </button>
                </div>
            </section>

            {/* Affordable */}
            <section className="pt-5 d-flex justify-content-center">
                <div className="container">
                    <div className="col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-md-6 col-xs-12 range-text-custom" data-aos="zoom-in-right">
                            <h1 className="fw-bolder text-dark">
                                {languageShortName == "ko" ? (
                                    <>
                                        <span className="quality-text-custom">{t("Services")} </span>
                                        <span>{t("Full Range of2")}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>{t("Full Range of2")} </span>
                                        <span className="quality-text-custom">{t("Services")}</span>
                                    </>
                                )}
                            </h1>
                            <div>
                                <p className="mt-40">{t("Unlike traditional dental insurance, GDenti Plans are...")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 img-group-sm-custom" data-aos="zoom-in-left">
                            <img src="/assets/images/home/img-group.png" alt="" height="430px" className="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-4 pt-4">
                <div className="container">
                    <div className="col-lg-12 col-sm-12 mt-4 pt-2">
                        {treatmentGroup &&
                            treatmentGroup.map((item, i) => {
                                const isOpen = openIndex === i;
                                return (
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" data-aos="flip-left">
                                        <div className="card" style={{ height: isOpen ? "207px" : "", overflowY: isOpen ? "scroll" : "" }}>
                                            <div className="text-center">
                                                <p className="mt-3">
                                                    <img src={`/assets/images/home/services/coverd-services-${i < 10 ? i + 1 : 1}.png`} alt="" height="50px" className="w-auto" />
                                                </p>
                                                <p className="pt-2 pb-4 bold text-number-color-custom">{item.name}</p>
                                                <button
                                                    className="btn p-26-20 input-radius-custom"
                                                    style={{ border: "1px solid #3A9BDC", background: "transparent", boxShadow: "0px 1px 2px 1px #3a9bdc57" }}
                                                    onClick={() => {
                                                        handleMoreInfo(item.name, isOpen, i);
                                                    }}
                                                >
                                                    {isOpen ? (
                                                        <i
                                                            className="fa-solid fa-chevron-up"
                                                            onClick={() => {
                                                                setInfo([]);
                                                                setOpenIndex(-1);
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        <i
                                                            className="fa-solid fa-chevron-down"
                                                            onClick={() => {
                                                                setInfo([]);
                                                                setOpenIndex(-1);
                                                            }}
                                                        ></i>
                                                    )}
                                                </button>
                                            </div>
                                            {isOpen
                                                ? info &&
                                                info.map((info, i) => {
                                                    return (
                                                        <div className="mt-2">
                                                            <ul>
                                                                <li>{info.name}</li>
                                                            </ul>
                                                        </div>
                                                    );
                                                })
                                                : ""}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>

            {/*  */}
            <section className="mt-4 pt-4" id="planForEveryOne">
                <div className="container">
                    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 range-text-custom">
                        <div className="d-sm-flex align-items-center">
                            <div className="servics-w-lg-custom">
                                <h1 className="fw-bolder text-dark" data-aos="flip-left">
                                    {languageShortName == "ko" ? (
                                        <>
                                            <span className="quality-text-custom">{t("everyone")}</span>
                                            <span>{t("A plan for")}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>{t("A plan for")}</span>
                                            <span className="quality-text-custom"> {t("everyone")}</span>
                                        </>
                                    )}
                                </h1>
                            </div>
                            <div data-aos="flip-right">
                                <p>{t("Our dental plans can help individuals and families...")}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-sm-12 mt-4 pt-2 d-flex justify-content-center">
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="zoom-in-right">
                            <div className="card basic-coverage-box-custom">
                                <div className="text-center">
                                    <p className="pt-2 bold text-black text-title-card-custom">{t("Basic Coverage")}</p>
                                    <p className="pt-2 bold text-number-color-custom nine-dot-text-custom">
                                        $11.99
                                        <span className="pt-2 pb-4 text-number-color-custom" style={{ fontSize: "12px" }}>
                                            /Month
                                        </span>
                                    </p>
                                    <p className="pt-2 pb-4" style={{ fontSize: "12px" }}>
                                        {t("Finally! A dental plan designed to fit your unique needs...")}
                                    </p>
                                </div>
                                <div className="row mt-2">
                                    <div className={`d-flex mb-3 ${showMore ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o text-number-color-custom mt-1 me-3" aria-hidden="true"></i>
                                        <span>Regular Check Up</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMore ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o text-number-color-custom mt-1 me-3" aria-hidden="true"></i>
                                        <span>Full X-Rays</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMore ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o text-number-color-custom mt-1 me-3" aria-hidden="true"></i>
                                        <span>Bitewing X-Ray</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMore ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o text-number-color-custom mt-1 me-3" aria-hidden="true"></i>
                                        <span>Cleaning</span>
                                    </div>
                                    <div className={`d-flex ${showMore ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o text-number-color-custom mt-1 me-3" aria-hidden="true"></i>
                                        <span>30-50% discount on treatments</span>
                                    </div>
                                    <div className={`d-flex flex-column ${showMore ? "" : "d-none"}`}>
                                        <div className="ps-4">
                                            <li>
                                                <span>crowns</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>root canals</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>implants</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>extractions</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>fillings</span>
                                            </li>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                        <button className="btn text-white btn-enroll-doctor-custom" onClick={handleShowMore}>
                                            {showMore ? t("Show less ∧") : t("Show more ∨")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="zoom-in-down">
                            <div
                                className="card"
                                style={{
                                    background: "#115278",
                                    borderRadius: "8px",
                                }}
                            >
                                <div className="text-center text-white">
                                    <p className="pt-2 bold text-title-card-custom">{t("Yearly Coverage")}</p>
                                    <p className="pt-2 bold nine-dot-text-custom">
                                        <span style={{ textDecoration: "line-through", textDecorationThickness: "2px", color: "#ACF8F3" }}>$143.88</span>
                                        <span className="pt-2 pb-4" style={{ fontSize: "12px" }}>
                                            /Year
                                        </span>
                                    </p>
                                    <p className="pt-2 bold nine-dot-text-custom">
                                        131.89
                                        <span className="pt-2 pb-4" style={{ fontSize: "12px" }}>
                                            /Year
                                        </span>
                                    </p>
                                    <p className="pt-2" style={{ fontSize: "12px" }}>
                                        {t("GDenti gives you the option to pay for your dental care plan...")}
                                    </p>
                                </div>
                                <div className="row mt-2 text-white">
                                    <div className={`d-flex mb-3 ${showMoreYearly ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o mt-1 me-3" aria-hidden="true"></i>
                                        <span>Regular Check Up</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMoreYearly ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o mt-1 me-3" aria-hidden="true"></i>
                                        <span>Full X-Rays</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMoreYearly ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o mt-1 me-3" aria-hidden="true"></i>
                                        <span>Bitewing X-Rays</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMoreYearly ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o mt-1 me-3" aria-hidden="true"></i>
                                        <span>Cleaning</span>
                                    </div>
                                    <div className={`d-flex mb-3 ${showMoreYearly ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o mt-1 me-3" aria-hidden="true"></i>
                                        <span>1 Month FREE</span>
                                    </div>
                                    <div className={`d-flex ${showMoreYearly ? "" : "d-none"}`}>
                                        <i className="fa fa-check-circle-o mt-1 me-3" aria-hidden="true"></i>
                                        <span>30-50% discount on treatments</span>
                                    </div>
                                    <div className={`d-flex flex-column ${showMoreYearly ? "" : "d-none"}`}>
                                        <div className="ps-4">
                                            <li>
                                                <span>crowns</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>root canals</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>implants</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>extractions</span>
                                            </li>
                                        </div>
                                        <div className="ps-4">
                                            <li>
                                                <span>fillings</span>
                                            </li>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-3">
                                        <button className="btn bg-white" style={{ borderRadius: "6px" }} onClick={handleShowMoreYearly}>
                                            {showMoreYearly ? t("Show less ∧") : t("Show more ∨")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <Footer />
        </>
    );
}
