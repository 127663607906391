import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, CLAIM_STATUS, ROLE_ID, VERIFIER_COMMENT_STATUS } from "../../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, getPreviousYear, monthDateYear } from "../../../helper/dateFormates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import UpsertClaim from "../manageClaim/UpsertClaim";
import UpsertComment from "./UpsertComment";
import { useTranslation } from "react-i18next";

function VerifyClaimList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const cookies = new Cookies();
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const [claimList, setClaimList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [editLoader, setEditLoader] = useState(false);
    const [isView, setIsView] = useState(false);
    const [showComment, setShowcomment] = useState(false);
    const [claimId, setClaimId] = useState("");
    const [filterValue, setFilterValue] = useState({
        // doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
        doctorId: "",
        code: "",
        searchPatientName: "",
        officeId: profileData.roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "",
        fromDate: getPreviousYear(new Date()),
        toDate: new Date(),
        statusSearch: "",
    });

    const [officeId, setOfficeId] = useState(profileData.roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "");
    const [doctorId, setDoctorId] = useState(profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "");
    // const [doctorId, setDoctorId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [doctorList, setDoctorList] = useState([]);
    const [dateOfService, setDateOfService] = useState("");
    const [sum, setSum] = useState([]);
    const [inputValueObj, setInputValueObj] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const [doctorFilterList, setDoctorFilterList] = useState([]);
    const [claimData, setClaimData] = useState([]);
    const [inputValue, setInputValue] = useState({
        verifierNote: "",
        doctorNote: "",
        finalResult: "",
        isVerifierNoteChanged: 0,
        isFinalResultChanged: 0,
        isDoctorNoteChanged: 0,
    });

    const [oldNoteValues, setOldNoteValues] = useState({
        verifierNote: "",
        doctorNote: "",
        finalResult: "",
    });

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const fetchClaimdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/claim/list`,
                {
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    doctorId: filterValue.doctorId,
                    code: filterValue.code,
                    searchPatientName: filterValue.searchPatientName,
                    officeId: filterValue.officeId,
                    fromDate: dateAPIFormate(filterValue.fromDate),
                    toDate: dateAPIFormate(filterValue.toDate),
                    isSubmitted: CLAIM_STATUS.Submitted,
                    finalResult: filterValue.statusSearch,
                    isVerifierClaimList: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const handleView = (id, officeId) => {
        setClaimId(id);
        claimDetail(id);
        fetchTreatmentTypeData(id, officeId);
        if (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT) {
            fetchDoctorList(officeId);
        }
        setIsView(true);
    };

    const handleComment = (id) => {
        setShowcomment(true);
        setClaimId(id);
        claimNoteDetail(id);
    };

    const claimNoteDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/claim/comment/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setOldNoteValues(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let selectOffice = [
        {
            id: "",
            name: t("Office"),
        },
    ];

    const fetchOfficedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {
                    isClaimOffice: true,
                    // isUserEvaluationOffice: profileData.roleId == ROLE_ID.PATIENT ? true : false,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onFilterOfficeChange = (e) => {
        setFilterValue({ ...filterValue, officeId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    let selectDoctor = [
        {
            id: "",
            firstName: t("Doctor"),
        },
    ];

    const fetchDoctorData = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    isClaimDoctor: profileData.roleId == ROLE_ID.PATIENT ? false : true,
                    isInOffice: true,
                    isUserEvaluationDoctor: profileData.roleId == ROLE_ID.PATIENT ? true : false,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorFilterList(res.data.data ? selectDoctor.concat(res.data.data) : selectDoctor);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorFilterList &&
        doctorFilterList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const onFilterDoctorChange = (e) => {
        setFilterValue({ ...filterValue, doctorId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchClaimdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const claimDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/claim/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                let response = res.data.data;
                setOfficeId(response.office.id);
                setDoctorId(response.doctor.id);
                setPatientId(response.patient.id);
                setDateOfService(response.dateOfService);
                setClaimData(response);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchTreatmentTypeData = async (claimId, officeId) => {
        axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/treatment/list?isPagination=false`,
                {
                    claimId: claimId,
                    officeId: officeId,
                    isListAllRecord: false,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                let obj = [];

                res.data.data &&
                    res.data.data.map((item) => {
                        obj.push({
                            treatmentTypeId: item.treatmentTypeId,
                            treatmentId: Number(item.id),
                            fee: Number(item.originalFee),
                            unit: item.unit,
                            totalUnitFee: item.totalUnitFee,
                        });
                    });
                setInputValueObj(obj);
                let sum =
                    res.data.data &&
                    res.data.data.reduce((acc, obj) => {
                        if (!acc[obj.treatmentTypeId]) {
                            acc[obj.treatmentTypeId] = 0;
                        }
                        acc[obj.treatmentTypeId] += obj.totalUnitFee;
                        return acc;
                    }, {});

                setSum(sum);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setClaimId("");
        if (profileData.roleId == ROLE_ID.ADMIN) {
            setDoctorId("");
            setOfficeId("");
        }
        setInputValueObj([]);
        setSum([]);
        setPatientId("");
        setDateOfService("");
        setIsSubmitted(false);
        setIsView(false);
        setInputValue({
            verifierNote: "",
            doctorNote: "",
            finalResult: "",
        });
        setShowcomment(false);
    };

    const fetchDoctorList = async (officeId) => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    officeId: officeId,
                    isClaimDoctor: true,
                    isInOffice: true,
                    // isUserEvaluationDoctor: profileData.roleId == ROLE_ID.PATIENT ? true : false,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        // fetchClaimdata();
        if (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.VERIFY || profileData.roleId == ROLE_ID.PATIENT) {
            fetchOfficedata();
            fetchDoctorData();
        }
    }, []);

    useEffect(() => {
        fetchClaimdata();
    }, [offset, filterValue.fromDate, filterValue.toDate, filterValue.officeId, filterValue.doctorId, filterValue.statusSearch]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Claim Verify")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            <li className="active cursor-pointer" onClick={handleClose}>
                                <strong>{t("Claim Verify List")}</strong>
                            </li>
                            {isView ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {t("View")} {t("Claim")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {isView ? (
                        <UpsertClaim
                            claimNoteDetail={claimNoteDetail}
                            oldNoteValues={oldNoteValues}
                            show={showComment}
                            setShow={setShowcomment}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onChangeValue={onChangeValue}
                            isVerifyClaimList={true}
                            //
                            fetchOfficedata={fetchOfficedata}
                            fetchDoctorData={fetchDoctorData}
                            editLoader={editLoader}
                            sum={sum}
                            setSum={setSum}
                            inputValueObj={inputValueObj}
                            setInputValueObj={setInputValueObj}
                            setDoctorList={setDoctorList}
                            doctorList={doctorList}
                            fetchDoctorList={fetchDoctorList}
                            officeId={officeId}
                            setOfficeId={setOfficeId}
                            doctorId={doctorId}
                            setDoctorId={setDoctorId}
                            patientId={patientId}
                            setPatientId={setPatientId}
                            dateOfService={dateOfService}
                            setDateOfService={setDateOfService}
                            handleClose={handleClose}
                            fetchClaimdata={fetchClaimdata}
                            claimData={claimData}
                            claimId={claimId}
                            isView={isView}
                            setIsView={setIsView}
                            isSubmitted={isSubmitted}
                        />
                    ) : (
                        <section className="box1">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-1 col-md-3 col-sm-3 title">{t("Date of service")}</div>
                                        <div className="col-lg-3 col-md-9 col-sm-9">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-12 d-flex justify-content-center">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"Start date"}
                                                            maxDate={filterValue.toDate}
                                                            scrollableMonthDropdown={true}
                                                            selected={filterValue && defaultTimeZone(filterValue.fromDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, fromDate: date })}
                                                            // onKeyDown={onKeyDown}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                    <span className="title">{t("To")}</span>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-12">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"End date"}
                                                            maxDate={new Date()}
                                                            scrollableMonthDropdown={true}
                                                            minDate={defaultTimeZone(filterValue.fromDate)}
                                                            selected={filterValue && defaultTimeZone(filterValue.toDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, toDate: date })}
                                                            // onKeyDown={onKeyDown}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12">
                                            <div className="row mx-2">
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select
                                                                name="officeList"
                                                                value={officeOptions && officeOptions.filter(({ value }) => value == filterValue.officeId)}
                                                                options={officeOptions}
                                                                onChange={onFilterOfficeChange}
                                                                placeholder={t("Office")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select
                                                                name="doctorList"
                                                                value={doctorOptions && doctorOptions.filter(({ value }) => value == filterValue.doctorId)}
                                                                options={doctorOptions}
                                                                onChange={onFilterDoctorChange}
                                                                placeholder={t("Doctor")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className={`col-lg-${profileData.roleId == ROLE_ID.PATIENT ? 3 : 2} col-md-${profileData.roleId == ROLE_ID.PATIENT ? 3 : 4} col-sm-${
                                                        profileData.roleId == ROLE_ID.PATIENT ? 3 : 4
                                                    } col-xs-${profileData.roleId == ROLE_ID.PATIENT ? 3 : 12}`}
                                                >
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="code"
                                                            value={filterValue.code}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={`${t("Claim")} #`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onKeyDown={onKeyDown} onChange={onFilterChangeValue}>
                                                            {Object.keys(VERIFIER_COMMENT_STATUS).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={VERIFIER_COMMENT_STATUS[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                {profileData.roleId == ROLE_ID.PATIENT ? (
                                                    ""
                                                ) : (
                                                    <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                                                        <div className="title">
                                                            <input
                                                                className="form-control"
                                                                name="searchPatientName"
                                                                value={filterValue.searchPatientName}
                                                                onChange={onFilterChangeValue}
                                                                onKeyDown={onKeyDown}
                                                                type="search"
                                                                placeholder={t("Patient")}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !claimList || (claimList && !claimList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("Claim")} #</th>
                                                        {profileData.roleId == ROLE_ID.DOCTOR ? "" : <th>{t("Office")}</th>}
                                                        <th>{t("Doctor")}</th>
                                                        {profileData.roleId == ROLE_ID.PATIENT ? "" : <th>{t("Patient")}</th>}
                                                        <th>{t("Date of service")}</th>
                                                        <th>{t("Amount")}</th>
                                                        <th>{t("Result")}</th>
                                                        <th>{t("Verified date")}</th>
                                                        <th>{t("Created Date")}</th>
                                                        <th>{t("Created by")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                {showComment ? (
                                                    <UpsertComment
                                                        oldNoteValues={oldNoteValues}
                                                        editLoader={editLoader}
                                                        claimId={claimId}
                                                        fetchClaimdata={fetchClaimdata}
                                                        show={showComment}
                                                        handleClose={handleClose}
                                                        setShow={setShowcomment}
                                                        inputValue={inputValue}
                                                        setInputValue={setInputValue}
                                                        onChangeValue={onChangeValue}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                <tbody>
                                                    {!claimList || (claimList && !claimList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        claimList &&
                                                        claimList.map((item, i) => {
                                                            return (
                                                                <tr
                                                                    key={i}
                                                                    style={{
                                                                        backgroundColor: `${
                                                                            (profileData.roleId == ROLE_ID.ADMIN && item.isDoctorNoteChanged == 1) ||
                                                                            (profileData.roleId == ROLE_ID.DOCTOR && (item.isFinalResultChanged == 1 || item.isVerifierNoteChanged == 1))
                                                                                ? "#9eeeff"
                                                                                : ""
                                                                        }`,
                                                                    }}
                                                                >
                                                                    <td className="cursor-pointer" onClick={() => handleView(item.id, item.office.id)}>
                                                                        <u>
                                                                            <h6>{item.code ? item.code : "-"}</h6>
                                                                        </u>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>{item.office ? item.office.name : "-"}</h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>
                                                                            {item.doctor
                                                                                ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>

                                                                    {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>
                                                                                {item.patient
                                                                                    ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName)
                                                                                    : "-"}
                                                                            </h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>${item.amount ? item.amount.toLocaleString("en-US") : "0"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className={`badge badge-${
                                                                                item.finalResult == VERIFIER_COMMENT_STATUS.Good
                                                                                    ? "success"
                                                                                    : item.finalResult == VERIFIER_COMMENT_STATUS.Normal
                                                                                    ? "warning"
                                                                                    : item.finalResult == VERIFIER_COMMENT_STATUS["Not Good"]
                                                                                    ? "danger"
                                                                                    : "info"
                                                                            }`}
                                                                        >
                                                                            {Object.keys(VERIFIER_COMMENT_STATUS).find((key) => VERIFIER_COMMENT_STATUS[key] === item.finalResult)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.verifiedDate ? monthDateYear(item.verifiedDate) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.createdBy
                                                                                ? (item.createdBy.firstName && item.createdBy.firstName) + " " + (item.createdBy.lastName && item.createdBy.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        {/* {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                            ""
                                                                        ) : ( */}
                                                                        <button className="btn btn-info btn-xs mx-2" onClick={() => handleComment(item.id)}>
                                                                            <i className="fa fa-commenting"></i>
                                                                        </button>
                                                                        {/* )} */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {claimList == null ? "0" : rowCount} {t("Submitted Claim")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default VerifyClaimList;
