import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import Loader from "../Loader";
import { ROLE_ID, ROWS_PER_PAGE } from "../../helper/Constant";
import NoDataFound from "../../commoncomponents/NoDataFound";
import { monthDateYear } from "../../helper/dateFormates";
import ReactPaginate from "react-paginate";
import ApproveAgent from "../../components/adminPanel/manageAgent/ApproveAgent";

const AgencyDashboard = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [dashboardData, setDashboardData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [agentReqestList, setAgentReqestList] = useState([]);
    const [agentCurrentPage, setAgentCurrentPage] = useState(0);
    const [agentOffset, setAgentOffset] = useState(1);
    const [agentPageCount, setAgentPageCount] = useState(0);
    const [agentRowCount, setAgentRowCount] = useState(0);
    const [agentIsLoading, setAgentIsLoading] = useState(true);
    const [agencyList, setAgencyList] = useState([]);
    const [agentId, setAgentId] = useState("");
    const [isView, setIsView] = useState(false);
    const [showAgent, setShowAgent] = useState(false);
    const [editLoader, setEditLoader] = useState(false);

    const [agentValue, setAgentValue] = useState({
        email: "",
        firstName: "",
        lastName: "",
        userName: "",
        gPointUserName: "",
        phone1: "",
        address1: "",
        statusId: "",
        agencyId: "",
    });
    const [agencyCheckedList, setAgencyCheckedList] = useState([]);

    const fetchDashboardData = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/agency/dashboard`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setDashboardData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchAgentReqestdata = async () => {
        setAgentIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/agency/registration-request/agentlist?isPagination=true`,
                {
                    page: agentOffset,
                    rowsPerPage: ROWS_PER_PAGE,
                    roleId: ROLE_ID.AGENT,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgentIsLoading(false);
                setAgentReqestList(res.data.data);
                setAgentPageCount(res.data.extraData.pageCount);
                setAgentRowCount(res.data.extraData.rowCount);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchAgencydata = async () => {
        setAgentIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/agency/agency/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgencyList(res.data.data);
                setAgentIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const agencyOptions =
        agencyList &&
        agencyList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName,
            };
        });

    const onAgencyChange = (e) => {
        setAgentValue({ ...agentValue, agencyId: e.value });
    };

    const handleAgentPageClick = (event) => {
        const selectedPage = event.selected;
        setAgentOffset(selectedPage + 1);
        setAgentCurrentPage(selectedPage);
    };

    const handleShowAgent = (id, roleId) => {
        agentDetail(id);
        setAgentId(id);
        setShowAgent(true);
        setIsView(true);
    };

    const agentDetail = async (agentId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/agency/agent/detail/${agentId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setAgentValue(res.data.data);
                setAgencyCheckedList(res.data.data && res.data.data.region && res.data.data.region.split("~").map((item) => Number(item)));
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setIsView(false);
        setShowAgent(false);
    };

    useEffect(() => {
        fetchDashboardData();
        fetchAgentReqestdata();
        fetchAgencydata();
    }, [agentOffset]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">
                            {t("Agency")} {t("Dashboard")}
                        </h1>
                    </div>
                </div>
            </div>
            {showAgent ? (
                <ApproveAgent
                    agencyCheckedList={agencyCheckedList}
                    setAgencyCheckedList={setAgencyCheckedList}
                    editLoader={editLoader}
                    handleClose={handleClose}
                    fetchAgentReqestdata={fetchAgentReqestdata}
                    agentId={agentId}
                    agencyOptions={agencyOptions}
                    onAgencyChange={onAgencyChange}
                    agentValue={agentValue}
                    setAgentValue={setAgentValue}
                    isView={isView}
                />
            ) : (
                <>
                    <div className="col-lg-12">
                        <div className="col-lg-12">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <section className="box nobox marginBottom0">
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-6 col-xs-12">
                                                <div className="r4_counter r8_counter db_box" onClick={() => navigate("/manage-agent")}>
                                                    <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                        <i className="fa fa-user-secret" style={{ fontSize: "34px", color: "rgb(4 113 81)" }}></i>
                                                    </i>
                                                    <div className="stats">
                                                        <h3 className="mb-5">
                                                            {dashboardData && dashboardData.totalAgent} {t("Agent")}
                                                            {/* {dashboardData && dashboardData.totalAgent > 1 ? "s" : ""} */}
                                                        </h3>
                                                        <span>
                                                            {t("Total")} {t("Agent")}
                                                            {/* {dashboardData && dashboardData.totalAgent > 1 ? "s" : ""} */}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title pull-left">{t("Pending Agent's list")}</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table vm table-small-font no-mb table-bordered table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("E-mail")}</th>
                                                                <th>{t("Username")}</th>
                                                                <th>{t("Name")}</th>
                                                                <th>{t("Created Date")}</th>
                                                                <th>{t("Action")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!agentReqestList || (agentReqestList && !agentReqestList.length) ? (
                                                                <NoDataFound />
                                                            ) : agentIsLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                agentReqestList &&
                                                                agentReqestList.map((item) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td>
                                                                                <h6>{item.email}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.userName}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{(item.firstName && item.firstName) + " " + (item.lastName && item.lastName)}</h6>
                                                                            </td>
                                                                            <td>{item.created_at && monthDateYear(item.created_at)}</td>
                                                                            <td>
                                                                                <button className="btn btn-warning btn-xs mx-2" onClick={() => handleShowAgent(item.id, ROLE_ID.AGENT)}>
                                                                                    <i className="fa fa-eye"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-md-flex  justify-content-between ">
                                                    <div className="d-flex align-center">
                                                        {t("Total")} {agentReqestList == null ? "0" : agentRowCount} {t("Agent")}- {t("Request")}
                                                    </div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={agentPageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleAgentPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel={t("Previous")}
                                                            nextLabel={t("Next")}
                                                            disableInitialCallback={true}
                                                            forcePage={agentCurrentPage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AgencyDashboard;
