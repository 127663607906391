import React from "react";
import { ROLE_ID, SNS_TYPE, STATUS } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import Loader from "../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";
import { checkSNSLink } from "../../../helper/Validator";

function UpsertSns(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const handleAdd = async () => {
        if (!props.inputValue.title || props.inputValue.title === "" || props.inputValue.title === null) {
            toast.error(t(`Please enter title`));
        } else if (!props.inputValue.type || props.inputValue.type === "" || props.inputValue.type === null) {
            toast.error(t(`Please select type`));
        } else if (!props.inputValue.SNSlink || props.inputValue.SNSlink === "" || props.inputValue.SNSlink === null) {
            toast.error(t(`Please enter SNS link`));
        } else if (props.inputValue.SNSlink && checkSNSLink(props.inputValue.SNSlink) == false) {
            toast.error(t(`Please enter valid SNS link`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/snslink/upsert`,
                    {
                        socialId: props.isEdit ? props.snsId : "",
                        title: props.inputValue.title,
                        type: props.inputValue.type,
                        statusId: props.inputValue.statusId,
                        description: props.inputValue.description,
                        SNSlink: props.inputValue.SNSlink,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchSnsdata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <div className="col-xs-12 ">
                <div className={`add-header-wrapper gradient-${profileData.roleId == ROLE_ID.ADMIN ? "blue" : "green1"} curved-section text-center`}>
                    <h2 className="uppercase bold w-text">
                        {props.isEdit ? t("Update") : props.isView ? t("View") : t("Add")} {t("Sns")}
                    </h2>
                    <div className="before-text">
                        {props.isEdit ? t("Update") : props.isView ? t("View") : t("Add")} {t("Sns")}
                    </div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Title")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="title"
                                                        value={props.inputValue.title}
                                                        onChange={props.isView ? () => {} : props.onChangeValue}
                                                        disabled={props.isView ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Type")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <select
                                                                className="form-control col-md-3"
                                                                name="type"
                                                                value={props.inputValue.type}
                                                                onChange={props.isView ? () => {} : props.onChangeValue}
                                                                disabled={props.isView ? "disabled" : ""}
                                                            >
                                                                {Object.keys(SNS_TYPE).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={SNS_TYPE[item]}>
                                                                            {item}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Link for SNS")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="SNSlink"
                                                        value={props.inputValue.SNSlink}
                                                        onChange={props.isView ? () => {} : props.onChangeValue}
                                                        disabled={props.isView ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("Description")}</label>

                                                <div className="col-md-12">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="description"
                                                        rows={5}
                                                        cols={12}
                                                        value={props.inputValue.description}
                                                        onChange={props.isView ? () => {} : props.onChangeValue}
                                                        disabled={props.isView ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Status")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <select
                                                                className="form-control col-md-3"
                                                                name="statusId"
                                                                value={props.inputValue.statusId}
                                                                onChange={props.isView ? () => {} : props.onChangeValue}
                                                                disabled={props.isView ? "disabled" : ""}
                                                            >
                                                                {Object.keys(STATUS).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={STATUS[item]}>
                                                                            {item}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="padding-bottom-30 d-flex justify-content-center">
                                            <div className="text-left">
                                                <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                    {t("Close")}
                                                </button>
                                                {profileData.roleId == ROLE_ID.ADMIN && !props.isView ? (
                                                    <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleAdd}>
                                                        {props.isEdit ? t("Update") : t("Add")}
                                                    </button>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertSns;
