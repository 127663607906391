import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, STATUS_SEARCH, ROLE_ID, STATUS } from "../../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, getPreviousYear, monthDateYear } from "../../../helper/dateFormates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DetailAgentRevenueList from "./DetailAgentRevenueList";
import { useTranslation } from "react-i18next";

function AgentRevenueList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const [agentList, setAgentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [filterValue, setFilterValue] = useState({
        searchAgentName: "",
        statusSearch: "",
        statusId: "",
        fromDate: getPreviousYear(new Date()),
        toDate: new Date(),
    });
    const [agencyId, setAgencyId] = useState("");
    const [agenctList, setAgenctList] = useState([]);
    const [show, setShow] = useState(false);
    const [agentId, setAgentId] = useState("");
    const [agentData, setAgentData] = useState({
        name: "",
        registeredDate: "",
        totalRevenue: "",
        totalAgencyRevenue: "",
    });

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENCY ? "agency" : "";

    const fetchAgentdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/agency/agent/revenue/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchAgentName: filterValue.searchAgentName,
                    statusId: filterValue.statusSearch,
                    isGDenticareAgent: true,
                    statusId: filterValue.statusId,
                    agencyId: profileData.roleId == ROLE_ID.AGENCY ? profileData.id : agencyId,
                    fromDate: dateAPIFormate(filterValue.fromDate),
                    toDate: dateAPIFormate(filterValue.toDate),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgentList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    let selectAgency = [
        {
            id: "",
            firstName: t("Agency"),
        },
    ];

    const fetchAgencydata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/agency/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgenctList(res.data.data ? selectAgency.concat(res.data.data) : selectAgency);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const agenctOptions =
        agenctList &&
        agenctList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName,
            };
        });

    const onAgentChange = (e) => {
        setAgencyId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchAgentdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const handleShow = (agentId, firstName, lastName, registeredDate, totalRevenue, totalAgencyRevenue) => {
        setShow(true);
        setAgentId(agentId);
        setAgentData({
            name: firstName + " " + lastName,
            registeredDate: registeredDate,
            totalRevenue: totalRevenue,
            totalAgencyRevenue: totalAgencyRevenue,
        });
    };

    const handleClose = () => {
        setShow(false);
        setAgentId("");
        setAgentData({
            name: "",
            registeredDate: "",
            totalRevenue: "",
            totalAgencyRevenue: "",
        });
    };

    useEffect(() => {
        fetchAgentdata();
    }, [offset, filterValue.fromDate, filterValue.toDate, filterValue.statusSearch, filterValue.statusId, agencyId]);

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN) {
            fetchAgencydata();
        }
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Agent Revenue")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer" onClick={show ? handleClose : ""}>
                                <strong>{t("Agent List")}</strong>
                            </li>
                            {show && (
                                <li className="active cursor-pointer">
                                    <strong>{t("Detail Revenue List")}</strong>
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <DetailAgentRevenueList agentId={agentId} agentData={agentData} />
                    ) : (
                        <section className="box1">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <label className="title">{t("Registered Date")}</label>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-5 col-md-5 col-sm-5 d-flex">
                                                            <div className="title">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    placeholderText={"Start date"}
                                                                    maxDate={filterValue.toDate}
                                                                    scrollableMonthDropdown={true}
                                                                    selected={filterValue && defaultTimeZone(filterValue.fromDate)}
                                                                    onChange={(date) => setFilterValue({ ...filterValue, fromDate: date })}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                                            <span className="title d-flex justify-content-center">{t("To")}</span>
                                                        </div>
                                                        <div className="col-lg-5 col-md-5 col-sm-5">
                                                            <div className="title">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    placeholderText={"End date"}
                                                                    maxDate={new Date()}
                                                                    scrollableMonthDropdown={true}
                                                                    minDate={defaultTimeZone(filterValue.fromDate)}
                                                                    selected={filterValue && defaultTimeZone(filterValue.toDate)}
                                                                    onChange={(date) => setFilterValue({ ...filterValue, toDate: date })}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="row mx-2">
                                                <div className="col-lg-2 hidden-md"></div>
                                                <div className="col-lg-4 col-md-4 col-sm-4">
                                                    {profileData.roleId == ROLE_ID.AGENCY ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select name="agent" options={agenctOptions} onChange={onAgentChange} placeholder={t("Agency")} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 d-flex justify-content-lg-end">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchAgentName"
                                                            value={filterValue.searchAgentName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search name")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-sm-4 d-flex justify-content-lg-end">
                                                    <div className="title">
                                                        <select className="form-control" name="statusId" value={filterValue.statusId} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_SEARCH[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !agentList || (agentList && !agentList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        {profileData.roleId == ROLE_ID.AGENCY ? "" : <th>{t("Agency")}</th>}
                                                        <th>{t("Agent")} {t("Name")}</th>
                                                        <th>{t("Registered Date")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Active Patient")}</th>
                                                        <th>{t("Agency Revenue")}</th>
                                                        <th>{t("Agent Revenue")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!agentList || (agentList && !agentList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        agentList &&
                                                        agentList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    {profileData.roleId == ROLE_ID.AGENCY ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>{item.agencyName ? item.agencyName : "-"}</h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.firstName ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                            {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.totalActivePatient ? item.totalActivePatient : 0}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6
                                                                            className="cursor-pointer"
                                                                            onClick={() => handleShow(item.id, item.firstName, item.lastName, item.created_at, item.revenue, item.agencyRevenue)}
                                                                        >
                                                                            <u>${item.agencyRevenue ? item.agencyRevenue.toLocaleString("en-US") : 0}</u>
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6
                                                                            className="cursor-pointer"
                                                                            onClick={() => handleShow(item.id, item.firstName, item.lastName, item.created_at, item.revenue, item.agencyRevenue)}
                                                                        >
                                                                            <u>${item.revenue ? item.revenue.toLocaleString("en-US") : 0}</u>
                                                                        </h6>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {agentList == null ? "0" : rowCount} {t("Agent")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default AgentRevenueList;
