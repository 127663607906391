import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { PAYMENT_TYPE_IN_ORDER, ROWS_PER_PAGE, PAYMENT_STATUS, ROLE_ID, SUBSCRIPTION } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import { useTranslation } from "react-i18next";
import { sliceString } from "../../../helper/helper";

function OrderHistoryList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [orderHistoryList, setOrderHistoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [filterValue, setFilterValue] = useState({
        searchUserName: "",
        statusSearch: "",
        paymentType: "",
    });

    const { profileData } = useOutletContext();
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.PATIENT ? "patient" : "";

    const fetchOrderHistorydata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/order-history/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchUserName: filterValue.searchUserName,
                    statusId: filterValue.statusSearch,
                    paymentType: filterValue.paymentType,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOrderHistoryList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchOrderHistorydata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    useEffect(() => {
        fetchOrderHistorydata();
    }, [offset, filterValue.statusSearch, filterValue.paymentType]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Order History")}</h1>
                    </div>

                    <div className="pull-right ">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>{t("Order History List")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <section className="box">
                        <header className="panel_header">
                            <div className="mx-3">
                                <div className="row ">
                                    <div className="col-lg-6 col-md-12 hidden-sm"></div>
                                    <div className=" col-lg-6 col-md-12 col-sm-12">
                                        <div className="row mx-2">
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className="title">
                                                    {profileData.roleId == ROLE_ID.PATIENT ? (
                                                        ""
                                                    ) : (
                                                        <input
                                                            className="form-control d-lg-flex justify-content-end"
                                                            name="searchUserName"
                                                            value={filterValue.searchUserName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search username")}
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="title">
                                                    <select className="form-control d-lg-flex justify-content-end" name="paymentType" value={filterValue.paymentType} onChange={onFilterChangeValue}>
                                                        {Object.keys(PAYMENT_TYPE_IN_ORDER).map((item, i) => {
                                                            return (
                                                                <option key={i} value={PAYMENT_TYPE_IN_ORDER[item]}>
                                                                    {t(item)}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6">
                                                <div className="title">
                                                    <select className="form-control d-lg-flex justify-content-end" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                        {Object.keys(PAYMENT_STATUS).map((item, i) => {
                                                            return (
                                                                <option key={i} value={PAYMENT_STATUS[item]}>
                                                                    {t(item)}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="content-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="table-responsive" data-pattern="priority-columns">
                                        <table
                                            id="tech-companies-1"
                                            className={`table vm table-small-font no-mb table-bordered ${isLoading || !orderHistoryList || (orderHistoryList && !orderHistoryList.length) ? "" : "table-striped"
                                                } table-hover`}
                                        >
                                            <thead>
                                                <tr>
                                                    {profileData.roleId == ROLE_ID.PATIENT ? (
                                                        ""
                                                    ) : (
                                                        <th>
                                                            {t("Name")} ({t("Username")})
                                                        </th>
                                                    )}
                                                    <th>{t("Order Id")}</th>
                                                    <th>{t("Plan Name")}</th>
                                                    <th>{t("Payment Type")}</th>
                                                    <th>{t("Amount")}</th>
                                                    <th>{t("Subscription")}</th>
                                                    <th>{t("Order Date")}</th>
                                                    <th>{t("Expiry Date")}</th>
                                                    <th>{t("Comment")}</th>
                                                    <th>{t("Status")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!orderHistoryList || (orderHistoryList && !orderHistoryList.length && !isLoading) ? (
                                                    <NoDataFound />
                                                ) : isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    orderHistoryList &&
                                                    orderHistoryList.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                    ""
                                                                ) : (
                                                                    <td>
                                                                        <h6>
                                                                            {item.firstName
                                                                                ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) + " (" + item.userName + ")"
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                )}
                                                                <td>
                                                                    <h6>{item.orderId ? item.orderId : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.planName ? item.planName : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>
                                                                        <span
                                                                            className={`badge badge-${item.paymentType == PAYMENT_TYPE_IN_ORDER.Cash
                                                                                ? "success"
                                                                                : item.paymentType == PAYMENT_TYPE_IN_ORDER.Card
                                                                                    ? "info"
                                                                                    : item.paymentType == PAYMENT_TYPE_IN_ORDER["G-Point"]
                                                                                    ? "danger"
                                                                                    : item.paymentType == PAYMENT_TYPE_IN_ORDER.ACH
                                                                                    ? "warning"
                                                                                    : item.paymentType == PAYMENT_TYPE_IN_ORDER.STRIPE_ACH
                                                                                    ? "success"
                                                                                    : "default"
                                                                            }`}
                                                                        >
                                                                            {Object.keys(PAYMENT_TYPE_IN_ORDER).find((key) => PAYMENT_TYPE_IN_ORDER[key] === item.paymentType)}
                                                                        </span>
                                                                    </h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.price ? "$" + item.price : "-"}</h6>
                                                                </td>
                                                                <td>{item.isSubscription && Object.keys(SUBSCRIPTION).find((key) => SUBSCRIPTION[key] == item.isSubscription)}</td>
                                                                <td>
                                                                    <h6>{item.orderDate ? monthDateYear(item.orderDate) : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.membershipExpiryDate ? monthDateYear(item.membershipExpiryDate) : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6 data-toggle="tooltip" title={item.comment}>
                                                                        {item.comment ? sliceString(item.comment, 16) : "-"}
                                                                    </h6>
                                                                </td>
                                                                <td>
                                                                    <h6>
                                                                        <span
                                                                            className={`badge badge-${item.statusId == PAYMENT_STATUS.Success
                                                                                ? "success"
                                                                                : item.statusId == PAYMENT_STATUS.Fail
                                                                                    ? "danger"
                                                                                    : item.statusId == PAYMENT_STATUS.Expire
                                                                                        ? "warning"
                                                                                        : item.statusId == PAYMENT_STATUS.Change
                                                                                            ? "info"
                                                                                            : item.statusId == PAYMENT_STATUS.Advance
                                                                                                ? "default"
                                                                                                : "default"
                                                                                }`}
                                                                        >
                                                                            {Object.keys(PAYMENT_STATUS).find((key) => PAYMENT_STATUS[key] == item.statusId)}
                                                                        </span>
                                                                    </h6>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-lg-flex justify-content-between ">
                                        <div className="d-flex align-center">
                                            {t("Total")} {orderHistoryList == null ? "0" : rowCount} {t("Order")}
                                            {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                        </div>
                                        <div className="pagination-center">
                                            <ReactPaginate
                                                initialPage={0}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={0}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                previousLabel={t("Previous")}
                                                nextLabel={t("Next")}
                                                disableInitialCallback={true}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default OrderHistoryList;
