import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE, STATUS, STATUS_SEARCH, WITHDRAWAL_TYPE } from "../../../helper/Constant";
import { useTranslation } from "react-i18next";
import { dateAPIFormate, defaultTimeZone, getPreviousYear, monthDateYear } from "../../../helper/dateFormates";
import DetailAgencyRevenueList from "./DetailAgencyRevenueList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import UpsertAgency from "../manageAgency/UpsertAgency";

function ManagerAgencyList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const [agencyList, setAgencyList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [filterValue, setFilterValue] = useState({
        managerId: "",
        statusSearch: "",
        fromDate: getPreviousYear(new Date()),
        toDate: new Date(),
    });
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [agencyId, setAgencyId] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [agencyData, setAgencyData] = useState({
        totalRevenue: "",
    });
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        gPointUserName: "",
        userName: "",
        statusId: 1,
        phone1: "",
        address1: "",
        commissionRate: "",
        achBank: "",
        achAccount: "",
        withdrawalType: WITHDRAWAL_TYPE.Manual,
        withdrawalMethod: "",
    });
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.MANAGER ? "manager" : "";

    const fetchAgencydata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/agency/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchManagerId: profileData.roleId == ROLE_ID.MANAGER ? profileData.id : filterValue.managerId,
                    searchAgencyName: filterValue.searchAgencyName,
                    statusId: filterValue.statusSearch,
                    fromDate: dateAPIFormate(filterValue.fromDate),
                    toDate: dateAPIFormate(filterValue.toDate),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgencyList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const agencyDetail = async (agencyId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/agency/detail/${agencyId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let selectManager = [
        {
            id: "",
            firstName: t("Manager"),
        },
    ];

    const fetchManagerdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/manager/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setManagerList(res.data.data ? selectManager.concat(res.data.data) : selectManager);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const managerOptions =
        managerList &&
        managerList.map((item) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const onManagerChange = (e) => {
        setFilterValue({ ...filterValue, managerId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchAgencydata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const handleShow = (managerId, totalRevenue) => {
        setShow(true);
        setAgencyId(managerId);
        setAgencyData({
            totalRevenue: totalRevenue,
        });
    };

    const handleClose = () => {
        setShow(false);
        setAgencyId("");
        setAgencyData({
            totalRevenue: "",
        });

        setInputValue({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            gPointUserName: "",
            userName: "",
            statusId: 1,
            phone1: "",
            address1: "",
            commissionRate: "",
            achBank: "",
            achAccount: "",
            withdrawalType: WITHDRAWAL_TYPE.Manual,
            withdrawalMethod: "",
        });
        setIsEdit(false);
        setShowAdd(false);
    };

    const handleEdit = (id) => {
        setAgencyId(id);
        agencyDetail(id);
        setIsEdit(true);
        setShowAdd(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const handleAdd = () => {
        setShowAdd(true);
    };
    useEffect(() => {
        fetchAgencydata();
    }, [offset, filterValue.statusSearch, filterValue.fromDate, filterValue.toDate, filterValue.managerId]);

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN) fetchManagerdata();
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Agency")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer" onClick={show || showAdd ? handleClose : ""}>
                                <strong>{t("Agency List")}</strong>
                            </li>
                            {show && (
                                <li className="active cursor-pointer">
                                    <strong>{t("Detail Revenue List")}</strong>
                                </li>
                            )}
                            {showAdd && (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : t("Add")} {t("Agency")}
                                    </strong>
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <DetailAgencyRevenueList agencyId={agencyId} agencyData={agencyData} />
                    ) : showAdd ? (
                        <UpsertAgency
                            editLoader={editLoader}
                            handleClose={handleClose}
                            fetchAgencydata={fetchAgencydata}
                            agencyId={agencyId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isEdit={isEdit}
                            show={showAdd}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box" style={{ overflow: "inherit" }}>
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-sm-12">
                                            <h2 className="title pull-left">
                                                <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                    <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                </button>
                                            </h2>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 d-flex justify-content-center">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"Start date"}
                                                            maxDate={filterValue.toDate}
                                                            scrollableMonthDropdown={true}
                                                            selected={filterValue && defaultTimeZone(filterValue.fromDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, fromDate: date })}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                    <span className="title">{t("To")}</span>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"End date"}
                                                            maxDate={new Date()}
                                                            scrollableMonthDropdown={true}
                                                            minDate={defaultTimeZone(filterValue.fromDate)}
                                                            selected={filterValue && defaultTimeZone(filterValue.toDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, toDate: date })}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                            <div className="row mx-2">
                                                {profileData.roleId == ROLE_ID.ADMIN ? (
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                        <div className="my-4">
                                                            <Select
                                                                name="managerList"
                                                                value={managerOptions && managerOptions.filter(({ value }) => value == filterValue.managerId)}
                                                                options={managerOptions}
                                                                onChange={onManagerChange}
                                                                placeholder={t("Manager")}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div className={`${profileData.roleId == ROLE_ID.ADMIN ? "col-lg-4 col-md-4 col-sm-4" : "col-lg-6 col-md-6 col-sm-6"} col-xs-12`}>
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchAgencyName"
                                                            value={filterValue.searchAgencyName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search agency")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`${profileData.roleId == ROLE_ID.ADMIN ? "col-lg-4 col-md-4 col-sm-4" : "col-lg-6 col-md-6 col-sm-6"} col-xs-12`}>
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_SEARCH[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !agencyList || (agencyList && !agencyList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("Agency Name")}</th>
                                                        <th>{t("Registered Date")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("# of Active Patient")}</th>
                                                        <th>{t("Revenue")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!agencyList || (agencyList && !agencyList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        agencyList &&
                                                        agencyList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{item.firstName ? item.firstName : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                            {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.totalActivePatient}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 className="cursor-pointer" onClick={() => handleShow(item.id, item.revenue)}>
                                                                            <u>${item.managerRevenue}</u>
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between">
                                            <div className="d-flex align-center">
                                                {t("Total")} {agencyList == null ? "0" : rowCount} {t("Agency")}
                                                {/* {rowCount && rowCount > 1 ? "ies" : "y"} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default ManagerAgencyList;
