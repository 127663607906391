import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { GENDER, PLAN_TYPE, ROLE_ID } from "../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, monthDateYear } from "../../helper/dateFormates";
import Select from "react-select";
import { useTranslation } from "react-i18next";

function CheckGDentalPatient(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [patientInfo, setPatientInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [patientNotExist, setPatientNotExist] = useState(false);

    const { profileData } = useOutletContext();
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.PATIENT ? "patient" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const handleCheck = async () => {
        setPatientNotExist(false);
        if (!props.inputValue.firstName || props.inputValue.firstName === "" || props.inputValue.firstName === null) {
            toast.error(t(`Please enter first name`));
        } else if (!props.inputValue.lastName || props.inputValue.lastName === "" || props.inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        } else if (!props.inputValue.dob || props.inputValue.dob === "" || props.inputValue.dob === null) {
            toast.error(t(`Please select date of birth`));
        } else if (!props.inputValue.dateOfService || props.inputValue.dateOfService === "" || props.inputValue.dateOfService === null) {
            toast.error(t(`Please select date of service`));
        } else {
            setIsLoading(true);
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/patient/check`,
                    {
                        firstName: props.inputValue.firstName,
                        lastName: props.inputValue.lastName,
                        dob: dateAPIFormate(props.inputValue.dob),
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setPatientInfo(res.data.data);
                    props.setShowDetails(true);
                    setIsLoading(false);
                })
                .catch((err) => {
                    if (err.response) {
                        setIsLoading(false);
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            if (err.response.data.message == "Patient does not exist.") {
                                setPatientInfo([]);
                                setPatientNotExist(true);
                                props.setShowDetails(true);
                            } else {
                                props.setShowDetails(false);
                                toast.error(err.response.data.message);
                            }
                        }
                    }
                });
        }
    };

    const fetchOfficeList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name + " (" + item.businessUsername + ")",
            };
        });

    const onOfficeChange = (e) => {
        props.setInputValue({ ...props.inputValue, officeId: e.value, doctorId: "" });
        setDoctorList([]);
        fetchDoctorList(e.value);
    };

    const fetchDoctorList = async (officeId) => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    officeId: officeId,
                    isInOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorList &&
        doctorList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName + " (" + item.userName + ")",
            };
        });

    const onDoctorChange = (e) => {
        props.setInputValue({ ...props.inputValue, doctorId: e.value });
    };

    const handleSave = async () => {
        if (!props.inputValue.officeId || props.inputValue.officeId === "" || props.inputValue.officeId === null) {
            toast.error(t(`Please select office`));
        } else if (!props.inputValue.doctorId || props.inputValue.doctorId === "" || props.inputValue.doctorId === null) {
            toast.error(t(`Please select doctor`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/patient/insert`,
                    {
                        dateOfService: dateAPIFormate(props.inputValue.dateOfService),
                        patientId: patientInfo.id,
                        officeId: props.inputValue.officeId,
                        doctorId: props.inputValue.doctorId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    toast.success(res.data.message);
                    props.setShowDetails(false);
                    setPatientInfo([]);
                    props.fetchPatientdata();
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN) {
            fetchOfficeList();
        }
    }, [profileData.roleId]);

    return (
        <div className="">
            <div className="col-xs-12">
                <div className="row">
                    <section className="box1">
                        <div className="box gradient-blue py-3 d-flex justify-content-start title-box mt-2">
                            <h4 className="m-0 title-fonts mx-3">{t("G-Denticare Plan Check")} </h4>
                        </div>
                        <div className="content-body my-4 border-0">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("First name")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="firstName" value={props.inputValue.firstName} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Last name")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="lastName" value={props.inputValue.lastName} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Birth Date")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <DatePicker
                                                        className="form-control input-height mr-sm-2"
                                                        selected={props.inputValue.dob && defaultTimeZone(props.inputValue.dob)}
                                                        placeholderText={"Please Select Date"}
                                                        maxDate={new Date()}
                                                        scrollableMonthDropdown={true}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        onChange={(date) => {
                                                            props.setShowDetails(false);
                                                            props.setInputValue({ ...props.inputValue, dob: date });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Date of service")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <DatePicker
                                                        className="form-control input-height mr-sm-2"
                                                        selected={props.inputValue.dateOfService && defaultTimeZone(props.inputValue.dateOfService)}
                                                        placeholderText={"Please Select Date"}
                                                        maxDate={new Date()}
                                                        scrollableMonthDropdown={true}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        onChange={(date) => {
                                                            props.setShowDetails(false);
                                                            props.setInputValue({ ...props.inputValue, dateOfService: date });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!patientInfo || (patientInfo && !patientInfo.length) ? (
                                    <div className="padding-bottom-30 d-flex justify-content-center">
                                        <div className="text-left">
                                            <button type="button" className="btn mx-2 btn-success" onClick={handleCheck}>
                                                {isLoading ? (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    ></i>
                                                ) : (
                                                    t("Check")
                                                )}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn mx-2 btn-secondary"
                                                onClick={() => {
                                                    props.setShowDetails(false);
                                                    setPatientInfo([]);
                                                    setPatientNotExist(false);
                                                    props.handleClose();
                                                }}
                                            >
                                                {t("Close")}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {props.showDetails ? (
                                    <>
                                        <div className="offset-2 col-8 mt-3">
                                            <section
                                                className={`box gradient-${
                                                    patientNotExist
                                                        ? "orange"
                                                        : patientInfo && patientInfo.isGDenticarePlan == 1
                                                        ? "green"
                                                        : patientInfo && patientInfo.isGDenticarePlan == 0
                                                        ? "orange"
                                                        : ""
                                                }`}
                                                style={{ padding: "20px", borderRadius: "16px" }}
                                            >
                                                <p className="g-text mb-0 text-center">
                                                    {patientNotExist
                                                        ? t("Patient does not exist")
                                                        : patientInfo && patientInfo.isGDenticarePlan == 1
                                                        ? t("This patient has been subscribed to G-Denticare Plan")
                                                        : patientInfo && patientInfo.isGDenticarePlan == 0
                                                        ? t("This patient has not joined G-Denticare Plan yet")
                                                        : ""}
                                                </p>
                                            </section>
                                        </div>
                                        {!patientNotExist && patientInfo && patientInfo.isGDenticarePlan == 1 ? (
                                            <div className="d-flex offset-3 col-6">
                                                <section className="box gradient-blue" style={{ padding: "20px", borderRadius: "16px" }}>
                                                    <div className="d-flex justify-content-center">
                                                        <h4 className="w-text fw-bold">{t("G-Denticare Plan Information")}</h4>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="w-text">{t("Name")} :</h4>
                                                        <p className=" g-text mr-15 mt-2">{patientInfo && patientInfo.firstName + " " + patientInfo.lastName}</p>
                                                    </div>
                                                    <hr className="my-0" />
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="w-text">{t("Plan")} :</h4>
                                                        <p className=" g-text mr-15 mt-2">
                                                            {patientInfo && patientInfo.plan && patientInfo.plan.name ? patientInfo.plan.name + "(" + patientInfo.membership + ")" : "-"}
                                                        </p>
                                                    </div>
                                                    <hr className="my-0" />
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="w-text">{t("Payment Type")} :</h4>
                                                        <p className=" g-text mr-15 mt-2">
                                                            {patientInfo && patientInfo.plan && patientInfo.plan.type
                                                                ? Object.keys(PLAN_TYPE).find((key) => PLAN_TYPE[key] == patientInfo.plan.type) + "ly"
                                                                : "-"}
                                                        </p>
                                                    </div>
                                                    <hr className="my-0" />
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="d-flex justify-content-between">
                                                                <h4 className="w-text">{t("Birth Date")} :</h4>
                                                                <p className=" g-text mr-15 mt-2">{patientInfo && patientInfo.dob ? monthDateYear(patientInfo.dob) : "-"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="d-flex justify-content-between">
                                                                <h4 className="w-text">{t("Gender")} :</h4>
                                                                <p className=" g-text mr-15 mt-2">
                                                                    {patientInfo && patientInfo.gender ? Object.keys(GENDER).find((key) => GENDER[key] === patientInfo.gender) : "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="my-0" />
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="w-text">{t("Address")} :</h4>
                                                        <p className=" g-text mr-15 mt-2">
                                                            {patientInfo.address1 && patientInfo.address1}
                                                            {patientInfo.cityName && patientInfo.cityName ? (patientInfo.address1 ? ", " : "") + patientInfo.cityName : ""}
                                                            {patientInfo.stateName && patientInfo.stateName ? ", " + patientInfo.stateName : ""}
                                                            {patientInfo.zip ? ", " + patientInfo.zip : ""}
                                                        </p>
                                                    </div>
                                                    <hr className="my-0" />
                                                    <div className="d-flex justify-content-between">
                                                        <h4 className="w-text">{t("Expiry Date")} :</h4>
                                                        <p className=" g-text mr-15 mt-2">
                                                            {patientInfo && patientInfo.membershipExpiryDate ? monthDateYear(patientInfo.membershipExpiryDate) : "-"}
                                                            {patientInfo.isWithdraw === 1 ? <span style={{ color: "yellow" }}>&nbsp;(Withdraw req)</span> : ""}
                                                        </p>
                                                    </div>
                                                </section>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {!patientNotExist && profileData.roleId == ROLE_ID.ADMIN ? (
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Office")} <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-12">
                                                            <Select
                                                                name="officeList"
                                                                value={officeOptions && officeOptions.filter(({ value }) => value == props.inputValue.officeId)}
                                                                options={officeOptions}
                                                                onChange={onOfficeChange}
                                                                placeholder={t("Select")}
                                                                menuPlacement="top"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Doctor")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="doctorList"
                                                                value={doctorOptions && doctorOptions.filter(({ value }) => value == props.inputValue.doctorId)}
                                                                options={doctorOptions}
                                                                onChange={onDoctorChange}
                                                                placeholder={t("Select")}
                                                                menuPlacement="top"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {!patientNotExist && props.inputValue.officeId && props.inputValue.doctorId ? (
                                            <div className="padding-bottom-30 d-flex justify-content-center">
                                                <div className="text-left">
                                                    <button type="button" className="btn mx-2 btn-info" onClick={handleSave}>
                                                        {t("Save")}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn mx-2 btn-secondary"
                                                        onClick={() => {
                                                            props.setShowDetails(false);
                                                            setPatientInfo([]);
                                                            setPatientNotExist(false);
                                                            props.handleClose();
                                                        }}
                                                    >
                                                        {t("Close")}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default CheckGDentalPatient;
