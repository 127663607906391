import React from "react";
import { useOutletContext } from "react-router-dom";
import { ROLE_ID } from "../../helper/Constant";
import AgentDashboard from "./AgentDashboard";
import AdminDashboard from "./AdminDashboard";
import AgencyDashboard from "./AgencyDashboard";
import DoctorDashboard from "./DoctorDashboard";
import PatientDashboard from "./PatientDashboard";
import VerifierDashboard from "./VerifierDashboard";
import ManagerDashboard from "./ManagerDashboard";

function Dashboard() {
    const { profileData } = useOutletContext();

    return (
        <>
            {profileData.roleId == ROLE_ID.ADMIN ? (
                <AdminDashboard />
            ) : profileData.roleId == ROLE_ID.PATIENT ? (
                <PatientDashboard />
            ) : profileData.roleId == ROLE_ID.DOCTOR ? (
                <DoctorDashboard />
            ) : profileData.roleId == ROLE_ID.VERIFY ? (
                <VerifierDashboard />
            ) : profileData.roleId == ROLE_ID.AGENCY ? (
                <AgencyDashboard />
            ) : profileData.roleId == ROLE_ID.AGENT ? (
                <AgentDashboard />
            ) : profileData.roleId == ROLE_ID.MANAGER ? (
                <ManagerDashboard />
            ) : (
                ""
            )}
        </>
    );
}

export default Dashboard;
