import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import UpsertAgency from "../../components/adminPanel/manageAgency/UpsertAgency";
import UpsertAgent from "../../components/adminPanel/manageAgent/UpsertAgent";
import UpsertManager from "../../components/adminPanel/manageManager/UpsertManager";
import { baseURL } from "../../helper/Config";
import { ROLE_ID } from "../../helper/Constant";
import UpdateDoctorProfile from "./UpdateDoctorProfile";
import UpdatePatientProfile from "./UpdatePatientProfile";

function UpdateProfile() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { profileData, setProfileData, fetchProfiledata, checkedList, setCheckedList } = useOutletContext();

    const [editLoader, setEditLoader] = useState(false);
    const [agencyList, setAgencyList] = useState([]);

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        if (name == "w9FormFile") {
            const fileExtension = e.target.files[0].name.split(".").at(-1).toLowerCase();
            let allowedFileTypes = ["pdf", "jpg", "png", "jpeg"];
            if (!allowedFileTypes.includes(fileExtension)) {
                e.target.value = null;
                alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
            } else {
                setProfileData({ ...profileData, w9FormFile: e.target.files[0] });
            }
        } else {
            setProfileData({ ...profileData, [name]: value });
        }
    };

    const fetchAgencydata = async () => {
        setEditLoader(true);
        await axios
            .post(
                `${baseURL}/api/v1/agent/agency/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgencyList(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const agencyOptions =
        agencyList &&
        agencyList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName,
            };
        });

    const onAgencyChange = (e) => {
        setProfileData({ ...profileData, agencyId: e.value });
    };

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.AGENT) {
            fetchAgencydata();
        }
    }, []);

    return (
        <>
            {profileData.roleId == ROLE_ID.PATIENT ? (
                <UpdatePatientProfile />
            ) : profileData.roleId == ROLE_ID.DOCTOR ? (
                <UpdateDoctorProfile />
            ) : profileData.roleId == ROLE_ID.AGENCY ? (
                <UpsertAgency
                    isProfile={true}
                    fetchAgencydata={fetchProfiledata}
                    agencyId={profileData.id}
                    inputValue={profileData}
                    setInputValue={setProfileData}
                    onChangeValue={onChangeValue}
                    isEdit={true}
                />
            ) : profileData.roleId == ROLE_ID.AGENT ? (
                <UpsertAgent
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                    editLoader={editLoader}
                    isProfile={true}
                    agencyOptions={agencyOptions}
                    onAgencyChange={onAgencyChange}
                    fetchAgentdata={fetchProfiledata}
                    agentId={profileData.id}
                    inputValue={profileData}
                    setInputValue={setProfileData}
                    onChangeValue={onChangeValue}
                    isEdit={true}
                />
            ) : profileData.roleId == ROLE_ID.MANAGER ? (
                <UpsertManager
                    editLoader={editLoader}
                    isProfile={true}
                    inputValue={profileData}
                    setInputValue={setProfileData}
                    onChangeValue={onChangeValue}
                    fetchManagerdata={fetchProfiledata}
                    managerId={profileData.id}
                    isEdit={true}
                />
            ) : (
                ""
            )}
        </>
    );
}

export default UpdateProfile;
