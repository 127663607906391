import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../commoncomponents/Loader";
import { baseURL } from "../../../../helper/Config";
import { PAGE_AUTHORITY_ROLE, STATUS } from "../../../../helper/Constant";
import { capitalizeFirstLetter } from "../../../../helper/helper";

function UpsertRole(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const handleAdd = async () => {
        if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter name`));
        } else if (!props.inputValue.mainRoleId || props.inputValue.mainRoleId === "" || props.inputValue.mainRoleId === null) {
            toast.error(t(`Please select role`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/role/upsert`,
                    {
                        name: capitalizeFirstLetter(props.inputValue.name),
                        mainRoleId: props.isEdit ? "" : props.inputValue.mainRoleId,
                        statusId: props.inputValue.statusId,
                        roleId: props.isEdit ? props.roleId : "",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchRoledata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.isEdit ? t("Update") : t("Add")} {t("Role")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.editLoader ? (
                        <Loader />
                    ) : (
                        <div className="justify-content-center row my-3">
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Name")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="name" value={props.inputValue.name} onChange={props.onChangeValue} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Role")} <span className="required"> * </span>
                                </label>
                                <div className="col-md-12">
                                    <select
                                        className="form-control col-md-3"
                                        name="mainRoleId"
                                        value={props.inputValue.mainRoleId}
                                        onChange={props.onChangeValue}
                                        disabled={props.isEdit ? "disabled" : ""}
                                    >
                                        {Object.keys(PAGE_AUTHORITY_ROLE).map((item, i) => {
                                            return (
                                                <option key={i} value={PAGE_AUTHORITY_ROLE[item]}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Status")} <span className="required"> * </span>
                                </label>
                                <div className="col-md-12">
                                    <select className="form-control col-md-3" name="statusId" value={props.inputValue.statusId} onChange={props.onChangeValue}>
                                        {Object.keys(STATUS).map((item, i) => {
                                            return (
                                                <option key={i} value={STATUS[item]}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleAdd}>
                        {props.isEdit ? t("Update") : t("Add")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UpsertRole;
