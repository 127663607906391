import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { FORM_TYPE, DFLT_BSN_CTGR_ID, ROLE_ID } from "../../helper/Constant";
import {
  checkEmail,
  checkPhNo,
  checkPwd,
  checkUserName,
  checkGoogleLink,
} from "../../helper/Validator";
import _ from "lodash";
import GoogleMapHelpImage from "../../commoncomponents/GoogleMapHelpImage";
import { useTranslation } from "react-i18next";

const DoctorSignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showMapImage, setShowMapImage] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [step1, setStep1] = useState({
    email: "",
    mobNo: "",
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });

  const [step2, setStep2] = useState({
    ofc: "",
    ofcName: "",
    phnNo: "",
    ofcEmail: "",
    businessUserName: "",
    licenseNo: "",
    npi1: "",
    googleMapLink: "",
    ofcAddress: "",
    countryId: "",
    stateId: "",
    cityId: "",
    zip: "",
    verifierApplied: "",
    businessCategory: "",
    serviceLang: "",
  });

  const [step3, setStep3] = useState([]);

  const [pillsNum, setPillsNum] = useState(100 / 3);

  const [serviceLangList, setServiceLangList] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);

  const [specialityPartList, setSpecialityPartList] = useState([]);

  const [inputValueObj, setInputValueObj] = useState([]);
  let InputObj = inputValueObj;

  const [officeList, setOfficeList] = useState([]);

  const [officeId, setOfficeId] = useState("");
  const [isExisting, setIsExisting] = useState(false);

  const [cbString, setCbString] = useState("");

  const [cbSpecialityString, setCbSpecialityString] = useState("");

  const [trmtGrpOpen, setTrmtGrpOpen] = useState([true, true, true, true]);

  const handleOpenClose = (e, i) => {
    setTrmtGrpOpen({ ...trmtGrpOpen, [i]: !trmtGrpOpen[i] });
  };

  const onchangeValue = (e, step, data, type) => {
    let value1 = e.target.value;

    if (value1 && parseFloat(value1) < 0) {
      e.target.value = 0;
      return;
    }

    const { name, value } = e.target;

    if (step == 1) {
      setStep1({ ...step1, [name]: value });
    }

    if (step == 2) {
      if (type == FORM_TYPE.checkbox) {
        let test;

        if (cbString == "") {
          test = `${cbString}${data}`;
          setCbString(test);
        } else {
          var includeArr = cbString.split("~");
          var result = includeArr.map(function (x) {
            return parseInt(x, 10);
          });
          if (!result.includes(data)) {
            test = `${cbString}~${data}`;
            setCbString(test);
          } else {
            result.splice(result.indexOf(data), 1);
            setCbString(result.join("~"));
          }
        }
      }

      setStep2({ ...step2, [name]: value });
    }

    if (step == 3) {
      setStep3({ ...step3, [name]: value });
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const showPwd = () => {
    if (passwordShown) {
      setPasswordShown(false);
    } else {
      setPasswordShown(true);
    }
  };

  const showConfirmPwd = () => {
    if (confirmPasswordShown) {
      setConfirmPasswordShown(false);
    } else {
      setConfirmPasswordShown(true);
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    if (pillsNum == (100 / 3) * 3) {
      setPillsNum((100 / 3) * 2);
      setCurrentStep(2);
    }

    if (pillsNum == (100 / 3) * 2) {
      setPillsNum(100 / 3);
      setCurrentStep(1);
      setIsExisting(false);
    }
  };

  const serviceLanguageList = async () => {
    await axios
      .post(`${baseURL}/api/v1/language/list?isPagination=false`, {
        search: "",
      })
      .then((res) => {
        setServiceLangList(res.data.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const fetchCountryList = async () => {
    await axios
      .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
        isId: true,
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const countryOptions =
    countryList &&
    countryList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const onCountryChange = (e) => {
    setStep2({ ...step2, countryId: e.value, stateId: "", cityId: "" });
    setStateList([]);
    setCityList([]);
    fetchStateList(e.value);
  };

  const fetchStateList = async (countryId) => {
    await axios
      .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
        countryId: countryId,
        isId: true,
      })
      .then((res) => {
        setStateList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const stateOptions =
    stateList &&
    stateList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const onStateChange = (e) => {
    setStep2({ ...step2, stateId: e.value });
    fetchCityList(e.value);
  };

  const fetchCityList = async (stateId) => {
    await axios
      .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
        stateId: stateId,
        isId: true,
      })
      .then((res) => {
        setCityList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const cityOptions =
    cityList &&
    cityList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const onCityChange = (e) => {
    setStep2({ ...step2, cityId: e.value });
  };

  const serviceCostInfo = async () => {
    await axios
      .post(`${baseURL}/api/v1/treatment/list?isPagination=false`, {
        search: "",
      })
      .then((res) => {
        setTreatmentData(res.data.data);
        let treatmentTypeList = res.data.data;
        const groupbydata = _.chain(treatmentTypeList)
          .groupBy("treatmentTypeGroup")
          .map(function (value, key) {
            return {
              treatmentTypeGroup: key,
              data: value,
            };
          })
          .value();
        setTreatmentData(groupbydata);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const fetchSpecialityPartdata = async () => {
    await axios
      .post(`${baseURL}/api/v1/speciality-part/list?isPagination=false`, {})
      .then((res) => {
        setSpecialityPartList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleSpecialityCheckChange = (e, data) => {
    const value = Number(e.target.value);
    let specialityCheckChange;

    if (cbSpecialityString == "") {
      specialityCheckChange = `${cbSpecialityString}${data}`;
      setCbSpecialityString(specialityCheckChange);
    } else {
      var includeArr = cbSpecialityString.split("~");
      var result = includeArr.map(function (x) {
        return parseInt(x, 10);
      });
      if (!result.includes(data)) {
        specialityCheckChange = `${cbSpecialityString}~${data}`;
        setCbSpecialityString(specialityCheckChange);
      } else {
        result.splice(result.indexOf(data), 1);
        setCbSpecialityString(result.join("~"));
      }
    }
  };

  const fetchOfficeList = async () => {
    await axios
      .post(`${baseURL}/api/v1/office/list?isPagination=false`, {})
      .then((res) => {
        setOfficeList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const officeOptions =
    officeList &&
    officeList.map((item, i) => {
      return {
        value: item.id,
        label: item.name + " (" + item.businessUsername + ")",
      };
    });

  const onOfficeChange = (e) => {
    setOfficeId(e.value);
    setIsExisting(true);
    setStep2("");
  };

  const handleClear = () => {
    setOfficeId("");
    setIsExisting(false);
  };

  const handleOriginalFeeChange = async (e, fid, GName) => {
    let id = fid;
    let value = e.target.value;
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );

    let discountRate =
      value == 0
        ? 0
        : (
            (Math.round(value - Number(treatmentId.discountFee)) / value) *
            100
          ).toFixed(2);

    if (value > -1) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(discountRate),
        discountFee: 0,
        originalFee: value,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };

      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;
      setTreatmentData([...oldgropudata]);

      let newObj = {
        treatmentId: Number(id),
        originalFee: value,
        discountFee: 0,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };

      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      toast.error(t(`Invalid amount entered`));
      // toast.error(
      //   t(
      //     `Original fee should not be less than discount fee or can not be less than 0`
      //   )
      // );
    }
  };

  const handleDiscountFeeChange = async (e, fid, GName) => {
    let id = fid;
    let value = e.target.value;
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate =
      value == 0
        ? 100
        : (
            ((treatmentId.originalFee - value) / treatmentId.originalFee) *
            100
          ).toFixed(2);

    if (value <= Number(treatmentId.originalFee)) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(treatmentId.discountRate),
        discountFee: value,
        originalFee: Number(treatmentId.originalFee),
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };
      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;

      setTreatmentData([...oldgropudata]);
      let newObj = {
        treatmentId: Number(id),
        originalFee: treatmentId.originalFee,
        discountFee: value,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };
      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      toast.error(t(`Invalid discount fee entered`));
      // toast.error(t(`Discount fee cannot be greater than discount fee`));
    }
  };

  const handleCustomRateChange = async (e, fid, GName) => {
    let id = fid;
    let value = Number(e.target.value);
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate = value;
    let discountFee =
      value == 0
        ? Math.round(treatmentId.originalFee).toFixed(2)
        : (
            Math.round(treatmentId.originalFee) -
            Math.round(treatmentId.originalFee) * (value / 100)
          ).toFixed(2);

    if (value <= 100) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(treatmentId.discountRate),
        discountFee: discountFee,
        originalFee: Number(treatmentId.originalFee),
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };
      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;
      setTreatmentData([...oldgropudata]);

      let newObj = {
        treatmentId: Number(id),
        originalFee: treatmentId.originalFee,
        discountFee: discountFee,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };
      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      toast.error(t(`Discount rate cannot be greater than 100%`));
    }
  };

  const handleCheckChange = async (e, fid, GName) => {
    let id = fid;
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );

    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let newobj = {
      code: treatmentId.code,
      description: treatmentId.description,
      id: Number(treatmentId.id),
      name: treatmentId.name,
      treatmentType: treatmentId.treatmentType,
      treatmentTypeGroup: treatmentId.treatmentTypeGroup,
      discountRate: Number(treatmentId.discountRate),
      discountFee: Number(treatmentId.discountFee),
      originalFee: Number(treatmentId.originalFee),
      isFreeOnceYear: !e.target.checked ? 1 : 0,
      unit: Number(treatmentId.unit),
    };
    objTreatmentdata[treatmentId_index] = newobj;
    oldgropudata[filterddata_index].data = objTreatmentdata;
    setTreatmentData([...oldgropudata]);
    let newObj = {
      treatmentId: Number(id),
      originalFee: treatmentId.originalFee,
      discountFee: treatmentId.discountFee,
      discountRate: treatmentId.discountRate,
      isFreeOnceYear: !e.target.checked ? 1 : 0,
    };
    let index = InputObj.findIndex((p) => p.treatmentId == id);
    if (index != -1) {
      InputObj[index] = newObj;
    } else {
      InputObj.push(newObj);
    }
    setInputValueObj(InputObj);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    if (currentStep == 1) {
      if (!step1.email) {
        toast.error("Enter email");
        return false;
      }

      if (checkEmail(step1.email) == false) {
        toast.error(t(`Enter Valid Email`));
        return false;
      }

      if (!step1.mobNo) {
        toast.error(t(`Enter Mobile Number`));
        return false;
      }

      if (step1.mobNo.length > 10) {
        toast.error("Enter 10 Digits Mobile Number");
        return false;
      }

      if (checkPhNo(step1.mobNo) == false) {
        toast.error(t(`Enter Valid Mobile Number`));
        return false;
      }

      if (!step1.firstName) {
        toast.error(t(`Please enter first name`));
        return false;
      }

      if (!step1.lastName) {
        toast.error(t(`Enter Last Name`));
        return false;
      }

      if (!step1.userName) {
        toast.error(t(`Enter User Name`));
        return false;
      }

      if (checkUserName(step1.userName) == false) {
        toast.error(t(`Enter Valid User Name`));
        return false;
      }

      if (!step1.password) {
        toast.error(t(`Enter Password`));
        return false;
      }

      if (checkPwd(step1.password) == false) {
        toast.error("Enter Valid Password");
        return false;
      }

      if (!step1.confirmPassword) {
        toast.error("Enter Confirm Password");
        return false;
      }

      if (checkPwd(step1.confirmPassword) == false) {
        toast.error("Enter Valid Confirm Password");
        return false;
      }

      if (step1.password !== step1.confirmPassword) {
        toast.error(t(`Password and Confirm Password must be same`));
        return false;
      }

      handleClear();
      setStepOne(true);
    }

    if (currentStep == 2) {
      if (isExisting) {
        if (!officeId || officeId == "") {
          toast.error("Select Office");
          return false;
        }
      } else {
        if (!step2.ofcName) {
          toast.error(t(`Enter Office Name`));
          return false;
        }

        if (!step2.phnNo) {
          toast.error(t(`Enter Phone Number`));
          return false;
        }

        if (checkPhNo(step2.phnNo) == false) {
          toast.error(t(`Enter Valid Mobile Number`));
          return false;
        }

        if (!step2.ofcEmail) {
          toast.error("Enter Office Email");
          return false;
        }

        if (checkEmail(step2.ofcEmail) == false) {
          toast.error(t(`Enter Valid Email`));
          return false;
        }

        if (!step2.businessUserName) {
          toast.error("Enter Business User Name");
          return false;
        }

        if (!step2.googleMapLink) {
          toast.error("Enter google map link");
          return false;
        }

        if (checkGoogleLink(step2.googleMapLink) == false) {
          toast.error(t(`Enter Valid Google Map Link`));
          return false;
        }

        if (!step2.ofcAddress) {
          toast.error(t(`Enter Office Address`));
          return false;
        }

        if (!step2.countryId) {
          toast.error(t(`Select Country`));
          return false;
        }

        if (!step2.stateId) {
          toast.error(t(`Select State`));
          return false;
        }

        if (!step2.cityId) {
          toast.error(t(`Select City`));
          return false;
        }

        if (!step2.zip) {
          toast.error(t(`Select ZIP Code`));
          return false;
        }

        if (!step2.serviceLang) {
          toast.error(t(`Select Service Language`));
          return false;
        }
      }

      setStepTwo(true);
    }

    var obj;

    if (currentStep == 1) {
      obj = {
        step: currentStep,
        userName: step1.userName,
        email: step1.email,
      };
    } else if (currentStep == 2) {
      if (!isExisting || isExisting == false) {
        obj = {
          step: currentStep,
          businessUsername: step2.businessUserName,
          officeEmail: step2.ofcEmail,
        };
      } else {
        obj = {
          officeId: officeId,
          isSelectOffice: true,
        };
      }
    }
    await axios
      .post(`${baseURL}/api/v1/check`, obj)
      .then(async (res) => {
        if (res.data.success == true) {
          if (pillsNum == 100 / 3 || currentStep == 1) {
            setPillsNum((100 / 3) * 2);
            setCurrentStep(2);
            setStepOne(true);
          }

          if (!isExisting && (pillsNum == (100 / 3) * 2 || currentStep == 2)) {
            setPillsNum((100 / 3) * 3);
            setCurrentStep(3);
            setStepTwo(true);
          }

          if (currentStep == 3 || pillsNum == (100 / 3) * 3 || isExisting) {
            await axios
              .post(`${baseURL}/api/v1/register`, {
                firstName: step1.firstName,
                lastName: step1.lastName,
                roleId: ROLE_ID.DOCTOR,
                userName: step1.userName,
                email: step1.email,
                password: step1.password,
                confirmPassword: step1.confirmPassword,
                gender: "",
                dob: "",
                phone1: step1.mobNo,
                phone2: "",
                address1: "",
                address2: "",
                stateId: "",
                cityId: "",
                zip: "",
                ssn: "",
                insuranceNumber: "",
                referralAgenecyNumber: "",

                isSelectOffice: isExisting,
                officeId: officeId,
                name: step2.ofcName,
                officePhone1: step2.phnNo,
                officeEmail: step2.ofcEmail,
                businessUsername: step2.businessUserName,
                licenseNumber: step2.licenseNo,
                npi1: step2.npi1,
                address1: step2.ofcAddress,
                countryId: step2.countryId,
                stateId: step2.stateId,
                cityId: step2.cityId,
                zip: step2.zip,
                verifierApplied: step2.verifierApplied,
                businessCategoryId: DFLT_BSN_CTGR_ID,
                googleMapLink: step2.googleMapLink,
                languageId: cbString,

                officeTreatmentFee: inputValueObj,
                specialtyPartId: cbSpecialityString,
              })
              .then((res) => {
                toast.success(res.data.message);
                navigate("/login");
              })
              .catch((err) => toast.error(err.response.data.message));
          }
        }
      })

      .catch((err) => toast.error(err.response.data.message));
  };

  useEffect(() => {
    if (currentStep == 2 && pillsNum == (100 / 3) * 2) {
      fetchOfficeList();
      serviceLanguageList();
      fetchCountryList();
    }
    if (currentStep == 3 && pillsNum == (100 / 3) * 3) {
      serviceCostInfo();
      fetchSpecialityPartdata();
    }
  }, [currentStep, pillsNum, stepOne]);

  return (
    <>
      <div className="page-container row-fluid container-fluid login-wrapper">
        <div
          className="wrapper main-wrapper row sign-up"
          style={{ paddingLeft: "380px" }}
        >
          <div className="row">
            <div className="col-lg-9">
              <section className="box ">
                <header className="panel_header">
                  <h2 className="title pull-left">Sign Up</h2>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12">
                      <form id="commentForm" noValidate="novalidate">
                        <div id="pills" className="wizardpills">
                          <ul className="form-wizard nav nav-pills">
                            <li
                              className={`${
                                currentStep == 1 ||
                                currentStep == 2 ||
                                currentStep == 3 ||
                                pillsNum === 100 / 3
                                  ? "complete active"
                                  : ""
                              }`}
                            >
                              <a
                                role="button"
                                className="pe-none"
                                data-toggle="tab"
                              >
                                <span>Basic</span>
                              </a>
                            </li>
                            <li
                              className={`col-sm-push-1 col-xs-push-1 ${
                                currentStep == 2 ||
                                currentStep == 3 ||
                                pillsNum === (100 / 3) * 2
                                  ? "complete active"
                                  : ""
                              }`}
                            >
                              <a
                                role="button"
                                className="pe-none"
                                data-toggle="tab"
                              >
                                <span>Office</span>
                              </a>
                            </li>
                            <li
                              className={`col-xs-push-2 ${
                                currentStep == 3 || pillsNum === (100 / 3) * 3
                                  ? "complete active"
                                  : ""
                              }`}
                            >
                              <a
                                role="button"
                                className="pe-none"
                                data-toggle="tab"
                              >
                                <span>Service</span>
                              </a>
                            </li>
                          </ul>
                          <div id="bar" className="progress active">
                            <div
                              className="progress-bar progress-bar-primary "
                              role="progressbar"
                              aria-valuenow="0"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: pillsNum + "%" }}
                            ></div>
                          </div>

                          <div className="tab-content">
                            <div
                              className={`tab-pane ${
                                pillsNum == 100 / 3 ||
                                (currentStep == 1 && stepTwo == true)
                                  ? "active"
                                  : ""
                              }`}
                              id="pills-tab1"
                            >
                              <h4>Basic Information</h4>
                              <br />
                              <div className="form-group">
                                <label className="control-label">
                                  Email <span className="required"> * </span>
                                </label>

                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter Email"
                                    className="form-control"
                                    name="email"
                                    id="txtEmail"
                                    value={step1 && step1.email}
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  Mobile Number{" "}
                                  <span className="required"> * </span>
                                </label>

                                <div className="controls">
                                  <input
                                    type="number"
                                    placeholder="Enter Mobile Number"
                                    className="form-control"
                                    name="mobNo"
                                    id="txtMobNo"
                                    value={step1 && step1.mobNo}
                                    maxLength="10"
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  {t("First name")}{" "}
                                  <span className="required"> * </span>
                                </label>

                                <div className="controls">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    id="txtFirstName"
                                    value={step1 && step1.firstName}
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  {t("Last name")}{" "}
                                  <span className="required"> * </span>
                                </label>

                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter Last Name"
                                    className="form-control"
                                    name="lastName"
                                    id="txtLastName"
                                    value={step1 && step1.lastName}
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  User Name{" "}
                                  <span className="required"> * </span>
                                </label>

                                <div className="controls">
                                  <input
                                    type="text"
                                    placeholder="Enter User Name"
                                    className="form-control"
                                    name="userName"
                                    id="txtUserName"
                                    value={step1 && step1.userName}
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="control-label">
                                  {t("Password")}{" "}
                                  <span className="required"> * </span>
                                </label>

                                <div className="controls d-flex">
                                  <input
                                    type={passwordShown ? "text" : "password"}
                                    placeholder="Enter Password"
                                    className="form-control"
                                    name="password"
                                    id="txtPassword"
                                    value={step1 && step1.password}
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                  <span
                                    className="input-group-text bg-transparent cursor-pointer"
                                    onClick={showPwd}
                                  >
                                    <i
                                      className={
                                        passwordShown
                                          ? "fa fa-eye"
                                          : "fa fa-eye-slash"
                                      }
                                    ></i>
                                  </span>
                                </div>
                                <p
                                  className="mb-0 pb-0 mx-1 fw-semibold"
                                  style={{ fontSize: "11px", color: "gray" }}
                                >
                                  *
                                  {t(
                                    "At least 8 characters : uppercase, lowercase, numeric, or special characters"
                                  )}
                                  .
                                </p>
                              </div>

                              <div className="form-group">
                                <label className="control-label">
                                  Confirm Password{" "}
                                  <span className="required"> * </span>
                                </label>

                                <div className="controls d-flex">
                                  <input
                                    type={
                                      confirmPasswordShown ? "text" : "password"
                                    }
                                    placeholder="Enter Confirm Password"
                                    className="form-control"
                                    name="confirmPassword"
                                    id="txtPassword"
                                    value={step1 && step1.confirmPassword}
                                    onChange={(e) => onchangeValue(e, 1)}
                                  />
                                  <span
                                    className="input-group-text bg-transparent cursor-pointer"
                                    onClick={showConfirmPwd}
                                  >
                                    <i
                                      className={
                                        confirmPasswordShown
                                          ? "fa fa-eye"
                                          : "fa fa-eye-slash"
                                      }
                                    ></i>
                                  </span>
                                </div>
                                <p
                                  className="mb-0 pb-0 mx-1 fw-semibold"
                                  style={{ fontSize: "11px", color: "gray" }}
                                ></p>
                              </div>
                            </div>
                          </div>

                          <div className="tab-content">
                            <div
                              className={`tab-pane ${
                                pillsNum == (100 / 3) * 2 || currentStep == 2
                                  ? "active"
                                  : ""
                              }`}
                              id="pills-tab2"
                            >
                              <h4>Office Information</h4>
                              <br />
                              <div className="form-group">
                                <label className="control-label">
                                  Select Office
                                </label>
                                <span className="desc"></span>
                                <Select
                                  name="officeList"
                                  value={
                                    officeOptions &&
                                    officeOptions.filter(
                                      ({ value }) => value == officeId
                                    )
                                  }
                                  options={officeOptions}
                                  onChange={onOfficeChange}
                                  placeholder={t("Select")}
                                />

                                {isExisting ? (
                                  <div className="padding-bottom-30 d-flex justify-content-center mt-4">
                                    <div className="text-left">
                                      <button
                                        type="button"
                                        className="btn btn-info gradient-blue1"
                                        onClick={handleClear}
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {isExisting ? (
                                ""
                              ) : (
                                <>
                                  <h4 className="text-center">OR</h4>
                                  <div className="form-group">
                                    <label className="control-label">
                                      {t("Office")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="text"
                                        placeholder="Enter Office Name"
                                        className="form-control"
                                        name="ofcName"
                                        id="txtOfcName"
                                        value={step2 && step2.ofcName}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="control-label">
                                      Phone No.{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="number"
                                        placeholder="Enter Phone No"
                                        className="form-control"
                                        name="phnNo"
                                        id="txtPhnNo"
                                        value={step2 && step2.phnNo}
                                        maxLength="10"
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="control-label">
                                      Office Email{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="email"
                                        placeholder="Enter Office Email"
                                        className="form-control"
                                        name="ofcEmail"
                                        id="txtOfcEmail"
                                        value={step2 && step2.ofcEmail}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="control-label">
                                      Business User Name{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="text"
                                        placeholder="Enter Business User Name"
                                        className="form-control"
                                        name="businessUserName"
                                        id="txtBusinessUserName"
                                        value={step2 && step2.businessUserName}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="control-label">
                                      License No.
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="number"
                                        placeholder="Enter License No."
                                        className="form-control"
                                        name="licenseNo"
                                        id="txtLicenseNo"
                                        value={step2 && step2.licenseNo}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="d-flex">
                                      <label className="control-label">
                                        NPI1
                                      </label>
                                    </div>
                                    <div className="controls">
                                      <input
                                        type="number"
                                        placeholder="Enter NPI1"
                                        className="form-control"
                                        name="npi1"
                                        id="txtNpi1"
                                        value={step2 && step2.npi1}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                  <GoogleMapHelpImage
                                    showMapImage={showMapImage}
                                    setShowMapImage={setShowMapImage}
                                  />
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Google Map Link")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12 d-flex">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="googleMapLink"
                                        value={step2.googleMapLink}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />

                                      <button
                                        className="btn mx-1"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setShowMapImage(true);
                                        }}
                                      >
                                        ?
                                      </button>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Office Address
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="text"
                                        placeholder="Enter Office Address"
                                        className="form-control"
                                        name="ofcAddress"
                                        id="txtOfcAddress"
                                        value={step2 && step2.ofcAddress}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Country")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <Select
                                        name="countryList"
                                        value={
                                          countryOptions &&
                                          countryOptions.filter(
                                            ({ value }) =>
                                              value == step2.countryId
                                          )
                                        }
                                        options={countryOptions}
                                        onChange={onCountryChange}
                                        placeholder={t("Select")}
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("State")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <Select
                                        name="stateList"
                                        value={
                                          stateOptions &&
                                          stateOptions.filter(
                                            ({ value }) =>
                                              value == step2.stateId
                                          )
                                        }
                                        options={stateOptions}
                                        onChange={onStateChange}
                                        placeholder={t("Select")}
                                        isDisabled={
                                          !step2.countryId ? true : false
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("City")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <Select
                                        name="cityList"
                                        value={
                                          cityOptions &&
                                          cityOptions.filter(
                                            ({ value }) => value == step2.cityId
                                          )
                                        }
                                        options={cityOptions}
                                        onChange={onCityChange}
                                        placeholder={t("Select")}
                                        isDisabled={
                                          !step2.stateId ? true : false
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Zip Code")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="zip"
                                        value={step2.zip}
                                        placeholder="Enter Zip Code"
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>

                                  {/* <div className="form-group">
                                                                        <div className="d-flex">
                                                                            <label className="control-label">Business Category</label>
                                                                        </div>

                                                                        <div className="controls">
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Business Category"
                                                                                className="form-control"
                                                                                name="businessCategory"
                                                                                id="txtBusinessCategory"
                                                                                value={step2 && step2.businessCategory}
                                                                                onChange={(e) => onchangeValue(e, 2)}
                                                                            />
                                                                        </div>
                                                                    </div> */}

                                  <div className="form-group">
                                    <label className="control-label">
                                      Service Language
                                      <span className="required"> * </span>
                                    </label>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {serviceLangList &&
                                        serviceLangList.map((item, i) => {
                                          return (
                                            <div className="controls" key={i}>
                                              <input
                                                type="checkbox"
                                                className=""
                                                id={item.id}
                                                name="serviceLang"
                                                onChange={(e) =>
                                                  onchangeValue(
                                                    e,
                                                    2,
                                                    item.id,
                                                    FORM_TYPE.checkbox
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={item.id}
                                                className="ml-5 mr-15"
                                              >
                                                {item.name}
                                              </label>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Verifier Applied")}
                                    </label>

                                    <div className="col-md-12">
                                      <input
                                        type="radio"
                                        className="mx-2"
                                        name="verifierApplied"
                                        value="1"
                                        checked={
                                          step2 && step2.verifierApplied == 1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                      <label className="ml-3 mr-15">Yes</label>
                                      <input
                                        type="radio"
                                        className="mx-2"
                                        name="verifierApplied"
                                        value="0"
                                        checked={
                                          step2 && step2.verifierApplied == 0
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                      <label className="ml-3 mr-15">No</label>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="tab-content">
                            <div
                              className={`tab-pane ${
                                pillsNum == (100 / 3) * 3 && currentStep == 3
                                  ? "active"
                                  : ""
                              }`}
                              id="pills-tab3"
                            >
                              <h4>Service Items & Cost Information</h4>
                              <br />
                              <div className="form-group">
                                {treatmentData &&
                                  treatmentData.map((item, index) => {
                                    return (
                                      <Fragment>
                                        <div
                                          className="row align-items-center my-2 border"
                                          key={index}
                                        >
                                          <div className="col-1">
                                            <button
                                              className="btn transparent"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#treatmentGroup${index}`}
                                              aria-expanded="false"
                                              aria-controls={`treatmentGroup${index}`}
                                              onClick={(e) =>
                                                handleOpenClose(e, index)
                                              }
                                            >
                                              {!trmtGrpOpen[index] ? (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  // fill="currentColor"
                                                  className="bi bi-plus-square-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                                </svg>
                                              ) : (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-dash-square-fill"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                                                </svg>
                                              )}
                                            </button>
                                          </div>
                                          <div className="col-3">
                                            <h5 className="mt-2 fw-bold fs-6">
                                              {item.treatmentTypeGroup ==
                                                "undefined" ||
                                              item.treatmentTypeGroup ==
                                                undefined ||
                                              item.treatmentTypeGroup ==
                                                "No-Group"
                                                ? ""
                                                : item.treatmentTypeGroup.toUpperCase()}
                                            </h5>
                                          </div>
                                          <div className="col-2 d-flex justify-content-center">
                                            <h5 className=" mt-2 fw-bold">
                                              {t("Original Fee")}
                                            </h5>
                                          </div>
                                          <div className="col-2 d-flex justify-content-center">
                                            <h5 className=" mt-2 fw-bold">
                                              {t("Discount Fee")}
                                            </h5>
                                          </div>
                                          <div className="col-2 d-flex justify-content-center">
                                            <h5 className=" mt-2 fw-bold">
                                              {t("Discount Rate")}
                                            </h5>
                                          </div>
                                          <div className="col-2"></div>
                                        </div>
                                        {item.data.map((item1, i) => (
                                          <div
                                            className={`collapse ${
                                              index < 4 ? "show" : ""
                                            }`}
                                            id={`treatmentGroup${index}`}
                                          >
                                            <div className="row my-1" key={i}>
                                              <div className="col-1"></div>
                                              <div className="col-3 my-2">
                                                {item1.name}
                                              </div>
                                              <div className="col-2">
                                                <input
                                                  id={item1.id}
                                                  type="text"
                                                  className="form-control my-1"
                                                  name={item1.name}
                                                  value={item1.originalFee}
                                                  min={0}
                                                  max={20000}
                                                  maxLength={7}
                                                  onChange={(e) =>
                                                    handleOriginalFeeChange(
                                                      e,
                                                      item1.id,
                                                      item1.treatmentTypeGroup
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-2">
                                                <input
                                                  id={item1.id}
                                                  name={item1.name}
                                                  type="text"
                                                  className="form-control my-1"
                                                  min={0}
                                                  max={20000}
                                                  maxLength={7}
                                                  value={item1.discountFee}
                                                  onChange={(e) =>
                                                    handleDiscountFeeChange(
                                                      e,
                                                      item1.id,
                                                      item1.treatmentTypeGroup
                                                    )
                                                  }
                                                />
                                              </div>
                                              {/* Discount Rate Dropdown */}
                                              <div className="btn-group col-2 mt-1">
                                                <button
                                                  id={item1.id}
                                                  className="btn btn-secondary dropdown-toggle w-100"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  {`${item1.discountRate}%`}
                                                </button>
                                                <div className="dropdown-menu">
                                                  <button
                                                    name="30%"
                                                    value={30}
                                                    className="dropdown-item"
                                                    type="button"
                                                    onClick={(e) => {
                                                      handleCustomRateChange(
                                                        e,
                                                        item1.id,
                                                        item1.treatmentTypeGroup
                                                      );
                                                    }}
                                                  >
                                                    30%
                                                  </button>
                                                  <button
                                                    name="40%"
                                                    value={40}
                                                    className="dropdown-item"
                                                    type="button"
                                                    onClick={(e) => {
                                                      handleCustomRateChange(
                                                        e,
                                                        item1.id,
                                                        item1.treatmentTypeGroup
                                                      );
                                                    }}
                                                  >
                                                    40%
                                                  </button>
                                                  <button
                                                    name="50%"
                                                    value={50}
                                                    className="dropdown-item"
                                                    type="button"
                                                    onClick={(e) => {
                                                      handleCustomRateChange(
                                                        e,
                                                        item1.id,
                                                        item1.treatmentTypeGroup
                                                      );
                                                    }}
                                                  >
                                                    50%
                                                  </button>
                                                  <input
                                                    name="custom-rate"
                                                    type="text"
                                                    className="dropdown-item"
                                                    placeholder="Custom (%)"
                                                    onChange={(e) => {
                                                      handleCustomRateChange(
                                                        e,
                                                        item1.id,
                                                        item1.treatmentTypeGroup
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* <div className="col-2">
                                                <input
                                                  type="text"
                                                  className="form-control my-1"
                                                  id={item1.id}
                                                  value={`${item1.discountRate}%`}
                                                  disabled
                                                />
                                              </div> */}
                                              <div className="col-2">
                                                <div className="row">
                                                  <div className="col-2">
                                                    <input
                                                      type="checkbox"
                                                      className="checkBox mt-2 mb-2"
                                                      id={
                                                        item1.id + "onceAYear"
                                                      }
                                                      value={item1.id}
                                                      onChange={(e) =>
                                                        handleCheckChange(
                                                          e,
                                                          item1.id,
                                                          item1.treatmentTypeGroup
                                                        )
                                                      }
                                                      checked={
                                                        item1.isFreeOnceYear ==
                                                          "1" ||
                                                        item1.isFreeOnceYear ==
                                                          1 ||
                                                        item1.isFreeOnceYear ==
                                                          true
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  </div>

                                                  <label
                                                    htmlFor={
                                                      item1.id + "onceAYear"
                                                    }
                                                    className="col-9 my-1"
                                                  >
                                                    {t("Free Once A Year")}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </Fragment>
                                    );
                                  })}
                                <div className="col-12 my-4">
                                  <div className="col-4">
                                    <h5 className="mx-5 mt-2 fw-bold">
                                      {"speciality parts in dentist".toUpperCase()}
                                    </h5>
                                  </div>

                                  <hr
                                    className="my-3"
                                    style={{
                                      border: "1px solid",
                                      height: "0.1rem",
                                    }}
                                  />
                                  <div className="row d-flex align-items-center">
                                    {specialityPartList &&
                                      specialityPartList.map((item, i) => {
                                        return (
                                          <Fragment key={i}>
                                            <div className="col-1 text-end">
                                              <input
                                                type="checkbox"
                                                className="checkBox"
                                                id={item.id + "speciality"}
                                                value={item.id}
                                                name={item.id}
                                                onChange={(e) =>
                                                  handleSpecialityCheckChange(
                                                    e,
                                                    item.id
                                                  )
                                                }
                                              />
                                            </div>
                                            <label
                                              htmlFor={item.id + "speciality"}
                                              className="col-3 my-1"
                                            >
                                              {item.name}
                                            </label>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="clearfix"></div>

                            <ul className="pager wizard">
                              {pillsNum == 100 / 3 ? (
                                ""
                              ) : (
                                <li className="previous">
                                  <a role="button">
                                    <div
                                      style={{
                                        borderRadius: "inherit",
                                        background: "none",
                                        color: "#4f9df8",
                                        padding: "3px",
                                      }}
                                      onClick={(e) => handlePrevious(e)}
                                    >
                                      {t("Previous")}
                                    </div>
                                  </a>
                                </li>
                              )}

                              <li
                                className={`cancle ${
                                  pillsNum == 100 / 3 ? "ms-5" : "mr-15"
                                }`}
                              >
                                <a role="button">
                                  <div
                                    style={{
                                      borderRadius: "inherit",
                                      background: "none",
                                      color: "#4f9df8",
                                      padding: "3px",
                                    }}
                                    onClick={(e) => navigate("/")}
                                  >
                                    Cancel
                                  </div>
                                </a>
                              </li>

                              <li className="next">
                                <a role="button">
                                  <div
                                    style={{
                                      borderRadius: "inherit",
                                      background: "none",
                                      color: "#4f9df8",
                                      padding: "3px",
                                    }}
                                    onClick={(e) => handleNext(e)}
                                  >
                                    {pillsNum == (100 / 3) * 3 || isExisting
                                      ? "Finish"
                                      : t("Next")}
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorSignUp;
