import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import $ from "jquery";

const AdminSidebar = (props) => {
    const { t } = useTranslation();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCollapse = () => {
        if (windowSize.width < 777) {
            $(".page-sidebar").addClass("collapseit").removeClass("expandit");
        }
    };

    return (
        <>
            <div className="page-sidebar fixedscroll">
                <div className="page-sidebar-wrapper" id="main-menu-wrapper">
                    <ul className="wraplist">
                        <li className="menusection">{t("Admin")}</li>
                        {(props.pageAccess && props.pageAccess.includes("Dashboard")) || props.pageAccess.length == 0 ? (
                            <li className="open" onClick={handleCollapse}>
                                <Link to="/dashboard">
                                    <i
                                        className="fa fa-dashcube"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Dashboard")}</span>
                                </Link>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-doctor`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-user-md"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Doctor")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-doctor">{t("All Doctors")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-office`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i className="img">
                                        <i
                                            className="fa fa-hospital-o "
                                            style={{
                                                fontSize: "18px",
                                                color: "rgb(77 108 143)",
                                                top: "2px",
                                            }}
                                        ></i>
                                    </i>
                                    <span className="title">{t("Office")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-office">{t("All Office")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-patient`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i className="img">
                                        <i
                                            className="fa fa-user-plus"
                                            style={{
                                                fontSize: "18px",
                                                color: "rgb(77 108 143)",
                                                top: "2px",
                                            }}
                                        ></i>
                                    </i>
                                    <span className="title">{t("Patient")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-patient">{t("All Patient")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-gdental-patient`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-wheelchair"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("G-Dental Patient")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-gdental-patient">{t("All G-Dental Patients")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes("Gdental-membership-plans")) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-credit-card-alt"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("G-Denticare Plans")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/g-dental-membership-plans">{t("All Plans")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-order-history`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-history"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Order History")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-order-history">{t("All Orders")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-claim`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa-regular fa-address-card"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Claim")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-claim">{t("All Claim")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-claim-verify`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-envelope-o"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Claim Verify")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-claim-verify">{t("All Claim Verify")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-evaluation`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-star-o"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Evaluation")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-evaluation">{t("All Evaluation")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes("Search-dental")) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-search-plus"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Search Dental")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/search-dental">{t("All Search Dental")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-dental-compare`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-star"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Dental Compare")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-dental-compare">{t("Dental Compare List")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-employee`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa-solid fa-users"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Employee")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-employee">{t("All Employee")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-agency`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-building"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Agency")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-agency">{t("All Agency")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-revenue-agent">{t("Agent Revenue List")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-agent`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-user-secret"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Agent")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-agent">{t("All Agent")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-revenue-patient">{t("Patient Revenue List")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-manager`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-user-circle"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Manager")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-manager">{t("All Manager")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-settlement`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-puzzle-piece"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Settlement")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-settlement">{t("All Settlement")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}

                        {(props.pageAccess && props.pageAccess.includes(`Manage-verifier`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-check-circle"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Verifier")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-verifier">{t("All Verifiers")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {/* {(props.pageAccess && props.pageAccess.includes(`Manage-office-employee`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-briefcase"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Office-Employee")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-office-employee">{t("All Office-Employees")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )} */}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-sns`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-link"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("SNS")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-sns">{t("Manage GDenti")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {/* {(props.pageAccess && props.pageAccess.includes(`Manage-zoom-meeting`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-video-camera"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Zoom-Meeting")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-zoom-meeting">{t("Manage Zoom-Meeting")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )} */}
                        {(props.pageAccess && props.pageAccess.includes(`Manage-W9Form`)) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-file-text"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(77 108 143)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("W9 Form")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-w9form">{t("Manage W9 Form")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        {(props.pageAccess && props.pageAccess.includes("Configuration")) || props.pageAccess.length == 0 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i className="img">
                                        <i
                                            className="fa fa-folder-open"
                                            style={{
                                                fontSize: "15px",
                                                color: "rgb(77 108 143)",
                                                top: "2px",
                                            }}
                                        ></i>
                                    </i>
                                    <span className="title">{t("Configuration")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-news">{t("News")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/speciality-part">{t("Speciality Parts")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/language">{t("Language")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/evaluation">{t("Evaluation")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/treatment">{t("Treatment")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/treatment-type">{t("Treatment Type")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/recommender">{t("Recommender")}</Link>
                                    </li>
                                    <li onClick={handleCollapse}>
                                        <Link to="/country">
                                            {t("Country")}/{t("State")}/{t("City")}
                                        </Link>
                                    </li>
                                    <li className="cursor-pointer">
                                        <a>
                                            <span className="title">{t("Manage Role")} </span>
                                            <span className="arrow "></span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li onClick={handleCollapse}>
                                                <Link to="/role">{t("Role")}</Link>
                                            </li>
                                            <li onClick={handleCollapse}>
                                                <Link to="/page">{t("Page")}</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AdminSidebar;
