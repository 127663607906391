import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, STATUS_SEARCH, ROLE_ID, STATUS } from "../../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, getPreviousYear, monthDateYear } from "../../../helper/dateFormates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DetailAgentRevenueList from "../manageAgent/DetailAgentRevenueList";
import { useTranslation } from "react-i18next";

const AgencyRevenueList = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");
  const { profileData } = useOutletContext();

  const { t } = useTranslation();

  const [agentList, setAgentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState({
    searchAgentName: "",
    statusSearch: "",
    statusId: "",
    fromDate: getPreviousYear(new Date()),
    toDate: new Date(),
  });
  const [agencyId, setAgencyId] = useState("");
  const [agenctList, setAgenctList] = useState([]);

  let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENCY ? "agency" : "";

  const fetchAgentdata = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/agency/agent/revenue/list?isPagination=true`,
        {
          page: offset,
          rowsPerPage: ROWS_PER_PAGE,
          searchAgentName: filterValue.searchAgentName,
          statusId: filterValue.statusSearch,
          isGDenticareAgent: true,
          statusId: filterValue.statusId,
          agencyId: profileData.roleId == ROLE_ID.AGENCY ? profileData.id : agencyId,
          fromDate: dateAPIFormate(filterValue.fromDate),
          toDate: dateAPIFormate(filterValue.toDate),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        fetchPatientData(res.data.data);
        setAgentList(res.data.data);
        setPageCount(res.data.extraData.pageCount);
        setRowCount(res.data.extraData.rowCount);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
    setCurrentPage(selectedPage);
  };

  const onFilterChangeValue = (e) => {
    const { name, value } = e.target;
    setFilterValue({ ...filterValue, [name]: value });
  };

  const [patientData, setPatientData] = useState([]);

  const fetchPatientData = async (agentList) => {
    setIsLoading(true);
    const patientData = await Promise.all(
      agentList.map(async (agent) => {
        const patient = await axios.post(
          `${baseURL}/api/v1/agency/agent/patient/revenue/list?isPagination=true`,
          {
            page: offset,
            rowsPerPage: ROWS_PER_PAGE,
            agentId: agent.id,
            isFromAgentRevenueList: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              lang: languageShortName,
            },
          }
        ).catch((err) => {
          if (err.response) {
            if (err.response.status == 500 || err.response.status == 401) {
              localStorage.clear();
              sessionStorage.clear();
              navigate("/login");
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
        return {
          ...agent,
          patients: patient.data.data || null,
        };
      })
    );

    setPatientData(patientData);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      fetchAgentdata();
      setOffset(1);
      setCurrentPage(0);
    }
  };

  useEffect(() => {
    fetchAgentdata();
  }, [offset, filterValue.fromDate, filterValue.toDate, filterValue.statusSearch, filterValue.statusId, agencyId]);

  useEffect(() => {
    // if (profileData.roleId == ROLE_ID.ADMIN) {}
  }, []);

  return (
    <>
      <div className="col-xs-12">
        <div className="page-title">
          <div className="pull-left">
            <h1 className="title">{t("Manage Agency Revenue")}</h1>
          </div>

          <div className="pull-right">
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard">
                  <i className="fa fa-home"></i>
                  {t("Home")}
                </Link>
              </li>
              <li >
                <strong>{t("Agency Revenue")}</strong>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <section className="box1">
            <header className="panel_header">
              <div className="mx-3">
                <div className="row ">
                  <div className="col-lg-3 col-md-6 col-sm-12 title bold">
                    {t("Agency")} : {profileData && profileData.userName}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 title bold">
                    {t("Total")} {t("Agency Revenue")}: ${patientData && patientData.reduce((acc, cur) => acc + cur.agencyRevenue, 0).toLocaleString("en-US")}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 title bold">
                    {t("Total")} {t("Agent Revenue")}: ${patientData && patientData.reduce((acc, cur) => acc + cur.revenue, 0).toLocaleString("en-US")}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 title">
                    <input
                      className="form-control"
                      name="searchAgentName"
                      value={filterValue.searchAgentName}
                      onChange={onFilterChangeValue}
                      onKeyDown={onKeyDown}
                      type="search"
                      placeholder={t("search name")}
                    />
                  </div>
                </div>
              </div>
            </header>
            <div className="content-body">
              <div className="row">
                <div className="col-xs-12">
                  <div className="table-responsive" data-pattern="priority-columns">
                    <table
                      id="tech-companies-1"
                      className={`table vm table-small-font no-mb table-bordered ${isLoading || !patientData || (patientData && !patientData.length) ? "" : "table-striped"
                        } table-hover`}
                    >
                      <thead>
                        <tr>
                          <th>{t("Created Date")}</th>
                          <th>{t("Agent")}</th>
                          <th>{t("Patient")}</th>
                          <th>{t("Patient Plan")}</th>
                          <th>{t("Agency Revenue")}</th>
                          <th>{t("Agent Revenue")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!patientData || (patientData && !patientData.length && !isLoading) ? (
                          <NoDataFound />
                        ) : isLoading ? (
                          <Loader />
                        ) : (
                          patientData &&
                          patientData.map((item, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>
                                    <h6 style={{ fontWeight: 'bold' }}>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                  </td>

                                  <td>
                                    <h6 style={{ fontWeight: 'bold' }}>{item.firstName ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) : "-"}</h6>
                                  </td>
                                  <td>
                                    <h6 style={{ fontWeight: 'bold' }}>{item.totalActivePatient ? `Total: ${item.totalActivePatient}` : `Total: 0`}</h6>
                                  </td>
                                  <td>
                                  </td>
                                  <td>
                                    <h6 style={{ fontWeight: 'bold' }}>{item.agencyRevenue ? `Total: $${item.agencyRevenue.toLocaleString("en-US")}` : `Total: 0`}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 style={{ fontWeight: 'bold' }}>
                                      {item.revenue ? `Total: $${item.revenue.toLocaleString("en-US")}` : `Total: 0`}
                                    </h6>
                                  </td>
                                </tr>
                                {
                                  item.patients ? item.patients.map((patient, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <h6>{patient.created_at ? monthDateYear(patient.created_at) : "-"}</h6>
                                        </td>
                                        <td></td>
                                        <td>
                                          <h6>{patient.firstName ? (patient.firstName && patient.firstName) + " " + (patient.lastName && patient.lastName) : "-"}</h6>
                                        </td>
                                        <td>
                                          <h6>{patient.name ? patient.name : "-"}</h6>
                                        </td>
                                        <td>
                                          <h6>${patient.agencyRevenue ? patient.agencyRevenue.toLocaleString("en-US") : 0}</h6>
                                        </td>
                                        <td>
                                          <h6>${patient.revenue ? patient.revenue.toLocaleString("en-US") : 0}</h6>
                                        </td>
                                      </tr>
                                    );
                                  }) :
                                    <tr key={i}>
                                      <td>
                                      </td>
                                      <td></td>
                                      <td>
                                        <h6 style={{ fontWeight: 'bold' }}>

                                          {"NO PATIENTS FOUND"}
                                        </h6>
                                      </td>
                                      <td>
                                      </td>
                                      <td>
                                      </td>
                                      <td>
                                      </td>
                                    </tr>
                                }
                              </>
                            );

                          }
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-lg-flex justify-content-between ">
                    <div className="d-flex align-center">
                      {t("Total")} {agentList == null ? "0" : rowCount} {t("Agent")}
                      {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                    </div>
                    <div className="pagination-center">
                      <ReactPaginate
                        initialPage={0}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={t("Previous")}
                        nextLabel={t("Next")}
                        disableInitialCallback={true}
                        forcePage={currentPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AgencyRevenueList;