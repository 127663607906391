import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "../../../commoncomponents/Loader";
import { baseURL } from "../../../helper/Config";
import { MEMBERSHIP_STATUS, PAYMENT_TYPE, PLAN_TYPE, ROLE_ID, SUBSCRIPTION } from "../../../helper/Constant";

function JoinGDentalPlan(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const { profileData, fetchProfiledata } = useOutletContext();
    const [type, setType] = useState("");
    const [isSubscription, setIsSubscription] = useState(0);
    const [price, setPrice] = useState(0);
    const [comment, setComment] = useState("");
    const [showPayButton, setShowPayButton] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditLoader, setIsEditLoader] = useState(false);

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const onCommentChange = (e) => {
        setComment(e.target.value);
    };

    const onTypeChange = async (e) => {
        setType(e.target.value);
        setIsSubscription(e.target.value == PLAN_TYPE.Year || (props.isAdmin && e.target.value == PLAN_TYPE.Month) ? SUBSCRIPTION.Manual : isSubscription);
        if (e.target.value == PLAN_TYPE.Year || (props.isAdmin && e.target.value == PLAN_TYPE.Month)) {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/plan/check`,
                    {
                        type: e.target.value,
                        isSubscription: e.target.value == PLAN_TYPE.Year || (props.isAdmin && e.target.value == PLAN_TYPE.Month) ? SUBSCRIPTION.Manual : isSubscription,
                        patientId: props.patientId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setPrice(res.data.data.price);
                    setShowPayButton(true);
                })
                .catch((err) => {
                    if (err.response) {
                        setPrice(0);
                        setShowPayButton(false);
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            setPrice(0);
            setShowPayButton(false);
        }
    };

    const onSubscriptionChange = async (e) => {
        setIsSubscription(e.target.value);
        if (type) {
            await axios
                .post(
                    `${baseURL}/api/v1/patient/plan/check`,
                    {
                        type: type,
                        isSubscription: e.target.value,
                        patientId: props.patientId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setPrice(res.data.data.price);
                    setShowPayButton(true);
                })
                .catch((err) => {
                    if (err.response) {
                        setPrice(0);
                        setShowPayButton(false);
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            setPrice(0);
            setShowPayButton(false);
        }
    };

    const onPaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleClose = () => {
        if (!isLoading) {
            props.setShow(false);
            setType(1);
            setIsSubscription(1);
            setPrice(0);
            setShowPayButton(false);
        }
    };

    const handlePay = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (!isSubscription || isSubscription === "" || isSubscription === null) {
            toast.error(t(`Please select subscription`));
        } else if (!paymentMethod || paymentMethod === "" || paymentMethod === null || paymentMethod === 0) {
            toast.error(t(`Please select payment method`));
        }
        if (profileData.roleId == ROLE_ID.PATIENT) {
            setIsLoading(true);
            await axios
                .post(
                    `${baseURL}/api/v1/patient/create-checkout-session`,
                    {
                        type: type,
                        isSubscription: isSubscription,
                        paymentType: paymentMethod,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setIsLoading(false);
                    window.location.replace(res.data.data.url);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            toast.error(t(`Only Patient can buy plan`));
        }
    };

    const handleCashPay = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (profileData.roleId == ROLE_ID.ADMIN) {
            setIsLoading(true);
            Swal.fire({
                title: `${t("Your total amount to be paid is")} $${props.isRegisterPatient == 0 ? props.registrationFee + price : price}`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, proceed to pay!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/admin/patient/membership/payment`,
                            {
                                patientId: props.patientId,
                                type: type,
                                comment: comment,
                                // isSubscription: isSubscription,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            toast.success(res.data.message);
                            props.fetchPatientdata();
                            handleClose();
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                } else if (!result.isConfirmed || result.isConfirmed == false) {
                    setIsLoading(false);
                }
            });
        } else {
            toast.error(t(`Only admin can buy plan in cash`));
        }
    };

    const handleGPointPay = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (!isSubscription || isSubscription === "" || isSubscription === null) {
            toast.error(t(`Please select subscription`));
        } else if (!paymentMethod || paymentMethod === "" || paymentMethod === null || paymentMethod === 0) {
            toast.error(t(`Please select payment method`));
        } else {
            setIsLoading(true);
            Swal.fire({
                title: `${t("Your total amount to be paid is")} $${props.isRegisterPatient == 0 ? props.registrationFee + price : price}`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, proceed to pay!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/patient/gdental/payment`,
                            {
                                type: type,
                                isSubscription: isSubscription,
                                isAch: paymentMethod == PAYMENT_TYPE.ACH ? true : false,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success").then((res) => {
                                    if (res.isConfirmed) {
                                        props.fetchDashboardData();
                                        fetchProfiledata();
                                        handleClose();
                                        setIsLoading(false);
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    Swal.fire(err.response.data.message, " ", "warning");
                                    setIsLoading(false);
                                }
                            }
                        });
                } else if (!result.isConfirmed || result.isConfirmed == false) {
                    setIsLoading(false);
                }
            });
        }
    };

    const handleCashPlanChange = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (profileData.roleId == ROLE_ID.ADMIN) {
            setIsLoading(true);
            Swal.fire({
                title: `${t("Your total amount to be paid is")} $${props.isRegisterPatient == 0 ? props.registrationFee + price : price}`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, proceed to pay!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/admin/patient/membership/chage-plan`,
                            {
                                patientId: props.patientId,
                                type: type,
                                comment: comment,
                                isSubscription: isSubscription,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            toast.success(res.data.message);
                            props.fetchPatientdata();
                            handleClose();
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                } else if (!result.isConfirmed || result.isConfirmed == false) {
                    setIsLoading(false);
                }
            });
        } else {
            toast.error(t(`Only admin can buy plan in cash`));
        }
    };

    const planDetail = async () => {
        setIsEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/admin/membership-plan/detail/${props.patientId}/${props.planType}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setType(res.data.data.type);
                setIsSubscription(res.data.data.isSubscription);
                setPrice(res.data.data.price);
                // setComment(res.data.data.comment);
                //   setInputValue(res.data.data);
                setIsEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        // if (props.show) {
        // }
        if (props.show && (props.planType == PLAN_TYPE.Month || props.planType == PLAN_TYPE.Year)) {
            setShowPayButton(true);
            planDetail();
        }
    }, [props.show]);

    return (
        <div>
            <Modal show={props.show} onHide={handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Join G-Denticare plan")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isEditLoader ? (
                        <Loader />
                    ) : (
                        <div className="justify-content-center row my-3">
                            {props.isRegisterPatient == 0 ? (
                                <div className="form-group row">
                                    <label className="control-label col-md-12">
                                        {t("Registration Fee")} <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="registrationFee" value={`$ ${props.registrationFee}`} disabled />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="form-group row">
                                <div className="col-6">
                                    <div className="row">
                                        <label className="control-label col-md-12">
                                            {t("Membership Plan")} <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-12">
                                            <select
                                                className="form-control col-md-3"
                                                name="type"
                                                value={type}
                                                onChange={onTypeChange}
                                                // disabled={props.planType == PLAN_TYPE.Year ? "disabled" : ""}
                                            >
                                                {Object.keys(PLAN_TYPE).map((item, i) => {
                                                    return (
                                                        <option key={i} value={PLAN_TYPE[item]}>
                                                            {t(item)}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <label className="control-label col-md-12">{t("Price")}</label>

                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="price" value={`$${price ? price : 0}`} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {props.isAdmin ? (
                                <>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div className="row">
                                                <label className="control-label col-md-12">{t("Payment method")}</label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="cash" value="Cash" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div className="row">
                                                <label className="control-label col-md-12">{t("Comment")}</label>

                                                <div className="col-md-12">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="comment"
                                                        value={comment}
                                                        onChange={onCommentChange}
                                                        // disabled={props.planType == PLAN_TYPE.Year ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            {type == PLAN_TYPE.Month && !props.isAdmin ? (
                                <div className="form-group row">
                                    <label className="control-label col-md-12">
                                        {t("Subscription")} <span className="required"> * </span>
                                    </label>
                                    <div className="col-md-12">
                                        <input
                                            className="my-2 mx-3"
                                            type="radio"
                                            name="isSubscription"
                                            value="2"
                                            onChange={onSubscriptionChange}
                                            // disabled={props.planType == PLAN_TYPE.Year ? "disabled" : ""}
                                        />
                                        {t("Auto")}
                                        <input
                                            className="my-2 mx-3"
                                            type="radio"
                                            name="isSubscription"
                                            value="1"
                                            onChange={onSubscriptionChange}
                                            // disabled={props.planType == PLAN_TYPE.Year ? "disabled" : ""}
                                        />
                                        {t("Manual")}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {props.isAdmin ? (
                                ""
                            ) : (
                                <div className="form-group row">
                                    <label className="control-label col-md-12">
                                        {t("Payment")} <span className="required"> * </span>
                                    </label>
                                    <div className="col-md-12">
                                        <input className="my-2 mx-3" type="radio" name="paymentMethod" value={PAYMENT_TYPE.Card} onChange={onPaymentMethodChange} />
                                        Debit/Credit Card
                                        {/* <input className="my-2 mx-3" type="radio" name="paymentMethod" value={PAYMENT_TYPE["G-Point"]} onChange={onPaymentMethodChange} />
                                        {t("Pay With G-Point")} */}
                                        <input className="my-2 mx-3" type="radio" name="paymentMethod" value={PAYMENT_TYPE.STRIPE_ACH} onChange={onPaymentMethodChange} />
                                        <span>ACH</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {showPayButton ? (
                        <>
                            {props.planType && props.planType == type && props.gDenticarePlanStatusId == MEMBERSHIP_STATUS.Active ? (
                                <Button variant="success" onClick={isLoading ? "" : handleCashPlanChange}>
                                    {" "}
                                    {isLoading ? (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{
                                                position: "relative",
                                            }}
                                        ></i>
                                    ) : (
                                        <>
                                            {t("Advance")} {t("Pay")}
                                        </>
                                    )}
                                </Button>
                            ) : props.gDenticarePlanStatusId == MEMBERSHIP_STATUS.Active ? (
                                <Button variant="success" onClick={isLoading ? "" : handleCashPlanChange}>
                                    {" "}
                                    {isLoading ? (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{
                                                position: "relative",
                                            }}
                                        ></i>
                                    ) : (
                                        <>
                                            {`${t("Change")} &`} {t("Pay")}
                                        </>
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    variant="success"
                                    onClick={
                                        isLoading
                                            ? ""
                                            : props.isAdmin
                                            ? handleCashPay
                                            : paymentMethod == PAYMENT_TYPE.Card || paymentMethod == PAYMENT_TYPE.STRIPE_ACH
                                            ? handlePay
                                            : // : paymentMethod == PAYMENT_TYPE["G-Point"] || paymentMethod == PAYMENT_TYPE.ACH
                                              // ? handleGPointPay
                                              ""
                                    }
                                >
                                    {" "}
                                    {isLoading ? (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{
                                                position: "relative",
                                            }}
                                        ></i>
                                    ) : (
                                        t("Pay")
                                    )}
                                </Button>
                            )}
                        </>
                    ) : (
                        ""
                    )}
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                        {props.type}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default JoinGDentalPlan;
