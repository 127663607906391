import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../../helper/Config";
import ReactPaginate from "react-paginate";
import { PAGE_AUTHORITY_ROLE, PAGE_AUTHORITY_ROLE_SEARCH, ROWS_PER_PAGE, STATUS, STATUS_SEARCH } from "../../../../helper/Constant";
import Swal from "sweetalert2";
import Loader from "../../../../commoncomponents/Loader";
import NoDataFound from "../../../../commoncomponents/NoDataFound";
import UpsertRole from "./UpsertRole";
import UpsertRolePage from "./UpsertRolePage";
import { useTranslation } from "react-i18next";

function RoleList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [roleList, setRoleList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [isPageShow, setIsPageshow] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [show, setShow] = useState(false);
    const [inputValue, setInputValue] = useState({
        name: "",
        mainRoleId: 1,
        statusId: 1,
    });
    const [roleId, setRoleId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        mainRoleSearch: "",
        statusSearch: "",
    });
    const [pageList, setPageList] = useState([]);

    const fetchRoledata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/role/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    name: filterValue.nameSearch,
                    statusId: filterValue.statusSearch,
                    mainRoleId: filterValue.mainRoleSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setRoleList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setIsEdit(true);
        setRoleId(id);
        roleDetail(id);
        setShow(true);
    };

    const handlePageAdd = (parentId, title, roleId) => {
        setIsPageshow(true);
        setPageTitle(title);
        setRoleId(roleId);
        fetchPagedata(parentId, roleId);
    };

    const handlePageClose = () => {
        setIsPageshow(false);
        setPageTitle("");
        setRoleId("");
        setPageList([]);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchRoledata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const roleDetail = async (roleId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/admin/role/detail/${roleId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            name: "",
            mainRoleId: 1,
            statusId: 1,
        });
        setRoleId("");
        setShow(false);
        setIsEdit(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this role ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/admin/role/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchRoledata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const fetchPagedata = async (parentId, roleId) => {
        setEditLoader(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/page/list?isPagination=false`,
                {
                    mainRoleId: parentId,
                    roleId: roleId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setPageList(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleCheck = (e) => {
        const { checked } = e.target;
        setPageList((value) => value?.map((data) => (data.id == e.target.value ? { ...data, isPageAuthority: checked ? 1 : 0 } : data)));
    };

    useEffect(() => {
        fetchRoledata();
    }, [offset, filterValue.statusSearch, filterValue.mainRoleSearch]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Role List")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>{t("Role List")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <section className="box">
                        <header className="panel_header">
                            <div className="mx-3">
                                <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-12">
                                        <h2 className="title pull-left">
                                            <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                            </button>
                                            <UpsertRole
                                                editLoader={editLoader}
                                                handleClose={handleClose}
                                                inputValue={inputValue}
                                                onChangeValue={onChangeValue}
                                                show={show}
                                                setShow={setShow}
                                                isEdit={isEdit}
                                                roleId={roleId}
                                                fetchRoledata={fetchRoledata}
                                            />
                                        </h2>
                                    </div>
                                    <div className="col-lg-6 col-md-8 col-sm-12 d-lg-flex justify-content-lg-end">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="title">
                                                    <input
                                                        className="form-control"
                                                        name="nameSearch"
                                                        value={filterValue.nameSearch}
                                                        onChange={onFilterChangeValue}
                                                        onKeyDown={onKeyDown}
                                                        type="search"
                                                        placeholder={t("search name")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="title">
                                                    <select className="form-control" name="mainRoleSearch" value={filterValue.mainRoleSearch} onChange={onFilterChangeValue}>
                                                        {Object.keys(PAGE_AUTHORITY_ROLE_SEARCH).map((item, i) => {
                                                            return (
                                                                <option key={i} value={PAGE_AUTHORITY_ROLE_SEARCH[item]}>
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="title">
                                                    <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                        {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                            return (
                                                                <option key={i} value={STATUS_SEARCH[item]}>
                                                                    {t(item)}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="content-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="table-responsive" data-pattern="priority-columns">
                                        <table
                                            id="tech-companies-1"
                                            className={`table vm table-small-font no-mb table-bordered ${isLoading || !roleList || (roleList && !roleList.length) ? "" : "table-striped"} table-hover`}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>{t("No")}</th>
                                                    <th>{t("Name")}</th>
                                                    <th>{t("Role")}</th>
                                                    <th>{t("Status")}</th>
                                                    <th>{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <UpsertRolePage
                                                    editLoader={editLoader}
                                                    isLoading={isLoading}
                                                    roleId={roleId}
                                                    handleCheck={handleCheck}
                                                    pageTitle={pageTitle}
                                                    pageList={pageList}
                                                    isPageShow={isPageShow}
                                                    handlePageClose={handlePageClose}
                                                />
                                                {!roleList || (roleList && !roleList.length && !isLoading) ? (
                                                    <NoDataFound />
                                                ) : isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    roleList &&
                                                    roleList.map((item, i) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="left">{(offset - 1) * ROWS_PER_PAGE + i + 1}</td>
                                                                <td>
                                                                    <h6>{item.name}</h6>
                                                                </td>
                                                                <td>
                                                                    {item.mainRoleId == 0 ? (
                                                                        <span className={`badge badge-danger`}>Main role</span>
                                                                    ) : (
                                                                        <span className={`badge badge-${item.mainRoleId == PAGE_AUTHORITY_ROLE.Admin ? "warning" : "info"}`}>
                                                                            {Object.keys(PAGE_AUTHORITY_ROLE).find((key) => PAGE_AUTHORITY_ROLE[key] === item.mainRoleId)}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                        {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {item.mainRoleId == 0 ? (
                                                                        ""
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                            <button className="btn btn-warning btn-xs mx-2" onClick={() => handlePageAdd(item.mainRoleId, item.name, item.id)}>
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                            <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                <i className="fa fa-trash-o "></i>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-lg-flex justify-content-between ">
                                        <div className="d-flex align-center">
                                            {t("Total")} {roleList == null ? "0" : rowCount} {t("Role")}
                                            {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                        </div>
                                        <div className="pagination-center">
                                            <ReactPaginate
                                                initialPage={0}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={0}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                previousLabel={t("Previous")}
                                                nextLabel={t("Next")}
                                                disableInitialCallback={true}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default RoleList;
