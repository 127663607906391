import { round } from "lodash";

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sliceString = (str, length) => {
    return str.length > length ? str.slice(0, length) + "..." : str;
};

export const displayLanguageList = (userLanguageId, allLanguageList) => {
    const languageIdArray = userLanguageId && userLanguageId.split("~");
    let languageList = [];

    allLanguageList &&
        allLanguageList.map((list) => {
            return (
                languageIdArray &&
                languageIdArray.map((id) => {
                    return list.id == id ? languageList.push(list.name) : "";
                })
            );
        });

    return languageList;
};

export const displaySpecialityList = (userSpecialityId, allSpecialityList) => {
    const specialityIdArray = userSpecialityId && userSpecialityId.split("~");
    let specialityList = [];

    allSpecialityList &&
        allSpecialityList.map((list) => {
            return (
                specialityIdArray &&
                specialityIdArray.map((id) => {
                    return list.id == id ? specialityList.push(list.name) : "";
                })
            );
        });

    return specialityList;
};

export const evaluationRating = (number) => {
    let stars = [];
    for (let i = 0; i < Number(round(number)); i++) {
        stars.push(<i className="fa fa-star" aria-hidden="true" style={{ color: "#de8032" }}></i>);
    }
    return stars;
};

export const evaluationEmptyStars = (number) => {
    let stars = [];
    let emptyStarNumber = 5 - Number(round(number));
    for (let i = 0; i < emptyStarNumber; i++) {
        stars.push(<i className="fa fa-star-o" aria-hidden="true" style={{ color: "#de8032" }}></i>);
    }
    return stars;
};
