import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../commoncomponents/Loader";
import { baseURL } from "../../../helper/Config";
import { PLAN_STATUS, PLAN_TYPE, STATUS } from "../../../helper/Constant";
import { capitalizeFirstLetter } from "../../../helper/helper";
import { useTranslation } from "react-i18next";

function UpdateMembershipPlan(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const handleUpdate = async () => {
        if (!props.inputValue.stripeProductId || props.inputValue.stripeProductId === "" || props.inputValue.stripeProductId === null) {
            toast.error(t(`Please enter Product Id`));
        } else if (!props.inputValue.stripePriceId || props.inputValue.stripePriceId === "" || props.inputValue.stripePriceId === null) {
            toast.error(t(`Please enter Price Id`));
        } else if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter plan name`));
        } else if (props.inputValue.type == 0 && (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null)) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/plan/update`,
                    {
                        name: capitalizeFirstLetter(props.inputValue.name),
                        stripeProductId: props.inputValue.stripeProductId,
                        stripePriceId: props.inputValue.stripePriceId,
                        statusId: props.inputValue.type == 0 && props.inputValue.statusId,
                        planId: props.planId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchMembershipdata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleAdd = async () => {
        if (!props.inputValue.stripeProductId || props.inputValue.stripeProductId === "" || props.inputValue.stripeProductId === null) {
            toast.error(t(`Please enter Product Id`));
        } else if (!props.inputValue.stripePriceId || props.inputValue.stripePriceId === "" || props.inputValue.stripePriceId === null) {
            toast.error(t(`Please enter Price Id`));
        } else if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter plan name`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/plan/new-plan/add`,
                    {
                        name: capitalizeFirstLetter(props.inputValue.name),
                        stripeProductId: props.inputValue.stripeProductId,
                        stripePriceId: props.inputValue.stripePriceId,
                        statusId: props.inputValue.statusId,
                        planId: props.planId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchMembershipdata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <div>
            <Modal show={props.isEdit} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.show ? t("Add") : t("Update")} {t("Membership Plan")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.editLoader ? (
                        <Loader />
                    ) : (
                        <div className="justify-content-center row my-3">
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Product Id")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="stripeProductId"
                                        value={props.inputValue.stripeProductId}
                                        onChange={props.show || props.inputValue.type == 0 ? props.onChangeValue : () => {}}
                                        disabled={props.show || props.inputValue.type == 0 ? "" : "disabled"}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Price Id")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="stripePriceId"
                                        value={props.inputValue.stripePriceId}
                                        onChange={props.show || props.inputValue.type == 0 ? props.onChangeValue : () => {}}
                                        disabled={props.show || props.inputValue.type == 0 ? "" : "disabled"}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Name")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="name" value={props.inputValue.name} onChange={props.onChangeValue} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className={`${props.inputValue.type == 0 ? "" : "form-group row"}`}>
                                    {props.inputValue.type == 0 ? (
                                        ""
                                    ) : (
                                        <div className="col-6">
                                            <label className="control-label col-md-12">
                                                {t("Monthly/Yearly")} <span className="required"> * </span>
                                            </label>

                                            <div className="col-md-12">
                                                <select className="form-control col-md-3" name="type" value={props.inputValue.type} disabled>
                                                    {Object.keys(PLAN_TYPE).map((item, i) => {
                                                        return (
                                                            <option key={i} value={PLAN_TYPE[item]}>
                                                                {item}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {props.inputValue.type == 0 ? (
                                        ""
                                    ) : (
                                        <div className="col-6">
                                            <label className="control-label col-md-12">
                                                {t("Subscription")} <span className="required"> * </span>
                                            </label>

                                            <div className="col-md-12">
                                                <input className="my-2 mx-3" type="radio" name="isSubscription" value="2" checked={props.inputValue.isSubscription == 2 ? true : false} disabled />
                                                {t("Auto")}
                                                <input className="my-2 mx-3" type="radio" name="isSubscription" value="1" checked={props.inputValue.isSubscription == 1 ? true : false} disabled />
                                                {t("Manual")}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label className="control-label col-md-12">
                                            {t("Price")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="price" value={`$${props.inputValue.price}`} disabled />
                                        </div>
                                    </div>
                                    {!props.show && props.inputValue.type !== 0 ? (
                                        ""
                                    ) : (
                                        <div className="col-6">
                                            <label className="control-label col-md-12">
                                                {props.inputValue.type == 0 ? t("Status") : t("Old Plan Status")} <span className="required"> * </span>
                                            </label>
                                            <div className="col-md-12">
                                                <select className="form-control col-md-3" name="statusId" value={props.inputValue.statusId} onChange={props.onChangeValue}>
                                                    {Object.keys(props.inputValue.type == 0 ? STATUS : PLAN_STATUS).map((item, i) => {
                                                        return (
                                                            <option key={i} value={props.inputValue.type == 0 ? STATUS[item] : PLAN_STATUS[item]}>
                                                                {t(item)}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={props.show ? handleAdd : handleUpdate}>
                        {props.show ? t("Add") : t("Update")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UpdateMembershipPlan;
