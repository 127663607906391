import React, { useRef, useState } from "react";
import { ROLE_ID, STATUS } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkEmail, checkPwd, checkUserName } from "../../../helper/Validator";
import Loader from "../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";

function UpsertEmployee(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const ref = useRef();

    const [passwordShown, setPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };

    const handleAdd = async () => {
        if ((!props.isEdit || props.isEdit == false) && (!props.inputValue.email || props.inputValue.email === "" || props.inputValue.email === null)) {
            toast.error(t(`Please enter email`));
        } else if ((!props.isEdit || props.isEdit == false) && props.inputValue.email && checkEmail(props.inputValue.email) == false) {
            toast.error(t(`Please enter valid email`));
        } else if ((!props.isEdit || props.isEdit == false) && (!props.inputValue.password || props.inputValue.password === "" || props.inputValue.password === null)) {
            toast.error(t(`Please enter password`));
        } else if ((!props.isEdit || props.isEdit == false) && props.inputValue.password && checkPwd(props.inputValue.password) == false) {
            toast.error(t(`Please enter valid password`));
        } else if ((!props.isEdit || props.isEdit == false) && (!props.inputValue.confirmPassword || props.inputValue.confirmPassword === "" || props.inputValue.confirmPassword === null)) {
            toast.error(t(`Please enter confirm password`));
        } else if ((!props.isEdit || props.isEdit == false) && props.inputValue.password !== props.inputValue.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
        } else if (!props.inputValue.firstName || props.inputValue.firstName === "" || props.inputValue.firstName === null) {
            toast.error(t(`Please enter first name`));
        } else if (!props.inputValue.lastName || props.inputValue.lastName === "" || props.inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        } else if (!props.inputValue.userName || props.inputValue.userName === "" || props.inputValue.userName === null) {
            toast.error(t(`Please enter username`));
        } else if (props.inputValue.userName && checkUserName(props.inputValue.userName) == false) {
            toast.error(t(`Please enter valid username`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else if (!props.inputValue.address1 || props.inputValue.address1 === "" || props.inputValue.address1 === null) {
            toast.error(t(`Please enter address`));
        } else if (!props.inputValue.roleId || props.inputValue.roleId === "" || props.inputValue.roleId === null) {
            toast.error(t(`Please select role`));
        } else if ((!props.isEdit || props.isEdit == false) && !props.agreementInfo.data) {
            toast.error(t(`Please Upload agreement document`));
        } else if (props.isEdit) {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/employee/upsert`,
                    {
                        employeeId: props.isEdit ? props.employeeId : "",
                        firstName: props.inputValue.firstName,
                        lastName: props.inputValue.lastName,
                        userName: props.inputValue.userName,
                        statusId: props.inputValue.statusId,
                        address1: props.inputValue.address1,
                        roleId: props.inputValue.roleId,
                        mainRoleId: ROLE_ID.ADMIN,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchEmployeedata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            let formData = new FormData();
            formData.append("email", props.inputValue.email);
            formData.append("password", props.inputValue.password);
            formData.append("firstName", props.inputValue.firstName);
            formData.append("lastName", props.inputValue.lastName);
            formData.append("userName", props.inputValue.userName);
            formData.append("statusId", props.inputValue.statusId);
            formData.append("agreement", props.agreementInfo.data);
            formData.append("address1", props.inputValue.address1);
            formData.append("roleId", props.inputValue.roleId);
            formData.append("mainRoleId", ROLE_ID.ADMIN);

            await axios
                .post(
                    `${baseURL}/api/v1/admin/employee/upsert`,
                    formData,

                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    ref.current.value = "";
                    toast.success(res.data.message);
                    props.fetchEmployeedata();
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <div className="col-xs-12 ">
                <div className="add-header-wrapper gradient-blue curved-section text-center">
                    <h2 className="uppercase bold w-text">
                        {props.isEdit ? t("Update") : t("Add")} {t("Employee")}
                    </h2>
                    <div className="before-text">
                        {props.isEdit ? t("Update") : t("Add")} {t("Employee")}
                    </div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        {props.isEdit ? (
                                            ""
                                        ) : (
                                            <div className="col-xs-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-12">
                                                        {t("E-mail")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-12">
                                                        <input type="email" className="form-control" name="email" value={props.inputValue.email} onChange={props.onChangeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Username")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="userName" value={props.inputValue.userName} onChange={props.onChangeValue} />
                                                    <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                        *{t("At least 5 characters : start and end with character or number, can have special character")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {props.isEdit ? (
                                            ""
                                        ) : (
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Password")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12 d-flex">
                                                                <input
                                                                    type={passwordShown ? "text" : "password"}
                                                                    className="form-control"
                                                                    name="password"
                                                                    value={props.inputValue.password}
                                                                    onChange={props.onChangeValue}
                                                                />
                                                                <i
                                                                    className={`${passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                    id="basic-addon1"
                                                                    onClick={togglePassword}
                                                                ></i>
                                                            </div>
                                                            <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Confirm Password")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12 d-flex">
                                                                <input
                                                                    type={cfmpasswordShown ? "text" : "password"}
                                                                    className="form-control"
                                                                    name="confirmPassword"
                                                                    value={props.inputValue.confirmPassword}
                                                                    onChange={props.onChangeValue}
                                                                />
                                                                <i
                                                                    className={`${cfmpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                    id="basic-addon1"
                                                                    onClick={toggleCfmPassword}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("First name")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="firstName" value={props.inputValue.firstName} onChange={props.onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Last name")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="lastName" value={props.inputValue.lastName} onChange={props.onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Access Level")} <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-12">
                                                            <Select
                                                                menuPlacement="top"
                                                                name="recommender"
                                                                value={props.roleOptions && props.roleOptions.filter(({ value }) => value == props.inputValue.roleId)}
                                                                options={props.roleOptions}
                                                                onChange={props.onRoleChange}
                                                                placeholder={t("Select")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Status")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <select className="form-control col-md-3" name="statusId" value={props.inputValue.statusId} onChange={props.onChangeValue}>
                                                                {Object.keys(STATUS).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={STATUS[item]}>
                                                                            {item}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {props.isEdit ? (
                                            ""
                                        ) : (
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Agreement")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        <input type="file" className="form-control" ref={ref} name="agreement" onChange={props.onChangeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Address")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="address1"
                                                        rows={5}
                                                        cols={12}
                                                        value={props.inputValue.address1}
                                                        onChange={props.onChangeValue}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="padding-bottom-30 d-flex justify-content-center">
                                            <div className="text-left">
                                                <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                    {t("Close")}
                                                </button>
                                                <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleAdd}>
                                                    {props.isEdit ? t("Update") : t("Add")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertEmployee;
