import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../../helper/Config";
import { ROLE_ID, STATUS } from "../../../../helper/Constant";
import { capitalizeFirstLetter } from "../../../../helper/helper";
import Select from "react-select";
import Loader from "../../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";

function Upsertreatment(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const handleAdd = async () => {
        if (!props.treatmentTypeId || props.treatmentTypeId === "" || props.treatmentTypeId === null) {
            toast.error(t(`Please select type`));
        } else if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter name`));
        } else if (!props.inputValue.code || props.inputValue.code === "" || props.inputValue.code === null) {
            toast.error(t(`Please enter code`));
        } else if (props.inputValue.code && props.inputValue.code.length > 10) {
            toast.error(t(`Please enter code less than 10 digit`));
        } else if (props.inputValue.fee && props.inputValue.fee > 10000) {
            toast.error(t(`Please enter fee less than 10,000`));
        } else if (!props.inputValue.priority || props.inputValue.priority === "" || props.inputValue.priority === null) {
            toast.error(t(`Please enter priority`));
        } else if (props.inputValue.priority && props.inputValue.priority > 9999) {
            toast.error(t(`Please enter priority less than 9999`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${props.apiRouteName}/treatment/upsert`,
                    {
                        treatmentTypeId: props.treatmentTypeId,
                        treatmentTypeGroupId: props.treatmentTypeGroupId,
                        name: capitalizeFirstLetter(props.inputValue.name),
                        code: props.inputValue.code,
                        description: props.inputValue.description,
                        statusId: props.inputValue.statusId,
                        priority: props.inputValue.priority,
                        treatmentId: props.isEdit ? props.treatmentId : "",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchTreatmentData();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.isEdit ? t("Update") : t("Add")} {t("Treatment")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.editLoader ? (
                        <Loader />
                    ) : (
                        <div className="justify-content-center row my-3">
                            <div className="col-12 my-3">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label className="control-label col-md-12">
                                            {t("Treatment Type")} <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-12">
                                            <Select
                                                name="treatmentType"
                                                value={props.treatmentTypeOptions && props.treatmentTypeOptions.filter(({ value }) => value == props.treatmentTypeId)}
                                                options={props.treatmentTypeOptions}
                                                onChange={props.onTreatmentTypeChange}
                                                placeholder={`${t("Treatment Type")}`}
                                            />
                                        </div>
                                    </div>
                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <label className="control-label col-md-12">{t("Treatment Group")}</label>
                                            <div className="col-md-12">
                                                <Select
                                                    name="treatmentTypegroup"
                                                    value={props.treatmentTypegroupOptions && props.treatmentTypegroupOptions.filter(({ value }) => value == props.treatmentTypeGroupId)}
                                                    options={props.treatmentTypegroupOptions}
                                                    onChange={props.onTreatmentTypeGroupChange}
                                                    placeholder={`${t("Treatment Group")}`}
                                                    isDisabled={props.treatmentTypeId ? false : true}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="form-group row p-0">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="control-label col-md-12">
                                        {t("Name")} <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="name" value={props.inputValue.name} onChange={props.onChangeValue} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="control-label col-md-12">
                                        {t("Code")} <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="code" value={props.inputValue.code} onChange={props.onChangeValue} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="control-label col-md-12">{t("Description")}</label>

                                <div className="col-md-12">
                                    <textarea type="text" rows={5} cols={10} className="form-control" name="description" value={props.inputValue.description} onChange={props.onChangeValue} />
                                </div>
                            </div>
                            <div className="form-group row p-0">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="control-label col-md-12">
                                        {t("Priority")} <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="priority" value={props.inputValue.priority} onChange={props.onChangeValue} />
                                    </div>
                                </div>
                                {profileData.roleId == ROLE_ID.ADMIN ? (
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <label className="control-label col-md-12">
                                            {t("Status")} <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-12">
                                            <select className="form-control col-md-3" name="statusId" value={props.inputValue.statusId} onChange={props.onChangeValue}>
                                                {Object.keys(STATUS).map((item, i) => {
                                                    return (
                                                        <option key={i} value={STATUS[item]}>
                                                            {item}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleAdd}>
                        {props.isEdit ? t("Update") : t("Add")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Upsertreatment;
