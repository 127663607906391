import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { ROLE_ID } from "../../helper/Constant";

function PrivateRoutes() {
    const token = sessionStorage.getItem("token");
    const roleId = sessionStorage.getItem("roleId");

    if (!token || token == "" || token == null || token == undefined) {
        return (
            <>
                <Navigate to="/signup" />
                <Navigate to="/login" />
            </>
        );
    } else if (roleId === ROLE_ID.DOCTOR && token) {
        return (
            <>
                <Navigate to="/select-dental" />
            </>
        );
    } else {
        return (
            <>
                <Outlet />
            </>
        );
    }
}

export default PrivateRoutes;
