import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { toast } from "react-toastify";
import { baseURL, investorFrontURL } from "../../helper/Config";
import { checkEmail } from "../../helper/Validator";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const navigate = useNavigate();
    const [mail, setMail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <section className="">
                <div className="container-fluid pt-5 mb-50 wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: "1" }}>
                    <div className="container">
                        <div className="col-lg-12 col-md-12 col-xs-12 p-5 join-now-custom">
                            <form className="mx-auto">
                                <h2 className="text-white text-center pb-1">{t("Want to Learn More?")}</h2>
                                <div className="d-sm-flex justify-content-center align-center">
                                    <div className="col-lg-5">
                                        <input
                                            value={mail}
                                            type="text"
                                            className="form-control email-address-custom input-radius-custom"
                                            placeholder="Enter your email address..."
                                            onChange={(e) => setMail(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="btn ms-3 my-1 btn-contact-me-custom"
                                        onClick={
                                            isLoading
                                                ? (e) => {
                                                      e.preventDefault();
                                                  }
                                                : async (e) => {
                                                      e.preventDefault();
                                                      if (!mail) {
                                                          toast.error(`Please enter email`);
                                                      } else if (mail && checkEmail(mail) == false) {
                                                          toast.error(`Please enter valid email`);
                                                      } else {
                                                          setIsLoading(true);
                                                          await axios
                                                              .post(`${baseURL}/api/v1/gdental/want-to-learn-more`, {
                                                                  email: mail,
                                                              })
                                                              .then(async (res) => {
                                                                  setMail("");
                                                                  setIsLoading(false);
                                                                  toast.success(res.data.message);
                                                              })
                                                              .catch((err) => {
                                                                  setIsLoading(false);
                                                                  toast.error(err.response.data.message);
                                                              });
                                                      }
                                                  }
                                        }
                                    >
                                        {isLoading ? (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{
                                                    position: "relative",
                                                }}
                                            ></i>
                                        ) : (
                                            <>
                                                {t("Contact Me")}
                                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 wow fadeInUp footer-theme-custom" data-wow-delay="0.3s">
                    <div className="container pt-5">
                        <div className="row g-5 pt-0">
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <div className="d-flex mb-20">
                                    <p className="navbar-brand p-0">
                                        <img src="/assets/images/home/logo.png" alt="" width="80" height="80" />
                                    </p>
                                </div>
                                <div>
                                    <p className="mb-2 w-50">{t("Advocating for your dental health.")}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-2 col-sm-12">
                                <div className="d-flex flex-column justify-content-start">
                                    <h4 className="fw-bolder text-dark">
                                        <span className="quality-text-custom">{t("Links")}</span>
                                    </h4>
                                    <a className="mb-2 footer-text-link" role="button" onClick={() => navigate("/")}>
                                        {t("Home")}
                                    </a>
                                    <a className="mb-2 footer-text-link" role="button" onClick={() => navigate("/services")}>
                                        {t("Services")}
                                    </a>
                                    <a className="mb-2 footer-text-link" role="button">
                                        <NavHashLink to="/services#planForEveryOne">
                                            <span style={{ color: "#7a7d7e" }}>{t("Pricing")}</span>
                                        </NavHashLink>
                                    </a>
                                    <a className="mb-2 footer-text-link" role="button" onClick={() => navigate("/get-in-touch")}>
                                        {t("Contact")}
                                    </a>
                                    <a className="mb-2 footer-text-link" role="button" onClick={() => navigate("/faq")}>
                                        FAQ
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-3 col-sm-12">
                                <div>
                                    <h4 className="fw-bolder text-dark">
                                        <span className="quality-text-custom">{t("Contact")}</span>
                                    </h4>
                                    <p className="pt-0 mb-2">support@gdenti.com</p>
                                    <p className="pt-0 mb-2">201-496-6366</p>
                                    <p className="mb-0">7 Broad Ave Suite #101 Palisades Park, NJ 07650</p>
                                    <p className="pt-2">{t("Follow us on Social")}:</p>
                                    <p>
                                        <a href="https://www.facebook.com/GDentiOfficial" target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-facebook mx-2 fs-4 link-social"></i>
                                        </a>
                                        <a href="https://twitter.com/GDentiOfficial" target="_blank" rel="noonpener nonreferrer">
                                            <i className="fa-brands fa-twitter mx-2 fs-4 link-social"></i>
                                        </a>
                                        <a href="https://www.instagram.com/gdentiofficial/" target="_blank" rel="noonpener nonreferrer">
                                            <i className="fab fa-instagram fw-normal mx-2 fs-4 link-social"></i>
                                        </a>
                                        <a href="https://www.tiktok.com/@gdentiofficial" target="_blank" rel="noonpener nonreferrer">
                                            <i className="fa-brands fa-tiktok mx-2 fs-4 link-social"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-md-flex justify-content-center pt-4">
                            <span className="d-flex justify-content-center">
                                <a className="mb-2 footer-text-link" href="/downloads/footer/GDenti_Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer" style={{ color: "#115278" }}>
                                    {t("Privacy Policy")}
                                </a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a className="mb-2 footer-text-link" href="/downloads/footer/GDenti_Terms_of_Use.pdf" target="_blank" rel="noopener noreferrer" style={{ color: "#115278" }}>
                                    {t("Terms of Use")}
                                </a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <a className="mb-2 footer-text-link" href={`${investorFrontURL}`} target="_blank" rel="noopener noreferrer" style={{ color: "#115278" }}>
                                    {t("Investor Portal")}
                                </a>
                            </span>
                            <div className="col-lg-4 col-md-6 text-center">GDENTI © 2023 {t("ALL RIGHTS RESERVED")}</div>
                        </div>
                    </div>
                    
                    <div className="container pt-1" style={{ width: '70%' }}>
                        <div className="d-md-flex justify-content-center pt-2">
                            <h6>
                                {t("Savings plans are NOT insurance, and the amount saved...")}
                                <br/><br/>{t("The discounts offered are applicable only through participating...")}
                                <br/><br/>{t("Kindly check with your participating provider before proceeding with treatment.")}
                            </h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
