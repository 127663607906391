import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../commoncomponents/Loader";
import NoDataFound from "../../commoncomponents/NoDataFound";
import { baseURL } from "../../helper/Config";
import { CLAIM_STATUS, ROWS_PER_PAGE, VERIFIER_COMMENT_STATUS } from "../../helper/Constant";
import { monthDateYear } from "../../helper/dateFormates";
import UpsertClaim from "../../components/adminPanel/manageClaim/UpsertClaim";
import { evaluationEmptyStars, evaluationRating } from "../../helper/helper";
import UpsertEvaluation from "../../components/adminPanel/manageEvaluation/UpsertEvaluation";
import { useTranslation } from "react-i18next";

const VerifierDashboard = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [claimList, setClaimList] = useState([]);
    const [claimCurrentPage, setClaimCurrentPage] = useState(0);
    const [claimOffset, setClaimOffset] = useState(1);
    const [claimPageCount, setClaimPageCount] = useState(0);
    const [claimRowCount, setClaimRowCount] = useState(0);
    const [claimIsLoading, setClaimIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState();
    const [claimData, setClaimData] = useState([]);
    const [editLoader, setEditLoader] = useState(false);
    const [editEvaluationLoader, setEditEvaluationLoader] = useState(false);
    const [sum, setSum] = useState([]);
    const [inputValueObj, setInputValueObj] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [evaluationList, setEvaluationList] = useState([]);
    const [evaluationCurrentPage, setEvaluationCurrentPage] = useState(0);
    const [evaluationOffset, setEvaluationOffset] = useState(1);
    const [evaluationPageCount, setEvaluationPageCount] = useState(0);
    const [evaluationRowCount, setEvaluationRowCount] = useState(0);
    const [evaluationIsLoading, setEvaluationIsLoading] = useState(true);
    const [inputValue, setInputValue] = useState({
        officeId: "",
        doctorId: "",
        dateOfService: "",
    });
    const [isEvaluationView, setIsEvaluationView] = useState(false);
    const [evaluationId, setEvaluationId] = useState("");

    const [officeId, setOfficeId] = useState("");
    const [doctorId, setDoctorId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [dateOfService, setDateOfService] = useState("");
    const [isView, setIsView] = useState(false);
    const [claimId, setClaimId] = useState("");
    const [show, setShow] = useState(false);
    const [claimInputValue, setClaimInputValue] = useState({
        verifierNote: "",
        doctorNote: "",
        finalResult: "",
        isVerifierNoteChanged: 0,
        isFinalResultChanged: 0,
        isDoctorNoteChanged: 0,
    });
    const [oldNoteValues, setOldNoteValues] = useState({
        verifierNote: "",
        doctorNote: "",
        finalResult: "",
    });

    const handleClaimPageClick = (event) => {
        const selectedPage = event.selected;
        setClaimOffset(selectedPage + 1);
        setClaimCurrentPage(selectedPage);
    };

    const handleClaimView = (id, officeId) => {
        setClaimId(id);
        claimDetail(id);
        fetchTreatmentTypeData(id, officeId);
        setIsView(true);
    };

    const handleClose = () => {
        setClaimId("");
        setInputValueObj([]);
        setSum([]);
        setPatientId("");
        setDateOfService("");
        setIsView(false);
        setInputValue("");
        setEvaluationId("");
        setIsEvaluationView(false);
    };

    const fetchTreatmentTypeData = async (claimId, officeId) => {
        axios
            .post(
                `${baseURL}/api/v1/verifier/treatment/list?isPagination=false`,
                {
                    claimId: claimId,
                    officeId: officeId,
                    isListAllRecord: false,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                let obj = [];

                res.data.data &&
                    res.data.data.map((item) => {
                        obj.push({
                            treatmentTypeId: item.treatmentTypeId,
                            treatmentId: Number(item.id),
                            fee: Number(item.originalFee),
                            unit: item.unit,
                            totalUnitFee: item.totalUnitFee,
                        });
                    });
                setInputValueObj(obj);
                let sum =
                    res.data.data &&
                    res.data.data.reduce((acc, obj) => {
                        if (!acc[obj.treatmentTypeId]) {
                            acc[obj.treatmentTypeId] = 0;
                        }
                        acc[obj.treatmentTypeId] += obj.totalUnitFee;
                        return acc;
                    }, {});

                setSum(sum);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const claimDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/verifier/claim/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                let response = res.data.data;
                setOfficeId(response.office.id);
                setDoctorId(response.doctor.id);
                setPatientId(response.patient.id);
                setDateOfService(response.dateOfService);
                setClaimData(response);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const claimNoteDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/verifier/claim/comment/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setClaimInputValue(res.data.data);
                setOldNoteValues(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setClaimInputValue({ ...claimInputValue, [name]: value });
    };

    const fetchClaimdata = async () => {
        setClaimIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/verifier/claim/list`,
                {
                    page: claimOffset,
                    rowsPerPage: ROWS_PER_PAGE,
                    isSubmitted: CLAIM_STATUS.Submitted,
                    isVerifierClaimList: true,
                    isWeeklyVerifiedClaim: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setClaimList(res.data.data);
                setClaimPageCount(res.data.extraData.pageCount);
                setClaimRowCount(res.data.extraData.rowCount);
                setClaimIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchClaimdata();
    }, [claimOffset]);

    const handleEvaluationView = (id) => {
        setEvaluationId(id);
        evaluationDetail(id);
        setIsEvaluationView(true);
    };

    const evaluationDetail = async (evaluationId) => {
        setEditEvaluationLoader(true);
        await axios
            .get(`${baseURL}/api/v1/verifier/dental-evaluation/detail/${evaluationId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditEvaluationLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchEvaluationdata = async () => {
        setEvaluationIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/verifier/dental-evaluation/list?isPagination=true`,
                {
                    page: evaluationOffset,
                    rowsPerPage: ROWS_PER_PAGE,
                    isWeeklyEvaluation: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setEvaluationList(res.data.data);
                setEvaluationPageCount(res.data.extraData.pageCount);
                setEvaluationRowCount(res.data.extraData.rowCount);
                setEvaluationIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleEvaluationPageClick = (event) => {
        const selectedPage = event.selected;
        setEvaluationOffset(selectedPage + 1);
        setEvaluationCurrentPage(selectedPage);
    };

    useEffect(() => {
        fetchEvaluationdata();
    }, [evaluationOffset]);

    const fetchDashboardData = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/verifier/dashboard`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setDashboardData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{isView ? `${t("Manage")} claim Verify` : isEvaluationView ? `${t("Manage Evaluation")} ` : `${t("Verifier")} ${t("Dashboard")}`}</h1>
                    </div>
                    {isView || isEvaluationView ? (
                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li className="cursor-pointer" onClick={handleClose}>
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </li>

                                <li className="active cursor-pointer">
                                    <strong>
                                        {t("View")} {isView ? t("Claim") : t("Evaluation")}
                                    </strong>
                                </li>
                            </ol>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {isView ? (
                <UpsertClaim
                    fetchClaimdata={fetchClaimdata}
                    oldNoteValues={oldNoteValues}
                    inputValue={claimInputValue}
                    setInputValue={setClaimInputValue}
                    onChangeValue={onChangeValue}
                    show={show}
                    setShow={setShow}
                    claimDetail={claimDetail}
                    claimNoteDetail={claimNoteDetail}
                    isVerifyClaimList={true}
                    editLoader={editLoader}
                    sum={sum}
                    setSum={setSum}
                    inputValueObj={inputValueObj}
                    setInputValueObj={setInputValueObj}
                    officeId={officeId}
                    doctorId={doctorId}
                    patientId={patientId}
                    dateOfService={dateOfService}
                    handleClose={handleClose}
                    claimData={claimData}
                    claimId={claimId}
                    isView={isView}
                />
            ) : isEvaluationView ? (
                <>
                    <UpsertEvaluation
                        editLoader={editEvaluationLoader}
                        isView={isEvaluationView}
                        handleClose={handleClose}
                        fetchEvaluationdata={fetchEvaluationdata}
                        evaluationId={evaluationId}
                        inputValue={inputValue}
                    />
                </>
            ) : (
                <>
                    <div className="col-lg-12">
                        <section className="box nobox marginBottom0">
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="r4_counter r7_counter  db_box" onClick={() => navigate("/manage-evaluation")}>
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa fa-star-o" style={{ fontSize: "34px", color: "rgb(205 79 79)" }}></i>
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalUserEvaluation} {t("Evaluation")}
                                                    {/* {dashboardData && dashboardData.totalUserEvaluation > 1 ? "s" : ""} */}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Evaluation")}
                                                    {/* {dashboardData && dashboardData.totalUserEvaluation > 1 ? "s" : ""} */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="r4_counter r7_counter  db_box" onClick={() => navigate("/manage-claim-verify")}>
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa fa-envelope-o" style={{ fontSize: "34px", color: "rgb(205 79 79)" }}></i>
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalVerifiedClaim} {t("Verified")} {t("Claim")}
                                                    {/* {dashboardData && dashboardData.totalVerifiedClaim > 1 ? "s" : ""} */}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Verified")} {t("Claim")}
                                                    {/* {dashboardData && dashboardData.totalVerifiedClaim > 1 ? "s" : ""} */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title pull-left"> {t("Last week's verified claim list")}</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table vm table-small-font no-mb table-bordered table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("Claim")} #</th>
                                                                <th>{t("Office")}</th>
                                                                <th>{t("Doctor")}</th>
                                                                <th>{t("Patient")}</th>
                                                                <th>{t("Date of service")}</th>
                                                                <th>{t("Result")}</th>
                                                                <th>{t("Action")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!claimList || (claimList && !claimList.length && !isLoading) ? (
                                                                <NoDataFound />
                                                            ) : claimIsLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                claimList &&
                                                                claimList.map((item, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <h6>{item.code ? item.code : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.office ? item.office.name : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.doctor
                                                                                        ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.patient
                                                                                        ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    className={`badge badge-${item.finalResult == VERIFIER_COMMENT_STATUS.Good
                                                                                            ? "success"
                                                                                            : item.finalResult == VERIFIER_COMMENT_STATUS.Normal
                                                                                                ? "warning"
                                                                                                : item.finalResult == VERIFIER_COMMENT_STATUS["Not Good"]
                                                                                                    ? "danger"
                                                                                                    : "info"
                                                                                        }`}
                                                                                >
                                                                                    {Object.keys(VERIFIER_COMMENT_STATUS).find((key) => VERIFIER_COMMENT_STATUS[key] === item.finalResult)}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-warning btn-xs" onClick={() => handleClaimView(item.id, item.office.id)}>
                                                                                    <i className="fa fa-eye "></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-lg-flex justify-content-between ">
                                                    <div className="d-flex align-center">
                                                        {t("Total")} {claimList == null ? "0" : claimRowCount} {t("Claim")}
                                                        {/* {claimRowCount && claimRowCount > 1 ? "s" : ""} */}
                                                    </div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={claimPageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleClaimPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel={t("Previous")}
                                                            nextLabel={t("Next")}
                                                            disableInitialCallback={true}
                                                            forcePage={claimCurrentPage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title pull-left">{t("Last 1 week Evaluation List")}</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table vm table-small-font no-mb table-bordered table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("Office")}</th>
                                                                <th>{t("Doctor")}</th>
                                                                <th>{t("Patient")}</th>
                                                                <th>{t("Date of service")}</th>
                                                                <th>{t("Evaluation")}</th>
                                                                <th>{t("Action")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!evaluationList || (evaluationList && !evaluationList.length && !isLoading) ? (
                                                                <NoDataFound />
                                                            ) : evaluationIsLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                evaluationList &&
                                                                evaluationList.map((item, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <h6>{item.office && item.office.name ? item.office.name : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.doctor
                                                                                        ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.patient
                                                                                        ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.evaluation ? evaluationRating(item.evaluation).concat(evaluationEmptyStars(item.evaluation)) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-warning btn-xs mx-2" onClick={() => handleEvaluationView(item.id)}>
                                                                                    <i className="fa fa-eye"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-lg-flex justify-content-between ">
                                                    <div className="d-flex align-center">
                                                        {t("Total")} {evaluationList == null ? "0" : evaluationRowCount} {t("Evaluation")}
                                                        {/* {evaluationRowCount && evaluationRowCount > 1 ? "s" : ""} */}
                                                    </div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={evaluationPageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleEvaluationPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel={t("Previous")}
                                                            nextLabel={t("Next")}
                                                            disableInitialCallback={true}
                                                            forcePage={evaluationCurrentPage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default VerifierDashboard;
