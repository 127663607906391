import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../../helper/Config";
import { PAGE_AUTHORITY_ROLE, STATUS } from "../../../../helper/Constant";
import Select from "react-select";
import Loader from "../../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";

function UpsertPage(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const handleAdd = async () => {
        if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter name`));
        } else if (!props.inputValue.mainRoleId || props.inputValue.mainRoleId === "" || props.inputValue.mainRoleId === null) {
            toast.error(t(`Please select role`));
        } else if (!props.inputValue.priority || props.inputValue.priority === "" || props.inputValue.priority === null) {
            toast.error(t(`Please enter priority`));
        } else if (props.inputValue.priority && props.inputValue.priority > 9999) {
            toast.error(t(`Please enter priority less than 9999`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/page/upsert`,
                    {
                        name: props.inputValue.name,
                        mainRoleId: props.inputValue.mainRoleId,
                        priority: props.inputValue.priority,
                        statusId: props.inputValue.statusId,
                        pageId: props.isEdit ? props.pageId : "",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchPagedata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const pageList = [
        { value: `Manage-doctor`, label: `${t("Manage")}-${t("Doctor")}` },
        { value: `Manage-office`, label: `${t("Manage")}-${t("Office")}` },
        { value: `Manage-patient`, label: `${t("Manage")}-${t("Patient")}` },
        { value: `Manage-gdental-patient`, label: `${t("Manage")}-${t("Gdental-Patient")}` },
        { value: `Manage-order-history`, label: `${t("Manage")}-${t("Order-History")}` },
        { value: `Manage-evaluation`, label: `${t("Manage")}-${t("Evaluation")}` },
        { value: `Manage-dental-compare`, label: `${t("Manage")}-${t("Dental-Compare")}` },
        { value: `Manage-claim`, label: `${t("Manage")}-${t("Claim")}` },
        { value: `Manage-claim-verify`, label: `${t("Manage")}-${t("Claim-Verify")}` },
        { value: `Manage-employee`, label: `${t("Manage")}-${t("Employee")}` },
        { value: `Manage-agency`, label: `${t("Manage")}-${t("Agency")}` },
        { value: `Manage-agent`, label: `${t("Manage")}-${t("Agent")}` },
        { value: `Manage-manager`, label: `${t("Manage")}-${t("Manager")}` },
        { value: `Manage-settlement`, label: `${t("Manage")}-${t("Settlement")}` },
        { value: `Manage-verifier`, label: `${t("Manage")}-${t("Verifier")}` },
        { value: `Manage-office-employee`, label: `${t("Manage")}-${t("Office-Employee")}` },
        { value: `Manage-sns`, label: `${t("Manage")}-${t("Sns")}` },
        { value: `Manage-zoom-meeting`, label: `${t("Manage")}-${t("Zoom-Meeting")}` },
        { value: "Configuration", label: t("Configuration") },
        { value: "Search-dental", label: t("Search-Dental") },
        { value: "Gdental-membership-plans", label: t("Gdental-Membership-Plans") },
    ];

    const onPageChange = (e) => {
        props.setInputValue({ ...props.inputValue, name: e.label });
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.isEdit ? t("Update") : t("Add")} {t("Page")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.editLoader ? (
                        <Loader />
                    ) : (
                        <div className="justify-content-center row my-3">
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Name")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12">
                                    <div className="">
                                        <Select
                                            name="pageList"
                                            value={pageList && pageList.filter(({ label }) => label === props.inputValue.name)}
                                            options={pageList}
                                            onChange={onPageChange}
                                            placeholder={t("Select")}
                                            isDisabled={props.isEdit}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Role")} <span className="required"> * </span>
                                </label>
                                <div className="col-md-12">
                                    <select
                                        className="form-control col-md-3"
                                        name="mainRoleId"
                                        value={props.inputValue.mainRoleId}
                                        onChange={props.onChangeValue}
                                        disabled={props.isEdit ? "disabled" : ""}
                                    >
                                        {Object.keys(PAGE_AUTHORITY_ROLE).map((item, i) => {
                                            return (
                                                <option key={i} value={PAGE_AUTHORITY_ROLE[item]}>
                                                    {item}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group row">
                                    <div className="col-6">
                                        <label className="control-label col-md-12">
                                            {t("Priority")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="priority" value={props.inputValue.priority} onChange={props.onChangeValue} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label className="control-label col-md-12">
                                            {t("Status")} <span className="required"> * </span>
                                        </label>
                                        <div className="col-md-12">
                                            <select className="form-control col-md-3" name="statusId" value={props.inputValue.statusId} onChange={props.onChangeValue}>
                                                {Object.keys(STATUS).map((item, i) => {
                                                    return (
                                                        <option key={i} value={STATUS[item]}>
                                                            {item}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleAdd}>
                        {props.isEdit ? t("Update") : t("Add")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UpsertPage;
