import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GENDER } from "../../helper/Constant";
import { checkPhNo } from "../../helper/Validator";
import { dateAPIFormate, defaultTimeZone } from "../../helper/dateFormates";
import Select from "react-select";
import { Link } from "react-router-dom";
import GPointWalletUserVerify from "../GPointWalletUserVerify";
import { useTranslation } from "react-i18next";

const UpdatePatientProfile = () => {
    const { profileData, setProfileData } = useOutletContext();

    const { t } = useTranslation();

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const [recommender, setRecommender] = useState([]);

    //Recommender Value // onChange
    // const [temp, setTemp] = useState(profileData.patient.recommenderId);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [patient, setPatient] = useState(profileData.patient);

    const [show, setShow] = useState(false);

    const handleSyncWallet = async () => {
        setShow(true);
    };

    const onchangeValue = (e) => {
        let value1 = e.target.value;

        if (value1 && parseFloat(value1) < 0) {
            e.target.value = 0;
            return;
        }

        const { name, value } = e.target;

        setProfileData({ ...profileData, [name]: value });

        setPatient({ ...patient, [name]: value });
    };

    let selectRecommender = [
        {
            id: "",
            name: "-- Select Recommender --",
        },
    ];

    const recommenderList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/recommender/list`,
                {
                    name: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => setRecommender(res.data.data ? selectRecommender.concat(res.data.data) : selectRecommender))
            .catch((err) => toast.error(err.response.message));
    };

    // const onRecommenderChange = (e) => {
    //     setTemp(e.value);
    // };

    const recommenderOptions =
        recommender &&
        recommender.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const fetchCountryList = async () => {
        await axios
            .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
                isId: true,
            })
            .then((res) => {
                setCountryList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const countryOptions =
        countryList &&
        countryList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCountryChange = (e) => {
        setProfileData({ ...profileData, countryId: e.value, stateId: "", cityId: "" });
        setStateList([]);
        fetchStateList(e.value);
    };

    const fetchStateList = async (countryId) => {
        await axios
            .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
                countryId: countryId,
                isId: true,
            })
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onStateChange = (e) => {
        setProfileData({ ...profileData, stateId: e.value, cityId: "" });
        setCityList([]);
        fetchCityList(e.value);
    };

    const fetchCityList = async (stateId) => {
        await axios
            .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
                stateId: stateId,
                isId: true,
            })
            .then((res) => {
                setCityList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cityOptions =
        cityList &&
        cityList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCityChange = (e) => {
        setProfileData({ ...profileData, cityId: e.value });
    };

    const profileUpdate = async (e) => {
        e.preventDefault();

        if (!profileData.firstName) {
            toast.error(t(`Please enter first name`));
            return false;
        }

        if (!profileData.lastName) {
            toast.error(t(`Enter Last Name`));
            return false;
        }

        if (!profileData.dob) {
            toast.error(t(`Enter Birth Date`));
            return false;
        }

        if (!profileData.phone1) {
            toast.error(t(`Enter Phone Number`));
            return false;
        }

        if (checkPhNo(profileData.phone1) == false) {
            toast.error(t(`Enter Valid Phone Number`));
            return false;
        }

        if (!profileData.countryId) {
            toast.error(t(`Select Country`));
            return false;
        }

        if (!profileData.stateId) {
            toast.error(t(`Select State`));
            return false;
        }

        if (!profileData.cityId) {
            toast.error(t(`Select City`));
            return false;
        }

        if (!profileData.zip) {
            toast.error(t(`Select ZIP Code`));
            return false;
        }

        await axios
            .post(
                `${baseURL}/api/v1/patient/profile/update`,
                {
                    firstName: profileData.firstName,
                    lastName: profileData.lastName,
                    userName: profileData.userName,
                    gender: profileData.gender,
                    dob: dateAPIFormate(profileData.dob),
                    phone1: profileData.phone1,
                    address1: profileData.address1,
                    countryId: profileData.countryId,
                    stateId: profileData.stateId,
                    cityId: profileData.cityId,
                    zip: profileData.zip,
                    ssn: patient.ssn,
                    // referralAgenecyNumber: patient.referralAgenecyNumber,
                    // membership: patient.membership,
                    // recommenderId: temp,
                    howus: patient.howus,
                    gPointUserName: profileData.gPointUserName,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                toast.success(res.data.message);
                navigate("/dashboard");
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 401) {
                        navigate("/login");
                        localStorage.clear();
                        sessionStorage.clear();
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchCountryList();
        fetchStateList(profileData.countryId);
        fetchCityList(profileData.stateId);
        recommenderList();
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("View & Update Profile")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>{t("View & Update Profile")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className="col-xs-12 ">
                <div className="add-header-wrapper gradient-orange curved-section text-center">
                    <h2 className="uppercase bold w-text">{t("View & Update Profile")}</h2>
                    <div className="before-text">{t("View & Update Profile")}</div>
                </div>

                <div className="bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        <section className="box row justify-content-center">
                            <div className="content-body my-4 border-0" style={{ paddingTop: "30px" }}>
                                <div className="row mx-1">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <label className="control-label">{t("Username")}</label>
                                            </div>

                                            <div className="controls" style={{ background: "#9b9b9b" }}>
                                                <input type="text" className="form-control" name="userName" value={profileData && profileData.userName} disabled />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="control-label">
                                                {t("First name")} <span className="required"> * </span>
                                            </label>

                                            <div className="controls">
                                                <input type="text" className="form-control" name="firstName" value={profileData && profileData.firstName} onChange={(e) => onchangeValue(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="control-label">
                                                {t("Last name")} <span className="required"> * </span>
                                            </label>

                                            <div className="controls">
                                                <input type="text" className="form-control" name="lastName" value={profileData && profileData.lastName} onChange={(e) => onchangeValue(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="control-label">
                                                {t("Phone")} <span className="required"> * </span>
                                            </label>

                                            <div className="controls">
                                                <input type="number" className="form-control" name="phone1" value={profileData && profileData.phone1} onChange={(e) => onchangeValue(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <label className="control-label">{t("Gender")}</label>
                                                </div>
                                            </div>
                                            <select className="form-control col-md-3 my-1" name="gender" value={profileData && profileData.gender} onChange={(e) => onchangeValue(e)}>
                                                {Object.keys(GENDER).map((item, i) => {
                                                    return (
                                                        <option key={i} value={GENDER[item]}>
                                                            {item}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label className="control-label">
                                                {t("Birth Date")} <span className="required"> * </span>
                                            </label>

                                            <div className="controls">
                                                <DatePicker
                                                    className="form-control input-height mr-sm-2 cursor-pointer"
                                                    name="dob"
                                                    selected={profileData && defaultTimeZone(profileData.dob)}
                                                    placeholderText={"Please Select Date"}
                                                    maxDate={new Date()}
                                                    scrollableMonthDropdown={true}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    onChange={(date) => setProfileData({ ...profileData, dob: date })}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <label className="control-label">{t("Address")}</label>
                                            </div>

                                            <div className="controls">
                                                <textarea
                                                    type="text"
                                                    rows="4"
                                                    className="form-control"
                                                    name="address1"
                                                    value={profileData && profileData.address1 ? profileData.address1 : ""}
                                                    onChange={(e) => onchangeValue(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group row">
                                            <label className="control-label col-md-12">
                                                {t("Country")} <span className="required"> * </span>
                                            </label>

                                            <div className="col-md-12">
                                                <Select
                                                    name="countryList"
                                                    value={countryOptions && countryOptions.filter(({ value }) => value == profileData.countryId)}
                                                    options={countryOptions}
                                                    onChange={onCountryChange}
                                                    placeholder={t("Select")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group row">
                                            <label className="control-label col-md-12">
                                                {t("State")} <span className="required"> * </span>
                                            </label>

                                            <div className="col-md-12">
                                                <Select
                                                    name="stateList"
                                                    value={stateOptions && stateOptions.filter(({ value }) => value == profileData.stateId)}
                                                    options={stateOptions}
                                                    onChange={onStateChange}
                                                    placeholder={t("Select")}
                                                    isDisabled={!profileData.countryId ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("City")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        <Select
                                                            name="cityList"
                                                            value={cityOptions && cityOptions.filter(({ value }) => value == profileData.cityId)}
                                                            options={cityOptions}
                                                            onChange={onCityChange}
                                                            placeholder={t("Select")}
                                                            isDisabled={!profileData.stateId ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Zip Code")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        <input type="text" className="form-control" name="zip" value={profileData.zip} onChange={onchangeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <label className="control-label">{t("SSN")}</label>
                                                </div>
                                            </div>

                                            <div className="controls">
                                                <input type="number" className="form-control" name="ssn" value={patient && patient.ssn} onChange={(e) => onchangeValue(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <label className="control-label">{t("Agent")}</label>
                                                </div>
                                            </div>

                                            <div className="controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="agent"
                                                    value={patient.agent && patient.agent ? patient.agent.firstName + " " + patient.agent.lastName + " (" + patient.agent.userName + ")" : ""}
                                                    disabled="disabled"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <label className="control-label">{t("Membership")}</label>
                                            </div>

                                            <div className="controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="membership"
                                                    value={patient && patient.membership}
                                                    // onChange={(e) => onchangeValue(e)}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <label className="control-label">{t("Recommender")}</label>
                                            </div>
                                            <div className="controls">
                                                <Select
                                                    className="fw-normal"
                                                    menuPlacement="top"
                                                    name="recommenderId"
                                                    value={recommenderOptions && recommenderOptions.filter(({ value }) => value == temp)}
                                                    options={recommenderOptions}
                                                    onChange={(e) => onRecommenderChange(e)}
                                                    placeholder={t("Select")}
                                                />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <label className="control-label">{t("How did you hear about us?")}</label>
                                            </div>

                                            <div className="controls">
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    name="howus"
                                                    placeholder={t("How did you hear about us?")}
                                                    value={patient && patient.howus ? patient.howus : ""}
                                                    onChange={(e) => onchangeValue(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <GPointWalletUserVerify show={show} setShow={setShow} inputGroup={profileData} setInputGroup={setProfileData} />
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <label className="control-label">{t("GPoint Wallet Username")}</label>
                                            </div>
                                            <div className="controls">
                                                <div className="row">
                                                    <div className="col-xl-11 col-md-8 col-sm-8 col-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="gPointUserName"
                                                            value={profileData && profileData.gPointUserName ? profileData.gPointUserName : ""}
                                                            onChange={(e) => onchangeValue(e)}
                                                            disabled="disabled"
                                                        />
                                                    </div>
                                                    <div className="col-xl-1 col-md-4 col-sm-4 col-12">
                                                        <button type="button" className="form-control btn btn-info" onClick={handleSyncWallet}>
                                                            {t("SYNC Wallet")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="padding-bottom-30 d-flex justify-content-end">
                                    <div className="text-left">
                                        <button type="button" className="btn btn-secondary mx-2" onClick={() => navigate("/dashboard")}>
                                            {t("Close")}
                                        </button>
                                        <button type="button" className="btn btn-accent gradient-orange mx-2" onClick={(e) => profileUpdate(e)}>
                                            {t("Update")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdatePatientProfile;
