import Modal from "react-bootstrap/Modal";
import { checkEmail, checkPhNo } from "../../helper/Validator";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { baseURL } from "../../helper/Config";
import { useNavigate } from "react-router-dom";
import { dateAPIFormate, defaultTimeZone } from "../../helper/dateFormates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ZoomMeetingModal(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAdd = async () => {
        if (!props.inputValue.email || props.inputValue.email === "" || props.inputValue.email === null) {
            toast.error(t(`Please enter email`));
        } else if (props.inputValue.email && checkEmail(props.inputValue.email) == false) {
            toast.error(t(`Please enter valid email`));
        } else if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter name`));
        } else if (!props.inputValue.phone || props.inputValue.phone === "" || props.inputValue.phone === null) {
            toast.error(t(`Please enter phone`));
        } else if (props.inputValue.phone && checkPhNo(props.inputValue.phone) == false) {
            toast.error(t(`Please enter valid phone`));
        } else if (!props.inputValue.date || props.inputValue.date === "" || props.inputValue.date === null) {
            toast.error(t(`Please enter date`));
        } else if (!props.inputValue.time || props.inputValue.time === "" || props.inputValue.time === null) {
            toast.error(t(`Please enter time`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/visitor/request-zoom`,
                    {
                        name: props.inputValue.name,
                        email: props.inputValue.email,
                        phone: props.inputValue.phone,
                        statusId: 4,
                        requestedDate: dateAPIFormate(props.inputValue.date),
                        requestedTime: props.inputValue.time,
                        notes: props.inputValue.notes,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            // Authorization: token,
                            // lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.handleClose();
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Request your zoom-meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="box" style={{ overflow: "inherit" }}>
                        <div className="content-body my-4 border-0">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group row">
                                        <label className="control-label col-12">
                                            {t("E-mail")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-12">
                                            <input type="email" className="form-control" name="email" value={props.inputValue.email} onChange={props.onChangeValue} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Name")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="name" value={props.inputValue.name} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Phone")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="number" className="form-control" name="phone" value={props.inputValue.phone} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Date")} <span className="required"> * </span>
                                                </label>
                                                <div className="col-md-12">
                                                    <DatePicker
                                                        className="form-control input-height mr-sm-2"
                                                        placeholderText={"Enter date"}
                                                        minDate={defaultTimeZone(new Date())}
                                                        scrollableMonthDropdown={true}
                                                        selected={props.inputValue.date && defaultTimeZone(props.inputValue.date)}
                                                        onChange={(date) => props.setInputValue({ ...props.inputValue, date: date })}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Time")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="time" className="form-control" name="time" value={props.inputValue.time} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group row">
                                        <label className="control-label col-md-12">{t("Notes")}</label>

                                        <div className="col-md-12">
                                            <textarea type="text" className="form-control" name="notes" rows={5} cols={12} value={props.inputValue.notes} onChange={props.onChangeValue} />
                                        </div>
                                    </div>
                                </div>

                                <div className="padding-bottom-30 d-flex justify-content-center">
                                    <div className="text-left">
                                        <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                            {t("Close")}
                                        </button>
                                        <button type="button" className={`btn btn-primary mx-2`} onClick={handleAdd}>
                                            {t("Request")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ZoomMeetingModal;
