import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { baseURL } from "../helper/Config";
import { toast } from "react-toastify";
import axios from "axios";
import { ROLE_ID, STATUS_REJECTED, WITHDRAWAL_METHOD } from "../helper/Constant";
import { useTranslation } from "react-i18next";

function RequestWithdrawal(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();
    let apiRouteName = profileData.roleId == ROLE_ID.AGENCY ? "agency" : profileData.roleId == ROLE_ID.AGENT ? "agent" : profileData.roleId == ROLE_ID.MANAGER ? "manager" : "";

    const [isLoading, setIsLoading] = useState(false);

    const handleRequestWithdrawal = async () => {
        if (!profileData.userName || profileData.userName === "" || profileData.userName === null) {
            toast.error(t(`Please enter username in your profile page`));
        } else if (profileData.withdrawalMethod == WITHDRAWAL_METHOD.ACH && (!profileData.achBank || profileData.achBank === "" || profileData.achBank === null)) {
            toast.error(t(`Please enter ACH bank in your profile page`));
        } else if (profileData.withdrawalMethod == WITHDRAWAL_METHOD.ACH && (!profileData.achAccount || profileData.achAccount === "" || profileData.achAccount === null)) {
            toast.error(t(`Please enter ACH account in your profile page`));
        } else if (!props.withdrawalAmount || props.withdrawalAmount === "" || props.withdrawalAmount === null) {
            toast.error(t(`Please enter withdrawal amount`));
        } else if (!profileData.w9FormStatus || profileData.w9FormStatus === "" || profileData.w9FormStatus === null || profileData.w9FormStatus != STATUS_REJECTED.Approved) {
            toast.error(t(`You need to get approval of your w9 form before requesting withdrawal amount`));
        } else {
            setIsLoading(true);
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/request-revenue`,
                    {
                        requestorId: profileData.id,
                        gDenti: profileData.userName,
                        achBank: profileData.achBank,
                        achAccount: profileData.achAccount,
                        withdrawlAmount: props.withdrawalAmount,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    toast.success(res.data.message);
                    props.setWithdrawalAmount(0);
                    props.fetchSettlementData();
                    props.checkBalance();
                    setIsLoading(false);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        setIsLoading(false);
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <div
                className={`add-header-wrapper gradient-${
                    profileData.roleId == ROLE_ID.AGENCY ? "green" : profileData.roleId == ROLE_ID.AGENT ? "green1" : profileData.roleId == ROLE_ID.MANAGER ? "blue3" : "grep"
                } curved-section text-center`}
            >
                <h2 className="uppercase bold w-text">{t("Request Withdrawal")}</h2>
                <div className="before-text">{t("Request Withdrawal")}</div>
            </div>
            <div className="bg-w">
                <div className="col-lg-6 col-lg-offset-3 col-xs-12 my-5">
                    <section className="box">
                        <div className="content-body my-4 border-0">
                            <div className="form-group row">
                                <label className="control-label col-md-12 mx-2">
                                    {t("Username")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12 d-flex">
                                    <input type="text" className="form-control mx-2" name="gDenti" value={profileData.userName} disabled />
                                </div>
                            </div>
                            {profileData.withdrawalMethod && profileData.withdrawalMethod != WITHDRAWAL_METHOD.ACH ? (
                                <div className="form-group row">
                                    <label className="control-label col-md-12 mx-2">{t("Withdrawal Method")}</label>

                                    <div className="col-md-12 d-flex">
                                        <input
                                            type="text"
                                            className="form-control mx-2"
                                            name="achBank"
                                            value={profileData.withdrawalMethod ? Object.keys(WITHDRAWAL_METHOD).find((key) => WITHDRAWAL_METHOD[key] == profileData.withdrawalMethod) : "-"}
                                            disabled
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="form-group row">
                                        <label className="control-label col-md-12 mx-2">
                                            {t("ACH Bank")} {profileData.withdrawalMethod == WITHDRAWAL_METHOD.ACH ? <span className="required"> * </span> : ""}
                                        </label>

                                        <div className="col-md-12 d-flex">
                                            <input type="text" className="form-control mx-2" name="achBank" value={profileData.achBank} disabled />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-12 mx-2">
                                            {t("ACH Account")} {profileData.withdrawalMethod == WITHDRAWAL_METHOD.ACH ? <span className="required"> * </span> : ""}
                                        </label>

                                        <div className="col-md-12 d-flex">
                                            <input type="text" className="form-control mx-2" name="achAccount" value={profileData.achAccount} disabled />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="form-group row">
                                <label className="control-label col-md-12 mx-2">
                                    {t("Withdrawal Amount")} <span className="required"> * </span>
                                </label>

                                <div className="col-md-12 d-flex">
                                    <input type="number" className="form-control mx-2" name="withdrawalAmount" value={props.withdrawalAmount} disabled />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-2">
                                <Button variant="secondary" className="my-2 mx-2" onClick={props.handleClose}>
                                    {t("Close")}
                                </Button>
                                <Button
                                    variant="primary"
                                    className={`my-2 mx-2 gradient-${
                                        profileData.roleId == ROLE_ID.ADMIN
                                            ? "blue"
                                            : profileData.roleId == ROLE_ID.DOCTOR
                                            ? "violet"
                                            : profileData.roleId == ROLE_ID.PATIENT
                                            ? "orange"
                                            : profileData.roleId == ROLE_ID.VERIFY
                                            ? "pink"
                                            : profileData.roleId == ROLE_ID.AGENCY
                                            ? "green"
                                            : profileData.roleId == ROLE_ID.AGENT
                                            ? "green1"
                                            : profileData.roleId == ROLE_ID.MANAGER
                                            ? "blue3"
                                            : "grep"
                                    }`}
                                    onClick={handleRequestWithdrawal}
                                >
                                    {isLoading ? (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{
                                                position: "relative",
                                            }}
                                        ></i>
                                    ) : (
                                        t("Request")
                                    )}
                                </Button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default RequestWithdrawal;
