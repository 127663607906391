import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../helper/Config";

function GPointWalletUserVerify(props) {
    const navigate = useNavigate();
    const languageShortName = localStorage.getItem("languageShortName");
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState({
        username: "",
        password: "",
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const handleClose = () => {
        if (!isLoading) {
            setInputValue({ username: "", password: "" });
            props.setShow(false);
        }
    };

    const handleVerify = async () => {
        if (!inputValue.username || inputValue.username === "" || inputValue.username === null) {
            toast.error(t(`Please enter username`));
        } else if (!inputValue.password || inputValue.password === "" || inputValue.password === null) {
            toast.error(t(`Please enter password`));
        } else {
            setIsLoading(true);
            await axios
                .post(
                    `${baseURL}/api/v1/gdental/user-verification`,
                    {
                        username: inputValue.username,
                        password: inputValue.password,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.setInputGroup({ ...props.inputGroup, gPointUserName: res.data.data.username });
                    toast.success(res.data.message);
                    setIsLoading(false);
                    handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                            setIsLoading(false);
                        }
                    }
                });
        }
    };

    return (
        <div>
            <Modal show={props.show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add G-Point Wallet Credentials")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="justify-content-center row my-3">
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {t("Username")} <span className="required"> * </span>
                            </label>

                            <div className="col-md-12">
                                <input type="text" className="form-control" name="username" value={inputValue.username} onChange={onChangeValue} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {t("Password")} <span className="required"> * </span>
                            </label>

                            <div className="col-md-12 d-flex">
                                <input type={passwordShown ? "text" : "password"} className="form-control" name="password" value={inputValue.password} onChange={onChangeValue} />
                                <i className={`${passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`} id="basic-addon1" onClick={togglePassword}></i>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={isLoading ? "" : handleVerify}>
                        {isLoading ? (
                            <i
                                className="fa fa-spinner fa-spin"
                                style={{
                                    position: "relative",
                                }}
                            ></i>
                        ) : (
                            t("Verify")
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GPointWalletUserVerify;
