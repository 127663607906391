import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { checkGoogleLink, checkPhNo } from "../../helper/Validator";
import _ from "lodash";
import Select from "react-select";
import { DFLT_BSN_CTGR_ID } from "../../helper/Constant";
import GoogleMapHelpImage from "../GoogleMapHelpImage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpdateDoctorProfile = () => {
  const {
    profileData,
    setProfileData,
    officeData,
    setOfficeData,
    checkedList,
    setCheckedList,
    specialityCheckedList,
    setSpecialityCheckedList,
  } = useOutletContext();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");

  const [doctorId] = useState(profileData && profileData.id);
  const [officeId] = useState(officeData && officeData.id);

  const [showMapImage, setShowMapImage] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [inputValueObj, setInputValueObj] = useState([]);
  let InputObj = inputValueObj;

  const [treatmentData, setTreatmentData] = useState([]);

  const [specialityPartList, setSpecialityPartList] = useState([]);

  const [step, setStep] = useState(2);

  const [serviceLangList, setServiceLangList] = useState([]);

  const [trmtGrpOpen, setTrmtGrpOpen] = useState([true, true, true, true]);

  const handleOpenClose = (e, i) => {
    setTrmtGrpOpen({ ...trmtGrpOpen, [i]: !trmtGrpOpen[i] });
  };

  const onchangeValue = (e, step) => {
    let value1 = e.target.value;

    if (value1 && parseFloat(value1) < 0) {
      e.target.value = 0;
      return;
    }

    const { name, value } = e.target;

    setProfileData({ ...profileData, [name]: value });

    setOfficeData({ ...officeData, [name]: value });
  };

  const fetchCountryList = async () => {
    await axios
      .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
        isId: true,
      })
      .then((res) => {
        setCountryList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const countryOptions =
    countryList &&
    countryList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const onCountryChange = (e) => {
    setOfficeData({
      ...officeData,
      countryId: e.value,
      stateId: "",
      cityId: "",
    });
    setStateList([]);
    setCityList([]);
    fetchStateList(e.value);
  };

  const fetchStateList = async (countryId) => {
    await axios
      .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
        countryId: countryId,
        isId: true,
      })
      .then((res) => {
        setStateList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const stateOptions =
    stateList &&
    stateList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const onStateChange = (e) => {
    setOfficeData({ ...officeData, stateId: e.value });
    fetchCityList(e.value);
  };

  const fetchCityList = async (stateId) => {
    await axios
      .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
        stateId: stateId,
        isId: true,
      })
      .then((res) => {
        setCityList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const cityOptions =
    cityList &&
    cityList.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const onCityChange = (e) => {
    setOfficeData({ ...officeData, cityId: e.value });
  };

  const serviceDetails = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/doctor/treatment/list?isPagination=false`,
        {
          officeId: officeId,
          isListAllRecord: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setTreatmentData(res.data.data);
        let treatmentTypeList = res.data.data;
        const groupbydata = _.chain(treatmentTypeList)
          .groupBy("treatmentTypeGroup")
          .map(function (value, key) {
            return {
              treatmentTypeGroup: key,
              data: value,
            };
          })
          .value();
        setTreatmentData(groupbydata);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const serviceLanguageList = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/language/list?isPagination=false`,
        {
          doctorId: doctorId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setServiceLangList(res.data.data);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const handleOriginalFeeChange = async (e, fid, GName) => {
    let id = fid;
    let value = e.target.value;
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate =
      value == 0
        ? 0
        : (
            (Math.round(value - Number(treatmentId.discountFee)) / value) *
            100
          ).toFixed(2);

    if (value > -1) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(discountRate),
        discountFee: 0,
        originalFee: value,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };

      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;
      setTreatmentData([...oldgropudata]);

      let newObj = {
        treatmentId: Number(id),
        originalFee: value,
        discountFee: 0,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };

      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      // toast.error(t(`Original fee cannot be less than discount fee`));
      toast.error(t(`Invalid amount entered`));
    }
  };

  const handleDiscountFeeChange = async (e, fid, GName) => {
    let id = fid;
    let value = e.target.value;
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate =
      value == 0
        ? 100
        : (
            ((treatmentId.originalFee - value) / treatmentId.originalFee) *
            100
          ).toFixed(2);

    if (value <= Number(treatmentId.originalFee)) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(treatmentId.discountRate),
        discountFee: value,
        originalFee: Number(treatmentId.originalFee),
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };
      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;

      setTreatmentData([...oldgropudata]);
      let newObj = {
        treatmentId: Number(id),
        originalFee: treatmentId.originalFee,
        discountFee: value,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };
      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      // toast.error(t(`Discount fee cannot be greater than original fee`));
      toast.error(t(`Invalid discount fee entered`));
    }
  };

  const handleCustomRateChange = async (e, fid, GName) => {
    let id = fid;
    let value = Number(e.target.value);

    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate = value;
    let discountFee =
      value == 0
        ? Math.round(treatmentId.originalFee).toFixed(2)
        : (
            Math.round(treatmentId.originalFee) -
            Math.round(treatmentId.originalFee) * (value / 100)
          ).toFixed(2);

    if (value <= 100) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(treatmentId.discountRate),
        discountFee: discountFee,
        originalFee: Number(treatmentId.originalFee),
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };
      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;
      setTreatmentData([...oldgropudata]);

      let newObj = {
        treatmentId: Number(id),
        originalFee: treatmentId.originalFee,
        discountFee: discountFee,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };

      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      toast.error(t(`Discount rate cannot be greater than 100%`));
    }
  };

  const handleCheckChange = async (e, fid, GName) => {
    let id = fid;
    let oldgropudata = treatmentData;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );

    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let newobj = {
      code: treatmentId.code,
      description: treatmentId.description,
      id: Number(treatmentId.id),
      name: treatmentId.name,
      treatmentType: treatmentId.treatmentType,
      treatmentTypeGroup: treatmentId.treatmentTypeGroup,
      discountRate: Number(treatmentId.discountRate),
      discountFee: Number(treatmentId.discountFee),
      originalFee: Number(treatmentId.originalFee),
      isFreeOnceYear: !e.target.checked ? 1 : 0,
      unit: Number(treatmentId.unit),
    };
    objTreatmentdata[treatmentId_index] = newobj;
    oldgropudata[filterddata_index].data = objTreatmentdata;
    setTreatmentData([...oldgropudata]);
    let newObj = {
      treatmentId: Number(id),
      originalFee: treatmentId.originalFee,
      discountFee: treatmentId.discountFee,
      discountRate: treatmentId.discountRate,
      isFreeOnceYear: !e.target.checked ? 1 : 0,
    };
    let index = InputObj.findIndex((p) => p.treatmentId == id);
    if (index != -1) {
      InputObj[index] = newObj;
    } else {
      InputObj.push(newObj);
    }
    setInputValueObj(InputObj);
  };

  const fetchSpecialityPartdata = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/speciality-part/list?isPagination=false`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setSpecialityPartList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleSpecialityCheckChange = (e) => {
    const value = Number(e.target.value);
    let specialityCheckedListData = specialityCheckedList;
    const index = specialityCheckedListData.indexOf(value);
    if (index > -1) {
      specialityCheckedListData.splice(index, 1);
      if (index == 0) {
        setSpecialityCheckedList([...specialityCheckedList]);
      } else {
        setSpecialityCheckedList([
          ...specialityCheckedList,
          ...specialityCheckedList.slice(index, 1),
        ]);
      }
    } else {
      setSpecialityCheckedList([...specialityCheckedList, value]);
    }
  };

  const checkedchange = (e) => {
    const value = Number(e.target.value);
    let checkedListData = checkedList;
    const index = checkedListData.indexOf(value);
    if (index > -1) {
      checkedListData.splice(index, 1);
      if (index == 0) {
        setCheckedList([...checkedList]);
      } else {
        setCheckedList([...checkedList, ...checkedList.slice(index, 1)]);
      }
    } else {
      setCheckedList([...checkedList, value]);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (!profileData.firstName) {
      toast.error(t(`Please enter first name`));
      return false;
    }

    if (!profileData.lastName) {
      toast.error(t(`Enter Last Name`));
      return false;
    }

    if (!profileData.phone1) {
      toast.error(t(`Enter Mobile Number`));
      return false;
    }

    if (checkPhNo(profileData.phone1) == false) {
      toast.error(t(`Enter Valid Mobile Number`));
      return false;
    }

    await axios
      .post(
        `${baseURL}/api/v1/check`,
        {
          step: 1,
          isNotNeededField: true,
          doctorId: doctorId,
          userName: profileData.userName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then(async (res) => {
        if (res.data.success == true) {
          // setStep(3);

          // if (step == 3) {
          await axios
            .post(
              `${baseURL}/api/v1/doctor/profile/update`,
              {
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                userName: profileData.userName,
                gender: "",
                dob: "",
                phone1: profileData.phone1,
                address1: "",
                stateId: "",
                cityId: "",
                zip: "",
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                  lang: languageShortName,
                },
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              navigate("/dashboard");
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 401) {
                  navigate("/login");
                  localStorage.clear();
                  sessionStorage.clear();
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
        // }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleOfficeUpdate = async (e) => {
    e.preventDefault();

    if (!officeData.name) {
      toast.error(t(`Enter Office Name`));
      return false;
    }

    if (!officeData.googleMapLink) {
      toast.error(t(`Enter Google Map Link`));
      return false;
    }

    if (checkGoogleLink(officeData.googleMapLink) == false) {
      toast.error(t(`Enter Valid Google Map Link`));
      return false;
    }

    if (!officeData.address1) {
      toast.error(t(`Enter Office Address`));
      return false;
    }

    if (!officeData.phone1) {
      toast.error(t(`Enter Phone Number`));
      return false;
    }

    if (checkPhNo(officeData.phone1) == false) {
      toast.error(t(`Enter Valid Phone Number`));
      return false;
    }

    if (!officeData.languageId) {
      toast.error(t(`Select Service Language`));
      return false;
    }

    serviceDetails();
    fetchSpecialityPartdata();

    setStep(3);

    if (step == 3) {
      await axios
        .post(
          `${baseURL}/api/v1/doctor/office/update`,
          {
            officeId: officeId,
            name: officeData.name,
            businessUsername: officeData.businessUsername,
            phone1: officeData.phone1,
            npi1: officeData.npi1,
            licenseNumber: officeData.licenseNumber,
            languageId:
              checkedList && checkedList.length
                ? String(checkedList).replaceAll(",", "~")
                : "0",
            businessCategoryId: DFLT_BSN_CTGR_ID,
            googleMapLink: officeData.googleMapLink,
            address1: officeData.address1,
            verifierApplied: officeData.verifierApplied,

            officeTreatmentFee: inputValueObj,
            specialtyPartId:
              specialityCheckedList && specialityCheckedList.length
                ? String(specialityCheckedList).replaceAll(",", "~")
                : "0",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
              lang: languageShortName,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          navigate("/dashboard");
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 401) {
              navigate("/login");
              localStorage.clear();
              sessionStorage.clear();
            } else {
              toast.error(err.response.data.message);
            }
          }
        });
    }
  };

  useEffect(() => {
    if (doctorId) {
      serviceLanguageList();
    }
    fetchCountryList();
    fetchStateList(officeData.countryId);
    fetchCityList(officeData.stateId);
  }, [doctorId]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="col-xs-12">
        <div className="page-title">
          <div className="pull-left">
            <h1 className="title">{t("View & Update Profile")}</h1>
          </div>

          <div className="pull-right">
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard">
                  <i className="fa fa-home"></i>
                  {t("Home")}
                </Link>
              </li>
              <li className="active cursor-pointer">
                <strong>{t("View & Update Profile")}</strong>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="col-xs-12 ">
        <div className="add-header-wrapper gradient-violet curved-section text-center">
          <h2 className="uppercase bold w-text">
            {t("View & Update Profile")}
          </h2>
          <div className="before-text">{t("View & Update Profile")}</div>
        </div>

        <div
          style={{
            background: "white",
            width: "auto",
          }}
        >
          <div className="col-lg-12 col-xs-12 px-0">
            <section className="box1">
              <div className="content-body my-4 border-0">
                <div className="row mx-3">
                  <div className="col-xs-12">
                    <div className="row">
                      {/* {step == 1 ? (
                                                <> */}
                      <header className="panel_header border-0">
                        <h2 className="fs-5 pull-left">
                          <u>
                            {t("Doctor")} {t("Information")}
                          </u>
                        </h2>
                      </header>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="control-label">
                              {t("E-mail")}
                            </label>
                          </div>

                          <div className="controls">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="txtEmail"
                              defaultValue={profileData && profileData.email}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="control-label">
                              {t("Username")}
                            </label>
                          </div>

                          <div className="controls">
                            <input
                              type="text"
                              className="form-control"
                              name="userName"
                              id="txtUserName"
                              defaultValue={profileData && profileData.userName}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="control-label">
                            {t("First name")}{" "}
                            <span className="required"> * </span>
                          </label>

                          <div className="controls">
                            <input
                              type="text"
                              className="form-control"
                              name="firstName"
                              id="txtFirstName"
                              value={profileData && profileData.firstName}
                              onChange={(e) => onchangeValue(e, 1)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="control-label">
                            {t("Last name")}{" "}
                            <span className="required"> * </span>
                          </label>

                          <div className="controls">
                            <input
                              type="text"
                              className="form-control"
                              name="lastName"
                              id="txtLastName"
                              value={profileData && profileData.lastName}
                              onChange={(e) => onchangeValue(e, 1)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label className="control-label">
                            {t("Phone")} <span className="required"> * </span>
                          </label>

                          <div className="controls">
                            <input
                              type="number"
                              className="form-control"
                              name="phone1"
                              id="txtLastName"
                              value={profileData && profileData.phone1}
                              maxLength="10"
                              onChange={(e) => onchangeValue(e, 1)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* </> */}
                      {/* ) } */}
                    </div>
                  </div>

                  <div className="padding-bottom-30 d-flex justify-content-end">
                    <div className="text-left">
                      <button
                        type="button"
                        className="btn btn-secondary mx-4"
                        onClick={() => navigate("/dashboard")}
                      >
                        {t("Close")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-purple gradient-violet me-2"
                        onClick={(e) => handleProfileUpdate(e)}
                      >
                        {t("Update")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-lg-12 col-xs-12 px-0">
            <section className="box1 mt-0">
              <div className="content-body my-4 border-0">
                <div className="row mx-3">
                  <div className="col-lg-12">
                    <div className="row">
                      {step == 2 ? (
                        <>
                          <header className="panel_header border-0">
                            <h2 className="fs-5 pull-left">
                              <u>
                                {t("Office")} {t("Information")}
                              </u>
                            </h2>
                          </header>

                          <div className="row">
                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      <label className="control-label">
                                        {t("E-mail")}
                                      </label>
                                    </div>
                                    <div className="controls">
                                      <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="txtEmail"
                                        defaultValue={
                                          officeData && officeData.email
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      <label className="control-label">
                                        {t("Business Username")}
                                      </label>
                                    </div>
                                    <div className="controls">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="businessUsername"
                                        id="txtBusinessUserName"
                                        defaultValue={
                                          officeData &&
                                          officeData.businessUsername
                                        }
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label">
                                      {t("Office")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="txtName"
                                        defaultValue={
                                          officeData && officeData.name
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <GoogleMapHelpImage
                                  showMapImage={showMapImage}
                                  setShowMapImage={setShowMapImage}
                                />

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Google Map Link")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12 d-flex">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="googleMapLink"
                                        value={officeData.googleMapLink}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                      <button
                                        className="btn mx-1"
                                        onClick={() => setShowMapImage(true)}
                                      >
                                        ?
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label">
                                      {t("Address")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="address1"
                                        id="txtAddress1"
                                        defaultValue={
                                          officeData && officeData.address1
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Country")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <Select
                                        name="countryList"
                                        value={
                                          countryOptions &&
                                          countryOptions.filter(
                                            ({ value }) =>
                                              value == officeData.countryId
                                          )
                                        }
                                        options={countryOptions}
                                        onChange={onCountryChange}
                                        placeholder={t("Select")}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("State")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <Select
                                        name="stateList"
                                        value={
                                          stateOptions &&
                                          stateOptions.filter(
                                            ({ value }) =>
                                              value == officeData.stateId
                                          )
                                        }
                                        options={stateOptions}
                                        onChange={onStateChange}
                                        placeholder={t("Select")}
                                        isDisabled={
                                          !officeData.countryId ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("City")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <Select
                                        name="cityList"
                                        value={
                                          cityOptions &&
                                          cityOptions.filter(
                                            ({ value }) =>
                                              value == officeData.cityId
                                          )
                                        }
                                        options={cityOptions}
                                        onChange={onCityChange}
                                        placeholder={t("Select")}
                                        isDisabled={
                                          !officeData.stateId ? true : false
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group row">
                                    <label className="control-label col-md-12">
                                      {t("Zip Code")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="col-md-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="zip"
                                        value={officeData.zip}
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label">
                                      {t("Phone")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div className="controls">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="phone1"
                                        id="txtPhone1"
                                        maxLength="10"
                                        defaultValue={
                                          officeData && officeData.phone1
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  {/* <div className="form-group">
                                                                <div className="d-flex">
                                                                    <label className="control-label">Business Category</label>
                                                                </div>

                                                                <div className="controls">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="businessCategoryId"
                                                                        id="txtBusinessCategory"
                                                                        value={officeData && officeData.businessCategoryId}
                                                                        onChange={(e) => onchangeValue(e, 2)}
                                                                    />
                                                                </div>
                                                            </div> */}

                                  <div className="form-group">
                                    <div className="d-flex">
                                      <label className="control-label">
                                        {t("License Number")}
                                      </label>
                                    </div>
                                    <div className="controls">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="licenseNumber"
                                        id="txtLicense"
                                        defaultValue={
                                          officeData && officeData.licenseNumber
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      <label className="control-label">
                                        {t("NPI 1")}
                                      </label>
                                    </div>
                                    <div className="controls">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="npi1"
                                        id="txtNpi1"
                                        defaultValue={
                                          officeData && officeData.npi1
                                        }
                                        onChange={(e) => onchangeValue(e, 2)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <label className="control-label">
                                      {t("Language")}{" "}
                                      <span className="required"> * </span>
                                    </label>

                                    <div
                                      className="mt-2"
                                      style={{
                                        display: `${
                                          windowSize.width > 768
                                            ? "flex"
                                            : "inline"
                                        } `,
                                        alignItems: "flex-start",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {serviceLangList &&
                                        serviceLangList.map((item, i) => {
                                          return (
                                            <div
                                              className="d-flex mt-2"
                                              key={i}
                                            >
                                              <div className="mx-2">
                                                <input
                                                  type="checkbox"
                                                  className="checkBox "
                                                  value={item.id}
                                                  id={item.id}
                                                  name={item.id}
                                                  checked={
                                                    checkedList &&
                                                    checkedList.includes(
                                                      item.id
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    checkedchange(e, 2)
                                                  }
                                                />
                                              </div>
                                              <label
                                                htmlFor={item.id}
                                                className="ml-3 mr-15"
                                              >
                                                {item.name}
                                              </label>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                  <div className="form-group">
                                    <div className="d-flex">
                                      <label className="control-label">
                                        {t("Verifier Applied")}
                                      </label>
                                    </div>
                                    <div className="controls">
                                      <div
                                        className="mt-2"
                                        style={{
                                          display: `${
                                            windowSize.width > 768
                                              ? "flex"
                                              : "inline"
                                          } `,
                                          alignItems: "flex-start",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <div className="d-flex mt-2">
                                          <div className="mx-2">
                                            <input
                                              type="radio"
                                              className="mx-2"
                                              name="verifierApplied"
                                              value="1"
                                              checked={
                                                officeData &&
                                                officeData.verifierApplied == 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                onchangeValue(e, 2)
                                              }
                                            />
                                          </div>
                                          <label className="ml-3 mr-15">
                                            Yes
                                          </label>
                                          <div className="mx-2">
                                            <input
                                              type="radio"
                                              className="mx-2"
                                              name="verifierApplied"
                                              value="0"
                                              checked={
                                                officeData &&
                                                officeData.verifierApplied == 0
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                onchangeValue(e, 2)
                                              }
                                            />
                                          </div>
                                          <label className="ml-3 mr-15">
                                            No
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : step == 3 ? (
                        <>
                          <div className="col-lg-12">
                            <header className="panel_header border-0">
                              <h2 className="fs-5 pull-left">
                                <u>
                                  {t("Service And Cost")} {t("Information")}
                                </u>
                              </h2>
                            </header>
                            <div className="form-group align-items-center">
                              {treatmentData &&
                                treatmentData.map((item, index) => {
                                  return (
                                    <Fragment>
                                      <div
                                        className="row align-items-center my-2 border"
                                        key={index}
                                      >
                                        <div className="col-1">
                                          <button
                                            id={index}
                                            className="btn transparent"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#treatmentGroup${index}`}
                                            aria-expanded="false"
                                            aria-controls={`treatmentGroup${index}`}
                                            onClick={(e) =>
                                              handleOpenClose(e, index)
                                            }
                                          >
                                            {!trmtGrpOpen[index] ? (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                // fill="currentColor"
                                                className="bi bi-plus-square-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-dash-square-fill"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                                              </svg>
                                            )}
                                          </button>
                                        </div>
                                        <div className="col-3">
                                          <h5 className="mt-2 fw-bold fs-6">
                                            {item.treatmentTypeGroup ==
                                              "undefined" ||
                                            item.treatmentTypeGroup ==
                                              undefined ||
                                            item.treatmentTypeGroup ==
                                              "No-Group"
                                              ? ""
                                              : item.treatmentTypeGroup.toUpperCase()}
                                          </h5>
                                        </div>
                                        <div className="col-2 justify-content-center text-center">
                                          <h5 className="mt-2 fw-bold">
                                            {t("Original Fee")}
                                          </h5>
                                        </div>
                                        <div className="col-2 justify-content-center text-center">
                                          <h5 className="mt-2 fw-bold">
                                            {t("Discount Fee")}
                                          </h5>
                                        </div>
                                        <div className="col-2 justify-content-center text-center">
                                          <h5 className="mt-2 fw-bold">
                                            {t("Discount Rate")}
                                          </h5>
                                        </div>
                                        <div className="col-2"></div>
                                      </div>
                                      {item.data.map((item1, i) => (
                                        <div
                                          className={`collapse ${
                                            index < 4 ? "show" : ""
                                          }`}
                                          id={`treatmentGroup${index}`}
                                        >
                                          <div className="row my-1 align-items-center">
                                            <div className="col-1"></div>
                                            <div className="col-3 my-2">
                                              {item1.name}
                                            </div>
                                            {/* Orifinal Fee */}
                                            <div className="col-2 ">
                                              <input
                                                id={item1.id}
                                                name={item1.name}
                                                type="text"
                                                className="form-control my-1"
                                                pattern="\d*"
                                                min={0}
                                                max={20000}
                                                maxLength={7}
                                                value={item1.originalFee}
                                                // value={item1.originalFee.toLocaleString(
                                                //   "en-US"
                                                // )}
                                                onChange={(e) =>
                                                  handleOriginalFeeChange(
                                                    e,
                                                    item1.id,
                                                    item1.treatmentTypeGroup
                                                  )
                                                }
                                              />
                                            </div>
                                            {/* Discount Fee */}
                                            <div className="col-2 ">
                                              <input
                                                id={item1.id}
                                                name={item1.name}
                                                type="text"
                                                className="form-control my-1"
                                                pattern="\d*"
                                                min={0}
                                                max={20000}
                                                maxLength={7}
                                                value={item1.discountFee}
                                                // value={item1.discountFee.toLocaleString(
                                                //   "en-US"
                                                // )}
                                                onChange={(e) =>
                                                  handleDiscountFeeChange(
                                                    e,
                                                    item1.id,
                                                    item1.treatmentTypeGroup
                                                  )
                                                }
                                              />
                                            </div>
                                            {/* Discount Rate Dropdown */}
                                            <div className="btn-group col-2 mt-1">
                                              <button
                                                id={item1.id}
                                                className="btn btn-secondary dropdown-toggle w-100"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                {`${item1.discountRate}%`}
                                              </button>
                                              <div className="dropdown-menu">
                                                <button
                                                  name="30%"
                                                  value={30}
                                                  className="dropdown-item"
                                                  type="button"
                                                  onClick={(e) => {
                                                    handleCustomRateChange(
                                                      e,
                                                      item1.id,
                                                      item1.treatmentTypeGroup
                                                    );
                                                  }}
                                                >
                                                  30%
                                                </button>
                                                <button
                                                  name="40%"
                                                  value={40}
                                                  className="dropdown-item"
                                                  type="button"
                                                  onClick={(e) => {
                                                    handleCustomRateChange(
                                                      e,
                                                      item1.id,
                                                      item1.treatmentTypeGroup
                                                    );
                                                  }}
                                                >
                                                  40%
                                                </button>
                                                <button
                                                  name="50%"
                                                  value={50}
                                                  className="dropdown-item"
                                                  type="button"
                                                  onClick={(e) => {
                                                    handleCustomRateChange(
                                                      e,
                                                      item1.id,
                                                      item1.treatmentTypeGroup
                                                    );
                                                  }}
                                                >
                                                  50%
                                                </button>
                                                <input
                                                  name="custom-rate"
                                                  type="text"
                                                  className="dropdown-item"
                                                  placeholder="Custom (%)"
                                                  onChange={(e) => {
                                                    handleCustomRateChange(
                                                      e,
                                                      item1.id,
                                                      item1.treatmentTypeGroup
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {/* ---------------------- */}
                                            {/* <div className="col">
                                            <input
                                              type="radio"
                                              name="custom"
                                              id="custom"
                                            />
                                            <label for="custom">
                                              Custom(%)
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id={item1.id}
                                              value={`${item1.discountRate}%`}
                                              disabled
                                            />
                                          </div> */}
                                            {/* ---------------------- */}
                                            <div className="col text-center">
                                              <input
                                                className="checkBox col "
                                                type="checkbox"
                                                id={item1.id + "onceAYear"}
                                                value={item1.id}
                                                onChange={(e) =>
                                                  handleCheckChange(
                                                    e,
                                                    item1.id,
                                                    item1.treatmentTypeGroup
                                                  )
                                                }
                                                checked={
                                                  item1.isFreeOnceYear == "1" ||
                                                  item1.isFreeOnceYear == 1 ||
                                                  item1.isFreeOnceYear == true
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className="col mt-2 ms-2"
                                                htmlFor={item1.id + "onceAYear"}
                                              >
                                                {t("Free Once A Year")}
                                              </label>
                                            </div>
                                            {/* <div className="col-9 my-1">{t("Free Once A Year")}</div> */}
                                          </div>
                                        </div>
                                      ))}
                                    </Fragment>
                                  );
                                })}
                              <div className="col-12 my-4">
                                <div className="col-4">
                                  <h5 className="mx-5 mt-2 fw-bold">
                                    {"speciality parts in dentist".toUpperCase()}
                                  </h5>
                                </div>

                                <hr
                                  className="my-3"
                                  style={{
                                    border: "1px solid",
                                    height: "0.1rem",
                                  }}
                                />
                                <div className="row d-flex align-items-center">
                                  {specialityPartList &&
                                    specialityPartList.map((item, i) => {
                                      return (
                                        <Fragment key={i}>
                                          <div className="col-1 text-end">
                                            <input
                                              type="checkbox"
                                              className="checkBox "
                                              value={item.id}
                                              id={item.id + "speciality"}
                                              name={item.id}
                                              checked={
                                                specialityCheckedList &&
                                                specialityCheckedList.includes(
                                                  item.id
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={
                                                handleSpecialityCheckChange
                                              }
                                            />
                                          </div>
                                          <label
                                            htmlFor={item.id + "speciality"}
                                            className="col-2 my-1"
                                          >
                                            {item.name}
                                          </label>
                                        </Fragment>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    className={`${
                      step == 2
                        ? "d-flex justify-content-center"
                        : "pt-4 pb-2 d-flex justify-content-center"
                    }`}
                  >
                    <div className="text-left">
                      {step == 2 ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          className="btn gradient-blue1 text-white"
                          onClick={() => setStep(2)}
                        >
                          {t("Previous")}
                        </button>
                      )}
                      <button
                        type="button"
                        className={`btn btn-secondary ${
                          step == 2 ? "mx-2" : "mx-4"
                        }`}
                        onClick={() => navigate("/dashboard")}
                      >
                        {t("Close")}
                      </button>
                      <button
                        type="button"
                        className={`btn btn-purple gradient-violet ${
                          step == 2 ? "mx-4" : ""
                        }`}
                        onClick={(e) => handleOfficeUpdate(e)}
                      >
                        {step == 2 ? t("Next") : t("Update")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateDoctorProfile;
