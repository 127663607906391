import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/AuthPatient/Login";
import ActivatePatient from "./components/AuthPatient/ActivatePatient";
import Registration from "./components/AuthPatient/SignUp";
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoctorSignUp from "./components/AuthDoctor/SignUp";
import AgentSignUp from "./components/AuthAgent/SignUp";
import LanguageList from "./components/adminPanel/Configuration/language/LanguageList";
import ChangePassword from "./commoncomponents/ChangePassword";
import TreatmentList from "./components/adminPanel/Configuration/treatment/TreatmentList";
import TreatmentTypeList from "./components/adminPanel/Configuration/treatment-type/TreatmentTypeList";
import ViewProfile from "./components/profile/ViewProfile";
import RecommenderList from "./components/adminPanel/Configuration/recommender/RecommenderList";
import PrivateRoutes from "./components/Layout/PrivateRoutes";
import PageNotFound from "./commoncomponents/PageNotFound";
import DoctorList from "./components/adminPanel/manageDoctor/DoctorList";
import OfficeList from "./components/adminPanel/manageOffice/OfficeList";
import PatientList from "./components/adminPanel/managePatient/PatientList";
import ClaimList from "./components/adminPanel/manageClaim/ClaimList";
import RoleList from "./components/adminPanel/Configuration/roles/RoleList";
import PageList from "./components/adminPanel/Configuration/pages/PageList";
import EmployeeList from "./components/adminPanel/employee/EmployeeList";
import EvaluationList from "./components/adminPanel/Configuration/evaluation/EvaluationList";
import ManageEvaluationList from "./components/adminPanel/manageEvaluation/EvaluationList";
import Dashboard from "./commoncomponents/dashboard/Dashboard";
import UpdateProfile from "./commoncomponents/updateProfile/UpdateProfile";
import Cookies from "universal-cookie";
import GetInTouch from "./components/Home/GetInTouch";
import Enroll from "./components/Home/Enroll";
import NearMe from "./components/Home/NearMe";
import Services from "./components/Home/Services";
import FAQ from "./components/Home/FAQ";
import Practitioners from "./components/Home/Practitioners";
import SelectDental from "./components/AuthDoctor/SelectDental";
import OfficeForDoctorList from "./components/adminPanel/manageDoctor/OfficeForDoctorList";
import SpecialityPartsList from "./components/adminPanel/Configuration/specialityPart/SpecialityPartList";
import CountryList from "./components/adminPanel/Configuration/address/country/CountryList";
import VerifierList from "./components/adminPanel/verifier/VerifierList";
import VerifyClaimList from "./components/adminPanel/manageVerifyClaim/VerifyClaimList";
import GdentalPatientList from "./components/doctorPanel/GdentalPatientList";
import SearchDental from "./components/patientPanel/searchDental/SearchDental";
import TransactionPage from "./components/patientPanel/gdentalMembershipPlans/transactionPages/TransactionPage";
import MembershipList from "./components/adminPanel/manageMembershipPlan/MembershipPlanList";
import DentalCompareList from "./components/patientPanel/compareDental/CompareDentalList";
import OrderHistoryList from "./components/patientPanel/orderHistory/OrderHistoryList";
import ForgotPassword from "./commoncomponents/ForgotPassword";
import ResetPassword from "./commoncomponents/ResetPassword";
import AgentList from "./components/adminPanel/manageAgent/AgentList";
import AgencyList from "./components/adminPanel/manageAgency/AgencyList";
import PatientRevenueList from "./components/adminPanel/managePatient/PatientRevenueList";
import AgentRevenueList from "./components/adminPanel/manageAgent/AgentRevenueList";
import SnsList from "./components/adminPanel/manageSns/SnsList";
import GPCouponList from "./components/adminPanel/manageSns/GPCouponList";
import { baseURL } from "./helper/Config";
import axios from "axios";
import ManagerList from "./components/adminPanel/manageManager/ManagerList";
import ProgressList from "./components/adminPanel/Configuration/current-progress/ProgressList";
import OfficeEmployeeList from "./components/adminPanel/manageOfficeEmployee/OfficeEmployeeList";
import ShowServiceAndCost from "./components/doctorPanel/ShowServiceAndCost";
import SettlementRevenueList from "./components/adminPanel/manageSettlement/SettlementList";
import ManagerAgencyList from "./components/adminPanel/manageManager/ManagerAgencyList";
import W9FormList from "./components/adminPanel/Configuration/manageW9Form/W9FormList";
import ZoomMeetingList from "./components/adminPanel/manageZoomMeeting/ZoomMeetingList";
import PrintMyCard from "./components/patientPanel/printMyCard/PrintMyCard";
import AgencyRevenueList from "./components/adminPanel/manageAgency/AgencyRevenueList";

function App() {
  const cookies = new Cookies();
  let pageAccess =
    cookies.get("pageAccess") && cookies.get("pageAccess").length
      ? cookies.get("pageAccess")
      : [];
  // let hours = 10; // to clear the localStorage after 10 hour
  // let now = new Date().getTime();
  // let setupTime = localStorage.getItem("setupTime");
  // if (setupTime == null) {
  //     localStorage.setItem("setupTime", now);
  // } else {
  //     if (now - setupTime > hours * 60 * 60 * 1000) {
  //         localStorage.clear()
  // sessionStorage.clear();
  //     }
  // }

  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");

  const handleLogout = async () => {
    await axios
      .get(`${baseURL}/api/v1/logout`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        cookies.remove("pageAccess");
        cookies.remove("office");
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (token) {
      const handleUserInactivity = () => {
        handleLogout();
      };
      const activityTimer = setTimeout(handleUserInactivity, 1200000);
      return () => {
        clearTimeout(activityTimer);
      };
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route exact path="/signup" element={<Registration />} />
        <Route path="/authDoctor/signup" element={<DoctorSignUp />} />
        <Route path="/authAgent/signup" element={<AgentSignUp />} />
        <Route exact path="/" element={<Home />} />
        {/* Figma to React */}
        <Route exact path="/get-in-touch" element={<GetInTouch />} />
        <Route exact path="/enroll" element={<Enroll />} />
        <Route exact path="/practitioners" element={<Practitioners />} />
        <Route exact path="/near-me" element={<NearMe />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/faq" element={<FAQ />} />
        {/*  */}
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:link" element={<ResetPassword />} />
        <Route exact path="/select-dental" element={<SelectDental />} />
        <Route exact path="/membership/:id" element={<TransactionPage />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/activate-patient" element={<ActivatePatient />} />

          <Route element={<Layout />}>
            <Route exact path="/update-profile" element={<UpdateProfile />} />
            <Route
              exact
              path="/dashboard"
              element={
                (pageAccess && pageAccess.includes("Dashboard")) ||
                pageAccess.length == 0 ? (
                  <Dashboard />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/view-profile" element={<ViewProfile />} />
            {/* CONFIG */}
            <Route
              exact
              path="/manage-news"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <ProgressList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/speciality-part"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <SpecialityPartsList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/language"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <LanguageList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/evaluation"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <EvaluationList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/treatment"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <TreatmentList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/treatment-type"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <TreatmentTypeList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/recommender"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <RecommenderList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/country"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <CountryList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/page"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <PageList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/role"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <RoleList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/manage-w9form"
              element={
                (pageAccess && pageAccess.includes("Configuration")) ||
                pageAccess.length == 0 ? (
                  <W9FormList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* ORDER-HISTORY */}
            <Route
              exact
              path="/manage-order-history"
              element={
                (pageAccess && pageAccess.includes("Manage-order-history")) ||
                pageAccess.length == 0 ? (
                  <OrderHistoryList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* DOCTOR */}
            <Route
              exact
              path="/manage-doctor"
              element={
                (pageAccess && pageAccess.includes("Manage-doctor")) ||
                pageAccess.length == 0 ? (
                  <DoctorList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* OFFICE */}
            <Route
              exact
              path="/manage-office"
              element={
                (pageAccess && pageAccess.includes("Manage-office")) ||
                pageAccess.length == 0 ? (
                  <OfficeList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/manage-doctor-office"
              element={<OfficeForDoctorList />}
            />

            {/* PATIENT */}
            <Route
              exact
              path="/manage-patient"
              element={
                (pageAccess && pageAccess.includes("Manage-patient")) ||
                pageAccess.length == 0 ? (
                  <PatientList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* REVENUE-PATIENT */}
            <Route
              exact
              path="/manage-revenue-patient"
              element={
                (pageAccess && pageAccess.includes("Manage-agent")) ||
                pageAccess.length == 0 ? (
                  <PatientRevenueList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* G-DENTAL MEMBERSHIP PLANS */}
            <Route
              exact
              path="/g-dental-membership-plans"
              element={
                (pageAccess &&
                  pageAccess.includes("Gdental-membership-plans")) ||
                pageAccess.length == 0 ? (
                  <MembershipList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* SEARCH DENTAL */}
            <Route
              exact
              path="/search-dental"
              element={
                (pageAccess && pageAccess.includes("Search-dental")) ||
                pageAccess.length == 0 ? (
                  <SearchDental />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* G-DENTAL PATIENT */}
            <Route
              exact
              path="/manage-gdental-patient"
              element={
                (pageAccess && pageAccess.includes("Manage-gdental-patient")) ||
                pageAccess.length == 0 ? (
                  <GdentalPatientList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* DENTAL-COMPARE */}
            <Route
              exact
              path="/manage-dental-compare"
              element={
                (pageAccess && pageAccess.includes("Manage-dental-compare")) ||
                pageAccess.length == 0 ? (
                  <DentalCompareList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* SHOW-SERVICE-AND-COST */}
            <Route
              exact
              path="/service-and-cost"
              element={
                (pageAccess && pageAccess.includes("Service-and-cost")) ||
                pageAccess.length == 0 ? (
                  <ShowServiceAndCost />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* EVALUATION */}
            <Route
              exact
              path="/manage-evaluation"
              element={
                (pageAccess && pageAccess.includes("Manage-evaluation")) ||
                pageAccess.length == 0 ? (
                  <ManageEvaluationList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* CLAIM */}
            <Route
              exact
              path="/manage-claim"
              element={
                (pageAccess && pageAccess.includes("Manage-claim")) ||
                pageAccess.length == 0 ? (
                  <ClaimList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* VERIFIER */}
            <Route
              exact
              path="/manage-claim-verify"
              element={
                (pageAccess && pageAccess.includes("Manage-claim-verify")) ||
                pageAccess.length == 0 ? (
                  <VerifyClaimList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* VERIFIER */}
            <Route
              exact
              path="/manage-claim-verify"
              element={
                (pageAccess && pageAccess.includes("Manage-verifier")) ||
                pageAccess.length == 0 ? (
                  <VerifyClaimList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* EMPLOYEE */}
            <Route
              exact
              path="/manage-employee"
              element={
                (pageAccess && pageAccess.includes("Manage-employee")) ||
                pageAccess.length == 0 ? (
                  <EmployeeList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* AGENCY */}
            <Route
              exact
              path="/manage-agency"
              element={
                (pageAccess && pageAccess.includes("Manage-agency")) ||
                pageAccess.length == 0 ? (
                  <AgencyList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* AGENCY-REVENUE */}
            <Route
              exact
              path="/manage-revenue-agency"
              element={
                (pageAccess && pageAccess.includes("Manage-agency")) ||
                  pageAccess.length == 0 ? (
                  <AgencyRevenueList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* AGENT */}
            <Route
              exact
              path="/manage-agent"
              element={
                (pageAccess && pageAccess.includes("Manage-agent")) ||
                pageAccess.length == 0 ? (
                  <AgentList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            {/* REVENUE-AGENT */}
            <Route
              exact
              path="/manage-revenue-agent"
              element={
                (pageAccess && pageAccess.includes("Manage-agent")) ||
                pageAccess.length == 0 ? (
                  <AgentRevenueList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* MANAGER */}
            <Route
              exact
              path="/manage-manager"
              element={
                (pageAccess && pageAccess.includes("Manage-manager")) ||
                pageAccess.length == 0 ? (
                  <ManagerList />
                ) : (
                  <PageNotFound />
                )
              }
            />
            <Route
              exact
              path="/manage-manager-agency"
              element={
                (pageAccess && pageAccess.includes("Manage-manager")) ||
                pageAccess.length == 0 ? (
                  <ManagerAgencyList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* REVENUE-MANAGER */}
            <Route
              exact
              path="/manage-settlement"
              element={
                (pageAccess && pageAccess.includes("Manage-settlement")) ||
                pageAccess.length == 0 ? (
                  <SettlementRevenueList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* VERIFIER */}
            <Route
              exact
              path="/manage-verifier"
              element={
                (pageAccess && pageAccess.includes("Manage-verifier")) ||
                pageAccess.length == 0 ? (
                  <VerifierList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* OFFICE-EMPLOYEE */}
            <Route
              exact
              path="/manage-office-employee"
              element={
                (pageAccess && pageAccess.includes("Manage-office-employee")) ||
                pageAccess.length == 0 ? (
                  <OfficeEmployeeList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* SNS */}
            <Route
              exact
              path="/manage-sns"
              element={
                (pageAccess && pageAccess.includes("Manage-sns")) ||
                pageAccess.length == 0 ? (
                  <SnsList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* ZOOM-MEETING */}
            <Route
              exact
              path="/manage-zoom-meeting"
              element={
                (pageAccess && pageAccess.includes("Manage-zoom-meeting")) ||
                pageAccess.length == 0 ? (
                  <ZoomMeetingList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* GPcoupon */}
            <Route
              exact
              path="/manage-gpcoupon"
              element={
                (pageAccess && pageAccess.includes("Manage-gpcoupon")) ||
                pageAccess.length == 0 ? (
                  <GPCouponList />
                ) : (
                  <PageNotFound />
                )
              }
            />

            {/* PATIENT-PRINT-MY-CARD */}
            <Route
              exact
              path="/print-my-card"
              element={
                (pageAccess && pageAccess.includes("Manage-Patient")) ||
                pageAccess.length == 0 ? (
                  <PrintMyCard />
                ) : (
                  <PageNotFound />
                )
              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
