import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import Cookies from "universal-cookie";
import { GENDER, ROLE_ID, STATUS_PENDING } from "../../helper/Constant";
import { checkEmail, checkPhNo, checkPwd, checkUserName } from "../../helper/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, defaultTimeZone } from "../../helper/dateFormates";
import Select from "react-select";
import GPointWalletUserVerify from "../../commoncomponents/GPointWalletUserVerify";
import { useTranslation } from "react-i18next";

const Registration = () => {
    const search = useLocation().search;
    const agent = new URLSearchParams(search).get("agent");
    const cookies = new Cookies();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [recommender, setRecommender] = useState([]);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [inputGroup, setInputGroup] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        gender: "",
        dob: "",
        phone: "",
        address: "",
        countryId: "",
        stateId: "",
        cityId: "",
        zip: "",
        ssn: "",
        insurance: "",
        agentId: "",
        agencyId: "",
        referralAgenetName: "",
        membership: "",
        // recommenderId: "",
        howus: "",
    });

    const [agentList, setAgentList] = useState([]);
    const [show, setShow] = useState(false);

    const handleSyncWallet = async () => {
        setShow(true);
    };

    const onchangeValue = (e) => {
        let value1 = e.target.value;

        if (value1 && parseFloat(value1) < 0) {
            e.target.value = 0;
            return;
        }

        const { name, value } = e.target;

        setInputGroup({ ...inputGroup, [name]: value });
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPwdShown, setConfirmPwdShown] = useState(false);

    const showPwd = () => {
        if (passwordShown) {
            setPasswordShown(false);
        } else {
            setPasswordShown(true);
        }
    };

    const showConfirmPwd = () => {
        if (confirmPwdShown) {
            setConfirmPwdShown(false);
        } else {
            setConfirmPwdShown(true);
        }
    };

    let selectRecommender = [
        {
            id: "",
            name: "-- Select Recommender --",
        },
    ];

    const recommenderList = () => {
        axios
            .post(`${baseURL}/api/v1/recommender/list?isPagination=false`, {
                name: "",
            })
            .then((res) => setRecommender(res.data.data ? selectRecommender.concat(res.data.data) : selectRecommender))
            .catch((err) => toast.error(err.response.message));
    };
    const recommenderOptions =
        recommender &&
        recommender.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const fetchCountryList = async () => {
        await axios
            .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
                isId: true,
            })
            .then((res) => {
                setCountryList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const countryOptions =
        countryList &&
        countryList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCountryChange = (e) => {
        setInputGroup({
            ...inputGroup,
            countryId: e.value,
            stateId: "",
            cityId: "",
        });
        setStateList([]);
        fetchStateList(e.value);
    };

    const fetchStateList = async (countryId) => {
        await axios
            .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
                countryId: countryId,
                isId: true,
            })
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onStateChange = (e) => {
        setInputGroup({ ...inputGroup, stateId: e.value, cityId: "" });
        setCityList([]);
        fetchCityList(e.value);
    };

    const fetchCityList = async (stateId) => {
        await axios
            .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
                stateId: stateId,
                isId: true,
            })
            .then((res) => {
                setCityList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cityOptions =
        cityList &&
        cityList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCityChange = (e) => {
        setInputGroup({ ...inputGroup, cityId: e.value });
    };

    const fetchAgentInfo = async (agent) => {
        await axios
            .post(`${baseURL}/api/v1/agentinfo`, {
                userName: agent,
            })
            .then((res) => {
                setInputGroup({
                    ...inputGroup,
                    referralAgenetName: res.data.data.fullName,
                    agentId: res.data.data.agentId,
                    agencyId: res.data.data.agencyId,
                });
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleLogin = async (e) => {
        await axios
            .post(`${baseURL}/api/v1/login`, {
                emailOrUserName: inputGroup.userName,
                password: inputGroup.password,
                deviceType: null,
                deviceId: "",
            })
            .then(async (res) => {
                sessionStorage.setItem("token", res.data.data && res.data.data.token);
                localStorage.setItem("languageShortName", res.data.data && res.data.data.lang);
                sessionStorage.setItem("roleId", res.data.data && res.data.data.roleId);

                cookies.set("pageAccess", res.data.data.PageAuthority ? res.data.data.PageAuthority : []);
                if (res.data.data.roleId == ROLE_ID.PATIENT && res.data.data.statusId == STATUS_PENDING.Pending) {
                    navigate("/activate-patient");
                    window.location.reload();
                } else {
                    navigate("/dashboard");
                    window.location.reload();
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!inputGroup.firstName) {
            toast.error(t(`Please enter first name`));
            return false;
        } else if (!inputGroup.userName) {
            toast.error(t(`Enter User Name`));
        } else if (checkUserName(inputGroup.userName) === false) {
            toast.error(t(`Enter Valid User Name`));
        } else if (!inputGroup.email) {
            toast.error(t(`Enter Email`));
        } else if (checkEmail(inputGroup.email) === false) {
            toast.error(t(`Enter Valid Email`));
        } else if (!inputGroup.password) {
            toast.error(t(`Enter Password`));
        } else if (checkPwd(inputGroup.password) === false) {
            toast.error("Enter Valid Password");
        } else if (!inputGroup.confirmPassword) {
            toast.error("Enter Confirm Password");
        } else if (checkPwd(inputGroup.confirmPassword) === false) {
            toast.error("Enter Valid Confirm Password");
        } else if (inputGroup.password !== inputGroup.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
        } else if (!inputGroup.phone) {
            toast.error(t(`Enter Phone Number`));
        } else if (checkPhNo(inputGroup.phone) === false) {
            toast.error(t(`Enter Valid Phone Number`));
        } else if (!inputGroup.dob) {
            toast.error(t(`Enter Birth Date`));
        } else if (!inputGroup.countryId) {
            toast.error(t(`Select Country`));
        } else if (!inputGroup.stateId) {
            toast.error(t(`Select State`));
        } else if (!inputGroup.cityId) {
            toast.error(t(`Select City`));
        } else if (!inputGroup.zip) {
            toast.error("Enter ZIP code");
        } else {
            await axios
                .post(`${baseURL}/api/v1/register`, {
                    firstName: inputGroup.firstName,
                    lastName: inputGroup.lastName,
                    roleId: ROLE_ID.PATIENT,
                    userName: inputGroup.userName,
                    email: inputGroup.email,
                    password: inputGroup.password,
                    confirmPassword: inputGroup.confirmPassword,
                    gender: inputGroup.gender,
                    dob: dateAPIFormate(inputGroup.dob),
                    phone1: inputGroup.phone,
                    address1: inputGroup.address,
                    countryId: inputGroup.countryId,
                    stateId: inputGroup.stateId,
                    cityId: inputGroup.cityId,
                    zip: inputGroup.zip,
                    ssn: inputGroup.ssn,
                    insuranceNumber: inputGroup.insurance,
                    agentId: inputGroup.agentId,
                    agencyId: inputGroup.agencyId,
                    // membership: inputGroup.membership,
                    // recommenderId: inputGroup.recommenderId,
                    howus: inputGroup.howus,
                    gPointUserName: inputGroup.gPointUserName,
                })
                .then((res) => {
                    toast.success(res.data.message);
                    handleLogin();
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    };

    const fetchAgentList = async () => {
        await axios
            .post(`${baseURL}/api/v1/agentList`)
            .then((res) => {
                setAgentList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const AgentOptions =
        agentList &&
        agentList.map((item) => {
            return {
                value: item.agentId,
                label: item.fullName,
            };
        });

    const onAgentChange = (e) => {
        var agencyId = agentList.find((ele) => ele.agentId == e.value).agencyId;
        setInputGroup({
            ...inputGroup,
            agentId: e.value,
            agencyId: agencyId,
        });
    };

    useEffect(() => {
        recommenderList();
        fetchCountryList();
        if (agent) {
            fetchAgentInfo(agent);
        } else {
            fetchAgentList();
        }
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="login-wrapper row">
                    <div id="login" className="login loginpage login-style col-lg-offset-2 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-8" style={{ marginBottom: "3%" }}>
                        <div className="login-form-header" style={{ marginTop: "3%" }}>
                            <img
                                src="./data/icons/signup.png"
                                alt="login-icon"
                                style={{
                                    maxWidth: "64px",
                                    filter: "invert(0%) sepia(0%) saturate(1522%) hue-rotate(95deg) brightness(5%) contrast(38%)",
                                }}
                            />
                            <div className="login-header">
                                <h4 className="bold text-break">Signup Now!</h4>
                                <h4 className="">
                                    <small className="text-dark">Please enter your data to register .</small>
                                </h4>
                            </div>
                        </div>

                        <div className="box login">
                            <div className="content-body" style={{ paddingTop: "30px" }}>
                                <form id="msg_validate" action="#" noValidate="novalidate" className="no-mb no-mt">
                                    <div className="row mx-1">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("First name")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input type="text" className="form-control" name="firstName" value={inputGroup && inputGroup.firstName} onChange={(e) => onchangeValue(e)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Last name")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="lastName"
                                                        placeholder="Enter Last Name"
                                                        value={inputGroup && inputGroup.lastName}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    User name <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control my-0"
                                                        name="userName"
                                                        placeholder="Enter User Name"
                                                        value={inputGroup && inputGroup.userName}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                                <p className="mb-0 pb-0 mx-1" style={{ fontSize: "10px", color: "gray" }}>
                                                    *{t("At least 5 characters : start and end with character or number, can have special character")}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Email <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        value={inputGroup && inputGroup.email}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Password")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls d-flex">
                                                    <input
                                                        type={passwordShown ? "text" : "password"}
                                                        className="form-control my-0"
                                                        name="password"
                                                        placeholder="Enter Password"
                                                        value={inputGroup && inputGroup.password}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                    <span className="input-group-text bg-transparent cursor-pointer" onClick={showPwd}>
                                                        <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                                </div>
                                                <p className="mb-0 pb-0" style={{ fontSize: "10px", color: "gray" }}>
                                                    *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Confirm Password")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls d-flex">
                                                    <input
                                                        type={confirmPwdShown ? "text" : "password"}
                                                        className="form-control my-0"
                                                        name="confirmPassword"
                                                        value={inputGroup && inputGroup.confirmPassword}
                                                        placeholder="Enter Confirm Password"
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                    <span className="input-group-text bg-transparent cursor-pointer" onClick={showConfirmPwd}>
                                                        <i className={confirmPwdShown ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Phone")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="phone"
                                                        placeholder="Enter Phone Number"
                                                        maxLength="10"
                                                        value={inputGroup && inputGroup.phone}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Gender")} <span className="required"> * </span>
                                                </label>

                                                <select className="form-control col-md-3 my-1" name="gender" onChange={(e) => onchangeValue(e)}>
                                                    {Object.keys(GENDER).map((item, i) => {
                                                        return (
                                                            <option key={i} value={GENDER[item]}>
                                                                {item}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Birth Date")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <DatePicker
                                                        className="form-control input-height mr-sm-2 cursor-pointer"
                                                        selected={inputGroup && defaultTimeZone(inputGroup.dob)}
                                                        placeholderText={"Please Select Date"}
                                                        maxDate={new Date()}
                                                        scrollableMonthDropdown={true}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        onChange={(date) => setInputGroup({ ...inputGroup, dob: date })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <label className="form-label">{t("Address")}</label>
                                                    </div>
                                                </div>

                                                <div className="controls">
                                                    <textarea
                                                        type="text"
                                                        rows="3"
                                                        className="form-control"
                                                        name="address"
                                                        placeholder="Enter Address"
                                                        value={inputGroup && inputGroup.address}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Country")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="countryList"
                                                                value={countryOptions && countryOptions.filter(({ value }) => value == inputGroup.countryId)}
                                                                options={countryOptions}
                                                                onChange={onCountryChange}
                                                                placeholder={t("Select")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("State")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="stateList"
                                                                value={stateOptions && stateOptions.filter(({ value }) => value == inputGroup.stateId)}
                                                                options={stateOptions}
                                                                onChange={onStateChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={!inputGroup.countryId ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("City")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="cityList"
                                                                value={cityOptions && cityOptions.filter(({ value }) => value == inputGroup.cityId)}
                                                                options={cityOptions}
                                                                onChange={onCityChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={!inputGroup.stateId ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Zip Code")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="zip" value={inputGroup.zip} placeholder="Enter Zip Code" onChange={onchangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <GPointWalletUserVerify show={show} setShow={setShow} inputGroup={inputGroup} setInputGroup={setInputGroup} />
                                        <div className="col-lg-6">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("GPoint Wallet Username")}</label>

                                                <div className="col-xl-11 col-md-8 col-sm-8 col-12">
                                                    <input
                                                        // type="text"
                                                        className="form-control"
                                                        name="gPointUserName"
                                                        value={inputGroup.gPointUserName}
                                                        onChange={(e) => onchangeValue(e)}
                                                        disabled="disabled"
                                                    />
                                                </div>
                                                <div className="col-xl-1 col-md-4 col-sm-4 col-12">
                                                    <button type="button" className="form-control btn btn-info" onClick={handleSyncWallet}>
                                                        {t("SYNC Wallet")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">&nbsp;</div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <label className="form-label">{t("SSN")}</label>
                                                    </div>
                                                </div>

                                                <div className="controls">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="ssn"
                                                        value={inputGroup && inputGroup.ssn}
                                                        onChange={(e) => onchangeValue(e)}
                                                        placeholder="ssn"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <label className="form-label">insurance number</label>
                                                    </div>
                                                </div>

                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="insurance"
                                                        value={inputGroup && inputGroup.insurance}
                                                        onChange={(e) => onchangeValue(e)}
                                                        placeholder="Enter Insurance Number"
                                                    />
                                                </div>
                                            </div>
                                        </div> */}

                                        {agent ? (
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="d-flex">
                                                        <div className="d-flex">
                                                            <label className="form-label">{t("Agent")}</label>
                                                        </div>
                                                    </div>

                                                    <div className="controls">
                                                        <input type="text" className="form-control bgdisable" name="referralAgenetName" value={inputGroup && inputGroup.referralAgenetName} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-6">
                                                <div className="form-group row">
                                                    <label className="form-label">{t("Agent")}</label>

                                                    <div className="col-md-12">
                                                        <Select
                                                            menuPlacement="top"
                                                            name="agentList"
                                                            value={AgentOptions && AgentOptions.filter(({ value }) => value == inputGroup.agentId)}
                                                            options={AgentOptions}
                                                            onChange={onAgentChange}
                                                            placeholder={t("Select")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <label className="form-label">{t("Recommender")}</label>
                                                    </div>
                                                </div>
                                                <Select
                                                    menuPlacement="top"
                                                    name="recommenderId"
                                                    options={recommenderOptions}
                                                    onChange={(e) => setInputGroup({ ...inputGroup, recommenderId: e.value })}
                                                    placeholder={t("Select")}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <label className="form-label">{t("How did you hear about us?")}</label>
                                                    </div>
                                                </div>

                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="howus"
                                                        value={inputGroup && inputGroup.howus}
                                                        onChange={(e) => onchangeValue(e)}
                                                        placeholder={t("How did you hear about us?")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        <a href="/" className="btn mt-10 btn-corner signup login-btn mr-10">
                                            {t("Close")}
                                        </a>
                                        <a href="/login" className="btn btn-warning mt-10 btn-corner signup login-btn mr-10">
                                            Login
                                        </a>
                                        <button className="btn mt-10 btn-corner sign-up-btn right-15 text-white" style={{ background: "#115278" }} onClick={(e) => handleSignUp(e)}>
                                            Sign up
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Registration;
