import React, { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { baseURL } from "../helper/Config";
import { toast } from "react-toastify";
import axios from "axios";
import { checkPwd } from "../helper/Validator";
import { ROLE_ID } from "../helper/Constant";
import { useTranslation } from "react-i18next";

function ChangePassword() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };

    const onchangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const handleCancel = () => {
        navigate("/dashboard");
    };
    const handleChangePassword = async () => {
        if (!inputValue.oldPassword || inputValue.oldPassword === "" || inputValue.oldPassword === null) {
            toast.error(t(`Please enter old password`));
        } else if (!inputValue.newPassword || inputValue.newPassword === "" || inputValue.newPassword === null) {
            toast.error(t(`Please enter new password`));
        } else if (checkPwd(inputValue.newPassword) == false) {
            toast.error(t(`Enter Valid New Password`));
        } else if (!inputValue.confirmPassword || inputValue.confirmPassword === "" || inputValue.confirmPassword === null) {
            toast.error(t(`Please confirm your new password`));
        } else if (inputValue.newPassword != inputValue.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
            return false;
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/reset-password`,
                    {
                        oldPassword: inputValue.oldPassword,
                        newPassword: inputValue.newPassword,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    toast.success(res.data.message);
                    setInputValue({
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    });
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Change Password")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>{t("Change Password")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div
                        className={`add-header-wrapper gradient-${
                            profileData.roleId == ROLE_ID.ADMIN
                                ? "blue"
                                : profileData.roleId == ROLE_ID.DOCTOR
                                ? "violet"
                                : profileData.roleId == ROLE_ID.PATIENT
                                ? "orange"
                                : profileData.roleId == ROLE_ID.VERIFY
                                ? "pink"
                                : profileData.roleId == ROLE_ID.AGENCY
                                ? "green"
                                : profileData.roleId == ROLE_ID.AGENT
                                ? "green1"
                                : "grep"
                        } curved-section text-center`}
                    >
                        <h2 className="uppercase bold w-text">{t("Change Password")}</h2>
                        <div className="before-text">{t("Change Password")}</div>
                    </div>
                    <div className=" bg-w">
                        <div className="col-lg-6 col-lg-offset-3 col-xs-12 my-5">
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="form-group row">
                                        <label className="control-label col-md-12 mx-2">
                                            {t("Old Password")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12 d-flex">
                                            <input
                                                type={passwordShown ? "text" : "password"}
                                                className="form-control mx-2"
                                                name="oldPassword"
                                                value={inputValue.oldPassword}
                                                onChange={onchangeValue}
                                            />
                                            <i
                                                className={`${passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                id="basic-addon1"
                                                onClick={togglePassword}
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-12 mx-2">
                                            {t("New Password")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12 d-flex">
                                            <input
                                                type={newPasswordShown ? "text" : "password"}
                                                className="form-control mx-2"
                                                name="newPassword"
                                                value={inputValue.newPassword}
                                                onChange={onchangeValue}
                                            />
                                            <i
                                                className={`${newPasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                id="basic-addon1"
                                                onClick={toggleNewPassword}
                                            ></i>
                                        </div>
                                        <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                            *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                        </p>
                                    </div>
                                    <div className="form-group row">
                                        <label className="control-label col-md-12 mx-2">
                                            {t("Confirm New Password")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12 d-flex">
                                            <input
                                                type={cfmpasswordShown ? "text" : "password"}
                                                className="form-control mx-2"
                                                name="confirmPassword"
                                                value={inputValue.confirmPassword}
                                                onChange={onchangeValue}
                                            />
                                            <i
                                                className={`${cfmpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                id="basic-addon1"
                                                onClick={toggleCfmPassword}
                                            ></i>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mb-2">
                                        <Button variant="secondary" className="my-2 mx-2" onClick={handleCancel}>
                                            {t("Close")}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className={`my-2 mx-2 gradient-${
                                                profileData.roleId == ROLE_ID.ADMIN
                                                    ? "blue"
                                                    : profileData.roleId == ROLE_ID.DOCTOR
                                                    ? "violet"
                                                    : profileData.roleId == ROLE_ID.PATIENT
                                                    ? "orange"
                                                    : profileData.roleId == ROLE_ID.VERIFY
                                                    ? "pink"
                                                    : profileData.roleId == ROLE_ID.AGENCY
                                                    ? "green"
                                                    : profileData.roleId == ROLE_ID.AGENT
                                                    ? "green1"
                                                    : "grep"
                                            }`}
                                            onClick={handleChangePassword}
                                        >
                                            {t("Change")}
                                        </Button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;
