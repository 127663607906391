import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL } from "../../../helper/Config";

const PrintMyCard = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");

  const { profileData } = useOutletContext();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    if (profileData.patient.isGDenticarePlan > 0) {
      setIsMember(true);
    }
  }, [profileData.patient.isGDenticarePlan]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }

  const handleSendEmail = async () => {
    if (!profileData.email) {
      toast.error(t(`No email found`));
    } else {
      setIsLoading(true);
      await axios
        .post(`${baseURL}/api/v1/membership-card`, {
          username: profileData.userName,
          plan: profileData.plan.name,
        })
        .then(async (res) => {
          toast.success(res.data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      <div className="col-xs-12">
        <div className="page-title">
          <div className="pull-left">
            <h1 className="title">Print My Card</h1>
          </div>
          <div className="pull-right">
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard">
                  <i className="fa fa-home"></i>
                  {t("Home")}
                </Link>
              </li>
              <li className="active">
                <strong>{t("Print My Card")}</strong>
              </li>
            </ol>
          </div>
        </div>
      </div>
      {(profileData.patient.isGDenticarePlan == 1 && profileData.plan) && (
        <div className="bg-w container p-5">
          <div className="row g-3 g-5 d-sm-flex">
            {/* <div className="col-6 text-end">
              <img
                className="pe-5"
                src="assets/images/home/logo.png"
                alt="logo"
              />
            </div> */}
            <div className="col-sm-2 text-start">
              {/* <div className="row">
                <label className="form-label bold fs-5">Select Member</label>
              </div>
              <div className="row">
                <select
                  className="form-select form-select-sm"
                  aria-label="Select Member"
                >
                  <option defaultValue={""}>Select...</option>
                  <option value="1"></option>
                </select>
              </div> */}
            </div>

            {/* <div className="dropdown">
              <button
              className="btn btn-secondary btn-md dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >
                Select...
                </button>
                <ul className="dropdown-menu">
                <li>
                <a className="dropdown-item" href="#" >
                Action
                </a>
                </li>
                <li>
                <a className="dropdown-item" href="#">
                Another action
                  </a>
                  </li>
                  </ul>
                </div> */}
          </div>
          <div className="grid row">
            <p
              className="fs-5 fw-bold pt-5 pb-3 text-center"
              style={{ color: "#B57DB4" }}
            >
              MEMBERSHIP CARD
            </p>
            <p className="lh-sm col-lg-7 mx-auto pb-5">
              Thank you for joining! Below is your plan membership card. Simply
              cut along the dotted edges and take with you on your next dental
              appointment. Be sure to present the card at the office so they can
              be sure you get the full benefits of your plan. If you need
              additional cards, you may print as many copies as you'd like
              through your Member's Area.
            </p>
          </div>
          <div
            id="printArea"
            ref={componentRef}
            className="row border rounded-4 p-3 mx-auto"
          // style={{
          //   width: "auto",
          //   border: 0,
          //   margin: "0 5%",
          //   padding: 0,
          //   float: "none",
          //   position: "static",
          //   overflow: "visible",
          // }}
          >
            {/* <div className="col-lg-12"> */}
            {/* <div className="pt-4 col-lg-12"> */}
            <div className="col-lg-6 border">
              {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
              <div className="row pb-3">
                <div className="col-2 text-start">
                  <img
                    className=""
                    src="assets/images/home/logo.png"
                    alt="logo"
                  />
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12 text-center">
                  <p className="h3">GDenti Membership Plan</p>
                </div>
              </div>
              <dl className="row">
                <dt className="col-sm-3">Member</dt>
                <dd className="col-sm-9">
                  {profileData.firstName.toUpperCase()} {profileData.lastName.toUpperCase()}
                </dd>
                <dt className="col-sm-3">D.O.B</dt>
                <dd className="col-sm-9">
                  {new Date(profileData.dob).toLocaleDateString("en-US")}
                </dd>
                <dt className="col-sm-3">Plan Type</dt>
                <dd className="col-sm-9">
                  {profileData.plan.name}
                </dd>
                <dt className="col-sm-3">Start Date</dt>
                <dd className="col-sm-9">
                  {new Date(
                    profileData.patient.membershipStartDate
                  ).toLocaleDateString("en-US")}
                </dd>
                <dt className="col-sm-3">Expiry Date</dt>
                <dd className="col-sm-9">
                  {new Date(
                    profileData.patient.membershipExpiryDate
                  ).toLocaleDateString("en-US")}
                </dd>
              </dl>
              <div className="row pb-3">
                <div className="col-12 text-center">
                  <p className="h4 bold">THIS IS NOT INSURANCE</p>
                  <p className="lh-1">
                    It is a discount plan.
                    <br />
                    Administered at gdenti.com
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-1 d-flex h-100">
            <div className="vr d-none d-sm-block"></div>
          </div> */}
            {/* <div className="col-lg-6 col-md-12 col-sm-12"> */}
            <div className={`col-lg-6 pt-3 border`}>
              <div className="row px-3">
                <div className="col-12 text-center">
                  <p className="h4 bold">
                    Contact Us Monday-Friday 9AM-6PM EST
                    <br />
                    Plan Members: (201) 496-6366
                    <br />
                    Providers: (201) 496-6063 or visit gdenti.com
                  </p>
                </div>
                <div className="col-12 pt-2">
                  <p className="lh-sm text-justify px-3">
                    Within this program, participants are solely accountable for
                    all incurred charges. Equally, program providers hold
                    exclusive responsibility for the quality and delivery of
                    their products and services. There are no benefits payable.{" "}
                    <br />
                    <br />
                    Under the representation of GDenti, the product "GDenti" is
                    marketed by gdenti.com.
                  </p>
                </div>
                <div className="col-12 text-center">
                  <p className="bold h3">www.gdenti.com</p>
                  <p className="lh-1">
                    Search for participating providers at gdenti.com
                  </p>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="pt-5 d-grid gap-4 d-md-flex justify-content-md-center">
            <div className="col-6 text-end">
              <button
                className="btn btn-primary"
                type="button"
                style={{
                  borderRadius: "50px",
                }}
                onClick={handlePrint}
              // onClick={() => printDiv("printArea")}
              >
                Print Now
              </button>
            </div>
            <div className="col-6 text-start">
              <button
                className="btn btn-success"
                type="button"
                style={{
                  borderRadius: "50px",
                }}
                onClick={handleSendEmail}
              >
                Email Me
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrintMyCard;
