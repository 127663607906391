import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import Cookies from "universal-cookie";
import { ROLE_ID, STATUS_PENDING } from "../../helper/Constant";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Login = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const cookies = new Cookies();
    const { t } = useTranslation();

    const [deviceType, setDeviceType] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
    });

    const onchangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const [passwordShown, setPasswordShown] = useState(false);

    const showPwd = () => {
        if (passwordShown) {
            setPasswordShown(false);
        } else {
            setPasswordShown(true);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!inputValue.email) {
            toast.error(t(`Enter UserName`));
            return false;
        }
        if (!inputValue.password) {
            toast.error(t(`Enter Password`));
            return false;
        }
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/login`, {
                emailOrUserName: inputValue.email,
                password: inputValue.password,
                deviceType: deviceType,
                deviceId: "",
            })
            .then(async (res) => {
                if (res.data.data && (res.data.data.roleId == ROLE_ID.INVESTOR_USER || res.data.data.roleId == ROLE_ID.INVESTOR_ADMIN)) {
                    toast.error("Investor can not be logged in using this url");
                    setIsLoading(false);
                } else {
                    sessionStorage.setItem("token", res.data.data && res.data.data.token);
                    localStorage.setItem("languageShortName", res.data.data && res.data.data.lang);
                    sessionStorage.setItem("roleId", res.data.data && res.data.data.roleId);
                    cookies.set("pageAccess", res.data.data.PageAuthority ? res.data.data.PageAuthority : []);
                    toast.success(res.data.message);
                    if (res.data.data.roleId == ROLE_ID.DOCTOR && res.data.data.officeCount > 1) {
                        navigate("/select-dental");
                    } else if (res.data.data.roleId == ROLE_ID.DOCTOR && res.data.data.officeCount == 1) {
                        await axios
                            .post(
                                `${baseURL}/api/v1/doctor/doctor/office/list?isPagination=false`,
                                {
                                    doctorId: res.data.data.id,
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: res.data.data.token,
                                        lang: res.data.data.lang,
                                    },
                                }
                            )
                            .then((res) => {
                                cookies.set("office", res.data.data[0].id);
                                setIsLoading(false);
                                navigate("/dashboard");
                                window.location.reload();
                            })
                            .catch((err) => {
                                if (err.response) {
                                    if (err.response.status == 500) {
                                        localStorage.clear();
                                        sessionStorage.clear();
                                        navigate("/login");
                                    } else {
                                        toast.error(err.response.data.message);
                                    }
                                }
                            });
                    } else if (res.data.data.roleId == ROLE_ID.PATIENT && res.data.data.statusId == STATUS_PENDING.Pending) {
                        navigate("/activate-patient");
                        window.location.reload();
                    } else {
                        navigate("/dashboard");
                        window.location.reload();
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false);
                toast.error(err.response.data.message);
            });
    };

    const [showSignup, setShowSignup] = useState(false);

    const onSignupClick = () => {
        setShowSignup(!showSignup);
    };

    const onSignupSelected = (value) => {
        if (value === "2") {
            navigate("/authDoctor/signup");
        } else if (value === "3") {
            navigate("/authAgent/signup");
        } else {
            navigate("/signup");
        }
    };

    useEffect(() => {
        if (token) {
            navigate("/dashboard");
        }
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
            setDeviceType("Mobile");
        } else {
            setDeviceType("Desktop");
        }
    }, []);

    return (
        <>
            <div className="container-fluid login-style">
                <div className="login-wrapper row">
                    <div id="login" className="login loginpage col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4">
                        <div className="login-form-header" style={{ marginTop: "35%" }}>
                            <img
                                src="./data/icons/padlock.png"
                                alt="login-icon"
                                style={{ maxWidth: "64px", filter: "invert(0%) sepia(0%) saturate(1522%) hue-rotate(95deg) brightness(5%) contrast(38%)" }}
                            />
                            <div className="login-header">
                                <h4 className="bold text-break">{t("Login Now")}!</h4>
                                <h4 className="">
                                    <small className="text-dark">{t("Please enter your credentials to login")}.</small>
                                </h4>
                            </div>
                        </div>

                        <div className="box login">
                            <div className="content-body" style={{ paddingTop: "30px" }}>
                                <form id="msg_validate" action="#" noValidate="novalidate" className="no-mb no-mt">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <label className="form-label">{t("Username")}</label>
                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        value={inputValue && inputValue.email}
                                                        placeholder={t("Please enter username")}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label">{t("Password")}</label>
                                                <div className="controls d-flex">
                                                    <input
                                                        type={passwordShown ? "text" : "password"}
                                                        className="form-control my-0"
                                                        name="password"
                                                        placeholder={t("Please enter password")}
                                                        value={inputValue && inputValue.password}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                    <span className="input-group-text bg-transparent cursor-pointer" onClick={showPwd}>
                                                        <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="pull-right">
                                                <a href="/" className="btn mt-10 btn-corner signup login-btn mr-10">
                                                    {t("Close")}
                                                </a>
                                                <button
                                                    type="button"
                                                    className="btn mt-10 btn-corner sign-up-btn mr-10"
                                                    style={{ color: "#333333", background: "#ffc107" }}
                                                    onClick={() => {
                                                        onSignupClick();
                                                    }}
                                                >
                                                    {t("Sign Up")}
                                                </button>
                                                <button
                                                    className="btn mt-10 btn-corner sign-up-btn right-15 text-white"
                                                    style={{ background: "#115278" }}
                                                    onClick={(e) => (isLoading ? e.preventDefault() : handleLogin(e))}
                                                >
                                                    {isLoading ? (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            style={{
                                                                position: "relative",
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        t("Login")
                                                    )}
                                                </button>
                                            </div>
                                            {showSignup && (
                                                <div className="pull-right">
                                                    <input className="my-2 mx-3" type="radio" name="isSignup" value="1" onChange={() => onSignupSelected("1")} />
                                                    {t("Member")}
                                                    <input className="my-2 mx-3" type="radio" name="isSignup" value="2" onChange={() => onSignupSelected("2")} />
                                                    {t("Dentist")}
                                                    <input className="my-2 mx-3" type="radio" name="isSignup" value="3" onChange={() => onSignupSelected("3")} />
                                                    {t("Agent")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-4">
                                        {t("Forgot your password?")} &nbsp;
                                        <Link to="/forgot-password">
                                            <u className="text-info">{t("Reset here")}.</u>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
