import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { MEMBERSHIP_STATUS, ROWS_PER_PAGE, STATUS, STATUS_PENDING, SUBSCRIPTION, MEMBERSHIP_STATUS_SEARCH, PAYMENT_TYPE, PLAN_TYPE } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import UpsertPatient from "./UpsertPatient";
import JoinGDentalPlan from "./JoinGDentalPlan";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

function PatientList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const cookies = new Cookies();
    let pageAccess = cookies.get("pageAccess") && cookies.get("pageAccess").length ? cookies.get("pageAccess") : [];

    const { t } = useTranslation();

    const [patientList, setPatientList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [view, setView] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        userName: "",
        gender: "",
        dob: "",
        phone1: "",
        statusId: 1,
        address1: "",
        countryId: "",
        stateId: "",
        cityId: "",
        zip: "",
        ssn: "",
        insuranceNumber: "",
        agent: "",
        agentId: "",
        agencyId: "",
        membership: "",
        recommenderId: "",
        howus: "",
    });
    const [patientId, setPatientId] = useState("");
    const [planType, setPlanType] = useState("");
    const [gDenticarePlanStatusId, setGDenticarePlanStatusId] = useState("");
    const [recommenderList, setRecommenderList] = useState([]);
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
        searchPatientUsernameOrEmail: "",
        memStatusSearch: "",
    });
    const [joinMembership, setJoinMembership] = useState(false);
    const [isRegisterPatient, setIsRegisterPatient] = useState(0);
    const [registrationFee, setRegistrationFee] = useState(0);

    const fetchPatientdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/patient/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchPatientName: filterValue.searchPatientName,
                    searchPatientUsernameOrEmail: filterValue.searchPatientUsernameOrEmail,
                    statusId: filterValue.statusSearch,
                    isGDenticarePatient: true,
                    memStatusId: filterValue.memStatusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setPatientList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setPatientId(id);
        fetchRecommenderdata();
        patientDetail(id);
        setIsEdit(true);
    };

    const handleView = (id) => {
        setPatientId(id);
        fetchRecommenderdata();
        patientDetail(id);
        setView(true);
        setIsEdit(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchPatientdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const patientDetail = async (patientId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/admin/patient/detail/${patientId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let selectRecommender = [
        {
            id: "",
            name: "-- Select Recommender --",
        },
    ];

    const fetchRecommenderdata = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/recommender/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setRecommenderList(res.data.data ? selectRecommender.concat(res.data.data) : selectRecommender);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const recommenderOptions =
        recommenderList &&
        recommenderList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onRecommenderChange = (e) => {
        setInputValue({ ...inputValue, recommenderId: e.value });
    };

    const handleClose = () => {
        setInputValue({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            userName: "",
            gender: "",
            dob: "",
            phone1: "",
            statusId: 1,
            address1: "",
            countryId: "",
            stateId: "",
            cityId: "",
            zip: "",
            ssn: "",
            insuranceNumber: "",
            agent: "",
            agentId: "",
            agencyId: "",
            membership: "",
            recommenderId: "",
            howus: "",
        });
        // setRegistrationFee(0);
        // setIsRegisterPatient(0);
        setPatientId("");
        setIsEdit(false);
        setView(false);
        // setJoinMembership(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this patient ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/admin/patient/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchPatientdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchPatientdata();
    }, [offset, filterValue.statusSearch, filterValue.memStatusSearch]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Patient")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {isEdit || show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Patient List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Patient List")}</strong>
                                </li>
                            )}
                            {isEdit || show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit && !view ? t("Update") : view ? t("View") : t("Add")} {t("Patient")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {isEdit || show ? (
                        <UpsertPatient
                            view={view}
                            handleClose={handleClose}
                            fetchPatientdata={fetchPatientdata}
                            patientId={patientId}
                            setPatientId={setPatientId}
                            recommenderOptions={recommenderOptions}
                            onRecommenderChange={onRecommenderChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            show={show}
                            isEdit={isEdit}
                            onChangeValue={onChangeValue}
                            editLoader={editLoader}
                        />
                    ) : (
                        <section className="box">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-1 col-md-1 col-sm-12">
                                            <h2 className="title pull-left">
                                                <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                    <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                </button>
                                            </h2>
                                        </div>
                                        <div className="col-lg-11 col-md-12 col-sm-12 d-flex justify-content-end">
                                            <div className="row mx-lg-2">
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <div className="title">
                                                        <select
                                                            className="form-control"
                                                            name="memStatusSearch"
                                                            value={filterValue.memStatusSearch}
                                                            onChange={onFilterChangeValue}
                                                            style={{ width: "150px" }}
                                                        >
                                                            {Object.keys(MEMBERSHIP_STATUS_SEARCH).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={MEMBERSHIP_STATUS_SEARCH[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchPatientUsernameOrEmail"
                                                            value={filterValue.searchPatientUsernameOrEmail}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search username")}
                                                            style={{ width: "160px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchPatientName"
                                                            value={filterValue.searchPatientName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search name")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-12">
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_PENDING).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_PENDING[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !patientList || (patientList && !patientList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        {/* <th>{t("E-mail")}</th> */}
                                                        <th>{t("Username")}</th>
                                                        <th>{t("Name")}</th>
                                                        <th>{t("Membership Status")}</th>
                                                        <th>{t("Membership")}</th>
                                                        <th>
                                                            {t("Plan")}({t("Fee")})
                                                        </th>
                                                        <th>{t("Subscription")}</th>
                                                        <th>{t("Payment Type")}</th>
                                                        <th>{t("Start Date")}</th>
                                                        <th>{t("Expiry Date")}</th>
                                                        <th>{t("Agent")}</th>
                                                        {/* <th>{t("Recommender")}</th>
                                                        <th>{t("Phone")}</th> */}
                                                        <th>{t("Created Date")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {joinMembership ? (
                                                        <JoinGDentalPlan
                                                            fetchPatientdata={fetchPatientdata}
                                                            gDenticarePlanStatusId={gDenticarePlanStatusId}
                                                            // handleClose={handleClose}
                                                            patientId={patientId}
                                                            planType={planType}
                                                            show={joinMembership}
                                                            isRegisterPatient={isRegisterPatient}
                                                            setShow={setJoinMembership}
                                                            registrationFee={registrationFee}
                                                            isAdmin={true}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {!patientList || (patientList && !patientList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        patientList &&
                                                        patientList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    {/* <td>
                                                                        <h6>{item.email ? item.email : "-"}</h6>
                                                                    </td> */}
                                                                    <td className="cursor-pointer" onClick={() => handleView(item.id)}>
                                                                        <u>
                                                                            <h6>{item.userName ? item.userName : "-"}</h6>
                                                                        </u>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.firstName ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className={`badge badge-${
                                                                                item.GDenticarePlanStatusId == MEMBERSHIP_STATUS.Active
                                                                                    ? "success"
                                                                                    : item.GDenticarePlanStatusId == MEMBERSHIP_STATUS.Expire
                                                                                    ? "warning"
                                                                                    : "danger"
                                                                            }`}
                                                                        >
                                                                            {Object.keys(MEMBERSHIP_STATUS).find((key) => MEMBERSHIP_STATUS[key] === item.GDenticarePlanStatusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.membership ? item.membership : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.name ? item.name + "($" + item.price + ")" : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.isSubscription ? Object.keys(SUBSCRIPTION).find((key) => SUBSCRIPTION[key] == item.isSubscription) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            <span
                                                                                className={`badge badge-${
                                                                                    item.paymentType == PAYMENT_TYPE.Cash
                                                                                        ? "success"
                                                                                        : item.paymentType == PAYMENT_TYPE.Card
                                                                                        ? "info"
                                                                                        : item.paymentType == PAYMENT_TYPE["G-Point"]
                                                                                        ? "danger"
                                                                                        : item.paymentType == PAYMENT_TYPE.STRIPE_ACH
                                                                                        ? "warning"
                                                                                        : "default"
                                                                                }`}
                                                                            >
                                                                                {Object.keys(PAYMENT_TYPE).find((key) => PAYMENT_TYPE[key] == item.paymentType)}
                                                                            </span>
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.membershipStartDate ? monthDateYear(item.membershipStartDate) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.membershipExpiryDate ? monthDateYear(item.membershipExpiryDate) : "-"}
                                                                            {item.isWithdraw === 1 ? (
                                                                                <span style={{ color: "red" }}>
                                                                                    <br />
                                                                                    (Withdraw req)
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.agent && item.agent.firstName
                                                                                ? item.agent.firstName + " " + item.agent.lastName + " (" + item.agent.userName + ")"
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    {/* <td>
                                                                        <h6>{item.recommender ? item.recommender : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.phone1 ? item.phone1 : "-"}</h6>
                                                                    </td> */}
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                            {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </button>
                                                                        <button className="btn btn-danger btn-xs mx-2" onClick={() => handleDelete(item.id)}>
                                                                            <i className="fa fa-trash-o "></i>
                                                                        </button>
                                                                        {pageAccess && pageAccess.length == 0 ? (
                                                                            <button
                                                                                className={`btn btn-success btn-xs`}
                                                                                onClick={() => {
                                                                                    setPlanType(item.type);
                                                                                    setGDenticarePlanStatusId(item.GDenticarePlanStatusId);
                                                                                    setPatientId(item.id);
                                                                                    setRegistrationFee(item.isRegisterPatient ? 0 : item.registrationPlanFee);
                                                                                    setIsRegisterPatient(item.isRegisterPatient);
                                                                                    setJoinMembership(true);
                                                                                }}
                                                                            >
                                                                                <i className="fa fa-money"></i>
                                                                            </button>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {/* <button
                                                                            className={`btn btn-success btn-xs`}
                                                                            onClick={() => {
                                                                                setPlanType(item.type);
                                                                                setGDenticarePlanStatusId(item.GDenticarePlanStatusId);
                                                                                setPatientId(item.id);
                                                                                setRegistrationFee(item.isRegisterPatient ? 0 : item.registrationPlanFee);
                                                                                setIsRegisterPatient(item.isRegisterPatient);
                                                                                setJoinMembership(true);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-money"></i>
                                                                        </button> */}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-md-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {patientList == null ? "0" : rowCount} {t("Patient")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default PatientList;
