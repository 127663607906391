import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../../helper/Config";
import NoDataFound from "../../../../commoncomponents/NoDataFound";
import Loader from "../../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, STATUS, STATUS_SEARCH } from "../../../../helper/Constant";
import { monthDateYear } from "../../../../helper/dateFormates";
import UpsertProgress from "./UpsertProgress";
import { useTranslation } from "react-i18next";
import { sliceString } from "../../../../helper/helper";
import DocumentModal from "./DocumentModal";

function ProgressList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [progressList, setProgressList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        title: "",
        contents: "",
        statusId: 1,
    });
    const [progressId, setProgressId] = useState("");
    const [filterValue, setFilterValue] = useState({
        searchTitle: "",
        statusSearch: "",
    });
    const [showDocuments, setShowDocuments] = useState(false);

    const fetchProgressdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/progress/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    title: filterValue.searchTitle,
                    statusId: filterValue.statusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setProgressList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setProgressId(id);
        progressDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const handleView = (id) => {
        setProgressId(id);
        progressDetail(id);
        setIsEdit(true);
        setIsView(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchProgressdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const progressDetail = async (progressId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/admin/progress/detail/${progressId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            title: "",
            contents: "",
            statusId: 1,
        });
        setProgressId("");
        setIsEdit(false);
        setIsView(false);
        setShowDocuments(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this progress ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/admin/progress/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchProgressdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchProgressdata();
    }, [offset, filterValue.statusSearch]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage News")} </h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("News List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("News List")}</strong>
                                </li>
                            )}
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit && !isView ? t("Update") : isView ? t("View") : t("Add")} {t("News")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                {showDocuments ? <DocumentModal show={showDocuments} isView={true} progressId={progressId} handleClose={handleClose} /> : ""}
                <div className="col-12">
                    {show ? (
                        <UpsertProgress
                            editLoader={editLoader}
                            handleClose={handleClose}
                            fetchProgressdata={fetchProgressdata}
                            progressId={progressId}
                            setProgressId={setProgressId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isEdit={isEdit}
                            isView={isView}
                            show={show}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-8 col-md-6 col-sm-12">
                                            <h2 className="title pull-left">
                                                <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                    <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                </button>
                                            </h2>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-end">
                                            <div className="row mx-2">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchTitle"
                                                            value={filterValue.searchTitle}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search title")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_SEARCH[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !progressList || (progressList && !progressList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("No")}</th>
                                                        <th>{t("Title")}</th>
                                                        <th>{t("Contents")}</th>
                                                        <th>{t("Attach files")}</th>
                                                        <th>{t("Date/Time")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!progressList || (progressList && !progressList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        progressList &&
                                                        progressList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{(offset - 1) * ROWS_PER_PAGE + 1 + i}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.title ? item.title : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.contents ? sliceString(item.contents, 30) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-info btn-xs mx-2"
                                                                            onClick={() => {
                                                                                setShowDocuments(true);
                                                                                setProgressId(item.id);
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-paperclip"></i>
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                            {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </button>
                                                                        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                            <i className="fa fa-trash-o "></i>
                                                                        </button>
                                                                        <button className="btn btn-warning btn-xs mx-2" onClick={() => handleView(item.id)}>
                                                                            <i className="fa fa-eye"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {progressList == null ? "0" : rowCount} {t("News")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default ProgressList;
