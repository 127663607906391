import React from "react";

function Loader() {
    return (
        <>
            <tr style={{ height: "10rem" }}>
                <td colSpan={15} className="text-center mt-5">
                    <svg width="150px" height="100px" viewBox="0 0 473 202" version="1.1" xmlns="http://www.w3.org/2000/svg" className="wave">
                        <polyline
                            className="draw"
                            id="Path"
                            stroke="#80FFFC"
                            points="0.860997095 138.873289 134.14231 138.873289 179.72196 34.956929 236.304886 138.873289 263.697613 105.869702 281.135589 201.291758 309.757837 0.748786887 334.928688 138.873289 345.694798 163.251505 367.064745 131.32765 400.98579 101.020272 425.307836 131.32765 472.74737 131.32765"
                        ></polyline>
                    </svg>
                </td>
            </tr>
        </>
    );
}

export default Loader;
