import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../helper/Config";
import { ROLE_ID, STATUS_PENDING } from "../../../../helper/Constant";
import axios from "axios";
import { toast } from "react-toastify";

function FaildPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const [roleId, setRoleId] = useState(ROLE_ID.PATIENT);
    const [statusId, setStatusId] = useState(STATUS_PENDING.Active);

    const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);

    const fetchProfiledata = async () => {
        setIsPatientDataLoading(true);
        await axios
            .get(`${baseURL}/api/v1/profile`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setRoleId(res.data.data?.roleId);
                setStatusId(res.data.data?.statusId);
                setIsPatientDataLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    setIsPatientDataLoading(false);
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchProfiledata();
    }, []);

    return (
        <div className="login-form-header">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ width: "100px", display: "block", margin: "5px auto 0" }}>
                <circle class="path circle" fill="none" stroke="#dc3644" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <line class="path line" fill="none" stroke="#dc3644" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                <line class="path line" fill="none" stroke="#dc3644" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
            <h2 className="bold text-break text-danger text-center my-3">{t("Oops")} !!</h2>
            <div className="my-4">
                <h4 className="text-dark text-center">{t("Transaction failed")} !</h4>
                <h5 className="text-center">{t("Sorry, your transaction has been failed, Please check your email for more details")}.</h5>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <button
                    className="btn mt-10 btn-corner sign-up-btn right-15 text-white"
                    style={{ background: "#115278" }}
                    onClick={() => {
                        if (!isPatientDataLoading) {
                            navigate(statusId == STATUS_PENDING.Pending && roleId == ROLE_ID.PATIENT ? "/activate-patient" : "/dashboard");
                            window.location.reload();
                        }
                    }}
                >
                    {t("Back to home page")}
                </button>
            </div>
        </div>
    );
}

export default FaildPage;
