import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { baseURL } from "../../../helper/Config";
import { ROLE_ID } from "../../../helper/Constant";
import { unix } from "../../../helper/dateFormates";

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user",
};

function WebcamUpload(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.PATIENT ? "patient" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const [img, setImg] = useState("");

    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);
    });

    const handleRetake = () => {
        setImg("");
    };

    const handleWebcameClose = () => {
        setImg("");
        props.setWebcame(false);
    };

    const handleUpload = async () => {
        if (!img) {
            toast.error(t(`Please upload Image`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/patient/document/upsert`,
                    {
                        fileName: props.title + "_" + unix(new Date()) + ".png",
                        patientId: props.patientId,
                        type: props.type,
                        webCam: true,
                        document: img,
                    },
                    {
                        headers: {
                            // "Content-Type": "multipart/form-data",
                            "Content-Type": "application/x-www-form-urlencoded",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchThumbnailData();
                    handleWebcameClose();
                    setImg("");
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    const handleInvestorUpload = async () => {
        if (!img) {
            toast.error(t(`Please upload Image`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/progress/document/upsert`,
                    {
                        fileName: "progress" + "_" + unix(new Date()) + ".png",
                        progressId: props.progressId,
                        type: 1,
                        webCam: true,
                        document: img,
                    },
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchThumbnailData();
                    handleWebcameClose();
                    setImg("");
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <Modal show={props.webcame} onHide={handleWebcameClose} size="lg" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="webcam-container">
                        <div className="webcam-img text-center">
                            {img == "" ? <Webcam audio={false} height={500} ref={webcamRef} screenshotFormat="image/jpeg" width={520} videoConstraints={videoConstraints} /> : <img src={img} />}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={handleWebcameClose}>
                        {t("Close")}
                    </Button>

                    <div>
                        {img != "" ? (
                            <>
                                <button onClick={handleRetake} className="webcam-btn btn btn-warning">
                                    {t("Retake image")}
                                </button>
                                <button className="btn btn-primary gradient-blue mx-2" onClick={props.isFromInvester ? handleInvestorUpload : handleUpload}>
                                    {t("Upload")}
                                </button>
                            </>
                        ) : (
                            <button onClick={capture} className="webcam-btn btn btn-warning">
                                {t("Capture")}
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WebcamUpload;
