import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../helper/Config";
import { ROLE_ID } from "../../../helper/Constant";

function ImageFileUpload(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    let [image, setImage] = useState({ preview: "", data: "", name: "" });
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.PATIENT ? "patient" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const handleClose = () => {
        props.setShow(false);
        props.setIsEdit(false);
        props.setdocumentId("");
        setImage({ preview: "", data: "", name: "" });
    };

    const handleImageChange = (e) => {
        const data = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
            name: e.target.files[0].name,
        };
        const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
        let allowedFileTypes = ["pdf", "jpg", "png", "jpeg"];

        if (!allowedFileTypes.includes(fileExtension)) {
            e.target.value = null;
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            let element = document.getElementById("button");
            element.classList.remove("d-none");
            setImage(data);
        }
    };

    const handleUpload = async () => {
        let formData = new FormData();

        if (!image.data) {
            toast.error(t(`Please upload Image`));
        } else {
            if (props.isEdit) {
                formData.append("patientDocumentId", props.documentId);
            }
            formData.append("document", image.data);
            formData.append("fileName", image.name);
            formData.append("type", props.type);
            formData.append("webCam", false);
            formData.append("patientId", props.patientId);

            await axios
                .post(`${baseURL}/api/v1/${apiRouteName}/patient/document/upsert`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                        lang: languageShortName,
                    },
                })
                .then((res) => {
                    handleClose();
                    toast.success(res.data.message);
                    props.fetchThumbnailData();
                    // props.fetchPatientdata();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleInvestorUpload = async () => {
        let formData = new FormData();
        if (!image.data) {
            toast.error(t(`Please upload Image`));
        } else {
            formData.append("progressDocumentId", props.isEdit ? props.documentId : "");
            formData.append("document", image.data);
            formData.append("fileName", image.name);
            formData.append("type", image.name.split(".").at(-1).toLowerCase() == "pdf" ? 2 : 1);
            formData.append("webCam", false);
            formData.append("progressId", props.progressId);

            await axios
                .post(`${baseURL}/api/v1/admin/progress/document/upsert`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                        lang: languageShortName,
                    },
                })
                .then((res) => {
                    handleClose();
                    toast.success(res.data.message);
                    props.fetchThumbnailData();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="offset-2 col-md-8 my-4">
                            <input type="file" className="form-control" name="image" onChange={handleImageChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button className="btn btn-primary gradient-blue mx-2 d-none" id="button" onClick={props.isFromInvester ? handleInvestorUpload : handleUpload}>
                        {props.isEdit ? t("Update") : t(t("Add"))}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ImageFileUpload;
