import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../commoncomponents/Loader";
import { baseURL } from "../../../../helper/Config";
import { STATUS, STATUS_REJECTED } from "../../../../helper/Constant";
import { capitalizeFirstLetter } from "../../../../helper/helper";

function UpsertW9Form(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const handleUpdateStatus = async (statusId, comment) => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/w9Form/update`,
                {
                    id: props.w9FormId,
                    statusId: statusId,
                    comment: comment,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                props.fetchW9Formdata();
                toast.success(res.data.message);
                props.handleClose();
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Update")} {t("W9Form")} {t("Status")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="justify-content-center row my-3">
                        <div className="col-12 text-center">
                            <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true" style={{ fontSize: "4rem" }}></i>
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <label className="control-label col-md-12">{t("Comment")}</label>

                                <div className="col-md-12">
                                    <textarea type="text" className="form-control" name="comment" value={props.inputValue.comment} onChange={props.onChangeValue} rows={5} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="success" onClick={() => handleUpdateStatus(STATUS_REJECTED.Approved, props.inputValue.comment)}>
                        {t("Approve")}
                    </Button>
                    <Button variant="danger" onClick={() => handleUpdateStatus(STATUS_REJECTED.Rejected, props.inputValue.comment)}>
                        {t("Reject")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UpsertW9Form;
