import Modal from "react-bootstrap/Modal";

function VideoModal(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="m-5 d-flex justify-content-center">
                        <video
                            autoPlay
                            // muted
                            loop
                            className="hero-main-cont-custom"
                            controls="controls"
                            style={{ height: `${props.height <= 0 ? 100 : props.height}%`, width: `${props.height <= 0 ? 100 : props.height}%` }}
                        >
                            <source src={`${props.videoLink}`} type="video/mp4" />
                        </video>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default VideoModal;
