import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function GoogleMapHelpImage(props) {
    const { t } = useTranslation();

    return (
        <>
            <Modal show={props.showMapImage} onHide={() => props.setShowMapImage(false)} animation={false} size="lg" style={{ height: "1000px" }}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("How to get google map link?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img alt="" src="/assets/images/google-map-help.png" className="my-4" style={{ height: "100%", width: "100%" }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => props.setShowMapImage(false)}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default GoogleMapHelpImage;
