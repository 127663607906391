module.exports = {
    //////////////////////// SERVER ////////////////////////////////

    baseURL: "https://gdtapi.gdenti.com",
    frontURL: "https://gdenti.com",
    treatmentTypeId: "f14bd9fbc59c41fe8f2455e937bb94a16a01eba733b24a048d3bef496b911277",
    investorFrontURL: "https://investor.gdenti.com",

    //////////////////////// LOCAL ////////////////////////////////

    // baseURL: "http://localhost:5001",
    // frontURL: "http://localhost:3001",
    // treatmentTypeId: "a4b3436e8d4d4fe29124ccb716ac60e46bc849f91b1b4e529d2e2b6169a8b618",
    // investorFrontURL: "http://localhost:5003",
};
