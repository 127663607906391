import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL, frontURL } from "../../helper/Config";
import Loader from "../Loader";

const AgentDashboard = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [dashboardData, setDashboardData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchDashboardData = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/agent/dashboard`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setDashboardData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">
                            {t("Agent")} {t("Dashboard")}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="col-lg-12">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <section className="box nobox marginBottom0">
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="r4_counter r8_counter db_box" onClick={() => navigate("/manage-revenue-patient")}>
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa fa-user-plus" style={{ fontSize: "34px", color: "rgb(4 113 81)" }}></i>
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalPatient} {t("Patient")}
                                                    {/* {dashboardData && dashboardData.totalPatient > 1 ? "s" : ""} */}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Patient")}
                                                    {/* {dashboardData && dashboardData.totalPatient > 1 ? "s" : ""} */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="db_box">
                                            <div className="stats mt-15 mx-2">
                                                <div class="mb-2 d-flex justify-content-between">
                                                    <h5 className="fw-bold mt-15">Link for SNS</h5>
                                                    <span style={{ backgroundColor: "#eee", padding: "3px 5px", borderRadius: "4px" }}>
                                                        {frontURL}/signup?agent={profileData.userName}
                                                    </span>
                                                    <button
                                                        className="input-group-text mx-2 mt-1"
                                                        style={{
                                                            backgroundColor: "#03a9f4",
                                                            color: "white",
                                                        }}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`${frontURL}/signup?agent=${profileData.userName}`).then(() => {
                                                                toast.success("Link for SNS copied!");
                                                            });
                                                        }}
                                                    >
                                                        Copy
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default AgentDashboard;
