import moment from "moment";

export const monthDateYear = (date) => {
    if (date) {
        return moment(date).format("MM/DD/YYYY");
    } else {
        return "";
    }
};

export const time = (time) => {
    if (time) {
        return moment(time, "HH:mm:ss").format("h:mm A");
    } else {
        return "";
    }
};

export const defaultTimeZone = (time) => {
    if (time) {
        return moment(time, moment.defaultFormat).toDate();
    } else {
        return "";
    }
};

export const dateAPIFormate = (date) => {
    if (date) {
        return moment(date).format("YYYY-MM-DD");
    } else {
        return "";
    }
};

export const unix = (date) => {
    if (date) {
        return moment(date).unix();
    } else {
        return "";
    }
};

export const getPreviousYear = (date) => {
    if (date) {
        return moment(date).subtract(1, "years");
    } else {
        return "";
    }
};

export const daysDifference = (existingDate, expiryDate) => {
    if (existingDate && expiryDate) {
        // const expiryDate = moment(date);
        const daysUntilExpiry = moment(expiryDate).diff(moment(existingDate), "days");
        return daysUntilExpiry;
    } else {
        return "";
    }
};
