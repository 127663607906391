import React, { useState } from "react";
import { GENDER, STATUS, ROLE_ID, STATUS_PENDING } from "../../../helper/Constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, defaultTimeZone } from "../../../helper/dateFormates";
import Select from "react-select";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkEmail, checkPhNo, checkPwd, checkUserName } from "../../../helper/Validator";
import ManagePatientDocuments from "./ManagePatientDocuments";
import Loader from "../../../commoncomponents/Loader";
import { useEffect } from "react";
import GPointWalletUserVerify from "../../../commoncomponents/GPointWalletUserVerify";
import { useTranslation } from "react-i18next";

function UpsertPatient(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const { t } = useTranslation();

    const [step, setStep] = useState(1);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [passwordShown, setPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);
    const [show, setShow] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [officeId, setOfficeId] = useState("");
    const [doctorId, setDoctorId] = useState("");

    const handleSyncWallet = async () => {
        setShow(true);
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };

    const fetchCountryList = async () => {
        await axios
            .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
                isId: true,
            })
            .then((res) => {
                setCountryList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const countryOptions =
        countryList &&
        countryList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });
    const onCountryChange = (e) => {
        props.setInputValue({ ...props.inputValue, countryId: e.value, stateId: "", cityId: "" });
        setStateList([]);
        setCityList([]);
        fetchStateList(e.value);
    };

    const fetchStateList = async (countryId) => {
        await axios
            .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
                countryId: countryId,
                isId: true,
            })
            .then((res) => {
                setStateList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onStateChange = (e) => {
        props.setInputValue({ ...props.inputValue, stateId: e.value, cityId: "" });
        setCityList([]);
        fetchCityList(e.value);
    };

    const fetchCityList = async (stateId) => {
        await axios
            .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
                stateId: stateId,
                isId: true,
            })
            .then((res) => {
                setCityList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cityOptions =
        cityList &&
        cityList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCityChange = (e) => {
        props.setInputValue({ ...props.inputValue, cityId: e.value });
    };

    const fetchOfficeList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name + " (" + item.businessUsername + ")",
            };
        });

    const onOfficeChange = (e) => {
        setOfficeId(e.value);
        setDoctorId("");
        setDoctorList([]);
        fetchDoctorList(e.value);
    };
    const fetchDoctorList = async (officeId) => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/doctor/list?isPagination=false`,
                {
                    officeId: officeId,
                    isInOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorList &&
        doctorList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName + " (" + item.userName + ")",
            };
        });

    const onDoctorChange = (e) => {
        setDoctorId(e.value);
    };

    const [agentList, setAgentList] = useState([]);
    const fetchAgentList = async () => {
        await axios
            .post(`${baseURL}/api/v1/agentList`)
            .then((res) => {
                setAgentList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const AgentOptions =
        agentList &&
        agentList.map((item) => {
            return {
                value: item.agentId,
                label: item.fullName,
            };
        });

    const onAgentChange = (e) => {
        var agencyId = agentList.find((ele) => ele.agentId == e.value).agencyId;
        props.setInputValue({
            ...props.inputValue,
            agentId: e.value,
            agencyId: agencyId,
        });
    };

    const handleAdd = async () => {
        if (props.show && !props.isEdit && (!props.inputValue.email || props.inputValue.email === "" || props.inputValue.email === null)) {
            toast.error(t(`Please enter email`));
        } else if (props.show && !props.isEdit && (props.inputValue.email && checkEmail(props.inputValue.email)) == false) {
            toast.error(t(`Please enter valid email`));
        } else if (props.show && !props.isEdit && (!props.inputValue.password || props.inputValue.password === "" || props.inputValue.password === null)) {
            toast.error(t(`Please enter password`));
        } else if (props.show && !props.isEdit && props.inputValue.password && checkPwd(props.inputValue.password) == false) {
            toast.error(t(`Please enter valid password`));
        } else if (props.show && !props.isEdit && (!props.inputValue.confirmPassword || props.inputValue.confirmPassword === "" || props.inputValue.confirmPassword === null)) {
            toast.error(t(`Please enter confirm password`));
        } else if (props.show && !props.isEdit && props.inputValue.password !== props.inputValue.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
        } else if (!props.inputValue.firstName || props.inputValue.firstName === "" || props.inputValue.firstName === null) {
            toast.error(t(`Please enter first name`));
        } else if (!props.inputValue.lastName || props.inputValue.lastName === "" || props.inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        } else if (!props.inputValue.userName || props.inputValue.userName === "" || props.inputValue.userName === null) {
            toast.error(t(`Please enter username`));
        } else if (props.inputValue.userName && checkUserName(props.inputValue.userName) == false) {
            toast.error(t(`Please enter valid username`));
        } else if (!props.inputValue.phone1 || props.inputValue.phone1 === "" || props.inputValue.phone1 === null) {
            toast.error(t(`Please enter phone number`));
        } else if (!props.inputValue.dob || props.inputValue.dob === "" || props.inputValue.dob === null) {
            toast.error(t(`Please select date of birth`));
        } else if (props.inputValue.phone1 && checkPhNo(props.inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit phone number`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else if (!props.inputValue.countryId || props.inputValue.countryId === "" || props.inputValue.countryId === null) {
            toast.error(t(`Please enter country`));
        } else if (!props.inputValue.stateId || props.inputValue.stateId === "" || props.inputValue.stateId === null) {
            toast.error(t(`Please enter state`));
        } else if (!props.inputValue.cityId || props.inputValue.cityId === "" || props.inputValue.cityId === null) {
            toast.error(t(`Please enter city`));
        } else if (!props.inputValue.zip || props.inputValue.zip === "" || props.inputValue.zip === null) {
            toast.error(t(`Please enter zip`));
        } else if (props.isFromGDental && (!officeId || officeId === "" || officeId === null)) {
            toast.error(t(`Please select office`));
        } else if (props.isFromGDental && (!doctorId || doctorId === "" || doctorId === null)) {
            toast.error(t(`Please select doctor`));
        } else if (props.isFromGDental && (!props.inputValue.dateOfService || props.inputValue.dateOfService === "" || props.inputValue.dateOfService === null)) {
            toast.error(t(`Please select date of service`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/patient/upsert`,
                    {
                        patientId: props.isEdit ? props.patientId : "",
                        email: props.show && !props.isEdit ? props.inputValue.email : "",
                        password: props.show && !props.isEdit ? props.inputValue.password : "",
                        firstName: props.inputValue.firstName,
                        lastName: props.inputValue.lastName,
                        userName: props.inputValue.userName,
                        gender: props.inputValue.gender,
                        dob: dateAPIFormate(props.inputValue.dob),
                        phone1: props.inputValue.phone1,
                        statusId: props.inputValue.statusId,
                        address1: props.inputValue.address1,
                        countryId: props.inputValue.countryId,
                        stateId: props.inputValue.stateId,
                        cityId: props.inputValue.cityId,
                        zip: props.inputValue.zip,
                        ssn: props.inputValue.ssn,
                        agentId: props.inputValue.agentId,
                        agencyId: props.inputValue.agencyId,
                        // membership: props.inputValue.membership,
                        // recommenderId: props.inputValue.recommenderId,
                        howus: props.inputValue.howus,
                        gPointUserName: props.inputValue.gPointUserName,
                        isFromGDentalPatient: props.isFromGDental,
                        dateOfService: dateAPIFormate(props.inputValue.dateOfService),
                        officeId: officeId,
                        doctorId: doctorId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    if (props.show && !props.isEdit) {
                        props.setPatientId(res.data.data.patientId);
                    }
                    props.fetchPatientdata();
                    toast.success(res.data.message);
                    setStep(2);
                    // props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    useEffect(() => {
        if (props.isEdit && !props.editLoader) {
            fetchStateList(props.inputValue.countryId);
            fetchCityList(props.inputValue.stateId);
        }
        fetchCountryList();
        fetchOfficeList();
        fetchAgentList();
    }, [props.isEdit, props.editLoader]);

    return (
        <>
            <div className="col-xs-12 ">
                <div className="add-header-wrapper gradient-blue curved-section text-center">
                    <h2 className="uppercase bold w-text">
                        {props.isEdit && !props.view ? t("Update") : props.view ? t("View") : t("Add")} {t("Patient")}
                    </h2>
                    <div className="before-text">
                        {props.isEdit && !props.view ? t("Update") : props.view ? t("View") : t("Add")} {t("Patient")}
                    </div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                {step == 1 ? (
                                    <div className="content-body my-4 border-0">
                                        <div className="row">
                                            {props.show || props.view ? (
                                                <div className="col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-12">
                                                            {t("E-mail")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-12">
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                value={props.inputValue.email}
                                                                onChange={props.onChangeValue}
                                                                disabled={props.view ? "disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {props.show && !props.isEdit ? (
                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Password")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12 d-flex">
                                                                    <input
                                                                        type={passwordShown ? "text" : "password"}
                                                                        className="form-control"
                                                                        name="password"
                                                                        value={props.inputValue.password}
                                                                        onChange={props.onChangeValue}
                                                                        disabled={props.view ? "disabled" : ""}
                                                                    />
                                                                    <i
                                                                        className={`${passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                        id="basic-addon1"
                                                                        onClick={togglePassword}
                                                                    ></i>
                                                                </div>
                                                                <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                    *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Confirm Password")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12 d-flex">
                                                                    <input
                                                                        type={cfmpasswordShown ? "text" : "password"}
                                                                        className="form-control"
                                                                        name="confirmPassword"
                                                                        value={props.inputValue.confirmPassword}
                                                                        onChange={props.onChangeValue}
                                                                        disabled={props.view ? "disabled" : ""}
                                                                    />
                                                                    <i
                                                                        className={`${cfmpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                        id="basic-addon1"
                                                                        onClick={toggleCfmPassword}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("First name")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="firstName"
                                                                    value={props.inputValue.firstName}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Last name")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="lastName"
                                                                    value={props.inputValue.lastName}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Username")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="userName"
                                                                    value={props.inputValue.userName}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                                <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                    *{t("At least 5 characters : start and end with character or number, can have special character(-,_)")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Phone")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="phone1"
                                                                    value={props.inputValue.phone1}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("Gender")}</label>

                                                            <div className="col-md-12">
                                                                <select
                                                                    className="form-control col-md-3"
                                                                    name="gender"
                                                                    value={props.inputValue.gender}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                >
                                                                    {Object.keys(GENDER).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={GENDER[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Birth Date")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={props.inputValue.dob && defaultTimeZone(props.inputValue.dob)}
                                                                    placeholderText={"Please Select Date"}
                                                                    maxDate={new Date()}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    onChange={(date) => props.setInputValue({ ...props.inputValue, dob: date })}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Country")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="countryList"
                                                                    value={countryOptions && countryOptions.filter(({ value }) => value == props.inputValue.countryId)}
                                                                    options={countryOptions}
                                                                    onChange={onCountryChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("State")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="stateList"
                                                                    value={stateOptions && stateOptions.filter(({ value }) => value == props.inputValue.stateId)}
                                                                    options={stateOptions}
                                                                    onChange={onStateChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view || !props.inputValue.countryId ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("City")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="cityList"
                                                                    value={cityOptions && cityOptions.filter(({ value }) => value == props.inputValue.cityId)}
                                                                    options={cityOptions}
                                                                    onChange={onCityChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view || !props.inputValue.stateId ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Zip Code")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="zip"
                                                                    value={props.inputValue.zip}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Status")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <select
                                                                    className="form-control col-md-3"
                                                                    name="statusId"
                                                                    value={props.inputValue.statusId}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                >
                                                                    {Object.keys(props.inputValue.statusId == STATUS_PENDING.Pending ? STATUS_PENDING : STATUS).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={props.inputValue.statusId == STATUS_PENDING.Pending ? STATUS_PENDING[item] : STATUS[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("SSN")}</label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="ssn"
                                                                    value={props.inputValue.ssn}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("Recommender")}</label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    name="recommender"
                                                                    value={props.recommenderOptions && props.recommenderOptions.filter(({ value }) => value == props.inputValue.recommenderId)}
                                                                    options={props.recommenderOptions}
                                                                    onChange={props.onRecommenderChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("Membership")} #</label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="membership"
                                                                    value={props.inputValue.membership}
                                                                    // onChange={props.onChangeValue}
                                                                    // disabled={props.view ? "disabled" : ""}
                                                                    disabled="disabled"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <GPointWalletUserVerify show={show} setShow={setShow} inputGroup={props.inputValue} setInputGroup={props.setInputValue} />
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("GPoint Wallet Username")}</label>

                                                            <div className="col-xl-11 col-md-8 col-sm-8 col-12">
                                                                <input type="text" className="form-control" name="gPointUserName" value={props.inputValue.gPointUserName} disabled="disabled" />
                                                            </div>
                                                            {props.view ? (
                                                                ""
                                                            ) : (
                                                                <div className="col-xl-1 col-md-4 col-sm-4 col-12">
                                                                    <button type="button" className="form-control btn btn-info" onClick={handleSyncWallet}>
                                                                        {t("SYNC Wallet")}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("How did you hear about us?")}</label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="howus"
                                                                    value={props.inputValue.howus}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {props.isEdit ? (
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Agent")}</label>

                                                        <div className="col-md-12">
                                                            {/* <input
                                                                type="text"
                                                                className="form-control"
                                                                name="agent"
                                                                value={
                                                                    props.inputValue.agent && props.inputValue.agent
                                                                        ? props.inputValue.agent.firstName + " " + props.inputValue.agent.lastName + " (" + props.inputValue.agent.userName + ")"
                                                                        : ""
                                                                }
                                                                disabled}
                                                            /> */}
                                                            <Select
                                                                menuPlacement="top"
                                                                name="agent"
                                                                value={AgentOptions && AgentOptions.filter(({ value }) => value == props.inputValue.agentId)}
                                                                options={AgentOptions}
                                                                onChange={onAgentChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={profileData.roleId === ROLE_ID.ADMIN && !props.view ? false : true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {props.isFromGDental ? (
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Office")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="officeList"
                                                                    // value={officeOptions && officeOptions.filter(({ value }) => value == props.inputValue.officeId)}
                                                                    options={officeOptions}
                                                                    onChange={onOfficeChange}
                                                                    placeholder={t("Select")}
                                                                    menuPlacement="top"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Doctor")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="doctorList"
                                                                    // value={doctorOptions && doctorOptions.filter(({ value }) => value == props.inputValue.doctorId)}
                                                                    options={doctorOptions}
                                                                    onChange={onDoctorChange}
                                                                    placeholder={t("Select")}
                                                                    menuPlacement="top"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Date of service")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    selected={props.inputValue.dateOfService && defaultTimeZone(props.inputValue.dateOfService)}
                                                                    placeholderText={"Please Select Date"}
                                                                    maxDate={new Date()}
                                                                    scrollableMonthDropdown={true}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    onChange={(date) => {
                                                                        props.setInputValue({ ...props.inputValue, dateOfService: date });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className="padding-bottom-30 d-flex justify-content-center">
                                                <div className="text-left">
                                                    <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                        {t("Close")}
                                                    </button>
                                                    {props.view ? (
                                                        <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={() => setStep(2)}>
                                                            {t("Next")}
                                                        </button>
                                                    ) : (
                                                        <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleAdd}>
                                                            {props.isEdit ? t("Update") : t("Add")} & {t("Next")}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : step == 2 ? (
                                    <ManagePatientDocuments patientId={props.patientId} handleClose={props.handleClose} fetchPatientdata={props.fetchPatientdata} view={props.view} />
                                ) : (
                                    ""
                                )}
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertPatient;
