import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import NoDataFound from "../../commoncomponents/NoDataFound";
import Loader from "../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE } from "../../helper/Constant";
import { evaluationEmptyStars, evaluationRating } from "../../helper/helper";
import "react-datepicker/dist/react-datepicker.css";
import AddServiceAndCost from "../adminPanel/manageOffice/AddServiceAndCost";
import { useTranslation } from "react-i18next";

function ShowServiceAndCost(props) {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");

  const { t } = useTranslation();
  const { profileData } = useOutletContext();

  const [evaluationList, setEvaluationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showFee, setShowFee] = useState(false);
  const [officeId, setOfficeId] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [specialityCheckedList, setSpecialityCheckedList] = useState([]);
  const [filterValue, setFilterValue] = useState({
    doctorId: "",
    officeId: "",
  });
  const [officeList, setOfficeList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [doctor, setDoctor] = useState({
    name: "",
    id: "",
  });
  const [office, setOffice] = useState({
    name: "",
    id: "",
  });
  let apiRouteName =
    profileData.roleId == ROLE_ID.ADMIN
      ? "admin"
      : profileData.roleId == ROLE_ID.PATIENT
      ? "patient"
      : profileData.roleId == ROLE_ID.DOCTOR
      ? "doctor"
      : profileData.roleId == ROLE_ID.VERIFY
      ? "verifier"
      : "";

  const fetchEvaluationdata = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/dental-evaluation/compare/list?isPagination=true`,
        {
          // page: offset,
          rowsPerPage: ROWS_PER_PAGE,
          doctorId: filterValue.doctorId,
          officeId: filterValue.officeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setEvaluationList(res.data.data);
        setIsLoading(false);
        res.data.data.map((item) => {
          if (item.doctor.id === profileData.id) {
            setFilterValue({ ...filterValue, officeId: item.office.id });
            handleShowFee(
              item.office.id,
              item.office.name,
              item.office.specialtyPartId
            );
          }
        });
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  let selectOffice = [
    {
      id: "",
      name: t("Office"),
    },
  ];

  const fetchOfficedata = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
        {
          isDentalCompareOffice: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setOfficeList(
          res.data.data ? selectOffice.concat(res.data.data) : selectOffice
        );
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  let selectDoctor = [
    {
      id: "",
      firstName: t("Doctor"),
    },
  ];

  const fetchDoctorData = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
        {
          isDentalCompareDoctor: true,
          isInOffice: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setDoctorList(
          res.data.data ? selectDoctor.concat(res.data.data) : selectDoctor
        );
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const doctorOptions =
    doctorList &&
    doctorList.map((item) => {
      return {
        value: item.id,
        label: item.firstName + " " + (item.lastName ? item.lastName : ""),
      };
    });

  const handleShowFee = (officeId, officeName, specialtyPartId) => {
    setOfficeId(officeId);
    setOfficeName(officeName);
    setSpecialityCheckedList(
      specialtyPartId && specialtyPartId.split("~").map((item) => Number(item))
    );
    setShowFee(true);
  };

  const handleClose = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    fetchDoctorData();
    fetchOfficedata();
  }, []);

  useEffect(() => {
    fetchEvaluationdata();
  }, [filterValue.doctorId, filterValue.officeId]);

  return (
    <>
      <div className="col-xs-12">
        <div className="page-title">
          <div className="pull-left">
            <h1 className="title">
              {`${t("Service And Cost Information Of")} ${officeName}`}
            </h1>
          </div>

          <div className="pull-right">
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard">
                  <i className="fa fa-home"></i>
                  {t("Home")}
                </Link>
              </li>
              <>
                <li className="active cursor-pointer">
                  <strong>{t("Service-Cost Details")}</strong>
                </li>
              </>
            </ol>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {show ? (
            <></>
          ) : showFee ? (
            <div className="bg-w">
              <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                <section className="box1">
                  <AddServiceAndCost
                    view={true}
                    isfromSearchDental={true}
                    specialityCheckedList={specialityCheckedList}
                    setSpecialityCheckedList={setSpecialityCheckedList}
                    officeId={officeId}
                    step={3}
                    handleClose={handleClose}
                  />
                </section>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </>
  );
}

export default ShowServiceAndCost;
