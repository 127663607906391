import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../commoncomponents/Loader";
import NoDataFound from "../../commoncomponents/NoDataFound";
import { baseURL } from "../../helper/Config";
import { CLAIM_STATUS, PAYMENT_TYPE, PLAN_TYPE, ROWS_PER_PAGE, SUBSCRIPTION, VERIFIER_COMMENT_STATUS } from "../../helper/Constant";
import { monthDateYear } from "../../helper/dateFormates";
import UpsertClaim from "../../components/adminPanel/manageClaim/UpsertClaim";
import JoinGDentalPlan from "../../components/adminPanel/managePatient/JoinGDentalPlan";

import Swal from "sweetalert2";
import ChangeGdentalPlan from "../../components/adminPanel/managePatient/ChangeGDentalPlan";
import { useTranslation } from "react-i18next";

const PatientDashboard = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [dashboardData, setDashboardData] = useState();

    const [verifiedClaimList, setVerifiedClaimList] = useState([]);
    const [verifiedClaimCurrentPage, setVerifiedClaimCurrentPage] = useState(0);
    const [verifiedClaimOffset, setVerifiedClaimOffset] = useState(1);
    const [verifiedClaimPageCount, setVerifiedClaimPageCount] = useState(0);
    const [verifiedClaimRowCount, setVerifiedClaimRowCount] = useState(0);
    const [verifiedClaimIsLoading, setVerifiedClaimIsLoading] = useState(true);
    const [verifiedClaimData, setVerifiedClaimData] = useState([]);
    const [editLoader, setEditLoader] = useState(false);
    const [sum, setSum] = useState([]);
    const [inputValueObj, setInputValueObj] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [claimList, setClaimList] = useState([]);
    const [claimCurrentPage, setClaimCurrentPage] = useState(0);
    const [claimOffset, setClaimOffset] = useState(1);
    const [claimPageCount, setClaimPageCount] = useState(0);
    const [claimRowCount, setClaimRowCount] = useState(0);
    const [claimIsLoading, setClaimIsLoading] = useState(true);

    const [officeId, setOfficeId] = useState("");
    const [doctorId, setDoctorId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [dateOfService, setDateOfService] = useState("");
    const [isView, setIsView] = useState(false);
    const [isVerifyClaimList, setIsVerifyClaimList] = useState(false);
    const [claimId, setClaimId] = useState("");
    const [show, setShow] = useState(false);
    const [claimInputValue, setClaimInputValue] = useState({
        verifierNote: "",
        doctorNote: "",
        finalResult: "",
        isVerifierNoteChanged: 0,
        isFinalResultChanged: 0,
        isDoctorNoteChanged: 0,
    });
    const [oldNoteValues, setOldNoteValues] = useState({
        verifierNote: "",
        doctorNote: "",
        finalResult: "",
    });

    const [joinMembership, setJoinMembership] = useState(false);
    const [registrationFee, setRegistrationFee] = useState(0);
    // const [membershipRemainDay, setMembershipRemainDay] = useState(0);
    const [ispaymentchange, setIspaymentchange] = useState(false);

    const handleClaimView = (id, officeId) => {
        setClaimId(id);
        claimDetail(id);
        fetchTreatmentTypeData(id, officeId);
        setIsView(true);
    };

    const handleClose = () => {
        setClaimId("");
        setInputValueObj([]);
        setClaimInputValue("");
        setSum([]);
        setPatientId("");
        setDateOfService("");
        setIsView(false);
        setShow(false);
    };

    const fetchTreatmentTypeData = async (claimId, officeId) => {
        axios
            .post(
                `${baseURL}/api/v1/patient/treatment/list?isPagination=false`,
                {
                    claimId: claimId,
                    officeId: officeId,
                    isListAllRecord: false,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                let obj = [];

                res.data.data &&
                    res.data.data.map((item) => {
                        obj.push({
                            treatmentTypeId: item.treatmentTypeId,
                            treatmentId: Number(item.id),
                            fee: Number(item.originalFee),
                            unit: item.unit,
                            totalUnitFee: item.totalUnitFee,
                        });
                    });
                setInputValueObj(obj);
                let sum =
                    res.data.data &&
                    res.data.data.reduce((acc, obj) => {
                        if (!acc[obj.treatmentTypeId]) {
                            acc[obj.treatmentTypeId] = 0;
                        }
                        acc[obj.treatmentTypeId] += obj.totalUnitFee;
                        return acc;
                    }, {});

                setSum(sum);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const claimDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/patient/claim/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                let response = res.data.data;
                setOfficeId(response.office.id);
                setDoctorId(response.doctor.id);
                setPatientId(response.patient.id);
                setDateOfService(response.dateOfService);
                setVerifiedClaimData(response);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const claimNoteDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/patient/claim/comment/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setClaimInputValue(res.data.data);
                setOldNoteValues(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setClaimInputValue({ ...claimInputValue, [name]: value });
    };

    //////////////////////// CLAIM //////////////////////////////////////////////////////////////

    const handleClaimPageClick = (event) => {
        const selectedPage = event.selected;
        setClaimOffset(selectedPage + 1);
        setClaimCurrentPage(selectedPage);
    };

    const fetchClaimdata = async () => {
        setClaimIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/patient/claim/list`,
                {
                    page: claimOffset,
                    rowsPerPage: ROWS_PER_PAGE,
                    isHalfYearlyClaim: true,
                    patientId: profileData.id,
                    isSubmitted: "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setClaimList(res.data.data);
                setClaimPageCount(res.data.extraData.pageCount);
                setClaimRowCount(res.data.extraData.rowCount);
                setClaimIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchClaimdata();
    }, [claimOffset]);

    //////////////////////// VERIFIED - CLAIM //////////////////////////////////////////////////////////////

    const handleVerifiedClaimPageClick = (event) => {
        const selectedPage = event.selected;
        setVerifiedClaimOffset(selectedPage + 1);
        setVerifiedClaimCurrentPage(selectedPage);
    };

    const fetchVerifiedClaimdata = async () => {
        setVerifiedClaimIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/patient/claim/list`,
                {
                    page: verifiedClaimOffset,
                    rowsPerPage: ROWS_PER_PAGE,
                    isSubmitted: CLAIM_STATUS.Submitted,
                    isVerifierClaimList: true,
                    isHalfYearlyClaim: true,
                    patientId: profileData.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setVerifiedClaimList(res.data.data);
                setVerifiedClaimPageCount(res.data.extraData.pageCount);
                setVerifiedClaimRowCount(res.data.extraData.rowCount);
                setVerifiedClaimIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchVerifiedClaimdata();
    }, [verifiedClaimOffset]);

    const fetchDashboardData = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/patient/dashboard`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setDashboardData(res.data.data);
                // const expiredate = new Date(res.data.data.expiryDate);
                // const today = new Date();
                // const Days = Math.round(Math.abs((expiredate - today) / oneDay));
                // const Days = daysDifference(res.data.data.currentDate, res.data.data.expiryDate);
                // setMembershipRemainDay(Days);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    //////////////////////// BILLLING_PORTAL //////////////////////////////////////////////////////////////////

    const handleBillingPortal = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/patient/billing-portal/create`,
                {
                    patientId: profileData.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                window.location.replace(res.data.data.billingPortal);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleWithdraw = async () => {
        Swal.fire({
            title: t("Are you sure you want to withdraw this membership ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, withdraw it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/patient/registration-fee/withdraw`,
                            {
                                patientId: profileData.id,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success").then((res) => {
                                    if (res.isConfirmed) {
                                        fetchDashboardData();
                                    }
                                    fetchDashboardData();
                                });
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleWithdrawCancel = async () => {
        Swal.fire({
            title: t("Are you sure you want to cancel this membership's withdrawal?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, cancel it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/patient/registration-fee/withdrawCancel`,
                            {
                                patientId: profileData.id,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success").then((res) => {
                                    if (res.isConfirmed) {
                                        fetchDashboardData();
                                    }
                                    fetchDashboardData();
                                });
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };
    const [price, setPrice] = useState(dashboardData && dashboardData.membership && dashboardData.membership.price ? dashboardData.membership.price : 0);
    const handleChangePayment = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/patient/plan/check`,
                {
                    type: dashboardData.membership.type,
                    isSubscription: dashboardData.membership.isSubscription,
                    patientId: profileData.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setPrice(res.data.data.price);
                setIspaymentchange(true);
            })
            .catch((err) => {
                if (err.response) {
                    setPrice(dashboardData.membership.price);
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{isView && isVerifyClaimList ? t("Manage verified claim") : isView && !isVerifyClaimList ? t("Manage claim") : `${t("Patient")} ${t("Dashboard")}`}</h1>
                    </div>
                    {isView ? (
                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li className="cursor-pointer" onClick={handleClose}>
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </li>

                                <li className="active cursor-pointer">
                                    <strong>
                                        {t("View")} {isView ? `${t("Claim")}` : `${t("Evaluation")}`}
                                    </strong>
                                </li>
                            </ol>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            {isView ? (
                <UpsertClaim
                    fetchClaimdata={fetchVerifiedClaimdata}
                    oldNoteValues={oldNoteValues}
                    inputValue={claimInputValue}
                    setInputValue={setClaimInputValue}
                    onChangeValue={onChangeValue}
                    show={show}
                    setShow={setShow}
                    claimDetail={claimDetail}
                    claimNoteDetail={claimNoteDetail}
                    isVerifyClaimList={isVerifyClaimList}
                    editLoader={editLoader}
                    sum={sum}
                    setSum={setSum}
                    inputValueObj={inputValueObj}
                    setInputValueObj={setInputValueObj}
                    officeId={officeId}
                    doctorId={doctorId}
                    patientId={patientId}
                    dateOfService={dateOfService}
                    handleClose={handleClose}
                    claimData={verifiedClaimData}
                    claimId={claimId}
                    isView={isView}
                />
            ) : (
                <>
                    <div className="col-lg-12">
                        <section className="box nobox marginBottom0">
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="r4_counter r6_counter db_box" onClick={() => navigate("/manage-claim")}>
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa-regular fa-address-card" style={{ fontSize: "34px", color: "rgb(184 61 34)" }}></i>
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalClaim} {t("Claim")}
                                                    {/* {dashboardData && dashboardData.totalClaim > 1 ? "s" : ""} */}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Claim")}
                                                    {/* {dashboardData && dashboardData.totalClaim > 1 ? "s" : ""} */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 col-xs-12">
                                        <div className="r4_counter r6_counter db_box" onClick={() => navigate("/manage-claim-verify")}>
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa fa-envelope-o" style={{ fontSize: "34px", color: "rgb(184 61 34)" }}></i>
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalVerifiedClaim} {t("Verified")} {t("Claim")}
                                                    {/* {dashboardData && dashboardData.totalVerifiedClaim > 1 ? "s" : ""} */}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Verified")} {t("Claim")}
                                                    {/* {dashboardData && dashboardData.totalVerifiedClaim > 1 ? "s" : ""} */}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-xs-12">
                                        {profileData && profileData.patient && profileData.patient.isGDenticarePlan == 1 ? (
                                            <section className="box gradient-orange1 card m-0">
                                                <div className="d-lg-flex justify-content-between">
                                                    <h4 className="fw-bold text-center">{t("G-Dental membership status")}</h4>
                                                    <div className="d-lg-flex justify-content-between">
                                                        {dashboardData && dashboardData.isWithdraw == 0 ? (
                                                            <button
                                                                // className={`btn btn-danger gradient-orange fw-bold mt-0 mb-3  btn-corner ${membershipRemainDay >= 30 ? "mx-2" : ""}`}
                                                                className="btn btn-danger gradient-orange fw-bold mt-0 mb-3  btn-corner mx-2"
                                                                onClick={handleWithdraw}
                                                            >
                                                                {t("Withdraw")}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                // className={`btn btn-danger gradient-orange fw-bold mt-0 mb-3  btn-corner ${membershipRemainDay >= 30 ? "mx-2" : ""}`}
                                                                className="btn btn-danger gradient-orange fw-bold mt-0 mb-3  btn-corner mx-2"
                                                                onClick={handleWithdrawCancel}
                                                            >
                                                                {t("Cancel Withdrawal")}
                                                            </button>
                                                        )}
                                                        {/* {membershipRemainDay < 30 ? (
                                                            <> */}
                                                        <button className="btn btn-warning gradient-blue fw-bold mt-0 mb-3 btn-corner mx-2" onClick={handleChangePayment}>
                                                            {t("Change")}
                                                        </button>
                                                        {ispaymentchange ? (
                                                            <ChangeGdentalPlan
                                                                expiryDate={dashboardData.expiryDate}
                                                                fetchDashboardData={fetchDashboardData}
                                                                show={ispaymentchange}
                                                                setShow={setIspaymentchange}
                                                                type={dashboardData.membership.type}
                                                                isSubscription={dashboardData.membership.isSubscription}
                                                                price={price}
                                                                paymentMethod={dashboardData.membership.paymentType}
                                                                isRegisterPatient={dashboardData && dashboardData.isRegisterPatient}
                                                                registrationFee={registrationFee}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* </>
                                                        ) : (
                                                            <></>
                                                        )} */}

                                                        {dashboardData &&
                                                        dashboardData.membership &&
                                                        dashboardData.membership.isSubscription == SUBSCRIPTION.Auto &&
                                                        (dashboardData.membership.paymentType == PAYMENT_TYPE.Card || dashboardData.membership.paymentType == PAYMENT_TYPE.STRIPE_ACH) ? (
                                                            <button className="btn btn-danger fw-bold mt-0 mb-3 btn-corner" onClick={handleBillingPortal}>
                                                                {t("Billing Portal")}
                                                            </button>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                                <hr className="my-0" style={{ border: "0.5px solid #fff" }} />
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                        <div className="stats">
                                                            <h4 className="mb-5">
                                                                <b>{t("Membership Plan")} :</b> {dashboardData && dashboardData.membership && dashboardData.membership.name}
                                                            </h4>
                                                        </div>
                                                        <div className="stats">
                                                            <h4 className="mb-5">
                                                                <b>{t("Payment method")} :</b>{" "}
                                                                {dashboardData &&
                                                                    dashboardData.membership &&
                                                                    Object.keys(PAYMENT_TYPE).find((key) => PAYMENT_TYPE[key] == dashboardData.membership.paymentType)}{" "}
                                                            </h4>
                                                        </div>
                                                        <div className="stats">
                                                            <h4 className="mb-5">
                                                                <b>{t("Paid amount")} :</b> ${dashboardData && dashboardData.membership && dashboardData.membership.price}
                                                                <small className="fw-bold">
                                                                    {" "}
                                                                    /
                                                                    {dashboardData && dashboardData.membership && Object.keys(PLAN_TYPE).find((key) => PLAN_TYPE[key] == dashboardData.membership.type)}
                                                                    ly
                                                                </small>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                        <div className="stats">
                                                            <h4 className="mb-5">
                                                                <b>{t("Subscription")} :</b>{" "}
                                                                {dashboardData &&
                                                                    dashboardData.membership &&
                                                                    Object.keys(SUBSCRIPTION).find((key) => SUBSCRIPTION[key] == dashboardData.membership.isSubscription)}
                                                            </h4>
                                                        </div>
                                                        <div className="stats">
                                                            <h4 className="mb-5">
                                                                <b>{t("Expiry Date")} :</b> {dashboardData && monthDateYear(dashboardData.expiryDate)}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        ) : (
                                            <section className="box gradient-orange1 card m-0">
                                                <h4 className="mb-5 text-center">
                                                    {t("G-Dental membership")}: {t("Not Joined")}{" "}
                                                </h4>
                                                {joinMembership ? (
                                                    <JoinGDentalPlan
                                                        patientId={profileData.id}
                                                        fetchDashboardData={fetchDashboardData}
                                                        show={joinMembership}
                                                        setShow={setJoinMembership}
                                                        isRegisterPatient={dashboardData && dashboardData.isRegisterPatient}
                                                        registrationFee={registrationFee}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                        className="btn btn-danger mt-2 btn-corner"
                                                        onClick={() => {
                                                            setJoinMembership(true);
                                                            setRegistrationFee(dashboardData && dashboardData.registrationFee ? dashboardData.registrationFee : 0);
                                                        }}
                                                    >
                                                        {t("Join G-Denticare plan")}
                                                    </button>
                                                </div>
                                            </section>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="clearfix"></div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title pull-left"> {t("Last half year's claim list")}</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table vm table-small-font no-mb table-bordered table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("Created Date")}</th>
                                                                <th>{t("Date of service")}</th>
                                                                <th>{t("Doctor")}</th>
                                                                <th>{t("Office")}</th>
                                                                <th>{t("Action")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!claimList || (claimList && !claimList.length && !isLoading) ? (
                                                                <NoDataFound />
                                                            ) : claimIsLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                claimList &&
                                                                claimList.map((item, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.doctor
                                                                                        ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.office && item.office.name ? item.office.name : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-warning btn-xs"
                                                                                    onClick={() => {
                                                                                        setIsVerifyClaimList(false);
                                                                                        handleClaimView(item.id, item.office.id);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-eye "></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-lg-flex justify-content-between ">
                                                    <div className="d-flex align-center">
                                                        {t("Total")} {claimList == null ? "0" : claimRowCount} {t("Claim")}
                                                        {/* {claimRowCount && claimRowCount > 1 ? "s" : ""} */}
                                                    </div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={claimPageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleClaimPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel={t("Previous")}
                                                            nextLabel={t("Next")}
                                                            disableInitialCallback={true}
                                                            forcePage={claimCurrentPage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <section className="box">
                                    <header className="panel_header">
                                        <h2 className="title pull-left"> {t("Last half year's verified claim list")}</h2>
                                    </header>
                                    <div className="content-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table vm table-small-font no-mb table-bordered table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>{t("Claim")} #</th>
                                                                <th>{t("Doctor")}</th>
                                                                <th>{t("Office")}</th>
                                                                <th>{t("Date of service")}</th>
                                                                <th>{t("Result")}</th>
                                                                <th>{t("Action")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {!verifiedClaimList || (verifiedClaimList && !verifiedClaimList.length && !isLoading) ? (
                                                                <NoDataFound />
                                                            ) : verifiedClaimIsLoading ? (
                                                                <Loader />
                                                            ) : (
                                                                verifiedClaimList &&
                                                                verifiedClaimList.map((item, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <h6>{item.code ? item.code : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.doctor
                                                                                        ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.office && item.office.name ? item.office.name : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    className={`badge badge-${
                                                                                        item.finalResult == VERIFIER_COMMENT_STATUS.Good
                                                                                            ? "success"
                                                                                            : item.finalResult == VERIFIER_COMMENT_STATUS.Normal
                                                                                            ? "warning"
                                                                                            : item.finalResult == VERIFIER_COMMENT_STATUS["Not Good"]
                                                                                            ? "danger"
                                                                                            : "info"
                                                                                    }`}
                                                                                >
                                                                                    {Object.keys(VERIFIER_COMMENT_STATUS).find((key) => VERIFIER_COMMENT_STATUS[key] === item.finalResult)}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-warning btn-xs"
                                                                                    onClick={() => {
                                                                                        setIsVerifyClaimList(true);
                                                                                        handleClaimView(item.id, item.office.id);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-eye "></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="d-lg-flex justify-content-between ">
                                                    <div className="d-flex align-center">
                                                        {t("Total")} {verifiedClaimList == null ? "0" : verifiedClaimRowCount} {t("Claim")}
                                                        {/* {verifiedClaimRowCount && verifiedClaimRowCount > 1 ? "s" : ""} */}
                                                    </div>
                                                    <div className="pagination-center">
                                                        <ReactPaginate
                                                            initialPage={0}
                                                            pageCount={verifiedClaimPageCount}
                                                            marginPagesDisplayed={0}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handleVerifiedClaimPageClick}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageLinkClassName="page-link"
                                                            breakLinkClassName="page-link"
                                                            nextLinkClassName="page-link"
                                                            previousLinkClassName="page-link"
                                                            pageClassName="page-item"
                                                            breakClassName="page-item"
                                                            nextClassName="page-item"
                                                            previousClassName="page-item"
                                                            previousLabel={t("Previous")}
                                                            nextLabel={t("Next")}
                                                            disableInitialCallback={true}
                                                            forcePage={verifiedClaimCurrentPage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PatientDashboard;
