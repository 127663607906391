import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import Loader from "../Loader";

const ManagerDashboard = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { t } = useTranslation();

    const [dashboardData, setDashboardData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const fetchDashboardData = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/manager/dashboard`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setDashboardData(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">
                            {t("Manager")} {t("Dashboard")}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="col-lg-12">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <section className="box nobox marginBottom0">
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="r4_counter db_box" onClick={() => navigate("/manage-manager-agency")}>
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa fa-building" style={{ fontSize: "34px", color: "rgb(77 108 143)" }}></i>
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalAgency} {t("Agency")}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Agency")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6 col-xs-12">
                                        <div className="r4_counter db_box">
                                            <i className="pull-left ico-icon icon-md icon-primary mt-10">
                                                <i className="fa fa-user-secret" style={{ fontSize: "34px", color: "rgb(77 108 143)" }} />
                                            </i>
                                            <div className="stats">
                                                <h3 className="mb-5">
                                                    {dashboardData && dashboardData.totalAgent} {t("Agent")}
                                                </h3>
                                                <span>
                                                    {t("Total")} {t("Agent")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default ManagerDashboard;
