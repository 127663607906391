import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../../helper/Config";
import ReactPaginate from "react-paginate";
import { ROLE_ID, ROWS_PER_PAGE, STATUS_REJECTED } from "../../../../helper/Constant";
import UpsertW9FormStatus from "./UpsertW9FormStatus";
import Loader from "../../../../commoncomponents/Loader";
import NoDataFound from "../../../../commoncomponents/NoDataFound";
import { useTranslation } from "react-i18next";

function W9FormList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [w9FormList, setW9FormList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [inputValue, setInputValue] = useState({
        comment: "",
    });
    const [w9FormId, setW9FormId] = useState("");
    const [filterValue, setFilterValue] = useState({
        statusSearch: "",
    });

    const fetchW9Formdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/w9Formlist?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    statusId: filterValue.statusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setW9FormList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const handleStatusChange = (id) => {
        setW9FormId(id);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const handleClose = () => {
        setInputValue({
            comment: "",
        });
        setW9FormId("");
        setShow(false);
    };

    useEffect(() => {
        fetchW9Formdata();
    }, [offset, filterValue.statusSearch]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("W9Form List")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>{t("W9Form List")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <section className="box">
                        <header className="panel_header">
                            <div className="mx-3">
                                <div className="row ">
                                    <div className="col-lg-6 col-md-4 col-sm-12">
                                        <h2 className="title pull-left">
                                            <UpsertW9FormStatus
                                                handleClose={handleClose}
                                                inputValue={inputValue}
                                                onChangeValue={onChangeValue}
                                                show={show}
                                                setShow={setShow}
                                                w9FormId={w9FormId}
                                                fetchW9Formdata={fetchW9Formdata}
                                            />
                                        </h2>
                                    </div>
                                    <div className="col-lg-6 col-md-8 col-sm-12 d-lg-flex justify-content-lg-end">
                                        <div className="row mx-2">
                                            <div className="title">
                                                <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                    {Object.keys(STATUS_REJECTED).map((item, i) => {
                                                        return (
                                                            <option key={i} value={STATUS_REJECTED[item]}>
                                                                {t(item)}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="content-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="table-responsive" data-pattern="priority-columns">
                                        <table
                                            id="tech-companies-1"
                                            className={`table vm table-small-font no-mb table-bordered ${
                                                isLoading || !w9FormList || (w9FormList && !w9FormList.length) ? "" : "table-striped"
                                            } table-hover`}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>{t("No")}</th>
                                                    <th>{t("Type")}</th>
                                                    <th>{t("Username")}</th>
                                                    <th>{t("Name")}</th>
                                                    <th>{t("W9 Form")}</th>
                                                    <th>{t("Status")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!w9FormList || (w9FormList && !w9FormList.length && !isLoading) ? (
                                                    <NoDataFound />
                                                ) : isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    w9FormList &&
                                                    w9FormList.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="left">{(offset - 1) * ROWS_PER_PAGE + i + 1}</td>
                                                                <td>
                                                                    <h6>{item.roleId ? Object.keys(ROLE_ID).find((key) => ROLE_ID[key] == item.roleId) : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.userName ? item.userName : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.firstName ? item.firstName : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <a href={`${baseURL}${item.w9FormFile}`} target="_blank">
                                                                        <h6 className="text-primary">
                                                                            <u>W9 Form</u>
                                                                        </h6>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className={`badge badge-${
                                                                            item.w9FormStatus == STATUS_REJECTED.Approved
                                                                                ? "success"
                                                                                : item.w9FormStatus == STATUS_REJECTED.Requested
                                                                                ? "warning"
                                                                                : "danger"
                                                                        }`}
                                                                        onClick={item.w9FormStatus == STATUS_REJECTED.Requested ? () => handleStatusChange(item.id) : () => {}}
                                                                    >
                                                                        {Object.keys(STATUS_REJECTED).find((key) => STATUS_REJECTED[key] == item.w9FormStatus)}
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-lg-flex justify-content-between ">
                                        <div className="d-flex align-center">
                                            {t("Total")} {w9FormList == null ? "0" : rowCount} {t("W9 Form")}
                                        </div>
                                        <div className="pagination-center">
                                            <ReactPaginate
                                                initialPage={0}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={0}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                previousLabel={t("Previous")}
                                                nextLabel={t("Next")}
                                                disableInitialCallback={true}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default W9FormList;
