import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { baseURL } from "../../helper/Config";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

const DoctorSideBar = () => {
    const { t } = useTranslation();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    const cookies = new Cookies();
    const officeId = cookies.get("office");
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const [verifierApplied, setVerifierApplied] = useState("");

    const officeDetail = async (officeId) => {
        await axios
            .get(`${baseURL}/api/v1/doctor/office/detail/${officeId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setVerifierApplied(res.data.data.verifierApplied);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        officeDetail(officeId);
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCollapse = () => {
        if (windowSize.width < 777) {
            $(".page-sidebar").addClass("collapseit").removeClass("expandit");
        }
    };

    return (
        <>
            <div className="page-sidebar fixedscroll">
                <div className="page-sidebar-wrapper" id="main-menu-wrapper">
                    <ul className="wraplist">
                        <li className="menusection">{t("Doctor")}</li>
                        <li className="open" onClick={handleCollapse}>
                            <Link to="/dashboard">
                                <i
                                    className="fa fa-dashcube"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Dashboard")}</span>
                            </Link>
                        </li>
                        <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa-regular fa-hospital-o"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Office")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-doctor-office">{t("All Office")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-wheelchair"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("G-Dental Patient")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-gdental-patient">{t("All G-Dental Patients")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa-regular fa-address-card"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Claim")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-claim">{t("All Claim")}</Link>
                                </li>
                            </ul>
                        </li>
                        {verifierApplied == 1 ? (
                            <li className="cursor-pointer">
                                <a>
                                    <i
                                        className="fa fa-envelope-o"
                                        style={{
                                            fontSize: "18px",
                                            color: "rgb(109 53 134)",
                                            top: "2px",
                                        }}
                                    ></i>
                                    <span className="title">{t("Claim Verify")}</span>
                                    <span className="arrow "></span>
                                </a>
                                <ul className="sub-menu">
                                    <li onClick={handleCollapse}>
                                        <Link to="/manage-claim-verify">{t("All Claim Verify")}</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            ""
                        )}
                        <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-star-o"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Evaluation")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-evaluation">{t("All Evaluation")}</Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-briefcase"
                                    style={{
                                        fontSize: "15px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Office-Employee")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-office-employee">{t("All Office-Employees")}</Link>
                                </li>
                            </ul>
                        </li> */}
                        {/* <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-video-camera"
                                    style={{
                                        fontSize: "15px",
                                        color: "rgb(109 53 134)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Zoom-Meeting")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-zoom-meeting">{t("Manage Zoom-Meeting")}</Link>
                                </li>
                            </ul>
                        </li> */}
                        <li className="cursor-pointer">
                            <a>
                                <i className="img">
                                    <i
                                        className="fa fa-folder-open"
                                        style={{
                                            fontSize: "15px",
                                            color: "rgb(109 53 134)",
                                            top: "2px",
                                        }}
                                    ></i>
                                </i>
                                <span className="title">{t("Configuration")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/treatment">{t("Treatment")}</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default DoctorSideBar;
