import axios from "axios";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { checkEmail } from "../../helper/Validator";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function GetInTouch() {
    const navigate = useNavigate();
    const [mailData, setMailData] = useState({
        email: "",
        name: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();
    const languageShortName = localStorage.getItem("languageShortName");

    const divRef = useRef(null);
    const [clipPath, setClipPath] = useState("");

    const [linePosition, setLinePosition] = useState([]); //Initial Line position
    const [lineHeight, setLineHeight] = useState([]); //Initial Line Height
    const [lineWidth, setLineWidth] = useState([]); //Initial Line Width

    let verticalHeight = null;
    let verticalWidth = null;
    let verticalPosition = null;

    function handleVerticalImageLoad() {
        const divWidth = divRef.current.offsetWidth;

        verticalHeight = divWidth * 0.235;
        verticalWidth = divWidth * 0.015;
        verticalPosition = divWidth * 0.5;

        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setLinePosition(verticalPosition);
        setClipPath(`polygon(0 0, ${verticalPosition}px 0, ${verticalPosition}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    }

    const handleMouseMove = (event) => {
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const x = event.clientX - rect.left;
        setLinePosition(x);
        verticalHeight = rect.width * 0.235;
        verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // prevent scrolling on touch devices
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const touch = event.touches[0];
        const x = touch.clientX - rect.left;
        setLinePosition(x);
        const verticalHeight = rect.width * 0.235;
        const verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
      };

    return (
        <>
            <Header />

            <section>
                <div ref={divRef} className="hero-get-in-toch-custom" style={{ cursor: "grab", position: "relative", width: "100%", height: `${lineHeight}px` }} onMouseMove={handleMouseMove} onTouchStart={handleTouchMove} onTouchMove={handleTouchMove}>
                    <img src="/assets/images/home/home_contact_left.png" alt="before" style={{ position: "absolute", left: 0, top: 0, zIndex: 2, width: "100%", height: "auto", clipPath }} />
                    <img src="/assets/images/home/home_contact_right.png" alt="after" style={{ position: "absolute", left: 0, top: 0, zIndex: 1, width: "100%", height: "auto" }} />
                    <img
                        src="/assets/images/home/circle_line_icon.png"
                        alt="Vertical Line"
                        style={{ position: "absolute", top: 0, left: `${linePosition}px`, transform: "translateX(-50%)", width: `${lineWidth}px`, height: `${lineHeight}px`, zIndex: 3 }}
                        onLoad={handleVerticalImageLoad}
                    />
                </div>
            </section>
            <br />

            {/* Contact Us */}
            <section className="pt-10 d-flex justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-7 text-title-sm-custom text-center" data-aos="zoom-in">
                    <h3 className="bold pb-2">
                    {languageShortName == "ko" ? (
                        <>
                        <span className="quality-text-custom">{t("Touch2")}</span>
                        <span>{t("Get in2")}</span>
                        </>
                    ) : (
                        <>
                        <span>{t("Get in2")} </span>
                        <span className="quality-text-custom">{t("Touch2")}</span>
                        </>
                    )}
                    </h3>
                    <button className="btn btn-outline text-white join-now-custom btn-arrow-width-custom" onClick={() => navigate("/signup")}>
                        {t("Join now")}
                        <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                    </button>
                </div>
            </section>

            <section className="pt-5 d-flex justify-content-center contact-section-custom" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                <div className="card contact-info-box-custom">
                    <div className="row d-flex p-3">
                        <div className="col-lg-5 col-sm-5 contact-text-info-custom contact-info-custom position-relative">
                            <div className="mask-group-img-custom">
                                <img src="/assets/images/home/mask-group.png" alt="" />
                            </div>
                            <h3 className="bold text-info-contact-custom">{t("Contact Information")}</h3>
                            <div className="ref-text-center-custom">{t("We can't wait to hear from you! Kindly take a moment to share your information with us so we can get in touch.")}</div>

                            <div className="pt-4 cont-inf-bot-custom">
                                <div className="d-flex">
                                    <a className="d-flex align-items-center justify-content-center btn-primary btn-lg-square rounded me-2 btn-icon-link-custom" role="button">
                                        <i className="fa fa-phone"></i>
                                    </a>
                                    <div className="pb-2">
                                        <p className="mb-0">Phone Number</p>
                                        <p className="cont-details-custom">201-496-6366</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <a className="d-flex align-items-center justify-content-center btn-primary btn-lg-square rounded me-2 btn-icon-link-custom" role="button">
                                        <i className="fa fa-envelope"></i>
                                    </a>
                                    <div className="pb-2">
                                        <p className="mb-0">Email</p>
                                        <p className="cont-details-custom">support@gdenti.com</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <a className="d-flex align-items-center justify-content-center btn-primary btn-lg-square rounded me-2 btn-icon-link-custom" role="button">
                                        <i className="fa fa-map-marker"></i>
                                    </a>
                                    <div className="pb-2">
                                        <p className="mb-0">Map Street</p>
                                        <p className="cont-details-custom">7 Broad Ave Suite #101, </p><p className="cont-details-custom">Palisades Park, NJ 07650</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <form>
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <input
                                        type="text"
                                        value={mailData && mailData.name}
                                        className="form-control info-input-custom input-radius-custom"
                                        placeholder="Input your full name in here"
                                        onChange={(e) => setMailData({ ...mailData, name: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input
                                        type="email"
                                        value={mailData && mailData.email}
                                        className="form-control info-input-custom input-radius-custom"
                                        placeholder="Input your email address in here"
                                        onChange={(e) => setMailData({ ...mailData, email: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Messages</label>
                                    <textarea
                                        value={mailData && mailData.message}
                                        className="form-control info-input-custom input-radius-custom"
                                        placeholder="Write your messages in here"
                                        rows="3"
                                        onChange={(e) => setMailData({ ...mailData, message: e.target.value })}
                                    ></textarea>
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn text-white contact-info-custom send-btn-arrow-width-custom"
                                        onClick={
                                            isLoading
                                                ? (e) => {
                                                      e.preventDefault();
                                                  }
                                                : async (e) => {
                                                      e.preventDefault();
                                                      if (!mailData.email) {
                                                          toast.error(`Please enter email`);
                                                      } else if (mailData.email && checkEmail(mailData.email) == false) {
                                                          toast.error(`Please enter valid email`);
                                                      } else if (!mailData.name) {
                                                          toast.error(`Please enter name`);
                                                      } else if (!mailData.message) {
                                                          toast.error(`Please enter message`);
                                                      } else {
                                                          setIsLoading(true);
                                                          await axios
                                                              .post(`${baseURL}/api/v1/gdental/getintouch`, {
                                                                  email: mailData.email,
                                                                  name: mailData.name,
                                                                  text: mailData.message && mailData.message.replaceAll("\n", "<br />"),
                                                              })
                                                              .then(async (res) => {
                                                                  setMailData({ email: "", name: "", message: "" });
                                                                  setIsLoading(false);
                                                                  toast.success(res.data.message);
                                                              })
                                                              .catch((err) => {
                                                                  setIsLoading(false);
                                                                  toast.error(err.response.data.message);
                                                              });
                                                      }
                                                  }
                                        }
                                    >
                                        {isLoading ? (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{
                                                    position: "relative",
                                                }}
                                            ></i>
                                        ) : (
                                            <>
                                                Send
                                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
