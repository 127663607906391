import React, { Fragment, useEffect, useState } from "react";
import { ROLE_ID, STATUS } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkEmail, checkGoogleLink, checkPhNo } from "../../../helper/Validator";
import Loader from "../../../commoncomponents/Loader";
import AddServiceAndCost from "./AddServiceAndCost";
import GoogleMapHelpImage from "../../../commoncomponents/GoogleMapHelpImage";
import { useTranslation } from "react-i18next";

function UpdateOffice(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const { profileData } = useOutletContext();
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";
    const [step, setStep] = useState(1);

    const [officeList, setOfficeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [officeId, setOfficeId] = useState("");
    const [isExisting, setIsExisting] = useState(false);
    const [showMapImage, setShowMapImage] = useState(false);

    const handleAdd = async () => {
        if (!props.inputValue.email || props.inputValue.email === "" || props.inputValue.email === null) {
            toast.error(t(`Please enter email`));
        } else if (props.inputValue.email && checkEmail(props.inputValue.email) == false) {
            toast.error(t(`Please enter valid email`));
        } else if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter office name`));
        } else if (!props.inputValue.businessUsername || props.inputValue.businessUsername === "" || props.inputValue.businessUsername === null) {
            toast.error(t(`Please enter business username`));
        } else if (!props.inputValue.phone1 || props.inputValue.phone1 === "" || props.inputValue.phone1 === null) {
            toast.error(t(`Please enter phone number`));
        } else if (props.inputValue.phone1 && checkPhNo(props.inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit phone number`));
        } else if (!props.checkedList || props.checkedList === "" || props.checkedList === null || (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)) {
            toast.error(t(`Please select language`));
        } else if (
            !props.checkedList ||
            props.checkedList === "" ||
            props.checkedList === null ||
            props.checkedList == [0] ||
            (props.checkedList && !props.checkedList.length) ||
            (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)
        ) {
            toast.error(t(`Please select language`));
        } else if (!props.inputValue.googleMapLink || props.inputValue.googleMapLink === "" || props.inputValue.googleMapLink === null) {
            toast.error(t(`Please enter google map link`));
        } else if (props.inputValue.googleMapLink && checkGoogleLink(props.inputValue.googleMapLink) == false) {
            toast.error(t(`Please enter valid google map link`));
        } else if (!props.inputValue.address1 || props.inputValue.address1 === "" || props.inputValue.address1 === null) {
            toast.error(t(`Please enter address`));
        } else if (!props.inputValue.countryId || props.inputValue.countryId === "" || props.inputValue.countryId === null) {
            toast.error(t(`Please select country`));
        } else if (!props.inputValue.stateId || props.inputValue.stateId === "" || props.inputValue.stateId === null) {
            toast.error(t(`Please select state`));
        } else if (!props.inputValue.cityId || props.inputValue.cityId === "" || props.inputValue.cityId === null) {
            toast.error(t(`Please select city`));
        } else if (!props.inputValue.zip || props.inputValue.zip === "" || props.inputValue.zip === null) {
            toast.error(t(`Please enter zip`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/check`,
                    {
                        step: 2,
                        isNotNeededField: true,
                        officeEmail: props.inputValue.email,
                        officeId: props.officeId,
                        businessUsername: props.inputValue.businessUsername,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.success == true) {
                        setStep(3);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleExistingAdd = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/assign`,
                {
                    officeId: officeId,
                    doctorId: profileData.roleId == ROLE_ID.ADMIN ? props.doctorId : profileData.id,
                    isExistOffice: true,
                    statusId: profileData.roleId == ROLE_ID.DOCTOR ? "" : STATUS.Active,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                props.fetchOfficedata();
                toast.success(res.data.message);
                props.handleClose();
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleUpdate = async () => {
        if (!props.inputValue.name || props.inputValue.name === "" || props.inputValue.name === null) {
            toast.error(t(`Please enter office name`));
        } else if (!props.inputValue.businessUsername || props.inputValue.businessUsername === "" || props.inputValue.businessUsername === null) {
            toast.error(t(`Please enter business username`));
        } else if (!props.inputValue.phone1 || props.inputValue.phone1 === "" || props.inputValue.phone1 === null) {
            toast.error(t(`Please enter phone number`));
        } else if (props.inputValue.phone1 && checkPhNo(props.inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit phone number`));
        } else if (!props.checkedList || props.checkedList === "" || props.checkedList === null || (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)) {
            toast.error(t(`Please select language`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else if (
            !props.checkedList ||
            props.checkedList === "" ||
            props.checkedList === null ||
            props.checkedList == [0] ||
            (props.checkedList && !props.checkedList.length) ||
            (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)
        ) {
            toast.error(t(`Please select language`));
        } else if (!props.inputValue.googleMapLink || props.inputValue.googleMapLink === "" || props.inputValue.googleMapLink === null) {
            toast.error(t(`Please enter google map link`));
        } else if (props.inputValue.googleMapLink && checkGoogleLink(props.inputValue.googleMapLink) == false) {
            toast.error(t(`Please enter valid google map link`));
        } else if (!props.inputValue.address1 || props.inputValue.address1 === "" || props.inputValue.address1 === null) {
            toast.error(t(`Please enter address`));
        } else if (!props.inputValue.countryId || props.inputValue.countryId === "" || props.inputValue.countryId === null) {
            toast.error(t(`Please select country`));
        } else if (!props.inputValue.stateId || props.inputValue.stateId === "" || props.inputValue.stateId === null) {
            toast.error(t(`Please select state`));
        } else if (!props.inputValue.cityId || props.inputValue.cityId === "" || props.inputValue.cityId === null) {
            toast.error(t(`Please select city`));
        } else if (!props.inputValue.zip || props.inputValue.zip === "" || props.inputValue.zip === null) {
            toast.error(t(`Please enter zip`));
        } else {
            setStep(3);
        }
    };

    const checkedchange = (e) => {
        const value = Number(e.target.value);
        let checkedListData = props.checkedList;
        const index = checkedListData.indexOf(value);
        if (index > -1) {
            checkedListData.splice(index, 1);
            if (index == 0) {
                props.setCheckedList([...props.checkedList]);
            } else {
                props.setCheckedList([...props.checkedList, ...props.checkedList.slice(index, 1)]);
            }
        } else {
            props.setCheckedList([...props.checkedList, value]);
        }
    };

    const fetchOfficeList = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {
                    doctorId: profileData.roleId == ROLE_ID.ADMIN ? props.doctorId : profileData.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item) => {
            return {
                value: item.id,
                label: item.name + " (" + item.businessUsername + ")",
            };
        });

    const onOfficeChange = (e) => {
        setOfficeId(e.value);
        setIsExisting(true);
    };

    const fetchCountryList = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
                isId: true,
            })
            .then((res) => {
                setCountryList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const countryOptions =
        countryList &&
        countryList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCountryChange = (e) => {
        props.setInputValue({ ...props.inputValue, countryId: e.value, stateId: "", cityId: "" });
        setStateList([]);
        setCityList([]);
        fetchStateList(e.value);
    };

    const fetchStateList = async (countryId) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
                countryId: countryId,
                isId: true,
            })
            .then((res) => {
                setStateList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onStateChange = (e) => {
        props.setInputValue({ ...props.inputValue, stateId: e.value, cityId: "" });
        setCityList([]);
        fetchCityList(e.value);
    };

    const fetchCityList = async (stateId) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
                stateId: stateId,
                isId: true,
            })
            .then((res) => {
                setCityList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cityOptions =
        cityList &&
        cityList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCityChange = (e) => {
        props.setInputValue({ ...props.inputValue, cityId: e.value });
    };

    const handlePrevious = () => {
        setOfficeId("");
        setIsExisting(false);
    };

    useEffect(() => {
        if (props.show) {
            fetchOfficeList();
        }
        if ((props.isEdit || props.view) && !props.editLoader) {
            fetchStateList(props.inputValue.countryId);
            fetchCityList(props.inputValue.stateId);
        }
        fetchCountryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit, props.show, props.editLoader]);

    return (
        <>
            {props.isPendingData ? (
                ""
            ) : (
                <div className="col-xs-12">
                    <div className="page-title">
                        <div className="pull-left">
                            <h1 className="title">
                                {props.show ? t("Assign") : props.isEdit ? t("Update") : props.view ? t("View") : ""} {t("Office")}
                            </h1>
                        </div>

                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/dashboard">
                                        <i className="fa fa-home"></i>
                                        {t("Home")}
                                    </Link>
                                </li>
                                {props.isFromOfficeList ? (
                                    ""
                                ) : (
                                    <li className="active cursor-pointer" onClick={profileData.roleId == ROLE_ID.DOCTOR ? props.handleClose : props.backToDoctorList}>
                                        <strong>{profileData.roleId == ROLE_ID.DOCTOR ? t("Office List") : t("Doctor List")} </strong>
                                    </li>
                                )}
                                {profileData.roleId == ROLE_ID.DOCTOR ? (
                                    ""
                                ) : (
                                    <li className="active cursor-pointer" onClick={props.handleClose}>
                                        <strong>{props.isFromOfficeList ? t("Office List") : t("Office-Doctor List")}</strong>
                                    </li>
                                )}
                                <li className="active cursor-pointer">
                                    <strong>
                                        {props.show ? t("Assign") : props.isEdit ? t("Update") : props.view ? t("View") : ""} {t("Office")}
                                    </strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            <div className="col-xs-12 ">
                <div
                    className={`add-header-wrapper gradient-${
                        profileData.roleId == ROLE_ID.ADMIN
                            ? "blue"
                            : profileData.roleId == ROLE_ID.DOCTOR
                            ? "violet"
                            : profileData.roleId == ROLE_ID.PATIENT
                            ? "orange"
                            : profileData.roleId == ROLE_ID.VERIFY
                            ? "pink"
                            : profileData.roleId == ROLE_ID.AGENCY
                            ? "green"
                            : profileData.roleId == ROLE_ID.AGENT
                            ? "green1"
                            : "grep"
                    } curved-section text-center`}
                >
                    <h2 className="uppercase bold w-text">
                        {props.show ? t("Assign") : props.isEdit ? t("Update") : props.view ? t("View") : ""} {t("Office")}
                    </h2>
                    <div className="before-text">
                        {props.show ? t("Assign") : props.isEdit ? t("Update") : props.view ? t("View") : ""} {t("Office")}
                    </div>
                    {props.show ? (
                        <p className="g-text">
                            {t("Doctor")}: {props.doctorDetail && (props.doctorDetail.firstName && props.doctorDetail.firstName) + " " + (props.doctorDetail.lastName && props.doctorDetail.lastName)}
                            <br />
                            {t("Username")} : {props.doctorDetail && props.doctorDetail.userName && props.doctorDetail.userName}
                        </p>
                    ) : (
                        ""
                    )}
                </div>
                <div className={`bg-w${isExisting ? "1" : ""}`}>
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {isLoading || props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box1">
                                {props.show && step == 1 ? (
                                    <div className="content-body my-4 border-0">
                                        <label className="control-label col-12">
                                            {t("Office")} <span className="required"> * </span>
                                        </label>
                                        <Select
                                            name="officeList"
                                            value={officeOptions && officeOptions.filter(({ value }) => value == officeId)}
                                            options={officeOptions}
                                            onChange={onOfficeChange}
                                            placeholder={t("Select")}
                                        />
                                        {isExisting ? (
                                            <div className="padding-bottom-30 d-flex justify-content-center mt-4">
                                                <div className="text-left">
                                                    <button type="button" className="btn mx-2 btn-primary gradient-blue mx-2" onClick={handleExistingAdd}>
                                                        {t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-warning gradient-orange" onClick={handlePrevious}>
                                                        {t("Previous")}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {isExisting ? (
                                    ""
                                ) : (
                                    <div className={`content-body my-4 border-0 ${step == 1 ? "" : "d-none"}`}>
                                        {props.show && step == 1 ? <span className="d-flex justify-content-center mt-4"> -- {t("OR")} --</span> : ""}
                                        <div className="row">
                                            <div className="col-xs-12">
                                                {props.show || props.view ? (
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("E-mail")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                value={props.inputValue.email}
                                                                onChange={props.onChangeValue}
                                                                disabled={props.view ? "Disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Name")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="name"
                                                                    value={props.inputValue.name}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Business Username")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="businessUsername"
                                                                    value={props.inputValue.businessUsername}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Phone")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="phone1"
                                                                    value={props.inputValue.phone1}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12"> {t("NPI 1")} </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="npi1"
                                                                    value={props.inputValue.npi1}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12"> {t("License Number")} </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="licenseNumber"
                                                                    value={props.inputValue.licenseNumber}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        {props.show || profileData.roleId == ROLE_ID.DOCTOR ? (
                                                            ""
                                                        ) : (
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Status")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <select
                                                                        className="form-control col-md-3"
                                                                        name="statusId"
                                                                        value={props.inputValue.statusId}
                                                                        onChange={props.onChangeValue}
                                                                        disabled={props.view ? "Disabled" : ""}
                                                                    >
                                                                        {Object.keys(STATUS).map((item, i) => {
                                                                            return (
                                                                                <option key={i} value={STATUS[item]}>
                                                                                    {item}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">Business category</label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="businessCategoryId"
                                                                    value={props.inputValue.businessCategoryId}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Language")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <div className="row" style={{ paddingLeft: "5px" }}>
                                                                    {props.languageList &&
                                                                        props.languageList.map((item, i) => {
                                                                            return (
                                                                                <Fragment key={i}>
                                                                                    <div className="col-lg-1 col-sm-1 col-xs-4">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="checkBox "
                                                                                            value={item.id}
                                                                                            name={item.id}
                                                                                            checked={props.checkedList && props.checkedList.includes(item.id) ? true : false}
                                                                                            onChange={checkedchange}
                                                                                            disabled={props.view ? "Disabled" : ""}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-lg-3 col-sm-3 col-xs-6">{item.name}</div>
                                                                                </Fragment>
                                                                            );
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <GoogleMapHelpImage showMapImage={showMapImage} setShowMapImage={setShowMapImage} />
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Google Map Link")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12 d-flex">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="googleMapLink"
                                                                    value={props.inputValue.googleMapLink}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                                {props.view ? (
                                                                    ""
                                                                ) : (
                                                                    <button className="btn mx-1" onClick={() => setShowMapImage(true)}>
                                                                        ?
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Address")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            name="address1"
                                                            rows={5}
                                                            cols={12}
                                                            value={props.inputValue.address1}
                                                            onChange={props.onChangeValue}
                                                            disabled={props.view ? "Disabled" : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Country")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="countryList"
                                                                    value={countryOptions && countryOptions.filter(({ value }) => value == props.inputValue.countryId)}
                                                                    options={countryOptions}
                                                                    onChange={onCountryChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("State")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    name="stateList"
                                                                    value={stateOptions && stateOptions.filter(({ value }) => value == props.inputValue.stateId)}
                                                                    options={stateOptions}
                                                                    onChange={onStateChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view || !props.inputValue.countryId ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("City")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    name="cityList"
                                                                    value={cityOptions && cityOptions.filter(({ value }) => value == props.inputValue.cityId)}
                                                                    options={cityOptions}
                                                                    onChange={onCityChange}
                                                                    placeholder={t("Select")}
                                                                    isDisabled={props.view || !props.inputValue.stateId ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Zip Code")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="zip"
                                                                    value={props.inputValue.zip}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.view ? "Disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("Verifier Applied")}</label>

                                                            <div className="d-flex mt-2">
                                                                <div className="mx-2">
                                                                    <input
                                                                        type="radio"
                                                                        className="mx-2"
                                                                        name="verifierApplied"
                                                                        value="1"
                                                                        checked={props.inputValue && props.inputValue.verifierApplied == 1 ? true : false}
                                                                        onChange={props.onChangeValue}
                                                                        disabled={props.view ? "Disabled" : ""}
                                                                    />
                                                                </div>
                                                                <label className="ml-3 mr-15">Yes</label>
                                                                <div className="mx-2">
                                                                    <input
                                                                        type="radio"
                                                                        className="mx-2"
                                                                        name="verifierApplied"
                                                                        value="0"
                                                                        checked={props.inputValue && props.inputValue.verifierApplied == 0 ? true : false}
                                                                        onChange={props.onChangeValue}
                                                                        disabled={props.view ? "Disabled" : ""}
                                                                    />
                                                                </div>
                                                                <label className="ml-3 mr-15">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">{/* blank */}</div>
                                                </div>
                                            </div>
                                            <div className="padding-bottom-30 d-flex justify-content-center">
                                                <div className="text-left">
                                                    {props.isPendingData ? (
                                                        <button type="button" className="btn mx-2 btn-warning" onClick={() => props.setStep(1)}>
                                                            {t("Previous")}
                                                        </button>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                        {t("Close")}
                                                    </button>
                                                    <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={props.show ? handleAdd : handleUpdate}>
                                                        {t("Next")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <AddServiceAndCost
                                    fetchDashboardData={props.fetchDashboardData}
                                    doctorId={props.doctorId}
                                    idPendingData={props.isPendingData}
                                    fetchDoctorReqestdata={props.fetchDoctorReqestdata}
                                    view={props.view}
                                    specialityCheckedList={props.specialityCheckedList}
                                    setSpecialityCheckedList={props.setSpecialityCheckedList}
                                    isEdit={props.isEdit}
                                    checkedList={props.checkedList}
                                    inputValue={props.inputValue}
                                    step={step}
                                    setStep={setStep}
                                    isUpdateOffice={props.isEdit ? true : false}
                                    officeId={props.officeId}
                                    handleClose={props.handleClose}
                                    fetchOfficedata={props.fetchOfficedata}
                                />
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateOffice;
