import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

function DetailRequestSettlementList(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();
    const [settlementList, setSettlementList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.AGENCY
            ? "agency"
            : profileData.roleId == ROLE_ID.AGENT
            ? "agent"
            : profileData.roleId == ROLE_ID.MANAGER
            ? "manager"
            : "";

    const fetchSettlementdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/settlement/detail?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    requestorId: profileData.roleId == ROLE_ID.ADMIN ? props.requestorId : profileData.id,
                    settlementId: props.settlementId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSettlementList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchPendingSettlementdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/pending-list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSettlementList(res.data.data?.rows);
                setPageCount(res.data.data?.pagination?.pageCount);
                setRowCount(res.data.data?.pagination?.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        if (!props.showPendingData) {
            fetchSettlementdata();
        } else {
            fetchPendingSettlementdata();
        }
    }, [offset]);

    return (
        <>
            <section className="box1">
                <header className="panel_header">
                    <div className="mx-3">
                        <div className="row"></div>
                    </div>
                </header>
                <div className="content-body">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="table-responsive" data-pattern="priority-columns">
                                <table
                                    id="tech-companies-1"
                                    className={`table vm table-small-font no-mb table-bordered ${
                                        isLoading || !settlementList || (settlementList && !settlementList.length) ? "" : "table-striped"
                                    } table-hover`}
                                >
                                    <thead>
                                        <tr>
                                            <th>{t("Date")}</th>
                                            <th>{t("Member")}</th>
                                            <th>{t("Plan")}</th>
                                            <th>{t("Revenue")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!settlementList || (settlementList && !settlementList.length && !isLoading) ? (
                                            <NoDataFound />
                                        ) : isLoading ? (
                                            <Loader />
                                        ) : (
                                            settlementList &&
                                            settlementList.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <h6>{item.orderDate ? monthDateYear(item.orderDate) : "-"}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{item.patient ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName) : "-"}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{item.plan?.name ? item.plan.name : "-"}</h6>
                                                        </td>
                                                        <td>
                                                            {profileData.roleId == ROLE_ID.AGENT || props.roleId == ROLE_ID.AGENT ? (
                                                                <h6>${item.revenue ? item.revenue.toLocaleString("en-US") : 0}</h6>
                                                            ) : profileData.roleId == ROLE_ID.AGENCY || props.roleId == ROLE_ID.AGENCY ? (
                                                                <h6>${item.agencyRevenue ? item.agencyRevenue.toLocaleString("en-US") : 0}</h6>
                                                            ) : profileData.roleId == ROLE_ID.MANAGER || props.roleId == ROLE_ID.MANAGER ? (
                                                                <h6>${item.managerRevenue ? item.managerRevenue.toLocaleString("en-US") : 0}</h6>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="d-lg-flex justify-content-between ">
                                <div className="d-flex align-center">
                                    {t("Total")} {settlementList == null ? "0" : rowCount} {t("Revenue")}
                                    {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                </div>
                                <div className="pagination-center">
                                    <ReactPaginate
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={0}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        pageLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        pageClassName="page-item"
                                        breakClassName="page-item"
                                        nextClassName="page-item"
                                        previousClassName="page-item"
                                        previousLabel={t("Previous")}
                                        nextLabel={t("Next")}
                                        disableInitialCallback={true}
                                        forcePage={currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailRequestSettlementList;
