import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { baseURL } from "../../../helper/Config";
import { monthDateYear } from "../../../helper/dateFormates";
import axios from "axios";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { toast } from "react-toastify";
import ImageFileUpload from "./ImageFileUpload";
import Swal from "sweetalert2";
import WebcamUpload from "./WebcamUpload";
import { ROLE_ID } from "../../../helper/Constant";
import { useTranslation } from "react-i18next";
import { Modal, ModalContent } from "./Modal";

function DocumentsTable(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [webcame, setWebcame] = useState(false);
    const [documentId, setdocumentId] = useState("");
    const [documents, setDocuments] = useState([]);

    const handleClick = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setIsEdit(true);
        setdocumentId(id);
        setShow(true);
    };

    const handleWebcameClick = () => {
        setWebcame(true);
    };

    const fetchThumbnailData = async () => {
        axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/patient/document/list`,
                {
                    type: props.type,
                    patientId: props.patientId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDocuments(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this document ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/${apiRouteName}/patient/document/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchThumbnailData();
                                // props.fetchPatientdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const fetchInvestorThumbnailData = async () => {
        axios
            .post(
                `${baseURL}/api/v1/admin/progress/document/list`,
                {
                    progressId: props.progressId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDocuments(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleInvestorDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this document ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/admin/progress/document/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchInvestorThumbnailData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleDownload = (image, fileName, fixName) => {
        axios({
            url: `${baseURL}${image}`,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName ? fileName : fixName}`);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    };

    useEffect(() => {
        if (props.patientId && !props.isFromInvester) {
            fetchThumbnailData();
        } else if (props.isFromInvester) {
            fetchInvestorThumbnailData();
        }
    }, [props.patientId]);

    const [isOpen, setIsopen] = useState(false);
    const [img, setImg] = useState("");
    const showModal = (img) => {
        setIsopen((prev) => !prev);
        setImg(img);
    };

    return (
        <>
            <div className={`col-lg-${props.isFromInvester ? 12 : 6} col-md-12 col-sm-12 my-4`}>
                <ImageFileUpload
                    show={show}
                    setShow={setShow}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    documentId={documentId}
                    setdocumentId={setdocumentId}
                    title={t(props.title)}
                    patientId={props.patientId}
                    progressId={props.progressId}
                    type={props.type}
                    fetchThumbnailData={props.isFromInvester ? fetchInvestorThumbnailData : fetchThumbnailData}
                    isFromInvester={props.isFromInvester}
                    // fetchPatientdata={props.fetchPatientdata}
                />

                <WebcamUpload
                    title={t(props.title)}
                    webcame={webcame}
                    setWebcame={setWebcame}
                    patientId={props.patientId}
                    progressId={props.progressId}
                    type={props.type}
                    fetchThumbnailData={props.isFromInvester ? fetchInvestorThumbnailData : fetchThumbnailData}
                    isFromInvester={props.isFromInvester}
                />
                <div className="d-lg-flex justify-content-between">
                    <p style={{ fontSize: "Larger", fontWeight: "600" }}>{t(props.title)}</p>
                    {(props.patientId || props.isFromInvester) && !props.isView ? (
                        <div className="mb-1">
                            <button className="btn btn-warning btn-border mx-2 " style={{ borderRadius: "20%", width: "35px", height: "35px", position: "relative" }} onClick={() => handleClick()}>
                                <i className="fa fa-plus" style={{ marginLeft: "-1px", position: "absolute", top: "8px", right: "12px" }}></i>{" "}
                            </button>
                            <button className="btn btn-success btn-border " style={{ borderRadius: "20%", width: "35px", height: "35px", position: "relative" }} onClick={() => handleWebcameClick()}>
                                <i className="fa fa-camera " style={{ fontSize: "17px", position: "absolute", top: "6px", right: "9px" }}></i>
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                <div className="table-responsive" data-pattern="priority-columns">
                    <table id="tech-companies-1" className="table vm table-small-font no-mb table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{t("No")}</th>
                                <th>{t("File Name")}</th>
                                <th>{"Thumbnail"}</th>
                                {props.isFromInvester ? "" : <th>{t("Created Date")}</th>}
                                {props.isView ? "" : <th>{t("Action")}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {!documents || (!props.isFromInvester && (!props.patientId || props.patientId == null || props.patientId == "")) || (documents && !documents.length && !isLoading) ? (
                                <NoDataFound />
                            ) : isLoading ? (
                                <Loader />
                            ) : (
                                documents &&
                                documents.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                                <div data-toggle="tooltip" title={item.fileName}>
                                                    {item.fileName ? (item.fileName.length > 15 ? item.fileName.slice(0, 15) + "..." : item.fileName) : props.type + ".png"}
                                                </div>
                                            </td>
                                            <td>
                                                {item.document.split(".").at(-1).toLowerCase() == "pdf" ? (
                                                    <button type="button" className="btn btn-outline-danger" onClick={() => window.open(item.document, "_blank")}>
                                                        <i className="fa fa-file-pdf-o fa-2x"></i>
                                                    </button>
                                                ) : (
                                                    <Modal onOpen={() => showModal(item.document)}>
                                                        <div className="holder">
                                                            <img src={baseURL + item.document} width="100" height="100" alt="Document" style={{ height: "50px", width: "50px" }} />
                                                        </div>
                                                        {isOpen && (
                                                            <ModalContent onClose={() => setIsopen(false)}>
                                                                <img className="d-flex justify-content-center" src={baseURL + img} alt="" style={{ height: "500px", width: "500px" }} />
                                                            </ModalContent>
                                                        )}
                                                    </Modal>
                                                )}
                                            </td>
                                            {props.isFromInvester ? "" : <td>{item.created_at ? monthDateYear(item.created_at) : "-"}</td>}
                                            {props.isView ? (
                                                ""
                                            ) : (
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-xs mx-2"
                                                        onClick={() => {
                                                            handleEdit(item.id);
                                                        }}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-xs"
                                                        onClick={() => {
                                                            props.isFromInvester ? handleInvestorDelete(item.id) : handleDelete(item.id);
                                                        }}
                                                    >
                                                        <i className="fa  fa-trash"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-xs mx-2"
                                                        onClick={() => {
                                                            handleDownload(item.document, item.fileName, props.title);
                                                        }}
                                                    >
                                                        <i className="fa  fa-download"></i>
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default DocumentsTable;
