export const ROLE_ID = {
    ADMIN: 1,
    DOCTOR: 2,
    PATIENT: 3,
    VERIFY: 4,
    AGENCY: 5,
    AGENT: 6,
    INVESTOR_USER: 8,
    INVESTOR_ADMIN: 9,
    MANAGER: 10,
    OFFICE_EMPLOYEE: 11,
};

export const PAGE_AUTHORITY_ROLE = {
    Admin: 1,
    // Doctor: 2,
};

export const PAGE_AUTHORITY_ROLE_SEARCH = {
    Role: "",
    "Main role": 0,
    Admin: 1,
    // Doctor: 2,
};

export const PAGE_AUTHORITY_ROLE_WITHOUT_MAINROLE_SEARCH = {
    Role: "",
    Admin: 1,
    // Doctor: 2,
};

export const STATUS_PENDING = {
    Status: "",
    Inactive: 1,
    Active: 2,
    Pending: 8,
};

export const STATUS = {
    Inactive: 1,
    Active: 2,
};

export const STATUS_SEARCH = {
    Status: "",
    Inactive: 1,
    Active: 2,
};

export const AGENT_STATUS = {
    Inactive: 1,
    Approved: 2,
    Deleted: 3,
    Requested: 4,
    Rejected: 5,
};

export const STATUS_WITH_EXPIRY = {
    Status: "",
    Inactive: 1,
    Active: 2,
    Expiried: 9,
};

export const PLAN_STATUS = {
    Change: 11,
    // Delete: 3,
};

export const STATUS_REQUSTED = {
    Status: "",
    Inactive: 1,
    Active: 2,
    Requested: 4,
};

export const STATUS_REJECTED = {
    Status: "",
    Requested: 4,
    Rejected: 5,
    Approved: 6,
};

export const GENDER = {
    select: "",
    Male: 1,
    Female: 2,
};

export const FORM_TYPE = {
    inputbox: 1,
    checkbox: 2, // Use in Doctor SignUp - Language
    radiobutton: 3,
};

export const FORM_TYPE_SEARCH = {
    "Form-Type": "",
    inputbox: 1,
    // checkbox: 2,
};

export const DENTAL_EVALUATION = {
    Excellent: 5,
    Good: 4,
    Normal: 3,
    Bad: 2,
    Terrible: 1,
};

export const BOOL = {
    Yes: 1,
    No: 0,
};

export const BOOL_SEARCH = {
    Status: "",
    Yes: 1,
    No: 0,
};

export const CLAIM_STATUS = {
    Submitted: 1,
    Saved: 0,
};

export const VERIFIER_COMMENT_STATUS = {
    Result: "",
    Good: 1,
    Normal: 2,
    "Not Good": 3,
    "Pre verified": 4,
};

export const CLAIM_STATUS_SEARCH = {
    Status: "",
    Submitted: 1,
    Saved: 0,
};

export const ROWS_PER_PAGE = 10;

export const PATIENT_DOCUMENT_TYPE = {
    INSURANCE_FRONT: 1,
    INSURANCE_BACK: 2,
    X_RAY: 3,
    NOTE: 4,
    ID: 5,
};

//Default Business Category ID
export const DFLT_BSN_CTGR_ID = 63;

export const PLAN_TYPE = {
    Select: "",
    Year: 1,
    Month: 2,
};

export const PLAN_TYPE_CHANGE_METHOD = {
    Year: 1,
    Month: 2,
};

export const SUBSCRIPTION = {
    Manual: 1,
    Auto: 2,
};

export const MEMBERSHIP_STATUS = {
    Inactive: 1,
    Active: 2,
    Expire: 9,
};

export const MEMBERSHIP_STATUS_SEARCH = {
    "Membership Status": "",
    Inactive: 1,
    Active: 2,
    Expire: 9,
};

export const PAYMENT_STATUS = {
    Status: "",
    Expire: 9,
    Success: 6,
    Fail: 7,
    Change: 11,
    Advance: 12,
};

export const PAYMENT_TYPE_IN_ORDER = {
    "Payment Type": "",
    Card: 1,
    "G-Point": 2,
    Cash: 3,
    ACH: 4,
    STRIPE_ACH: 5,
};

export const REGION = [
    { name: "East", id: 1 },
    { name: "Central", id: 2 },
    { name: "West", id: 3 },
];

export const PAYMENT_TYPE = {
    "No payment": 0,
    Card: 1,
    "G-Point": 2,
    Cash: 3,
    ACH: 4,
    STRIPE_ACH: 5,
};

export const LANGUAGE_LIST = [
    { flag: "🇺🇸", name: "English", shortName: "en" },
    { flag: "🇰🇷", name: "Korean", shortName: "ko" },
    { flag: "🇨🇳", name: "Chinese", shortName: "zh" },
    { flag: "🇪🇸", name: "Spanish", shortName: "es" },
    // { flag: "🇮🇳", name: "Gujarati", shortName: "gu" },
];

export const SNS_TYPE = {
    Type: "",
    Shorts: 1,
    YouTube: 2,
    Image: 3,
};

export const WITHDRAWAL_METHOD = {
    Check: 1,
    "Pick Up": 2,
    ACH: 3,
};

export const WITHDRAWAL_TYPE = {
    Manual: 1,
    Auto: 2,
};
