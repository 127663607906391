import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../helper/Config";
import { checkUserName } from "../helper/Validator";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const handleSend = async () => {
        if (!email) {
            toast.error(t(`Please enter UserName`));
        } else if (email && checkUserName(email) == false) {
            toast.error(t(`Please enter valid UserName`));
        } else {
            setIsLoading(true);
            await axios
                .post(`${baseURL}/api/v1/forgot-password`, {
                    email: email,
                })
                .then(async (res) => {
                    toast.success(res.data.message);
                    setEmail("");
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.error(err.response.data.message);
                });
        }
    };

    return (
        <>
            <div className="container-fluid login-style">
                <div className="login-wrapper row  d-flex align-items-center">
                    <div className="login col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4">
                        <div className="login-form-header my-5">
                            <img
                                src="./data/icons/padlock.png"
                                alt="login-icon"
                                style={{ maxWidth: "64px", filter: "invert(0%) sepia(0%) saturate(1522%) hue-rotate(95deg) brightness(5%) contrast(38%)" }}
                            />
                            <div className="login-header">
                                <h4 className="bold text-break">Reset your password!</h4>
                                <h4 className="">
                                    <small className="text-dark">Please enter your email and we'll send you a link to reset your password.</small>
                                </h4>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-text bold text-center" style={{ color: "rgb(17 82 120)" }}>
                                    Forgot Your Password?
                                </h3>
                                <h4 className="card-text text-center" style={{ color: "rgb(17 82 120)" }}>
                                    Let us help you.
                                </h4>
                                <div className="card-body ">
                                    <div className="form-group">
                                        <label className="form-label">Usename</label>
                                        <div className="controls">
                                            <input type="text" className="form-control" name="email" value={email} placeholder="Enter UserName" onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="pull-right">
                                    <button href="/login" className="btn mt-10 btn-info btn-corner signup login-btn mr-10" onClick={isLoading ? "" : handleSend}>
                                        {isLoading ? (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{
                                                    position: "relative",
                                                }}
                                            ></i>
                                        ) : (
                                            "Send"
                                        )}
                                    </button>
                                    <button
                                        href="/login"
                                        className="btn mt-10 btn-corner signup login-btn mr-10"
                                        onClick={() => {
                                            sessionStorage.removeItem("token");
                                            navigate("/login");
                                        }}
                                    >
                                        {t("Close")}
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <a href="">
                                    <Link to="/login">
                                        <u className="text-info">Login?</u>
                                    </Link>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
