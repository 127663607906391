import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { REGION, ROLE_ID, ROWS_PER_PAGE, STATUS, STATUS_SEARCH, WITHDRAWAL_TYPE } from "../../../helper/Constant";
import UpsertAgent from "./UpsertAgent";
import Select from "react-select";
import { displayLanguageList, sliceString } from "../../../helper/helper";
import { useTranslation } from "react-i18next";

function AgentList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const [agentList, setAgentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        userName: "",
        statusId: 1,
        phone1: "",
        gPointUserName: "",
        address1: "",
        achBank: "",
        achAccount: "",
        agencyId: profileData.roleId == ROLE_ID.AGENCY ? profileData.id : "",
        withdrawalType: WITHDRAWAL_TYPE.Manual,
        withdrawalMethod: "",
    });
    const [agentId, setAgentId] = useState("");
    const [agencyList, setAgencyList] = useState([]);
    const [agencyFilterList, setAgencyFilterList] = useState([]);
    const [agencyId, setAgencyId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
        searchUserName: "",
    });

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENCY ? "agency" : "";
    const [checkedList, setCheckedList] = useState([]);

    const fetchAgentdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/agent/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchAgentName: filterValue.searchAgentName,
                    searchUserName: filterValue.searchUserName,
                    statusId: filterValue.statusSearch,
                    agencyId: profileData.roleId == ROLE_ID.AGENCY ? profileData.id : agencyId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgentList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setAgentId(id);
        agentDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchAgentdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const agentDetail = async (agentId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/agent/detail/${agentId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setCheckedList(res.data.data && res.data.data.region && res.data.data.region.split("~").map((item) => Number(item)));
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let selectAgency = [
        {
            id: "",
            firstName: t("Agency"),
        },
    ];

    const fetchAgencydata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/agency/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgencyList(res.data.data);
                setAgencyFilterList(res.data.data ? selectAgency.concat(res.data.data) : selectAgency);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const agencyFilterOptions =
        agencyFilterList &&
        agencyFilterList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName,
            };
        });

    const agencyOptions =
        agencyList &&
        agencyList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName,
            };
        });

    const onAgencyFilterChange = (e) => {
        setAgencyId(e.value);
        setOffset(1);
        setCurrentPage(0);
    };

    const onAgencyChange = (e) => {
        setInputValue({ ...inputValue, agencyId: e.value });
    };

    const handleClose = () => {
        setCheckedList([]);
        setInputValue({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            userName: "",
            statusId: 1,
            phone1: "",
            gPointUserName: "",
            address1: "",
            achBank: "",
            achAccount: "",
            agencyId: profileData.roleId == ROLE_ID.AGENCY ? profileData.id : "",
            withdrawalType: WITHDRAWAL_TYPE.Manual,
            withdrawalMethod: "",
        });
        setAgentId("");
        setIsEdit(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this agent ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/${apiRouteName}/agent/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchAgentdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchAgentdata();
    }, [offset, filterValue.statusSearch, agencyId]);

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN) {
            fetchAgencydata();
        }
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Agent")} </h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Agent List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Agent List")}</strong>
                                </li>
                            )}
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : t("Add")} {t("Agent")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <UpsertAgent
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            editLoader={editLoader}
                            handleClose={handleClose}
                            fetchAgentdata={fetchAgentdata}
                            agentId={agentId}
                            agencyOptions={agencyOptions}
                            onAgencyChange={onAgencyChange}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isEdit={isEdit}
                            show={show}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-5 col-md-2 col-sm-12">
                                            <h2 className="title pull-left">
                                                <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                    <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                </button>
                                            </h2>
                                        </div>
                                        <div className="col-lg-7 col-md-10 col-sm-12 d-flex justify-content-end">
                                            <div className="row mx-2">
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    {profileData.roleId == ROLE_ID.AGENCY ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select name="agency" options={agencyFilterOptions} onChange={onAgencyFilterChange} placeholder={t("Agency")} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchUserName"
                                                            value={filterValue.searchUserName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search username")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchAgentName"
                                                            value={filterValue.searchAgentName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search name")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_SEARCH[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !agentList || (agentList && !agentList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("No")}</th>
                                                        <th>{t("Username")}</th>
                                                        <th>{t("Name")}</th>
                                                        <th>{t("Region")}</th>
                                                        {profileData.roleId == ROLE_ID.AGENCY ? "" : <th>{t("Agency")}</th>}
                                                        <th>{t("Commission Rate")}</th>
                                                        <th>{t("Link for SNS")}</th>
                                                        <th>{t("Copy")}</th>
                                                        <th>{t("Phone")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!agentList || (agentList && !agentList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        agentList &&
                                                        agentList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{item.agencyOrAgentNumber ? item.agencyOrAgentNumber : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.userName ? item.userName : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.firstName ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.region ? displayLanguageList(item.region, REGION).toString() : "-"}</h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.AGENCY ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>{item.agencyName ? item.agencyName : "-"}</h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.commissionRate ? item.commissionRate : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 data-toggle="tooltip" title={item.linkForSNS}>
                                                                            {item.linkForSNS ? sliceString(item.linkForSNS, 50) : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-primary btn-xs mx-2"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(item.linkForSNS);
                                                                                toast.success("Link for SNS copied!");
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-clipboard" aria-hidden="true"></i>
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.phone1 ? item.phone1 : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                            {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </button>
                                                                        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                            <i className="fa fa-trash-o "></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {agentList == null ? "0" : rowCount} {t("Agent")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default AgentList;
