import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { baseURL } from "../../../helper/Config";
import axios from "axios";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { toast } from "react-toastify";
import _ from "lodash";
import { ROLE_ID } from "../../../helper/Constant";
import { useTranslation } from "react-i18next";

function TreatmentTable2(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const fetchTreatmentTypeData = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/claim/treatment/list?isPagination=false`,
                {
                    officeId: props.officeId,
                    patientId: props.patientId,
                    isListAllRecord: false,
                    treatmentTypeId: props.typeId,
                    claimId: !props.isPatientChange && (props.isView || props.isEdit) ? props.claimId : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setData(res.data.data);
                let treatmentTypeList = res.data.data;
                const groupbydata = _.chain(treatmentTypeList)
                    .groupBy("treatmentTypeGroup")
                    .map(function (value, key) {
                        return {
                            treatmentTypeGroup: key,
                            data: value,
                        };
                    })
                    .value();
                setData(groupbydata);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        if (props.patientId && props.officeId) {
            fetchTreatmentTypeData();
        }
    }, [props.typeId, props.patientId, props.officeId]);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <div className=" my-4">
                <section className="box py-3 d-flex justify-content-between title-box">
                    <h4 className="mx-3 title-fonts my-2">{props.name}</h4>
                    <input type="text" className="form-control mx-3" style={{ width: "auto" }} value={search} onChange={(e) => setSearch(e.target.value)} />
                </section>
                <div className="table-responsive" data-pattern="priority-columns">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="d-flex justify-content-end">
                                        <h5>
                                            {t("Total")} {t("Fee")} : {props.typeId && props.sum && props.typeId in props.sum ? props.sum[props.typeId] : 0}
                                        </h5>
                                    </div>
                                    <hr className="my-0" style={{ border: "0.5px solid " }} />
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="row header col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                {/* <div className="col fw-bold">
                                                    <h4 className="fw-bold">{t("Code")}</h4>
                                                </div> */}
                                                <div className="col fw-bold">
                                                    <h4 className="fw-bold">{t("Name")}</h4>
                                                </div>
                                                <div className="col fw-bold">
                                                    <h4 className="fw-bold">{t("Fee")}</h4>
                                                </div>
                                                <div className="col fw-bold">
                                                    <h4 className="fw-bold">{t("Unit")}</h4>
                                                </div>
                                            </div>
                                            {windowSize.width >= 1200 && (
                                                <div className="row header col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                    {/* <div className="col fw-bold">
                                                        <h4 className="fw-bold">{t("Code")}</h4>
                                                    </div> */}
                                                    <div className="col fw-bold">
                                                        <h4 className="fw-bold">{t("Name")}</h4>
                                                    </div>
                                                    <div className="col fw-bold">
                                                        <h4 className="fw-bold">{t("Fee")}</h4>
                                                    </div>
                                                    <div className="col fw-bold">
                                                        <h4 className="fw-bold">{t("Unit")}</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row">
                                            {!data || !props.typeId || props.typeId == null || props.typeId == "" || (data && !data.length && !isLoading) ? (
                                                <NoDataFound />
                                            ) : isLoading ? (
                                                <Loader />
                                            ) : (
                                                data &&
                                                data.map((item, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <div className="">
                                                                <div className="col fw-bold mt-3">
                                                                    {item.treatmentTypeGroup == "undefined" || item.treatmentTypeGroup == undefined || item.treatmentTypeGroup == "No-Group"
                                                                        ? ""
                                                                        : item.treatmentTypeGroup.toUpperCase()}
                                                                </div>
                                                                <hr className="my-0" style={{ border: "0.5px solid " }} />
                                                                {item.data &&
                                                                    item.data
                                                                        .filter((items) => {
                                                                            return JSON.stringify(items).toLowerCase().indexOf(search.toLowerCase()) !== -1;
                                                                        })
                                                                        .map((item1, i) => {
                                                                            return (
                                                                                <div className="row row-data mt-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" key={i}>
                                                                                    {/* <div className="col">{item1.code}</div> */}
                                                                                    <div className="col">{item1.name}</div>
                                                                                    <div className="col">
                                                                                        $
                                                                                        {item1.isFreeOnceYear && props.isGDenticarePlan && item1.isFreeOnceAYearUsed
                                                                                            ? 0
                                                                                            : // : item1.discountFee > 0 && props.isGDenticarePlan
                                                                                            props.isGDenticarePlan
                                                                                            ? item1.discountFee.toLocaleString("en-US")
                                                                                            : item1.originalFee.toLocaleString("en-US")}
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        <input
                                                                                            disabled={props.isView ? "disabled" : ""}
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            name={item1.name}
                                                                                            value={item1.unit}
                                                                                            min="0"
                                                                                            onChange={async (e) => {
                                                                                                let value = Number(e.target.value);
                                                                                                let id = item1.id;
                                                                                                const fee = Number(
                                                                                                    item1.isFreeOnceYear && props.isGDenticarePlan && item1.isFreeOnceAYearUsed
                                                                                                        ? 0
                                                                                                        : // : item1.discountFee > 0 && props.isGDenticarePlan
                                                                                                        props.isGDenticarePlan
                                                                                                        ? item1.discountFee
                                                                                                        : item1.originalFee
                                                                                                );
                                                                                                let oldgropudata = data;
                                                                                                let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: item1.treatmentTypeGroup });
                                                                                                let filterddata_index = oldgropudata.findIndex(
                                                                                                    (p) => p.treatmentTypeGroup === item1.treatmentTypeGroup
                                                                                                );
                                                                                                let objTreatmentdata = filterddata.data;
                                                                                                let treatmentId = await _.find(objTreatmentdata, { id: parseInt(id) });
                                                                                                let treatmentId_index = objTreatmentdata.findIndex((p) => p.id === parseInt(id));
                                                                                                let newobj = {
                                                                                                    code: treatmentId.code,
                                                                                                    description: treatmentId.description,
                                                                                                    id: Number(treatmentId.id),
                                                                                                    name: treatmentId.name,
                                                                                                    treatmentType: treatmentId.treatmentType,
                                                                                                    treatmentTypeGroup: treatmentId.treatmentTypeGroup,
                                                                                                    discountRate: treatmentId.discountRate,
                                                                                                    discountFee: treatmentId.discountFee,
                                                                                                    originalFee: treatmentId.originalFee,
                                                                                                    isFreeOnceYear: treatmentId.isFreeOnceYear,
                                                                                                    isFreeOnceAYearUsed: treatmentId.isFreeOnceAYearUsed,
                                                                                                    unit: value,
                                                                                                };
                                                                                                objTreatmentdata[treatmentId_index] = newobj;
                                                                                                oldgropudata[filterddata_index].data = objTreatmentdata;
                                                                                                setData([...oldgropudata]);
                                                                                                let obj = {
                                                                                                    treatmentTypeId: props.typeId,
                                                                                                    treatmentId: id,
                                                                                                    fee: fee,
                                                                                                    unit: value,
                                                                                                    totalUnitFee: fee * value,
                                                                                                    totalUnitFeeWithoutDiscount: Number(item1.originalFee) * value,
                                                                                                    discountFee: fee == 0 ? 0 : item1.discountFee,
                                                                                                    originalFee: fee == 0 ? 0 : item1.originalFee,
                                                                                                };

                                                                                                let index = props.InputObj.findIndex((p) => p.treatmentId == id);
                                                                                                if (index > -1) {
                                                                                                    props.InputObj[index] = obj;
                                                                                                } else {
                                                                                                    props.InputObj.push(obj);
                                                                                                }
                                                                                                props.setInputValueObj(props.InputObj);
                                                                                                await props.handleTreatmentUnitChange();
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                            </div>
                                                        </Fragment>
                                                    );
                                                })
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TreatmentTable2;
