import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../helper/Config";
import ReactPaginate from "react-paginate";
import { PLAN_TYPE, ROWS_PER_PAGE, STATUS, STATUS_SEARCH, SUBSCRIPTION } from "../../../helper/Constant";
import Loader from "../../../commoncomponents/Loader";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import UpdateMembershipPlan from "./UpdateMembershipPlan";
import { sliceString } from "../../../helper/helper";
import { monthDateYear } from "../../../helper/dateFormates";
import { useTranslation } from "react-i18next";

function MembershipList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [membershipList, setMembershipList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        stripeProductId: "",
        stripePriceId: "",
        name: "",
        type: "",
        isSubscription: "",
        price: "",
        statusId: 1,
    });
    const [planId, setMembershipId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
    });

    const fetchMembershipdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/plan/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    name: filterValue.nameSearch,
                    statusId: filterValue.statusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setMembershipList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = (id) => {
        setMembershipId(id);
        membershipDetail(id, false);
        setIsEdit(true);
        setShow(false);
    };

    const handleAdd = (id) => {
        setMembershipId(id);
        membershipDetail(id, true);
        setShow(true);
        setIsEdit(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchMembershipdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const membershipDetail = async (planId, show) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/admin/plan/detail/${planId}?isAddNewPlan=${show}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            name: "",
            shortName: "",
            priority: 9999,
            statusId: 1,
        });
        setMembershipId("");
        setIsEdit(false);
        setShow(false);
    };

    useEffect(() => {
        fetchMembershipdata();
    }, [offset, filterValue.statusSearch]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Membership Plan List")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>{t("Membership Plan List")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <section className="box">
                        <header className="panel_header">
                            <div className="mx-3">
                                <div className="row ">
                                    <div className="col-lg-6 col-md-6 hidden-sm">
                                        <h2 className="title pull-left">
                                            <UpdateMembershipPlan
                                                show={show}
                                                editLoader={editLoader}
                                                handleClose={handleClose}
                                                inputValue={inputValue}
                                                onChangeValue={onChangeValue}
                                                isEdit={isEdit}
                                                planId={planId}
                                                fetchMembershipdata={fetchMembershipdata}
                                            />
                                        </h2>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-end">
                                        <div className="row mx-2">
                                            <div className="col-lg-7 col-md-7 col-sm-12">
                                                <div className="title">
                                                    <input
                                                        className="form-control"
                                                        name="nameSearch"
                                                        value={filterValue.nameSearch}
                                                        onChange={onFilterChangeValue}
                                                        onKeyDown={onKeyDown}
                                                        type="search"
                                                        placeholder={t("search name")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12">
                                                <div className="title">
                                                    <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                        {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                            return (
                                                                <option key={i} value={STATUS_SEARCH[item]}>
                                                                    {t(item)}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="content-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="table-responsive" data-pattern="priority-columns">
                                        <table
                                            id="tech-companies-1"
                                            className={`table vm table-small-font no-mb table-bordered ${
                                                isLoading || !membershipList || (membershipList && !membershipList.length) ? "" : "table-striped"
                                            } table-hover`}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>{t("No")}</th>
                                                    <th>{t("Product Id")}</th>
                                                    <th>{t("Price Id")}</th>
                                                    <th>{t("Name")}</th>
                                                    <th>{t("Monthly/Yearly")}</th>
                                                    <th>{t("Subscription")}</th>
                                                    <th>{t("Price")}</th>
                                                    <th>{t("Updated Date")}</th>
                                                    <th>{t("Status")}</th>
                                                    <th>{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!membershipList || (membershipList && !membershipList.length && !isLoading) ? (
                                                    <NoDataFound />
                                                ) : isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    membershipList &&
                                                    membershipList.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="left">{(offset - 1) * ROWS_PER_PAGE + i + 1}</td>
                                                                <td>
                                                                    <h6 data-toggle="tooltip" title={item.stripeProductId}>
                                                                        {item.stripeProductId ? sliceString(item.stripeProductId, 15) : "-"}
                                                                    </h6>
                                                                </td>
                                                                <td>
                                                                    <h6 data-toggle="tooltip" title={item.stripePriceId}>
                                                                        {item.stripePriceId ? sliceString(item.stripePriceId, 15) : "-"}
                                                                    </h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.name ? item.name : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    {item.type == 0 ? (
                                                                        <span className="badge badge-danger">Register</span>
                                                                    ) : (
                                                                        <span className={`badge badge-${item.type == PLAN_TYPE.Year ? "info" : "dark"}`}>
                                                                            {Object.keys(PLAN_TYPE).find((key) => PLAN_TYPE[key] === item.type)}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <span className={`badge badge-${item.isSubscription == SUBSCRIPTION.Manual ? "warning" : "primary"}`}>
                                                                        {Object.keys(SUBSCRIPTION).find((key) => SUBSCRIPTION[key] === item.isSubscription)}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <h6>${item.price ? item.price : 0}</h6>
                                                                </td>
                                                                <td>
                                                                    <h6>{item.updated_at ? monthDateYear(item.updated_at) : "-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                        {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                        <i className="fa fa-pencil"></i>
                                                                    </button>
                                                                    {item.type !== 0 && (
                                                                        <button className="btn btn-warning btn-xs mx-2" onClick={() => handleAdd(item.id)}>
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-md-flex justify-content-between ">
                                        <div className="d-flex align-center">
                                            {t("Total")} {membershipList == null ? "0" : rowCount} {t("Membership Plan")}
                                            {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                        </div>
                                        <div className="pagination-center">
                                            <ReactPaginate
                                                initialPage={0}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={0}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                pageLinkClassName="page-link"
                                                breakLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                previousLinkClassName="page-link"
                                                pageClassName="page-item"
                                                breakClassName="page-item"
                                                nextClassName="page-item"
                                                previousClassName="page-item"
                                                previousLabel={t("Previous")}
                                                nextLabel={t("Next")}
                                                disableInitialCallback={true}
                                                forcePage={currentPage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default MembershipList;
