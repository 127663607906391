import axios from "axios";
import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import { PAYMENT_TYPE, PLAN_TYPE_CHANGE_METHOD, ROLE_ID, SUBSCRIPTION } from "../../../helper/Constant";
import { daysDifference } from "../../../helper/dateFormates";

function ChangeGdentalPlan(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const { profileData } = useOutletContext();
    const [type, setType] = useState(props.type);
    const [isSubscription, setIsSubscription] = useState(props.isSubscription);
    const [price, setPrice] = useState(props.price);
    const [showPayButton, setShowPayButton] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const onTypeChange = async (e) => {
        setType(e.target.value);
        setIsSubscription(e.target.value == PLAN_TYPE_CHANGE_METHOD.Year ? SUBSCRIPTION.Manual : isSubscription);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/plan/check`,
                {
                    type: e.target.value,
                    isSubscription: e.target.value == PLAN_TYPE_CHANGE_METHOD.Year ? SUBSCRIPTION.Manual : isSubscription,
                    patientId: profileData.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setPrice(res.data.data.price);
                setShowPayButton(true);
            })
            .catch((err) => {
                if (err.response) {
                    setPrice(0);
                    setShowPayButton(false);
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const onPaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
        setShowPayButton(true);
    };

    const handleClose = () => {
        props.setShow(false);
        setType(1);
        setIsSubscription(1);
        setPrice(0);
        setShowPayButton(false);
    };

    const onSubscriptionChange = async (e) => {
        setIsSubscription(e.target.value);
        if (type) {
            await axios
                .post(
                    `${baseURL}/api/v1/patient/plan/check`,
                    {
                        type: type,
                        isSubscription: e.target.value,
                        patientId: profileData.id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setPrice(res.data.data.price);
                    setShowPayButton(true);
                })
                .catch((err) => {
                    if (err.response) {
                        setPrice(0);
                        setShowPayButton(false);
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            setPrice(0);
            setShowPayButton(false);
        }
    };

    const handlechangeWithCard = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (isSubscription === "" || type === null) {
            toast.error(t(`Please select subscription`));
        } else if (!paymentMethod || paymentMethod === "" || paymentMethod === null || paymentMethod === 0) {
            toast.error(t(`Please select payment method`));
        } else if (profileData.roleId == ROLE_ID.PATIENT) {
            setIsLoading(true);
            Swal.fire({
                title: t(`Are you sure you want to change the plan?`),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, proceed to pay!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/patient/change/plan`,
                            {
                                patientId: profileData.id,
                                type: type,
                                paymentType: paymentMethod,
                                isSubscription: isSubscription,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.data.data.url) {
                                window.location.replace(res.data.data.url);
                            } else {
                                toast.success(res.data.message);
                            }
                            props.fetchDashboardData();
                            handleClose();
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                } else if (!result.isConfirmed || result.isConfirmed == false) {
                    setIsLoading(false);
                }
            });
        } else {
            toast.error(t(`Only Patient can buy plan`));
        }
    };

    const handleChangeWithGPoint = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (isSubscription === "" || type === null) {
            toast.error(t(`Please select subscription`));
        } else if (!paymentMethod || paymentMethod === "" || paymentMethod === null || paymentMethod === 0) {
            toast.error(t(`Please select payment method`));
        } else if (profileData.roleId == ROLE_ID.PATIENT) {
            setIsLoading(true);
            Swal.fire({
                title: t(`Are you sure you want to change the plan?`),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, proceed to pay!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/patient/gdental/change-plan/payment`,
                            {
                                patientId: profileData.id,
                                type: type,
                                isSubscription: isSubscription,
                                isAch: paymentMethod == PAYMENT_TYPE.ACH ? true : false,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success").then((res) => {
                                    if (res.isConfirmed) {
                                        props.fetchDashboardData();
                                        handleClose();
                                        setIsLoading(false);
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                } else if (!result.isConfirmed || result.isConfirmed == false) {
                    setIsLoading(false);
                }
            });
        } else {
            toast.error(t(`Only Patient can buy plan`));
        }
    };

    const handleAdvancePayment = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (isSubscription === "" || type === null) {
            toast.error(t(`Please select subscription`));
        } else if (!paymentMethod || paymentMethod === "" || paymentMethod === null || paymentMethod === 0) {
            toast.error(t(`Please select payment method`));
        } else if (profileData.roleId == ROLE_ID.PATIENT) {
            Swal.fire({
                title: `${t("Your total amount to be paid is")} $${props.isRegisterPatient == 0 ? props.registrationFee + price : price}`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, proceed to pay!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setShowLoader(true);
                    await axios
                        .post(
                            `${baseURL}/api/v1/patient/advance/plan`,
                            {
                                patientId: profileData.id,
                                type: type,
                                isSubscription: isSubscription,
                                paymentType: paymentMethod,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                if (paymentMethod == PAYMENT_TYPE.Card || paymentMethod == PAYMENT_TYPE.STRIPE_ACH) {
                                    window.location.replace(res.data.data.url);
                                } else {
                                    setTimeout(() => {
                                        setShowLoader(false);
                                        Swal.fire(res.data.message, " ", "success").then((res) => {
                                            if (res.isConfirmed) {
                                                props.fetchDashboardData();
                                                handleClose();
                                            }
                                        });
                                    }, 4000);
                                }
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                } else if (!result.isConfirmed || result.isConfirmed == false) {
                    setIsLoading(false);
                }
            });
        } else {
            toast.error(t(`Only Patient can buy plan`));
        }
    };

    return (
        <div>
            <Modal show={props.show} onHide={handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Change G-Denticare plan")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="justify-content-center row my-3">
                        <div className="form-group row">
                            <div className="col-6">
                                <div className="row">
                                    <label className="control-label col-md-12">
                                        {t("Membership Plan")} <span className="required"> * </span>
                                    </label>
                                    <div className="col-md-12">
                                        <select className="form-control col-md-3" name="type" value={type} onChange={onTypeChange}>
                                            {Object.keys(PLAN_TYPE_CHANGE_METHOD).map((item, i) => {
                                                return (
                                                    <option key={i} value={PLAN_TYPE_CHANGE_METHOD[item]}>
                                                        {t(item)}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <label className="control-label col-md-12">{t("Price")}</label>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="price" value={`$${price}`} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {t("Subscription")} <span className="required"> * </span>
                            </label>
                            <div className="col-md-12">
                                <>
                                    {type == PLAN_TYPE_CHANGE_METHOD.Month ? (
                                        <>
                                            <input className="my-2 mx-3" type="radio" name="isSubscription" value="2" checked={isSubscription == 2 ? true : false} onChange={onSubscriptionChange} />
                                            {t("Auto")}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                                <input className="my-2 mx-3" type="radio" name="isSubscription" value="1" checked={isSubscription == 1 ? true : false} onChange={onSubscriptionChange} />
                                {t("Manual")}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-md-12">
                                {t("Payment")} <span className="required"> * </span>
                            </label>
                            <div className="col-md-12">
                                <input
                                    className="my-2 mx-3"
                                    type="radio"
                                    name="paymentMethod"
                                    checked={paymentMethod == PAYMENT_TYPE.Card ? true : false}
                                    value={PAYMENT_TYPE.Card}
                                    onChange={onPaymentMethodChange}
                                />
                                {t("Pay With Card")}
                                <input
                                    className="my-2 mx-3"
                                    type="radio"
                                    name="paymentMethod"
                                    checked={paymentMethod == PAYMENT_TYPE["G-Point"] ? true : false}
                                    value={PAYMENT_TYPE["G-Point"]}
                                    onChange={onPaymentMethodChange}
                                />
                                {t("Pay With G-Point")}
                                <input
                                    className="my-2 mx-3 d-none"
                                    type="radio"
                                    name="paymentMethod"
                                    checked={paymentMethod == PAYMENT_TYPE.STRIPE_ACH ? true : false}
                                    value={PAYMENT_TYPE.STRIPE_ACH}
                                    onChange={onPaymentMethodChange}
                                />
                                <span className="d-none">{t("Pay with ACH")}</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {showPayButton && (isSubscription != props.isSubscription || props.type != type || (props.isSubscription == SUBSCRIPTION.Auto && props.paymentMethod != paymentMethod)) ? (
                        <>
                            <Button
                                variant="success"
                                // paymentMethod == PAYMENT_TYPE["G-Point"] || paymentMethod == PAYMENT_TYPE.ACH ? handleChangeWithGPoint :
                                onClick={isLoading ? () => {} : handlechangeWithCard}
                            >
                                {isLoading ? (
                                    <i
                                        className="fa fa-spinner fa-spin"
                                        style={{
                                            position: "relative",
                                        }}
                                    ></i>
                                ) : (
                                    <>
                                        {props.isSubscription == SUBSCRIPTION.Auto && isSubscription == SUBSCRIPTION.Auto && props.paymentMethod != paymentMethod
                                            ? t("Change Method")
                                            : `${t("Change")} & ${t("Pay")}`}
                                    </>
                                    // props.isSubscription == SUBSCRIPTION.Auto && props.type == PLAN_TYPE_CHANGE_METHOD.Month ? t("Change Method") :
                                )}
                            </Button>
                        </>
                    ) : (
                        ""
                    )}
                    {props.isSubscription == SUBSCRIPTION.Manual && isSubscription == props.isSubscription && props.type == type ? (
                        <Button variant="success" onClick={showLoader ? () => {} : handleAdvancePayment}>
                            {showLoader ? (
                                <i
                                    className="fa fa-spinner fa-spin"
                                    style={{
                                        position: "relative",
                                    }}
                                ></i>
                            ) : (
                                <>
                                    {t("Advance")} {t("Pay")}
                                </>
                            )}{" "}
                        </Button>
                    ) : (
                        ""
                    )}

                    <Button variant="secondary" onClick={isLoading || showLoader ? () => {} : handleClose}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ChangeGdentalPlan;
