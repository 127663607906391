import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, ROLE_ID, AGENT_STATUS, WITHDRAWAL_METHOD } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import "react-datepicker/dist/react-datepicker.css";
import DetailSettlementList from "./DetailSettlementList";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import RequestWithdrawal from "../../../commoncomponents/RequestWithdrawal";

function SettlementRevenueList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const [settlementList, setSettlementList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [request, setRequest] = useState(false);
    const [settlementId, setSettlementId] = useState("");
    const [settlementData, setSettlementData] = useState({
        totalRevenue: "",
    });
    const [requestorId, setRequestorId] = useState("");
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [showPendingData, setShowPendingData] = useState(false);
    const [roleId, setRoleId] = useState("");

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.AGENCY
            ? "agency"
            : profileData.roleId == ROLE_ID.AGENT
            ? "agent"
            : profileData.roleId == ROLE_ID.MANAGER
            ? "manager"
            : "";

    const fetchSettlementData = async () => {
        setIsLoading(true);
        await axios

            .post(
                `${baseURL}/api/v1/${apiRouteName}/settlement/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSettlementList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleShow = (settlementId, totalRevenue, requestorId, roleId) => {
        setSettlementId(settlementId);
        setRequestorId(requestorId);
        setRoleId(roleId);
        setSettlementData({
            totalRevenue: totalRevenue,
        });
        setShow(true);
    };

    const handleClose = () => {
        setSettlementId("");
        setSettlementData({
            totalRevenue: "",
        });
        setRequestorId("");
        setRoleId("");
        setRequest(false);
        setShowPendingData(false);
        setShow(false);
    };

    const handleStatusChange = async (id, settlementId) => {
        Swal.fire({
            title: t("Are you sure you want to pay their revenue ?"),
            icon: "warning",
            showCancelButton: true,
            showDenyButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            confirmButtonText: t("Yes, pay it!"),
            denyButtonText: t("No, reject it!"),
        }).then(async (result) => {
            if (result.isConfirmed || result.isDenied) {
                if (result.isConfirmed || result.isDenied) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/admin/settlement/upsert`,
                            {
                                id: id,
                                settlementId: settlementId,
                                statusId: result.isDenied ? AGENT_STATUS.Rejected : AGENT_STATUS.Approved,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchSettlementData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500 || err.response.status == 401) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const checkBalance = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/check-balance`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setWithdrawalAmount(res.data.data?.balance);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchSettlementData();
        if (profileData.roleId !== ROLE_ID.ADMIN) {
            checkBalance();
        }
    }, [offset]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Revenue Settlement")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer" onClick={show || request ? handleClose : ""}>
                                <strong>{t("Settlement List")}</strong>
                            </li>
                            {show && (
                                <li className="active cursor-pointer" onClick={request ? () => setRequest(false) : ""}>
                                    <strong>{t("Detail Revenue List")}</strong>
                                </li>
                            )}
                            {request && (
                                <li className="active cursor-pointer">
                                    <strong>{t("Request Withdrawal")}</strong>
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <DetailSettlementList
                            roleId={roleId}
                            showPendingData={showPendingData}
                            requestorId={requestorId}
                            request={request}
                            setRequest={setRequest}
                            settlementId={settlementId}
                            settlementData={settlementData}
                        />
                    ) : request ? (
                        <RequestWithdrawal
                            checkBalance={checkBalance}
                            fetchSettlementData={fetchSettlementData}
                            withdrawalAmount={parseFloat(withdrawalAmount).toFixed(2)}
                            setWithdrawalAmount={setWithdrawalAmount}
                            handleClose={handleClose}
                        />
                    ) : (
                        <section className="box1">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row">
                                        {profileData.roleId == ROLE_ID.ADMIN ? (
                                            ""
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-between">
                                                    <h2 className="title pull-left">
                                                        <button
                                                            className={`btn btn-success gradient-${
                                                                profileData.roleId == ROLE_ID.AGENCY
                                                                    ? "green"
                                                                    : profileData.roleId == ROLE_ID.AGENT
                                                                    ? "green1"
                                                                    : profileData.roleId == ROLE_ID.MANAGER
                                                                    ? "blue3"
                                                                    : "grep"
                                                            } btn-corner`}
                                                            onClick={() => setRequest(true)}
                                                        >
                                                            <i className="fa fa-money"></i> &nbsp; <span>{t("Request Withdrawal")}</span>
                                                        </button>
                                                    </h2>

                                                    <h2 className="title pull-left">
                                                        <button
                                                            className={`btn btn-success gradient-${
                                                                profileData.roleId == ROLE_ID.AGENCY
                                                                    ? "green"
                                                                    : profileData.roleId == ROLE_ID.AGENT
                                                                    ? "green1"
                                                                    : profileData.roleId == ROLE_ID.MANAGER
                                                                    ? "blue3"
                                                                    : "grep"
                                                            } btn-corner`}
                                                            onClick={() => {
                                                                setShowPendingData(true);
                                                                setShow(true);
                                                            }}
                                                        >
                                                            {t("Requestable Revenue")}: $ {parseFloat(withdrawalAmount).toFixed(2)}
                                                        </button>
                                                    </h2>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !settlementList || (settlementList && !settlementList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("No")}</th>
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Type")}</th> : ""}
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Requestor")}</th> : ""}
                                                        <th>{t("Amount")}</th>
                                                        <th>{t("ACH Bank")}</th>
                                                        <th>{t("ACH Account")}</th>
                                                        <th>{t("Requested Date")}</th>
                                                        <th>{t("Withdrawal Method")}</th>
                                                        <th>{t("Status")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!settlementList || (settlementList && !settlementList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        settlementList &&
                                                        settlementList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{(offset - 1) * ROWS_PER_PAGE + i + 1}</h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <>
                                                                            <td>
                                                                                <h6>{item.requestorType ? Object.keys(ROLE_ID).find((key) => ROLE_ID[key] == item.requestorType) : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>{item.requestor ? item.requestor : "-"}</h6>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <td>
                                                                        <h6
                                                                            className="cursor-pointer"
                                                                            onClick={() => handleShow(item.settlementId, item.withdrawlAmount, item.requestorId, item.requestorType)}
                                                                        >
                                                                            <u>{item.withdrawlAmount ? parseFloat(item.withdrawlAmount).toFixed(2) : 0}</u>
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.achBank ? item.achBank : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.achAccount ? item.achAccount : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.requestDate ? monthDateYear(item.requestDate) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.withdrawalMethod
                                                                                ? Object.keys(WITHDRAWAL_METHOD).find((key) => WITHDRAWAL_METHOD[key] == item.withdrawalMethod)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className={`badge badge-${
                                                                                item.statusId == AGENT_STATUS.Approved ? "success" : item.statusId == AGENT_STATUS.Requested ? "warning" : "danger"
                                                                            }`}
                                                                            onClick={
                                                                                item.statusId == AGENT_STATUS.Requested && profileData.roleId == ROLE_ID.ADMIN
                                                                                    ? () => handleStatusChange(item.id, item.settlementId)
                                                                                    : () => {}
                                                                            }
                                                                        >
                                                                            {Object.keys(AGENT_STATUS).find((key) => AGENT_STATUS[key] === item.statusId)}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {settlementList == null ? "0" : rowCount} {t("Settlement")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default SettlementRevenueList;
