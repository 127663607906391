import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";

function ImageUpload(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    let [image, setImage] = useState({ preview: "", data: "" });

    const handleImageChange = (e) => {
        const data = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
            name: e.target.files[0].name,
        };
        const fileExtension = data.data.name.split(".").at(-1).toLowerCase();
        let allowedFileTypes = ["pdf", "jpg", "png", "jpeg"];

        if (!allowedFileTypes.includes(fileExtension)) {
            e.target.value = null;
            alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        } else {
            let element = document.getElementById("button");
            element.classList.remove("d-none");
            setImage(data);
        }
    };

    const handleUpload = async () => {
        let formData = new FormData();

        if (!image.data) {
            toast.error(t(`Please upload Image`));
        } else {
            formData.append("profilePic", image.data);
            formData.append("webCam", false);

            await axios
                .post(`${baseURL}/api/v1/admin/profile-picture/update`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                        lang: languageShortName,
                    },
                })
                .then((res) => {
                    toast.success(res.data.message);
                    props.fetchProfiledata();
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleAgreementUpload = async () => {
        let formData = new FormData();
        if (!image.data) {
            toast.error(t(`Please upload Document`));
        } else {
            formData.append("agreement", image.data);
            if (props.verifierId) {
                formData.append("verifierId", props.verifierId);
            } else {
                formData.append("employeeId", props.employeeId);
            }
            await axios
                .post(`${baseURL}/api/v1/admin/${props.verifierId ? "verifier" : "employee"}-agreement/update`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                        lang: languageShortName,
                    },
                })
                .then((res) => {
                    toast.success(res.data.message);
                    if (props.verifierId) {
                        props.fetchVerifierdata();
                    } else {
                        props.fetchEmployeedata();
                    }
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Update")} {props.isAgreementShow ? t("Agreement") : t("Profile")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="offset-2 col-md-8 my-4">
                            <input type="file" className="form-control" name="image" onChange={handleImageChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary d-none" id="button" onClick={props.isAgreementShow ? handleAgreementUpload : handleUpload}>
                        {t("Update")}
                    </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        {t("Close")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ImageUpload;
