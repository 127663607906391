import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

export default function Enroll() {
    const navigate = useNavigate();

    return (
        <>
            <Header />

            <section className="enrollment-hero-custom">
                <div className="hero-get-in-toch-custom icons-height-custom">
                    <div className="icons-img-custom" data-aos="fade-left">
                        <img src="/assets/images/home/img-1.png" alt="" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-7 text-title-sm-custom" data-aos="zoom-in">
                        <h3 className="bold pb-2">Enrollment</h3>
                        <button className="btn btn-outline text-white join-now-custom btn-arrow-width-custom" onClick={() => navigate("/get-in-touch")}>
                            Contact Me
                            <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="icons-img-custom" data-aos="fade-right">
                        <img src="/assets/images/home/img-2.png" alt="" />
                    </div>
                </div>
            </section>

            {/* Affordable Coverage */}
            <section className="pt-5 d-flex justify-content-center">
                <div className="container">
                    <div className="col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-md-6 col-xs-12 aff-cov-text-custom" data-aos="fade-left">
                            <h1 className="fw-bolder text-dark">Affordable Coverage,</h1>
                            <h1 className="fw-bolder quality-text-custom">Quality Services.</h1>
                            <div>
                                <p className="mt-40 mb-20">
                                    Our plans offer a range of benefits, including coverage for preventive care, restorative services, and even special cosmetic procedures. Are you ready to take the
                                    first step in securing the dental care you and your family need? Enrolling in our dental insurance plan is easy and fast!
                                </p>
                            </div>
                            <div className="d-flex">
                                <button className="btn my-1 enroll-btn-custom btn-lg-display-custom" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                    Enroll Now
                                    <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                </button>
                                <button className="btn ms-3 my-1 text-white btn-enroll-doctor-custom btn-lg-display-custom" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                    Contact Us
                                    <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12" data-aos="fade-right">
                            <img src="/assets/images/home/img-group.png" alt="" height="367px" className="" />
                        </div>
                        <div className=" btn-sm-display-custom">
                            <button className="btn my-1 enroll-btn-custom" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                Enroll Now
                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                            </button>
                            <button className="btn ms-3 my-1 text-white btn-enroll-doctor-custom" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                Contact Us
                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/*  */}
            <section className="pt-5 d-flex justify-content-center">
                <div className="container">
                    <div className="col-lg-12 col-sm-12">
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-right" data-aos-anchor-placement="top-bottom">
                            <div className="card number-card-custom">
                                <div className="text-center">
                                    <p className="mt-3">
                                        <img src="/assets/images/home/1number.png" alt="" height="50px" className="w-auto number-img-custom" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom">Sign Up</p>
                                    <p className="d-flex justify-content-center well-sm">Create your member account through GPoint Wallet.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                            <div className="card number-card-custom number2-card-custom">
                                <div className="text-center">
                                    <p className="mt-3">
                                        <img src="/assets/images/home/2number.png" alt="" height="50px" className="w-auto number-img-custom" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom">Complete Enrollment</p>
                                    <p className="d-flex justify-content-center well-sm">All we need is your personal info and we can set you up with the plan that suits your needs, same day!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-left" data-aos-anchor-placement="bottom-bottom">
                            <div className="card number-card-custom number3-card-custom">
                                <div className="text-center">
                                    <p className="mt-3">
                                        <img src="/assets/images/home/3number.png" alt="" height="50px" className="w-auto number-img-custom" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom">Get Approved</p>
                                    <p className="d-flex justify-content-center well-sm">Our team will review your enrollment and provide you with confirmation of coverage, the very next day.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <Footer />
        </>
    );
}
