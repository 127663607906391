import React, { useEffect, useState } from "react";
import Header from "../../commoncomponents/header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { baseURL } from "../../helper/Config";
import { ROLE_ID, STATUS_PENDING } from "../../helper/Constant";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../commoncomponents/Loader";
import Sidebar from "../../commoncomponents/sidebar/Sidebar";
import Cookies from "universal-cookie";

function Layout() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const cookies = new Cookies();

    const roleId = sessionStorage.getItem("roleId");
    let pageAccess = cookies.get("pageAccess") && cookies.get("pageAccess").length ? cookies.get("pageAccess") : [];

    const [isLoading, setIsLoading] = useState(true);
    const [isOfficeLoading, setIsOfficeLoading] = useState(true);
    const [profileData, setProfileData] = useState({});
    const [officeData, setOfficeData] = useState([]);
    const [officeList, setOfficeList] = useState([]);
    const [officeId, setOfficeId] = useState(roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "");
    const [checkedList, setCheckedList] = useState([]);
    const [specialityCheckedList, setSpecialityCheckedList] = useState([]);

    const fetchProfiledata = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/profile`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setProfileData(res.data.data);
                if (res.data.data.roleId == ROLE_ID.DOCTOR) {
                    fetchOfficedata(res.data.data.id);
                }
                if (res.data.data.roleId == ROLE_ID.AGENT) {
                    setCheckedList(res.data.data && res.data.data.region && res.data.data.region.split("~").map((item) => Number(item)));
                }
                if (res.data.data.statusId == STATUS_PENDING.Pending && res.data.data.roleId == ROLE_ID.PATIENT) {
                    navigate("/activate-patient");
                }

                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchOfficedata = async (doctorId) => {
        await axios
            .post(
                `${baseURL}/api/v1/doctor/doctor/office/list?isPagination=false`,
                {
                    doctorId: doctorId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeDetail = async () => {
        setIsOfficeLoading(true);
        await axios
            .get(`${baseURL}/api/v1/doctor/office/detail/${officeId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setOfficeData(res.data.data);
                setCheckedList(res.data.data && res.data.data.languageId && res.data.data.languageId.split("~").map((item) => Number(item)));
                setSpecialityCheckedList(res.data.data && res.data.data.specialtyPartId && res.data.data.specialtyPartId.split("~").map((item) => Number(item)));
                setIsOfficeLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchProfiledata();
        if (roleId == ROLE_ID.DOCTOR) {
            officeDetail();
        }
    }, []);

    return (
        <>
            <Header profileData={profileData} fetchProfiledata={fetchProfiledata} officeList={officeList} setOfficeList={setOfficeList} officeId={officeId} setOfficeId={setOfficeId} />
            <div className="page-container row-fluid container-fluid">
                <Sidebar profileData={profileData} pageAccess={pageAccess} />
                <section id="main-content" className=" ">
                    <div className="wrapper main-wrapper row">
                        {isLoading || (roleId == ROLE_ID.DOCTOR && isOfficeLoading) ? (
                            <Loader />
                        ) : (
                            <Outlet
                                context={{ profileData, setProfileData, fetchProfiledata, officeData, setOfficeData, checkedList, setCheckedList, specialityCheckedList, setSpecialityCheckedList }}
                            />
                        )}
                    </div>
                </section>
                <div className="chatapi-windows"></div>
            </div>
        </>
    );
}

export default Layout;
