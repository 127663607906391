import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE } from "../../../helper/Constant";
import { evaluationEmptyStars, evaluationRating } from "../../../helper/helper";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DetailDentalCompareList from "./DetailCompareDentalList";
import AddServiceAndCost from "../../adminPanel/manageOffice/AddServiceAndCost";
import { useTranslation } from "react-i18next";

function DentalCompareList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();
    const { profileData } = useOutletContext();

    const [evaluationList, setEvaluationList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showFee, setShowFee] = useState(false);
    const [officeId, setOfficeId] = useState("");
    const [officeName, setOfficeName] = useState("");
    const [specialityCheckedList, setSpecialityCheckedList] = useState([]);
    const [filterValue, setFilterValue] = useState({
        doctorId: "",
        officeId: "",
    });
    const [officeList, setOfficeList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [doctor, setDoctor] = useState({
        name: "",
        id: "",
    });
    const [office, setOffice] = useState({
        name: "",
        id: "",
    });
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const fetchEvaluationdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/dental-evaluation/compare/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    doctorId: filterValue.doctorId,
                    officeId: filterValue.officeId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setEvaluationList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    let selectOffice = [
        {
            id: "",
            name: t("Office"),
        },
    ];

    const fetchOfficedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {
                    isDentalCompareOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onOfficeChange = (e) => {
        setFilterValue({ ...filterValue, officeId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    let selectDoctor = [
        {
            id: "",
            firstName: t("Doctor"),
        },
    ];

    const fetchDoctorData = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    isDentalCompareDoctor: true,
                    isInOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data ? selectDoctor.concat(res.data.data) : selectDoctor);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorList &&
        doctorList.map((item) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const onFilterDoctorChange = (e) => {
        setFilterValue({ ...filterValue, doctorId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleShow = (doctorId, firstName, lastName, officeId, officeName) => {
        setDoctor({
            name: firstName + " " + lastName,
            id: doctorId,
        });
        setOffice({
            name: officeName,
            id: officeId,
        });
        setShow(true);
    };

    const handleShowFee = (officeId, officeName, specialtyPartId) => {
        setOfficeId(officeId);
        setOfficeName(officeName);
        setSpecialityCheckedList(specialtyPartId && specialtyPartId.split("~").map((item) => Number(item)));
        setShowFee(true);
    };

    const handleClose = () => {
        setDoctor({
            name: "",
            id: "",
        });
        setOffice({
            name: "",
            id: "",
        });
        setShow(false);
    };

    const handleCloseFee = () => {
        setOfficeId("");
        setOfficeName("");
        setSpecialityCheckedList("");
        setShowFee(false);
    };

    useEffect(() => {
        fetchDoctorData();
        fetchOfficedata();
    }, []);

    useEffect(() => {
        fetchEvaluationdata();
    }, [offset, filterValue.doctorId, filterValue.officeId]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{show ? t("Detail Dental Compare List") : showFee ? `${t("Service And Cost Information Of")} ${officeName}` : `${t("Manage Dental Compare")}`}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            <li className="active cursor-pointer" onClick={show ? handleClose : showFee ? handleCloseFee : ""}>
                                <strong>{t("Dental Compare List")}</strong>
                            </li>
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>{t("Detail Dental Compare List")}</strong>
                                </li>
                            ) : showFee ? (
                                <>
                                    <li className="active cursor-pointer">
                                        <strong>{t("Service-Cost Details")}</strong>
                                    </li>
                                </>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <DetailDentalCompareList show={show} setShow={setShow} doctor={doctor} office={office} handleClose={handleClose} />
                    ) : showFee ? (
                        <div className="bg-w">
                            <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                                <section className="box1">
                                    <AddServiceAndCost
                                        view={true}
                                        isfromSearchDental={true}
                                        specialityCheckedList={specialityCheckedList}
                                        setSpecialityCheckedList={setSpecialityCheckedList}
                                        officeId={officeId}
                                        step={3}
                                        handleClose={handleCloseFee}
                                    />
                                </section>
                            </div>
                        </div>
                    ) : (
                        <section className="box1">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12"></div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                            <div className="row mx-2">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="my-4">
                                                        <Select
                                                            name="officeList"
                                                            value={officeOptions && officeOptions.filter(({ value }) => value == filterValue.officeId)}
                                                            options={officeOptions}
                                                            onChange={onOfficeChange}
                                                            placeholder={t("Office")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                    <div className="my-4">
                                                        <Select
                                                            name="doctorList"
                                                            value={doctorOptions && doctorOptions.filter(({ value }) => value == filterValue.doctorId)}
                                                            options={doctorOptions}
                                                            onChange={onFilterDoctorChange}
                                                            placeholder={t("Doctor")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !evaluationList || (evaluationList && !evaluationList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    {isLoading ? (
                                                        ""
                                                    ) : (
                                                        <tr>
                                                            <th>{t("Office")}</th>
                                                            <th>{t("Doctor")}</th>
                                                            {evaluationList &&
                                                                evaluationList.length &&
                                                                evaluationList[0] &&
                                                                evaluationList[0].evluation &&
                                                                Object.keys(evaluationList[0].evluation).map((item, i) => {
                                                                    return (
                                                                        <Fragment key={i}>
                                                                            <th>{item}</th>
                                                                        </Fragment>
                                                                    );
                                                                })}
                                                            <th>{t("View Map")}</th>
                                                        </tr>
                                                    )}
                                                </thead>
                                                <tbody>
                                                    {!evaluationList || (evaluationList && !evaluationList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        evaluationList &&
                                                        evaluationList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className="cursor-pointer" onClick={() => handleShowFee(item.office.id, item.office.name, item.office.specialtyPartId)}>
                                                                        <u>
                                                                            <h6>{item.office && item.office.name ? item.office.name : "-"}</h6>
                                                                        </u>
                                                                    </td>

                                                                    <td
                                                                        className="cursor-pointer"
                                                                        onClick={() => handleShow(item.doctor.id, item.doctor.firstName, item.doctor.lastName, item.office.id, item.office.name)}
                                                                    >
                                                                        <h6>
                                                                            <u>
                                                                                {item.doctor
                                                                                    ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                    : "-"}
                                                                            </u>
                                                                        </h6>
                                                                    </td>
                                                                    {item.evluation &&
                                                                        Object.values(item.evluation).map((item, i) => {
                                                                            return (
                                                                                <Fragment key={i}>
                                                                                    <td>
                                                                                        <h6>{evaluationRating(item).concat(evaluationEmptyStars(item))}</h6>
                                                                                    </td>
                                                                                </Fragment>
                                                                            );
                                                                        })}
                                                                    <td>
                                                                        <a className="btn btn-info btn-xs mx-2" href={item.office.googleMapLink} target="_blank">
                                                                            <i className="fa fa-map-marker"></i>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {evaluationList == null ? "0" : rowCount} {t("Dental Comparison")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default DentalCompareList;
