import React, { Fragment, useState } from "react";
import { REGION, ROLE_ID, STATUS } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import Loader from "../../../commoncomponents/Loader";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ApproveAgent(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    //
    const { t } = useTranslation();

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENCY ? "agency" : "";

    const handleUpdateAgent = async () => {
        if (!props.agentValue.agencyId || props.agentValue.agencyId === "" || props.agentValue.agencyId === null) {
            toast.error(t(`Please select agency`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/agent/upsert`,
                    {
                        agentId: props.agentId,
                        email: props.agentValue.email,
                        userName: props.agentValue.userName,
                        agencyId: props.agentValue.agencyId,
                        statusId: props.agentValue.statusId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchAgentReqestdata();
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleReject = async (agentId) => {
        Swal.fire({
            title: t(`Are you sure you want to reject this Agent?`),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, reject it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios
                    .get(`${baseURL}/api/v1/${apiRouteName}/registration-request/agentrejected/${agentId}?agencyId=${props.agentValue.agencyId}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    })
                    .then((res) => {
                        toast.success(res.data.message);
                        props.handleClose();
                        props.fetchAgentReqestdata();
                        props.fetchDashboardData();
                    })
                    .catch((err) => {
                        if (err.response) {
                            if (err.response.status == 500) {
                                localStorage.clear();
                                sessionStorage.clear();
                                navigate("/login");
                            } else {
                                toast.error(err.response.data.message);
                            }
                        }
                    });
            }
        });
    };

    const handleApprove = async (agentId) => {
        if (!props.agentValue.agencyId || props.agentValue.agencyId === "" || props.agentValue.agencyId === null) {
            toast.error(t(`Please select agency`));
        } else {
            Swal.fire({
                title: t(`Are you sure you want to approve this Agent?`),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: t("Close"),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("Yes, approve it!"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios
                        .get(`${baseURL}/api/v1/${apiRouteName}/registration-request/agentapproved/${agentId}?agencyId=${props.agentValue.agencyId}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            toast.success(res.data.message);
                            props.handleClose();
                            props.fetchAgentReqestdata();
                            props.fetchDashboardData();
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            });
        }
    };

    return (
        <>
            <div className="col-xs-12 ">
                <div
                    className={`add-header-wrapper gradient-${
                        profileData.roleId == ROLE_ID.ADMIN ? "blue" : profileData.roleId == ROLE_ID.AGENCY ? "green" : profileData.roleId == ROLE_ID.AGENT ? "green1" : "grep"
                    } curved-section text-center`}
                >
                    <h2 className="uppercase bold w-text">{`${t("View")} ${t("Agent")}`}</h2>
                    <div className="before-text">{`${t("View")} ${t("Agent")}`}</div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group row">
                                                <label className="control-label col-12">{t("E-mail")}</label>

                                                <div className="col-12">
                                                    <input type="email" className="form-control" name="email" value={props.agentValue.email} disabled={"disabled"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("Username")}</label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="userName" value={props.agentValue.userName} disabled={"disabled"} />
                                                    <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                        *{t("At least 5 characters : start and end with character or number, can have special character")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("First name")}</label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="firstName" value={props.agentValue.firstName} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Last name")}</label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="lastName" value={props.agentValue.lastName} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Agency")}
                                                            {props.isView ? "" : <span className="required"> * </span>}
                                                        </label>
                                                        <div className="col-md-12">
                                                            <Select
                                                                name="agencyId"
                                                                value={props.agencyOptions && props.agencyOptions.filter(({ value }) => value == props.agentValue.agencyId)}
                                                                options={props.agencyOptions}
                                                                onChange={props.onAgencyChange}
                                                                isDisabled={props.isView}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">{t("GPoint Wallet Username")}</label>

                                                                <div className="col-xl-11 col-md-8 col-sm-8 col-12">
                                                                    <input type="text" className="form-control" name="gPointUserName" value={props.agentValue.gPointUserName} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Region")}</label>

                                                        <div className="col-md-12">
                                                            <div className="row" style={{ paddingLeft: "5px" }}>
                                                                {REGION &&
                                                                    REGION.map((item, i) => {
                                                                        return (
                                                                            <Fragment key={i}>
                                                                                <div className="col-lg-1 col-sm-1 col-xs-4">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="checkBox "
                                                                                        value={item.id}
                                                                                        name={item.id}
                                                                                        checked={props.agencyCheckedList && props.agencyCheckedList.includes(item.id) ? true : false}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-xs-6">{t(item.name)}</div>
                                                                            </Fragment>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("Phone")}</label>

                                                <div className="col-md-12">
                                                    <input type="number" className="form-control" name="phone1" value={props.agentValue.phone1} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("Address")}</label>

                                                <div className="col-md-12">
                                                    <textarea type="text" className="form-control" name="address1" rows={5} cols={12} value={props.agentValue.address1} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="padding-bottom-30 d-flex justify-content-center">
                                            <div className="text-left">
                                                <button
                                                    type="button"
                                                    className="btn mx-2 btn-secondary"
                                                    onClick={() => {
                                                        props.handleClose();
                                                    }}
                                                >
                                                    {t("Close")}
                                                </button>
                                                {props.isView ? (
                                                    ""
                                                ) : (
                                                    <button type="button" className={`btn btn-primary gradient-blue mx-2`} onClick={handleUpdateAgent}>
                                                        {t("Save")}
                                                    </button>
                                                )}
                                                <button type="button" className="btn btn-danger mx-2" onClick={() => handleReject(props.agentId)}>
                                                    {t("Reject")}
                                                </button>
                                                <button type="button" className="btn mx-2 btn-success" onClick={() => handleApprove(props.agentId)} disabled={!props.agentValue.agencyId}>
                                                    {t("Approve")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ApproveAgent;
