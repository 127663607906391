import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE, STATUS_REJECTED } from "../../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, monthDateYear, time } from "../../../helper/dateFormates";
import UpsertZoomMeeting from "./UpsertZoomMeeting";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sliceString } from "../../../helper/helper";

function ZoomMeetingList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData, officeData } = useOutletContext();
    const { t } = useTranslation();
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : profileData.roleId == ROLE_ID.AGENCY
            ? "agency"
            : profileData.roleId == ROLE_ID.AGENT
            ? "agent"
            : profileData.roleId == ROLE_ID.MANAGER
            ? "manager"
            : "";
    const [zoomMeetingList, setZoomMeetingList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email:
            profileData.roleId == ROLE_ID.DOCTOR ||
            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
            profileData.roleId == ROLE_ID.PATIENT ||
            profileData.roleId == ROLE_ID.AGENT ||
            profileData.roleId == ROLE_ID.AGENCY ||
            profileData.roleId == ROLE_ID.MANAGER ||
            profileData.roleId == ROLE_ID.VERIFY
                ? profileData.email
                : "",
        name:
            profileData.roleId == ROLE_ID.DOCTOR ||
            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
            profileData.roleId == ROLE_ID.PATIENT ||
            profileData.roleId == ROLE_ID.AGENT ||
            profileData.roleId == ROLE_ID.AGENCY ||
            profileData.roleId == ROLE_ID.MANAGER ||
            profileData.roleId == ROLE_ID.VERIFY
                ? profileData.firstName
                : "",
        userName:
            profileData.roleId == ROLE_ID.DOCTOR ||
            profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
            profileData.roleId == ROLE_ID.PATIENT ||
            profileData.roleId == ROLE_ID.AGENT ||
            profileData.roleId == ROLE_ID.AGENCY ||
            profileData.roleId == ROLE_ID.MANAGER ||
            profileData.roleId == ROLE_ID.VERIFY
                ? profileData.userName
                : "",
        phone: "",
        requestedDate: "",
        requestedTime: "",
        notes: "",
        officeId: profileData.roleId == ROLE_ID.DOCTOR ? officeData.id : "",
        doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
    });
    const [zoomMeetingId, setZoomMeetingId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
        fromDate: "",
        toDate: "",
    });

    const [officeList, setOfficeList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    let selectOffice = [
        {
            id: "",
            name: t("Select Office"),
        },
    ];
    const fetchOfficeList = async () => {
        setEditLoader(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/office/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onOfficeChange = (e) => {
        setInputValue({ ...inputValue, officeId: e.value, doctorId: "" });
        setDoctorList([]);
        fetchDoctorList(e.value);
    };

    const fetchDoctorList = async (officeId) => {
        setEditLoader(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/doctor/list?isPagination=false`,
                {
                    officeId: officeId,
                    isInOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorList &&
        doctorList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName + " (" + item.userName + ")",
            };
        });

    const onDoctorChange = (e) => {
        setInputValue({ ...inputValue, doctorId: e.value });
    };

    const fetchZoomMeetingdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/zoom-meeting/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    search: filterValue.nameSearch,
                    statusId: filterValue.statusSearch,
                    fromDate: dateAPIFormate(filterValue.fromDate),
                    toDate: dateAPIFormate(filterValue.toDate),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setZoomMeetingList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleEdit = (id) => {
        setZoomMeetingId(id);
        zoomMeetingDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const handleView = (id) => {
        setZoomMeetingId(id);
        zoomMeetingDetail(id);
        setIsView(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchZoomMeetingdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const zoomMeetingDetail = async (zoomMeetingId) => {
        setEditLoader(false);
        await axios
            .get(`${baseURL}/api/v1/admin/zoom-meeting/detail/${zoomMeetingId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            email:
                profileData.roleId == ROLE_ID.DOCTOR ||
                profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                profileData.roleId == ROLE_ID.PATIENT ||
                profileData.roleId == ROLE_ID.AGENT ||
                profileData.roleId == ROLE_ID.AGENCY
                    ? profileData.email
                    : "",
            name:
                profileData.roleId == ROLE_ID.DOCTOR ||
                profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                profileData.roleId == ROLE_ID.PATIENT ||
                profileData.roleId == ROLE_ID.AGENT ||
                profileData.roleId == ROLE_ID.AGENCY
                    ? profileData.firstName
                    : "",
            userName:
                profileData.roleId == ROLE_ID.DOCTOR ||
                profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE ||
                profileData.roleId == ROLE_ID.PATIENT ||
                profileData.roleId == ROLE_ID.AGENT ||
                profileData.roleId == ROLE_ID.AGENCY
                    ? profileData.userName
                    : "",
            phone: "",
            requestedDate: "",
            requestedTime: "",
            notes: "",
            officeId: profileData.roleId == ROLE_ID.ADMIN ? "" : officeData.id,
            doctorId: profileData.roleId == ROLE_ID.ADMIN ? "" : profileData.id,
        });
        setZoomMeetingId("");
        setIsEdit(false);
        setIsView(false);
        setShow(false);
    };

    useEffect(() => {
        fetchZoomMeetingdata();
        if (profileData.roleId == ROLE_ID.OFFICE_EMPLOYEE) {
            fetchOfficeList();
        }
    }, [offset, filterValue.statusSearch, filterValue.officeId, filterValue.fromDate, filterValue.toDate]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Zoom-Meeting")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer" onClick={handleClose}>
                                <strong>{t("Zoom-Meeting List")}</strong>
                            </li>
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : t("Request")} {t("Zoom-Meeting")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <UpsertZoomMeeting
                            isView={isView}
                            onOfficeChange={onOfficeChange}
                            doctorOptions={doctorOptions}
                            officeOptions={officeOptions}
                            onDoctorChange={onDoctorChange}
                            editLoader={editLoader}
                            handleClose={handleClose}
                            fetchZoomMeetingdata={fetchZoomMeetingdata}
                            zoomMeetingId={zoomMeetingId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isEdit={isEdit}
                            show={show}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box" style={{ overflow: "inherit" }}>
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                        <label className="title">{t("Requested Date")}</label>
                                                    ) : (
                                                        <h2 className="title pull-left">
                                                            <button
                                                                className="btn btn-primary btn-corner"
                                                                onClick={() => {
                                                                    setShow(true);
                                                                }}
                                                            >
                                                                <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                            </button>
                                                        </h2>
                                                    )}
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-lg-5 col-md-5 col-sm-5 d-flex">
                                                            <div className="title">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    placeholderText={"Start date"}
                                                                    minDate={defaultTimeZone(new Date())}
                                                                    maxDate={filterValue.toDate}
                                                                    scrollableMonthDropdown={true}
                                                                    selected={filterValue && defaultTimeZone(filterValue.fromDate)}
                                                                    onChange={(date) => setFilterValue({ ...filterValue, fromDate: date })}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2 col-sm-2">
                                                            <span className="title d-flex justify-content-center">{t("To")}</span>
                                                        </div>
                                                        <div className="col-lg-5 col-md-5 col-sm-5">
                                                            <div className="title">
                                                                <DatePicker
                                                                    className="form-control input-height mr-sm-2"
                                                                    placeholderText={"End date"}
                                                                    minDate={filterValue.fromDate}
                                                                    scrollableMonthDropdown={true}
                                                                    selected={filterValue && defaultTimeZone(filterValue.toDate)}
                                                                    onChange={(date) => setFilterValue({ ...filterValue, toDate: date })}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12">
                                            <div className="row mx-2">
                                                <div className="col-lg-8 col-md-6 col-sm-6 d-flex justify-content-lg-end">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="nameSearch"
                                                            value={filterValue.nameSearch}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search name")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-lg-end">
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_REJECTED).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_REJECTED[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !zoomMeetingList || (zoomMeetingList && !zoomMeetingList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("No")}</th>
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Name")}</th> : ""}
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Username")}</th> : ""}
                                                        <th>{t("Phone")}</th>
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Email")}</th> : ""}
                                                        <th>{t("Date (Time)")}</th>
                                                        <th>{t("Notes")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!zoomMeetingList || (zoomMeetingList && !zoomMeetingList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        zoomMeetingList &&
                                                        zoomMeetingList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{(offset - 1) * ROWS_PER_PAGE + i + 1}</h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <td>
                                                                            <h6>{item.name ? item.name : "-"}</h6>
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <td>
                                                                            <h6>{item.userName ? item.userName : "-"}</h6>
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.phone ? item.phone : "-"}</h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <td>
                                                                            <h6>{item.email ? sliceString(item.email, 10) : "-"}</h6>
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <td>
                                                                        <h6>
                                                                            {item.requestedDate && item.requestedTime ? monthDateYear(item.requestedDate) + " (" + time(item.requestedTime) + ")" : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.notes ? sliceString(item.notes, 15) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className={`badge badge-${
                                                                                item.statusId == STATUS_REJECTED.Approved
                                                                                    ? "success"
                                                                                    : item.statusId == STATUS_REJECTED.Requested
                                                                                    ? "warning"
                                                                                    : "danger"
                                                                            }`}
                                                                        >
                                                                            {Object.keys(STATUS_REJECTED).find((key) => STATUS_REJECTED[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {item.statusId == STATUS_REJECTED.Requested && profileData.roleId == ROLE_ID.ADMIN ? (
                                                                            <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <button className="btn btn-warning btn-xs mx-2" onClick={() => handleView(item.id)}>
                                                                            <i className="fa fa-eye"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {zoomMeetingList == null ? "0" : rowCount} {t("Zoom-Meeting")}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        // )
                    )}
                </div>
            </div>
        </>
    );
}

export default ZoomMeetingList;
