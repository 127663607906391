import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import { baseURL } from "../../helper/Config";
import ImageUpload from "./ImageUpload";

function ViewProfile() {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const { profileData, fetchProfiledata } = useOutletContext();

    const handleImageChange = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">
                            {t("View")} {t("Profile")}
                        </h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer">
                                <strong>
                                    {t("View")} {t("Profile")}
                                </strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="offset-lg-3 col-lg-6 col-md-6 col-sm-12">
                <section className="box ">
                    <div className="content-body p">
                        <div className="row">
                            <div className="doctors-list patient relative">
                                <div className="doctors-head relative text-center">
                                    <div className="ar-image">
                                        <ImageUpload show={show} handleClose={handleClose} fetchProfiledata={fetchProfiledata} />
                                        {profileData && profileData.profilePic ? (
                                            <img src={baseURL + profileData.profilePic} className="rad-50 center-block article-image" alt="User" style={{ height: "116px", width: "116px" }} />
                                        ) : (
                                            <img src="../data/profile/profile.jpg" className="rad-50 center-block article-image" alt="profile_pic" style={{ height: "116px", width: "116px" }} />
                                        )}
                                        <i className=" fa fa-plus fa-5x" style={{ fontSize: "23px", color: "#b960e0" }} onClick={handleImageChange} />
                                    </div>
                                    <h3 className="header w-text relative bold">{profileData && profileData.firstName + " " + profileData.lastName}</h3>
                                    <p className="desc g-text relative">Username : {profileData && profileData.userName}</p>
                                </div>
                                <div className="row">
                                    <div className="patients-info relative">
                                        <div className="col-sm-12 col-xs-12">
                                            <div className="patient-card has-shadow2">
                                                <div className="doc-info-wrap">
                                                    <div className="patient-info">
                                                        <h5 className="bold">{t("E-mail")}</h5>
                                                        <h6>{profileData && profileData.email}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ViewProfile;
