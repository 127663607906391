import React, { Fragment, useState } from "react";
import { REGION, ROLE_ID, STATUS, STATUS_REJECTED, WITHDRAWAL_METHOD, WITHDRAWAL_TYPE } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL, frontURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkEmail, checkPhNo, checkPwd, checkUserName } from "../../../helper/Validator";
import Loader from "../../../commoncomponents/Loader";
import { Link } from "react-router-dom";
import GPointWalletUserVerify from "../../../commoncomponents/GPointWalletUserVerify";
import { useTranslation } from "react-i18next";

function UpsertAgent(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [passwordShown, setPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENCY ? "agency" : "";

    const [show, setShow] = useState(false);

    const handleSyncWallet = async () => {
        setShow(true);
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };

    const checkedchange = (e) => {
        const value = Number(e.target.value);
        let checkedListData = props.checkedList;
        const index = checkedListData.indexOf(value);
        if (index > -1) {
            checkedListData.splice(index, 1);
            if (index == 0) {
                props.setCheckedList([...props.checkedList]);
            } else {
                props.setCheckedList([...props.checkedList, ...props.checkedList.slice(index, 1)]);
            }
        } else {
            props.setCheckedList([...props.checkedList, value]);
        }
    };

    const handleAdd = async () => {
        if ((!props.isEdit || props.isEdit == false) && (!props.inputValue.email || props.inputValue.email === "" || props.inputValue.email === null)) {
            toast.error(t(`Please enter email`));
        } else if ((!props.isEdit || props.isEdit == false) && props.inputValue.email && checkEmail(props.inputValue.email) == false) {
            toast.error(t(`Please enter valid email`));
        } else if ((!props.isEdit || props.isEdit == false) && (!props.inputValue.password || props.inputValue.password === "" || props.inputValue.password === null)) {
            toast.error(t(`Please enter password`));
        } else if ((!props.isEdit || props.isEdit == false) && props.inputValue.password && checkPwd(props.inputValue.password) == false) {
            toast.error(t(`Please enter valid password`));
        } else if ((!props.isEdit || props.isEdit == false) && (!props.inputValue.confirmPassword || props.inputValue.confirmPassword === "" || props.inputValue.confirmPassword === null)) {
            toast.error(t(`Please enter confirm password`));
        } else if ((!props.isEdit || props.isEdit == false) && props.inputValue.password !== props.inputValue.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
        } else if (!props.inputValue.firstName || props.inputValue.firstName === "" || props.inputValue.firstName === null) {
            toast.error(t(`Please enter first name`));
        } else if (!props.inputValue.lastName || props.inputValue.lastName === "" || props.inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        }
        // else if (!props.inputValue.gPointUserName || props.inputValue.gPointUserName === "" || props.inputValue.gPointUserName === null) {
        //     toast.error(t(`Please enter g-wallet username`));
        // }
        else if (!props.inputValue.userName || props.inputValue.userName === "" || props.inputValue.userName === null) {
            toast.error(t(`Please enter username`));
        } else if (props.inputValue.userName && checkUserName(props.inputValue.userName) == false) {
            toast.error(t(`Please enter valid username`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else if (!props.inputValue.agencyId || props.inputValue.agencyId === "" || props.inputValue.agencyId === null) {
            toast.error(t(`Please select agency`));
        } else if (!props.inputValue.achBank || props.inputValue.achBank === "" || props.inputValue.achBank === null) {
            toast.error(t(`Please enter ACH Bank`));
        } else if (!props.inputValue.achAccount || props.inputValue.achAccount === "" || props.inputValue.achAccount === null) {
            toast.error(t(`Please enter ACH Account`));
        } else if (
            !props.checkedList ||
            props.checkedList === "" ||
            props.checkedList === null ||
            // props.checkedList === [] ||
            (props.checkedList && !props.checkedList.length) ||
            (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)
        ) {
            toast.error(t(`Please select region`));
        } else if (!props.inputValue.phone1 || props.inputValue.phone1 === "" || props.inputValue.phone1 === null) {
            toast.error(t(`Please enter phone number`));
        } else if (props.inputValue.phone1 && checkPhNo(props.inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit phone number`));
        } else if (!props.inputValue.address1 || props.inputValue.address1 === "" || props.inputValue.address1 === null) {
            toast.error(t(`Please enter address`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/agent/upsert`,
                    {
                        email: props.inputValue.email,
                        password: props.inputValue.password,
                        agentId: props.isEdit ? props.agentId : "",
                        firstName: props.inputValue.firstName,
                        lastName: props.inputValue.lastName,
                        userName: props.inputValue.userName,
                        gPointUserName: props.inputValue.gPointUserName,
                        statusId: props.inputValue.statusId,
                        address1: props.inputValue.address1,
                        region: props.checkedList && props.checkedList.length ? String(props.checkedList).replaceAll(",", "~") : "0",
                        agencyId: props.inputValue.agencyId,
                        phone1: props.inputValue.phone1,
                        achBank: props.inputValue.achBank,
                        achAccount: props.inputValue.achAccount,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchAgentdata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleUpdateProfile = async () => {
        if (!props.inputValue.firstName || props.inputValue.firstName === "" || props.inputValue.firstName === null) {
            toast.error(t(`Please enter agency name`));
        }
        // else if (!props.inputValue.gPointUserName || props.inputValue.gPointUserName === "" || props.inputValue.gPointUserName === null) {
        //     toast.error(t(`Please enter g-wallet username`));
        // }
        else if (!props.inputValue.lastName || props.inputValue.lastName === "" || props.inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        } else if (!props.inputValue.userName || props.inputValue.userName === "" || props.inputValue.userName === null) {
            toast.error(t(`Please enter username`));
        } else if (props.inputValue.userName && checkUserName(props.inputValue.userName) == false) {
            toast.error(t(`Please enter valid username`));
        } else if (!props.inputValue.agencyId || props.inputValue.agencyId === "" || props.inputValue.agencyId === null) {
            toast.error(t(`Please select agency`));
        } else if (
            !props.checkedList ||
            props.checkedList === "" ||
            props.checkedList === null ||
            // props.checkedList === [] ||
            (props.checkedList && !props.checkedList.length) ||
            (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)
        ) {
            toast.error(t(`Please select region`));
        } else if (!props.inputValue.phone1 || props.inputValue.phone1 === "" || props.inputValue.phone1 === null) {
            toast.error(t(`Please enter phone number`));
        } else if (props.inputValue.phone1 && checkPhNo(props.inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit phone number`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/agent/profile/update`,
                    {
                        agentId: props.isEdit ? props.agentId : "",
                        firstName: props.inputValue.firstName,
                        lastName: props.inputValue.lastName,
                        gPointUserName: props.inputValue.gPointUserName,
                        region: props.checkedList && props.checkedList.length ? String(props.checkedList).replaceAll(",", "~") : "0",
                        agencyId: props.inputValue.agencyId,
                        phone1: props.inputValue.phone1,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchAgentdata();
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleUpdateACHDetails = async () => {
        let formData = new FormData();
        // if (!props.inputValue.withdrawalType || props.inputValue.withdrawalType === "" || props.inputValue.withdrawalType === null) {
        //     toast.error(t(`Please select withdrawal type`));
        // } else
        if (!props.inputValue.w9FormFile || props.inputValue.w9FormFile === "" || props.inputValue.w9FormFile === null) {
            toast.error(t(`Please upload w9 form`));
        } else if (!props.inputValue.withdrawalMethod || props.inputValue.withdrawalMethod === "" || props.inputValue.withdrawalMethod === null) {
            toast.error(t(`Please select withdrawal method`));
        } else if (props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.Check && (!props.inputValue.address1 || props.inputValue.address1 === "" || props.inputValue.address1 === null)) {
            toast.error(t(`Please enter address`));
        } else if (props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.ACH && (!props.inputValue.achBank || props.inputValue.achBank === "" || props.inputValue.achBank === null)) {
            toast.error(t(`Please enter ACH Bank`));
        } else if (props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.ACH && (!props.inputValue.achAccount || props.inputValue.achAccount === "" || props.inputValue.achAccount === null)) {
            toast.error(t(`Please enter ACH Account`));
        } else {
            formData.append("withdrawalType", props.inputValue.withdrawalType ? props.inputValue.withdrawalType : WITHDRAWAL_TYPE.Manual);
            formData.append("withdrawalMethod", props.inputValue.withdrawalMethod);
            formData.append("address1", props.inputValue.address1);
            formData.append("achBank", props.inputValue.achBank ? props.inputValue.achBank : "");
            formData.append("achAccount", props.inputValue.achAccount ? props.inputValue.achAccount : "");
            formData.append("document", props.inputValue.w9FormFile);
            await axios
                .post(`${baseURL}/api/v1/agent/w9Form-register`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                        lang: languageShortName,
                    },
                })
                .then((res) => {
                    props.fetchAgentdata();
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500 || err.response.status == 401) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const handleDownload = () => {
        axios({
            url: `${frontURL}/downloads/w9form/W9_Form.pdf`,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `w9form.pdf`);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    };

    return (
        <>
            {props.isProfile ? (
                <div className="col-xs-12">
                    <div className="page-title">
                        <div className="pull-left">
                            <h1 className="title">{t("View & Update Profile")}</h1>
                        </div>

                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/dashboard">
                                        <i className="fa fa-home"></i>
                                        {t("Home")}
                                    </Link>
                                </li>
                                <li className="active cursor-pointer">
                                    <strong>{t("View & Update Profile")}</strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className="col-xs-12 ">
                <div
                    className={`add-header-wrapper gradient-${
                        profileData.roleId == ROLE_ID.ADMIN
                            ? "blue"
                            : profileData.roleId == ROLE_ID.DOCTOR
                            ? "violet"
                            : profileData.roleId == ROLE_ID.PATIENT
                            ? "orange"
                            : profileData.roleId == ROLE_ID.VERIFY
                            ? "pink"
                            : profileData.roleId == ROLE_ID.AGENCY
                            ? "green"
                            : profileData.roleId == ROLE_ID.AGENT
                            ? "green1"
                            : profileData.roleId == ROLE_ID.MANAGER
                            ? "blue3"
                            : "grep"
                    } curved-section text-center`}
                >
                    <h2 className="uppercase bold w-text">{`${props.isProfile ? t("View & Update Profile") : props.isEdit ? t("Update") : t("Add")} ${t("Agent")}`}</h2>
                    <div className="before-text">{`${props.isProfile ? t("View & Update Profile") : props.isEdit ? t("Update") : t("Add")} ${t("Agent")}`}</div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        {props.isEdit && !props.isProfile ? (
                                            ""
                                        ) : (
                                            <div className="col-xs-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-12">
                                                        {t("E-mail")}
                                                        {props.isProfile ? "" : <span className="required"> * </span>}
                                                    </label>

                                                    <div className="col-12">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            value={props.inputValue.email}
                                                            onChange={props.isProfile ? "" : props.onChangeValue}
                                                            disabled={props.isProfile ? "disabled" : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Username")} {props.isProfile ? "" : <span className="required"> * </span>}
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="userName"
                                                        value={props.inputValue.userName}
                                                        onChange={props.isProfile || profileData.roleId == ROLE_ID.AGENCY ? "" : props.onChangeValue}
                                                        disabled={props.isProfile || profileData.roleId == ROLE_ID.AGENCY ? "disabled" : ""}
                                                    />
                                                    {props.isProfile ? (
                                                        ""
                                                    ) : (
                                                        <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                            *{t("At least 5 characters : start and end with character or number, can have special character(-,_)")}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {props.isEdit ? (
                                            ""
                                        ) : (
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Password")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12 d-flex">
                                                                <input
                                                                    type={passwordShown ? "text" : "password"}
                                                                    className="form-control"
                                                                    name="password"
                                                                    value={props.inputValue.password}
                                                                    onChange={props.onChangeValue}
                                                                />
                                                                <i
                                                                    className={`${passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                    id="basic-addon1"
                                                                    onClick={togglePassword}
                                                                ></i>
                                                            </div>
                                                            <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Confirm Password")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12 d-flex">
                                                                <input
                                                                    type={cfmpasswordShown ? "text" : "password"}
                                                                    className="form-control"
                                                                    name="confirmPassword"
                                                                    value={props.inputValue.confirmPassword}
                                                                    onChange={props.onChangeValue}
                                                                />
                                                                <i
                                                                    className={`${cfmpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                    id="basic-addon1"
                                                                    onClick={toggleCfmPassword}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("First name")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="firstName" value={props.inputValue.firstName} onChange={props.onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Last name")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="lastName" value={props.inputValue.lastName} onChange={props.onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                {profileData.roleId == ROLE_ID.AGENCY ? (
                                                    ""
                                                ) : (
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Agency")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <Select
                                                                    menuPlacement="top"
                                                                    name="agencyId"
                                                                    value={props.agencyOptions && props.agencyOptions.filter(({ value }) => value == props.inputValue.agencyId)}
                                                                    options={props.agencyOptions}
                                                                    onChange={props.onAgencyChange}
                                                                    placeholder={t("Select")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <GPointWalletUserVerify show={show} setShow={setShow} inputGroup={props.inputValue} setInputGroup={props.setInputValue} />
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("GPoint Wallet Username")}
                                                                    {/* <span className="required"> * </span> */}
                                                                </label>

                                                                <div className="col-xl-11 col-md-8 col-sm-8 col-12">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="gPointUserName"
                                                                        value={props.inputValue.gPointUserName}
                                                                        onChange={props.onChangeValue}
                                                                        disabled="disabled"
                                                                    />
                                                                </div>
                                                                <div className="col-xl-1 col-md-4 col-sm-4 col-12">
                                                                    <button type="button" className="form-control btn btn-info" onClick={handleSyncWallet}>
                                                                        {t("SYNC Wallet")}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Region")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <div className="row" style={{ paddingLeft: "5px" }}>
                                                                {REGION &&
                                                                    REGION.map((item, i) => {
                                                                        return (
                                                                            <Fragment key={i}>
                                                                                <div className="col-lg-1 col-sm-1 col-xs-4">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="checkBox "
                                                                                        value={item.id}
                                                                                        name={item.id}
                                                                                        checked={props.checkedList && props.checkedList.includes(item.id) ? true : false}
                                                                                        onChange={checkedchange}
                                                                                        disabled={props.view ? "Disabled" : ""}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-xs-6">{t(item.name)}</div>
                                                                            </Fragment>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {props.isProfile ? (
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">{t("Link for SNS")}</label>
                                                            <div className="col-xl-11 col-md-10 col-sm-8 col-12">
                                                                <input type="text" className="form-control" name="linkForSNS" value={props.inputValue.linkForSNS} disabled />
                                                            </div>
                                                            <div className="col-xl-1 col-md-2 col-sm-4 col-12">
                                                                <button
                                                                    type="button"
                                                                    className="form-control btn btn-primary"
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(props.inputValue.linkForSNS);
                                                                        toast.success("Link copied!");
                                                                    }}
                                                                >
                                                                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Status")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <select className="form-control col-md-3" name="statusId" value={props.inputValue.statusId} onChange={props.onChangeValue}>
                                                                    {Object.keys(STATUS).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={STATUS[item]}>
                                                                                {t(item)}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Phone")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="phone1"
                                                        value={props.inputValue.phone1}
                                                        onChange={props.onChangeValue}
                                                        disabled={props.view ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {props.isProfile ? (
                                            <>
                                                <div className="padding-bottom-30 d-flex justify-content-center my-3">
                                                    <div className="text-left">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-primary gradient-${
                                                                profileData.roleId == ROLE_ID.ADMIN
                                                                    ? "blue"
                                                                    : profileData.roleId == ROLE_ID.DOCTOR
                                                                    ? "violet"
                                                                    : profileData.roleId == ROLE_ID.PATIENT
                                                                    ? "orange"
                                                                    : profileData.roleId == ROLE_ID.VERIFY
                                                                    ? "pink"
                                                                    : profileData.roleId == ROLE_ID.AGENCY
                                                                    ? "green"
                                                                    : profileData.roleId == ROLE_ID.AGENT
                                                                    ? "green1"
                                                                    : profileData.roleId == ROLE_ID.MANAGER
                                                                    ? "blue3"
                                                                    : "grep"
                                                            } mx-2`}
                                                            onClick={handleUpdateProfile}
                                                        >
                                                            {t("Update")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {props.isProfile ? (
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12 d-flex">
                                                                {t("W9 Form")}
                                                                <span className="required"> * </span>
                                                                <span>
                                                                    <span>
                                                                        <u className="cursor-pointer" onClick={() => handleDownload()}>
                                                                            <h6 className="mt-1 mx-2 text-primary">{t("Form Download")}: W9 Form</h6>
                                                                        </u>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                            {props.inputValue.w9FormFile ? (
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <a className="btn btn-primary bold" href={`${baseURL}${props.inputValue.w9FormFile}`} target="_blank">
                                                                                Preview your w9form &nbsp; <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </a>
                                                                        </div>
                                                                        {props.inputValue.w9FormStatus == STATUS_REJECTED.Approved ? (
                                                                            ""
                                                                        ) : (
                                                                            <div className="col-6">
                                                                                <div className="col-md-12">
                                                                                    <input
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        name="w9FormFile"
                                                                                        onChange={props.onChangeValue}
                                                                                        disabled={props.view ? "disabled" : ""}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="col-md-12">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name="w9FormFile"
                                                                        onChange={props.onChangeValue}
                                                                        disabled={props.view ? "disabled" : ""}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12 d-flex">{t("W9 Form Status")}</label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className={`mt-1 form-control bold ${
                                                                        props.inputValue.w9FormStatus == STATUS_REJECTED.Approved
                                                                            ? "bg-success"
                                                                            : props.inputValue.w9FormStatus == STATUS_REJECTED.Requested
                                                                            ? "bg-warning"
                                                                            : props.inputValue.w9FormStatus == STATUS_REJECTED.Rejected
                                                                            ? "bg-danger"
                                                                            : ""
                                                                    }`}
                                                                    name="w9FormStatus"
                                                                    value={Object.keys(STATUS_REJECTED).find((key) => STATUS_REJECTED[key] == props.inputValue.w9FormStatus)}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {props.isProfile ? (
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Withdrawal Type")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    className="my-2 mx-3"
                                                                    type="radio"
                                                                    name="withdrawalType"
                                                                    value="2"
                                                                    onChange={props.inputValue.w9FormStatus == STATUS_REJECTED.Approved ? props.onChangeValue : () => {}}
                                                                    checked={props.inputValue.withdrawalType == WITHDRAWAL_TYPE.Auto ? true : false}
                                                                    disabled={props.inputValue.w9FormStatus == STATUS_REJECTED.Approved ? "" : "disabled"}
                                                                />
                                                                {t("Auto")}
                                                                <input
                                                                    className="my-2 mx-3"
                                                                    type="radio"
                                                                    name="withdrawalType"
                                                                    value="1"
                                                                    onChange={props.onChangeValue}
                                                                    checked={props.inputValue.withdrawalType == WITHDRAWAL_TYPE.Manual || !props.inputValue.withdrawalType ? true : false}
                                                                />
                                                                {t("Manual")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Withdrawal Method")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="my-2 mx-3"
                                                                    type="radio"
                                                                    name="withdrawalMethod"
                                                                    value="1"
                                                                    onChange={props.onChangeValue}
                                                                    checked={props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.Check ? true : false}
                                                                />
                                                                Check
                                                                <input
                                                                    className="my-2 mx-3"
                                                                    type="radio"
                                                                    name="withdrawalMethod"
                                                                    value="2"
                                                                    onChange={props.onChangeValue}
                                                                    checked={props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD["Pick Up"] ? true : false}
                                                                />
                                                                {t("Pickup")}
                                                                <input
                                                                    className="my-2 mx-3"
                                                                    type="radio"
                                                                    name="withdrawalMethod"
                                                                    value="3"
                                                                    onChange={props.onChangeValue}
                                                                    checked={props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.ACH ? true : false}
                                                                />
                                                                {t("ACH")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("ACH Bank")}{" "}
                                                            {props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.ACH ||
                                                            profileData.roleId == ROLE_ID.ADMIN ||
                                                            profileData.roleId == ROLE_ID.AGENCY ? (
                                                                <span className="required"> * </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="achBank"
                                                                value={props.inputValue.achBank}
                                                                onChange={props.onChangeValue}
                                                                disabled={props.view ? "disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("ACH Account")}{" "}
                                                            {props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.ACH ||
                                                            profileData.roleId == ROLE_ID.ADMIN ||
                                                            profileData.roleId == ROLE_ID.AGENCY ? (
                                                                <span className="required"> * </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="achAccount"
                                                                value={props.inputValue.achAccount}
                                                                onChange={props.onChangeValue}
                                                                disabled={props.view ? "disabled" : ""}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Address")} {props.inputValue.withdrawalMethod == WITHDRAWAL_METHOD.Check ? <span className="required"> * </span> : ""}
                                                </label>

                                                <div className="col-md-12">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="address1"
                                                        rows={5}
                                                        cols={12}
                                                        value={props.inputValue.address1}
                                                        onChange={props.onChangeValue}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="padding-bottom-30 d-flex justify-content-center">
                                            <div className="text-left">
                                                <button
                                                    type="button"
                                                    className="btn mx-2 btn-secondary"
                                                    onClick={() => {
                                                        props.isProfile ? navigate("/dashboard") : props.handleClose();
                                                    }}
                                                >
                                                    {t("Close")}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn btn-primary gradient-${
                                                        profileData.roleId == ROLE_ID.ADMIN
                                                            ? "blue"
                                                            : profileData.roleId == ROLE_ID.DOCTOR
                                                            ? "violet"
                                                            : profileData.roleId == ROLE_ID.PATIENT
                                                            ? "orange"
                                                            : profileData.roleId == ROLE_ID.VERIFY
                                                            ? "pink"
                                                            : profileData.roleId == ROLE_ID.AGENCY
                                                            ? "green"
                                                            : profileData.roleId == ROLE_ID.AGENT
                                                            ? "green1"
                                                            : profileData.roleId == ROLE_ID.MANAGER
                                                            ? "blue3"
                                                            : "grep"
                                                    } mx-2`}
                                                    onClick={props.isProfile ? handleUpdateACHDetails : handleAdd}
                                                >
                                                    {props.isEdit ? t("Update") : t("Add")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertAgent;
