import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "../../../commoncomponents/Loader";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import { baseURL } from "../../../helper/Config";
import { ROLE_ID, ROWS_PER_PAGE, STATUS_REQUSTED } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import { displayLanguageList } from "../../../helper/helper";
import UpdateOffice from "../manageOffice/UpdateOffice";

function OfficeForDoctorList(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [officeList, setOfficeList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        name: "",
        businessUsername: "",
        phone1: "",
        npi1: "",
        licenseNumber: "",
        languageId: "",
        businessCategoryId: "",
        businessCategory: "",
        address1: "",
        statusId: "",
        countryId: "",
        stateId: "",
        cityId: "",
        zip: "",
        googleMapLink: "",
    });
    const [officeId, setOfficeId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
    });
    const [languageList, setLanguageList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [specialityCheckedList, setSpecialityCheckedList] = useState([]);

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const fetchOfficedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/office/list?isPagination=true`,
                {
                    doctorId: profileData.roleId == ROLE_ID.ADMIN ? props.doctorId : profileData.id,
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchOfficeName: filterValue.searchOfficeName,
                    searchOfficeEmailOrBusinessUsername: filterValue.searchOfficeUsername,
                    statusId: filterValue.statusSearch,
                    isRequestedOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setIsEdit(true);
        setOfficeId(id);
        officeDetail(id);
    };

    const handleView = (id) => {
        setView(true);
        setOfficeId(id);
        officeDetail(id);
    };

    const handleClose = () => {
        setOfficeId("");
        setCheckedList([]);
        setSpecialityCheckedList([]);
        setInputValue("");
        setShow(false);
        setIsEdit(false);
        setView(false);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchOfficedata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const officeDetail = async (officeId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/office/detail/${officeId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setCheckedList(res.data.data && res.data.data.languageId && res.data.data.languageId.split("~").map((item) => Number(item)));
                setSpecialityCheckedList(res.data.data && res.data.data.specialtyPartId && res.data.data.specialtyPartId.split("~").map((item) => Number(item)));
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchLanguagedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/language/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setLanguageList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this office ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/admin/office/delete`,
                            {
                                officeId: id,
                                doctorId: props.doctorId,
                                isUserAssigned: true,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchOfficedata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchLanguagedata();
        fetchOfficedata();
    }, [offset, filterValue.statusSearch]);

    return (
        <>
            {(isEdit || show || view) && (!isLoading || isLoading == false) ? (
                <UpdateOffice
                    editLoader={editLoader}
                    doctorDetail={officeList && officeList[0] && officeList[0].doctor}
                    doctorId={props.doctorId}
                    show={show}
                    checkedList={checkedList}
                    setCheckedList={setCheckedList}
                    specialityCheckedList={specialityCheckedList}
                    setSpecialityCheckedList={setSpecialityCheckedList}
                    isFromOfficeList={false}
                    languageList={languageList}
                    backToDoctorList={props.handleClose}
                    handleClose={handleClose}
                    fetchOfficedata={fetchOfficedata}
                    officeId={officeId}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    view={view}
                    onChangeValue={onChangeValue}
                />
            ) : (
                <>
                    <div className="col-xs-12">
                        <div className="page-title">
                            <div className="pull-left">
                                <h1 className="title">{profileData.roleId == ROLE_ID.DOCTOR ? t("Manage Office") : t("Manage Office For Doctor")}</h1>
                            </div>

                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard">
                                            <i className="fa fa-home"></i>
                                            {t("Home")}
                                        </Link>
                                    </li>
                                    <li className="active cursor-pointer" onClick={props.handleClose}>
                                        <strong>{profileData.roleId == ROLE_ID.DOCTOR ? t("Office List") : t("Doctor List")} </strong>
                                    </li>
                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                        ""
                                    ) : (
                                        <li className="active cursor-pointer">
                                            <strong>{t("Office-Doctor List")}</strong>
                                        </li>
                                    )}
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <section className="box">
                                <header className="panel_header">
                                    <div className="mx-3">
                                        <div className="row ">
                                            <div className="col-lg-6 col-md-2 col-sm-12">
                                                <h2 className="title pull-left">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                            <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                        </button>
                                                    )}
                                                </h2>
                                            </div>
                                            <div className="col-lg-6 col-md-10 col-sm-12 d-flex justify-content-end">
                                                <div className="row mx-lg-2">
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="title">
                                                            <input
                                                                className="form-control"
                                                                name="searchOfficeUsername"
                                                                value={filterValue.searchOfficeUsername}
                                                                onChange={onFilterChangeValue}
                                                                onKeyDown={onKeyDown}
                                                                type="search"
                                                                placeholder={t("search email or username")}
                                                                // style={{ width: "200px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="title">
                                                            <input
                                                                className="form-control"
                                                                name="searchOfficeName"
                                                                value={filterValue.searchOfficeName}
                                                                onChange={onFilterChangeValue}
                                                                onKeyDown={onKeyDown}
                                                                type="search"
                                                                placeholder={t("search name")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="title">
                                                            <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                                {Object.keys(STATUS_REQUSTED).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={STATUS_REQUSTED[item]}>
                                                                            {t(item)}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="content-body">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="table-responsive" data-pattern="priority-columns">
                                                <table
                                                    id="tech-companies-1"
                                                    className={`table vm table-small-font no-mb table-bordered ${
                                                        isLoading || !officeList || (officeList && !officeList.length) ? "" : "table-striped"
                                                    } table-hover`}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>{t("E-mail")}</th>
                                                            <th>{t("Business Username")}</th>
                                                            <th>{t("Name")}</th>
                                                            {/* <th>Business category</th> */}
                                                            <th>{t("NPI 1")}</th>
                                                            <th>{t("License Number")}</th>
                                                            <th>{t("Phone")}</th>
                                                            <th>{t("Language")}</th>
                                                            <th>{t("Created Date")}</th>
                                                            <th>{t("Status")}</th>
                                                            {profileData.roleId == ROLE_ID.DOCTOR ? "" : <th>{t("Action")}</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!officeList || (officeList && !officeList.length && !isLoading) ? (
                                                            <NoDataFound />
                                                        ) : isLoading ? (
                                                            <Loader />
                                                        ) : (
                                                            officeList &&
                                                            officeList.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <h6>{item.email ? item.email : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.businessUsername ? item.businessUsername : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.name ? item.name : "-"}</h6>
                                                                        </td>
                                                                        {/* <td>
                                                                            <h6>{item.businessCategory ? item.businessCategory : "-"}</h6>
                                                                        </td> */}
                                                                        <td>
                                                                            <h6>{item.npi1 ? item.npi1 : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.licenseNumber ? item.licenseNumber : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.phone1 ? item.phone1 : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.languageId ? displayLanguageList(officeList && item.languageId, languageList).toString() : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                className={`badge badge-${
                                                                                    item.statusId == STATUS_REQUSTED.Active ? "success" : item.statusId == STATUS_REQUSTED.Requested ? "info" : "danger"
                                                                                }`}
                                                                            >
                                                                                {Object.keys(STATUS_REQUSTED).find((key) => STATUS_REQUSTED[key] === item.statusId)}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {item.statusId == STATUS_REQUSTED.Requested ? (
                                                                                <></>
                                                                            ) : (
                                                                                <>
                                                                                    <button className="btn btn-warning btn-xs" onClick={() => handleView(item.id)}>
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button>
                                                                                    <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                                        <i className="fa fa-pencil"></i>
                                                                                    </button>
                                                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                            <i className="fa fa-trash-o "></i>
                                                                                        </button>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-lg-flex justify-content-between ">
                                                <div className="d-flex align-center">
                                                    {t("Total")} {officeList == null ? "0" : rowCount} {t("Office")}
                                                    {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                                </div>
                                                <div className="pagination-center">
                                                    <ReactPaginate
                                                        initialPage={0}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={0}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageLinkClassName="page-link"
                                                        breakLinkClassName="page-link"
                                                        nextLinkClassName="page-link"
                                                        previousLinkClassName="page-link"
                                                        pageClassName="page-item"
                                                        breakClassName="page-item"
                                                        nextClassName="page-item"
                                                        previousClassName="page-item"
                                                        previousLabel={t("Previous")}
                                                        nextLabel={t("Next")}
                                                        disableInitialCallback={true}
                                                        forcePage={currentPage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default OfficeForDoctorList;
