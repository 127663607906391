import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAYMENT_TYPE, PLAN_TYPE, ROLE_ID, STATUS, STATUS_PENDING, SUBSCRIPTION } from "../../helper/Constant";
import Button from "react-bootstrap/Button";
import Loader from "../../commoncomponents/Loader";

function ActivatePatient() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const [patientId, setPatientId] = useState("");
    const [statusId, setStatusId] = useState(STATUS_PENDING.Pending);
    const [roleId, setRoleId] = useState(ROLE_ID.PATIENT);
    let registrationFee = 30;
    const { t } = useTranslation();

    const [type, setType] = useState("");
    const [isSubscription, setIsSubscription] = useState(0);
    const [price, setPrice] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isPatientDataLoading, setIsPatientDataLoading] = useState(false);

    const fetchProfiledata = async () => {
        setIsPatientDataLoading(true);
        await axios
            .get(`${baseURL}/api/v1/profile`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setPatientId(res.data.data?.id);
                setStatusId(res.data.data?.statusId);
                setRoleId(res.data.data?.roleId);
                setIsPatientDataLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    setIsPatientDataLoading(false);
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchProfiledata();
        if (statusId == STATUS.Active && roleId == ROLE_ID.PATIENT) {
            navigate("/dashboard");
        }
    }, [statusId, roleId]);

    const onTypeChange = async (e) => {
        setType(e.target.value);
        setIsSubscription(e.target.value == PLAN_TYPE.Year || e.target.value == PLAN_TYPE.Month ? SUBSCRIPTION.Manual : isSubscription);
        if (e.target.value == PLAN_TYPE.Year || e.target.value == PLAN_TYPE.Month) {
            await axios
                .post(
                    `${baseURL}/api/v1/patient/plan/check`,
                    {
                        type: e.target.value,
                        isSubscription: e.target.value == PLAN_TYPE.Year || e.target.value == PLAN_TYPE.Month ? SUBSCRIPTION.Manual : isSubscription,
                        patientId: patientId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setPrice(res.data.data.price);
                })
                .catch((err) => {
                    if (err.response) {
                        setPrice(0);
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            setPrice(0);
        }
    };

    const onSubscriptionChange = async (e) => {
        setIsSubscription(e.target.value);
        if (type) {
            await axios
                .post(
                    `${baseURL}/api/v1/patient/plan/check`,
                    {
                        type: type,
                        isSubscription: e.target.value,
                        patientId: patientId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setPrice(res.data.data.price);
                })
                .catch((err) => {
                    if (err.response) {
                        setPrice(0);
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            setPrice(0);
        }
    };

    const onPaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handlePay = async () => {
        if (!type || type === "" || type === null) {
            toast.error(t(`Please select type`));
        } else if (!isSubscription || isSubscription === "" || isSubscription === null) {
            toast.error(t(`Please select subscription`));
        } else if (!paymentMethod || paymentMethod === "" || paymentMethod === null || paymentMethod === 0) {
            toast.error(t(`Please select payment method`));
        }
        if (roleId == ROLE_ID.PATIENT) {
            setIsLoading(true);
            await axios
                .post(
                    `${baseURL}/api/v1/patient/create-checkout-session`,
                    {
                        type: type,
                        isSubscription: isSubscription,
                        paymentType: paymentMethod,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    setIsLoading(false);
                    window.location.replace(res.data.data.url);
                })
                .catch((err) => {
                    setIsLoading(false);
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            toast.error(t(`Only Patient can buy plan`));
        }
    };

    return (
        <div>
            <div className="container-fluid login-style">
                <div className="login-wrapper row">
                    <div id="login" className="login loginpage col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4">
                        <div className="login-form-header" style={{ marginTop: "35%" }}>
                            <div className="login-header">
                                <h4 className="bold text-break">{t("Purchase Plan")}!</h4>
                                <h4 className="">
                                    <small className="text-dark">{t("Please Purchase your plan to complete your registration process")}.</small>
                                </h4>
                            </div>
                        </div>
                        <div className="box login">
                            <div className="content-body" style={{ paddingTop: "30px" }}>
                                {isPatientDataLoading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <form id="msg_validate" action="#" noValidate="novalidate" className="no-mb no-mt">
                                            <div className="row">
                                                <div className="justify-content-center row my-3">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Registration Fee")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="registrationFee" value={`$ ${registrationFee}`} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-lg-7 col-md-8 col-sm-12">
                                                            <div className="row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Membership Plan")} <span className="required"> * </span>
                                                                </label>
                                                                <div className="col-md-12">
                                                                    <select className="form-control col-md-3" name="type" value={type} onChange={onTypeChange}>
                                                                        {Object.keys(PLAN_TYPE).map((item, i) => {
                                                                            return (
                                                                                <option key={i} value={PLAN_TYPE[item]}>
                                                                                    {t(item)}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 col-md-4 col-sm-12">
                                                            <div className="row">
                                                                <label className="control-label col-md-12">{t("Price")}</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" className="form-control mt-0" name="price" value={`$${price ? price : 0}`} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {type == PLAN_TYPE.Month ? (
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Subscription")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input className="my-2 mx-3" type="radio" name="isSubscription" value="2" onChange={onSubscriptionChange} />
                                                                {t("Auto")}
                                                                <input className="my-2 mx-3" type="radio" name="isSubscription" value="1" onChange={onSubscriptionChange} />
                                                                {t("Manual")}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Payment")} <span className="required"> * </span>
                                                        </label>
                                                        <div className="col-md-12">
                                                            <input className="my-2 mx-3" type="radio" name="paymentMethod" value={PAYMENT_TYPE.Card} onChange={onPaymentMethodChange} />
                                                            {t("Pay With Card")}
                                                            <input className="my-2 mx-3 " type="radio" name="paymentMethod" value={PAYMENT_TYPE.STRIPE_ACH} onChange={onPaymentMethodChange} />
                                                            <span className="">{t("Pay with ACH")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="text-center">
                                            <Button variant="success" onClick={isLoading ? "" : paymentMethod == PAYMENT_TYPE.Card || paymentMethod == PAYMENT_TYPE.STRIPE_ACH ? handlePay : ""}>
                                                {isLoading ? (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    ></i>
                                                ) : (
                                                    t("Pay")
                                                )}
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActivatePatient;