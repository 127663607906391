import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL, frontURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE, SNS_TYPE, STATUS, STATUS_SEARCH } from "../../../helper/Constant";
import UpsertSns from "./UpsertSns";
import { useTranslation } from "react-i18next";
import { sliceString } from "../../../helper/helper";

function SnsList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [snsList, setSnsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        SNSlink: "",
        lastName: "",
        title: "",
        statusId: 1,
        description: "",
    });
    const [snsId, setSnsId] = useState("");
    const [filterValue, setFilterValue] = useState({
        title: "",
        SNSlink: "",
        type: "",
        statusId: "",
    });
    const [agreementInfo, setAgreementInfo] = useState({ preview: "", data: "" });

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENT ? "agent" : "";

    const fetchSnsdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/snslink/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    title: filterValue.title,
                    SNSlink: filterValue.SNSlink,
                    type: filterValue.type,
                    statusId: filterValue.statusId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSnsList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setSnsId(id);
        snsDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });

        if (e.target.name === "agreement") {
            const agreement = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            };

            const fileExtension = agreement.data.name.split(".").at(-1).toLowerCase();
            const allowedFileTypes = ["pdf", "jpg", "png", "gif"];
            if (!allowedFileTypes.includes(fileExtension)) {
                e.target.value = null;
                alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
            } else {
                setAgreementInfo(agreement);
            }
        }
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchSnsdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const snsDetail = async (snsId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/snslink/detail/${snsId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            SNSlink: "",
            title: "",
            statusId: 1,
            description: "",
        });
        setSnsId("");
        setIsView(false);
        setIsEdit(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this sns ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/admin/snslink/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchSnsdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleButtonClick = (SNSlink) => {
        const textToCopy = `${SNSlink}\n${frontURL}/signup?agent=${profileData.userName}`;
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                toast.success("Link for SNS copied!");
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };

    const handleTwitterClick = (SNSlink) => {
        const twUrls = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`https://gdenti.com/signup?agent=${profileData.userName}`)}`;
        window.open(twUrls, "_blank");

        setTimeout(() => {
            const twUrl2 = `https://twitter.com/intent/tweet?text=${encodeURIComponent(SNSlink)}`;
            window.open(twUrl2, "_blank");
        }, 500);
    };

    const handleKakaoClick = (SNSlink) => {
        const kakaoChatUrl = `https://gdenti.com/signup?agent=${profileData.userName}`;
        const kakaoChatUrl2 = `https://gdenti.com`;

        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: "SNS Link: " + SNSlink,
              description: "Click 'Sign Up' button to join a Gdenti Plan",
              imageUrl:
                '',
            //   imageWidth: 1000,
            //   imageHeight: 500,
              link: {
                mobileWebUrl: kakaoChatUrl2,
                webUrl: kakaoChatUrl2,
              },
            },
            itemContent: {
              profileText: 'GDenti',
              profileImageUrl:
                'https://gdenti.com/assets/images/logo-folded.png',
            },
            buttons: [
              {
                title: 'Sign Up',
                link: {
                  mobileWebUrl: kakaoChatUrl,
                  webUrl: kakaoChatUrl,
                },
              },
            ],
          });
    };

    const handleFacebookClick = (SNSlink) => {
        const fbUrls = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://gdenti.com/signup?agent=${profileData.userName}`)}`;
        window.open(fbUrls, "_blank");

        setTimeout(() => {
            const fbUrl2 = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(SNSlink)}`;
            window.open(fbUrl2, "_blank");
        }, 500);
    };

    useEffect(() => {
        fetchSnsdata();
    }, [offset, filterValue.type, filterValue.statusId]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage GDenti")} </h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Sns List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Sns List")}</strong>
                                </li>
                            )}
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : isView ? t("View") : t("Add")} {"Sns"}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <UpsertSns
                            isView={isView}
                            editLoader={editLoader}
                            handleClose={handleClose}
                            agreementInfo={agreementInfo}
                            fetchSnsdata={fetchSnsdata}
                            snsId={snsId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isEdit={isEdit}
                            show={show}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-1 col-md-1 col-sm-12">
                                            {profileData.roleId == ROLE_ID.ADMIN ? (
                                                <h2 className="title pull-left">
                                                    <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                        <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                    </button>
                                                </h2>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="col-lg-11 col-md-11 col-sm-12  d-flex justify-content-end">
                                            <div className="row mx-2">
                                                <div className={`${profileData.roleId == ROLE_ID.ADMIN ? "col-lg-3 col-md-3 col-sm-3 col-xs-12" : "col-lg-4 col-md-4 col-sm-4 col-xs-12 "} `}>
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="title"
                                                            value={filterValue.title}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search title")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`${profileData.roleId == ROLE_ID.ADMIN ? "col-lg-3 col-md-3 col-sm-3 col-xs-12" : "col-lg-4 col-md-4 col-sm-4 col-xs-12 "} `}>
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="type"
                                                            value={filterValue.SNSlink}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search SNS link")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`${profileData.roleId == ROLE_ID.ADMIN ? "col-lg-3 col-md-3 col-sm-3 col-xs-12" : "col-lg-4 col-md-4 col-sm-4 col-xs-12 "} `}>
                                                    <div className="title">
                                                        <select className="form-control" name="type" value={filterValue.type} onChange={onFilterChangeValue}>
                                                            {Object.keys(SNS_TYPE).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={SNS_TYPE[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                {profileData.roleId == ROLE_ID.ADMIN ? (
                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <div className="title">
                                                            <select className="form-control" name="statusId" value={filterValue.statusId} onChange={onFilterChangeValue}>
                                                                {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={STATUS_SEARCH[item]}>
                                                                            {t(item)}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${isLoading || !snsList || (snsList && !snsList.length) ? "" : "table-striped"} table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("No")}</th>
                                                        <th>{t("Title")}</th>
                                                        <th>{t("Type")}</th>
                                                        <th>{t("Description")}</th>
                                                        <th>{t("Link for SNS")}</th>
                                                        {profileData.roleId == ROLE_ID.ADMIN ? (
                                                            <>
                                                                <th>{t("Status")}</th>
                                                                <th>{t("Action")}</th>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <th>{t("Copy")}</th>
                                                                <th>{t("Share")}</th>
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!snsList || (snsList && !snsList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        snsList &&
                                                        snsList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{item.social_no ? item.social_no : "-"}</h6>
                                                                    </td>
                                                                    <td
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            snsDetail(item.id);
                                                                            setIsView(true);
                                                                            setShow(true);
                                                                        }}
                                                                    >
                                                                        <h6>
                                                                            <u>{item.title ? item.title : "-"}</u>
                                                                        </h6>
                                                                    </td>
                                                                    <td>{Object.keys(SNS_TYPE).find((key) => SNS_TYPE[key] === item.type)}</td>
                                                                    <td>
                                                                        <h6 data-toggle="tooltip" title={item.description}>
                                                                            {item.description ? sliceString(item.description, 30) : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 data-toggle="tooltip" title={item.SNSlink}>
                                                                            <a href={item.SNSlink} target="_blank">
                                                                                {item.SNSlink ? sliceString(item.SNSlink, 30) : "-"}
                                                                            </a>
                                                                        </h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <>
                                                                            <td>
                                                                                <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                                    {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                                    <i className="fa fa-pencil"></i>
                                                                                </button>
                                                                                <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                    <i className="fa fa-trash-o"></i>
                                                                                </button>
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <td>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary btn-xs mx-2"
                                                                                    onClick={() => {
                                                                                        handleButtonClick(item.SNSlink);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    className="btn btn-primary btn-xs btn-round"
                                                                                    style={{ height: "35px", width: "35px" }}
                                                                                    // onClick={() => {
                                                                                    // const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                                                                                    //     `SNS Link: https://gdenti.com/signup?agent=${profileData.userName},${item.description}`
                                                                                    // )}`;

                                                                                    //     window.open(twitterUrl, "_blank");
                                                                                    // }}
                                                                                    onClick={() => {
                                                                                        handleTwitterClick(item.SNSlink);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-twitter mt-1"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-primary btn-xs mx-2 btn-round"
                                                                                    style={{ background: "#1877f2", height: "35px", width: "35px" }}
                                                                                    // onClick={() => {
                                                                                    //     const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                                                    //         `https://gdenti.com/signup?agent=${profileData.userName}`
                                                                                    //     )}`;

                                                                                    //     window.open(fbUrl, "_blank");
                                                                                    // }}
                                                                                    onClick={() => {
                                                                                        handleFacebookClick(item.SNSlink);
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-facebook mt-1"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-warning btn-xs btn-round"
                                                                                    style={{ height: "35px", width: "35px" }}
                                                                                    // onClick={() => {
                                                                                    //     // const kakaoChatUrl = `https://pf.kakao.com/_kDxks/chat`;
                                                                                    //     const kakaoChatUrl = `https://story.kakao.com/s/share?url=${encodeURIComponent(
                                                                                    //         `https://gdenti.com/signup?agent=${profileData.userName}`)}&text=${item.description}
                                                                                    //     `;

                                                                                    //     window.open(kakaoChatUrl, "_blank");
                                                                                    // }}
                                                                                    onClick={() => {
                                                                                        handleKakaoClick(item.SNSlink);
                                                                                    }}
                                                                                >
                                                                                    <svg
                                                                                        fill="#000000"
                                                                                        width="800px"
                                                                                        height="800px"
                                                                                        viewBox="0 0 24 24"
                                                                                        role="img"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        style={{ height: "20px", width: "20px" }}
                                                                                    >
                                                                                        <path d="M22.125 0H1.875C.839 0 0 .84 0 1.875v20.25C0 23.161.84 24 1.875 24h20.25C23.161 24 24 23.16 24 22.125V1.875C24 .839 23.16 0 22.125 0zM12 18.75c-.591 0-1.17-.041-1.732-.12-.562.396-3.813 2.679-4.12 2.722 0 0-.125.049-.232-.014s-.088-.229-.088-.229c.032-.22.843-3.018.992-3.533-2.745-1.36-4.57-3.769-4.57-6.513 0-4.246 4.365-7.688 9.75-7.688s9.75 3.442 9.75 7.688c0 4.245-4.365 7.687-9.75 7.687zM8.05 9.867h-.878v3.342c0 .296-.252.537-.563.537s-.562-.24-.562-.537V9.867h-.878a.552.552 0 0 1 0-1.101h2.88a.552.552 0 0 1 0 1.101zm10.987 2.957a.558.558 0 0 1 .109.417.559.559 0 0 1-.219.37.557.557 0 0 1-.338.114.558.558 0 0 1-.45-.224l-1.319-1.747-.195.195v1.227a.564.564 0 0 1-.562.563.563.563 0 0 1-.563-.563V9.328a.563.563 0 0 1 1.125 0v1.21l1.57-1.57a.437.437 0 0 1 .311-.126c.14 0 .282.061.388.167a.555.555 0 0 1 .165.356.438.438 0 0 1-.124.343l-1.282 1.281 1.385 1.835zm-8.35-3.502c-.095-.27-.383-.548-.75-.556-.366.008-.654.286-.749.555l-1.345 3.541c-.171.53-.022.728.133.8a.857.857 0 0 0 .357.077c.235 0 .414-.095.468-.248l.279-.73h1.715l.279.73c.054.153.233.248.468.248a.86.86 0 0 0 .357-.078c.155-.071.304-.268.133-.8l-1.345-3.54zm-1.311 2.443.562-1.596.561 1.596H9.376zm5.905 1.383a.528.528 0 0 1-.539.516h-1.804a.528.528 0 0 1-.54-.516v-3.82c0-.31.258-.562.575-.562s.574.252.574.562v3.305h1.195c.297 0 .54.231.54.515z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {snsList == null ? "0" : rowCount} {t("SNS Link")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default SnsList;
