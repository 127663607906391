import React, { useState, useRef, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import Footer from "./Footer";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL, treatmentTypeId } from "../../helper/Config";
import { checkEmail } from "../../helper/Validator";
import { useTranslation } from "react-i18next";
import VideoModal from "./VideoModal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import "./carousel.css";
import ZoomMeetingModal from "./ZoomMeetingModal";

const Home = () => {
    const navigate = useNavigate();
    const [mailData, setMailData] = useState({
        email: "",
        name: "",
        message: "",
    });

    const { t } = useTranslation();
    const languageShortName = localStorage.getItem("languageShortName");

    const [isLoading, setIsLoading] = useState(false);

    const divRef = useRef(null);
    const [clipPath, setClipPath] = useState("");

    const [linePosition, setLinePosition] = useState([]); //Initial Line position
    const [lineHeight, setLineHeight] = useState([]); //Initial Line Height
    const [lineWidth, setLineWidth] = useState([]); //Initial Line Width

    let verticalHeight = null;
    let verticalWidth = null;
    let verticalPosition = null;

    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [height, setHeight] = useState(0);
    const handleClick = (title, link, height) => {
        if (link !== "") {
            setTitle(title);
            setVideoLink(link);
            setHeight(height);
            setShow(true);
        }
    };

    const handleClose = () => {
        setTitle("");
        setVideoLink("");
        setHeight(0);
        setShow(false);
    };

    function handleVerticalImageLoad() {
        const divWidth = divRef.current.offsetWidth;

        verticalHeight = divWidth * 0.235;
        verticalWidth = divWidth * 0.015;
        verticalPosition = divWidth * 0.5;

        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setLinePosition(verticalPosition);
        setClipPath(`polygon(0 0, ${verticalPosition}px 0, ${verticalPosition}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    }

    const handleMouseMove = (event) => {
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const x = event.clientX - rect.left;
        setLinePosition(x);
        verticalHeight = rect.width * 0.235;
        verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // prevent scrolling on touch devices
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const touch = event.touches[0];
        const x = touch.clientX - rect.left;
        setLinePosition(x);
        const verticalHeight = rect.width * 0.235;
        const verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    /////////////////////////// carousel ////////////////////////////
    const dotsarray = Array.from({ length: 3 });
    const imageData = [
        {
            text: t("Paying for dental services was always a headache since..."),
            video: "/assets/images/Patient_E_Lee.mp4",
            alt: "image1",
            url: "/assets/images/home/dental-care/patient_elliot_lee.jpg",
            author: "Patricia Ramos",
            city: "New York",
            height: 100,
        },
        {
            text: t("5 star membership plan! GDenti is a must have program!!"),
            video: "/assets/images/Patient_H_Lee.mp4",
            alt: "image2",
            url: "/assets/images/home/dental-care/patient_dh_lee.jpg",
            author: "Henry Parker",
            city: "New York",
            height: 100,
        },
        {
            text: t("I have always been able to talk to the doctor..."),
            video: "",
            alt: "image3",
            url: "/assets/images/home/dental-care/carousel-1.png",
            author: "Olivia Skyler",
            city: "Los Angeles",
            height: 100,
        },
        {
            text: t("Paying for dental services was always a headache since..."),
            video: "/assets/images/Patient_E_Lee.mp4",
            alt: "image1",
            url: "/assets/images/home/dental-care/patient_elliot_lee.jpg",
            author: "Patricia Ramos",
            city: "New York",
            height: 100,
        },
        {
            text: t("5 star membership plan! GDenti is a must have program!!"),
            video: "/assets/images/Patient_H_Lee.mp4",
            alt: "image2",
            url: "/assets/images/home/dental-care/patient_dh_lee.jpg",
            author: "Henry Parker",
            city: "New York",
            height: 100,
        },
        {
            text: t("I have always been able to talk to the doctor..."),
            video: "",
            alt: "image13",
            url: "/assets/images/home/dental-care/carousel-1.png",
            author: "Olivia Skyler",
            city: "Los Angeles",
            height: 100,
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef(null);

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };

    const handleSlideClick = (index) => {
        setActiveIndex(index);
        sliderRef.current.slickGoTo(index);
    };

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        arrows: false,
        beforeChange: (current, next) => handleSlideChange(next),
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [doctorActiveIndex, setDoctorActiveIndex] = useState(0);
    const doctorCarousel = [
        {
            alt: "image1",
            url: "/assets/images/home/dental-care/doctor_Alex_Musheyev.jpg",
            video: "/assets/images/doctor_Alex Musheyev.mp4",
            height: 45,
        },
        {
            alt: "image32",
            url: "/assets/images/home/dental-care/doctor_Geraldo_Polanco.jpg",
            video: "/assets/images/doctor_Gerald_Polanco.mp4",
            height: 45,
        },
        {
            alt: "image3",
            url: "/assets/images/home/dental-care/doctor_HR_Lee.jpg",
            video: "/assets/images/doctor_HR_Lee.mp4",
            height: 100,
        },
        {
            alt: "image4",
            url: "/assets/images/home/dental-care/doctor_Daniel_Kim.png",
            video: "/assets/images/doctor_Daniel_Kim.mp4",
            height: 100,
        },
    ];

    const handleDoctorSlideChange = (index) => {
        setDoctorActiveIndex(index);
    };

    const doctorSettings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        arrows: false,
        beforeChange: (current, next) => handleDoctorSlideChange(next),
    };

    const [agentActiveIndex, setAgentActiveIndex] = useState(0);
    const agentCarousel = [
        {
            alt: "image1",
            url: "/assets/images/home/home/agent.png",
        },
        {
            alt: "image2",
            url: "/assets/images/home/home/agent.png",
        },
    ];

    const handleAgentSlideChange = (index) => {
        setAgentActiveIndex(index);
    };

    const agentSettings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        arrows: false,
        beforeChange: (current, next) => handleAgentSlideChange(next),
    };
    const [treatmentGroup1, setTreatmentGroup1] = useState([]);
    const [treatmentGroup2, setTreatmentGroup2] = useState([]);

    const fetchTreatmentTypeGroupdata = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/treatment-type-group/list?isPagination=false`,
                {
                    treatmentTypeId: treatmentTypeId,
                    limited: 100,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                const halfIndex = Math.ceil(res.data.data?.length / 2);
                setTreatmentGroup1(res.data.data.slice(0, halfIndex));
                setTreatmentGroup2(res.data.data.slice(halfIndex));
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    useEffect(() => {
        fetchTreatmentTypeGroupdata();
    }, []);

    /////////////////////////// zoom-meeting ////////////////////////////

    const [zoomShow, setZoomShow] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        name: "",
        phone: "",
        date: "",
        time: "",
        notes: "",
    });
    const handleZoomClose = () => {
        setInputValue({
            email: "",
            name: "",
            phone: "",
            date: "",
            time: "",
            notes: "",
        });
        setZoomShow(false);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    return (
        <>
            <style jsx>{`
                li::marker {
                    color: #505458 !important;
                    font-size: 1rem !important;
                }
                .text-warning {
                    color: #ff7020 !important;
                }
                .text-primary {
                    color: #4fb3ae !important;
                }
                .text-blue {
                    color: #3a9bdc !important;
                }
                .btn-blue {
                    background: #3a9bdc !important;
                }
                .btn-primary {
                    background: #4fb3ae !important;
                }
                .btn-info {
                    background: #16558f !important;
                }
                }
            `}</style>

            <Header />
            {show ? <VideoModal show={show} title={title} videoLink={videoLink} height={height} handleClose={handleClose} /> : ""}
            <section className="hero-section-custom p-5">
                <div className="container">
                    <div className="col-lg-6">
                        <h1 className="bold" data-aos="fade-right">
                            {t("Need dental care but traditional")}
                            <br />
                            {t("insurance is just too expensive?")}{" "}
                        </h1>
                        <p className="text-black" data-aos="fade-left">
                            {t("GDenti Hybrid Membership brings you")} <br /> {t("quality care for just")}{" "}
                            <span className="bold text-blue" style={{ fontSize: "20px" }}>
                                $11.99{" "}
                            </span>{" "}
                            {t("a month and up")} <br />
                            to{" "}
                            <span className="bold text-blue" style={{ fontSize: "20px" }}>
                                {" "}
                                70% off
                            </span>{" "}
                            {t("on necessary treatments")}.
                        </p>
                        <Link to="/signup" className="bold text-blue text-decoration-underline">
                            {t("Enroll As Patient")}
                        </Link>
                        {/* <h3 className="bold" data-aos="fade-right">
                            {t("Get your first month free!")}
                        </h3> */}
                        <p className="text-black" data-aos="fade-left">
                            {t("Sign up today and unlock more discounts.")}
                        </p>
                        {/* <div className="col-lg-4 col-sm-8 p-0" data-aos="fade-right">
                            <div className="d-flex">
                                <input type="text" className="form-control input-radius-custom" placeholder="Unlock the Offer" />
                                <button className="btn btn-blue mx-2 input-radius-custom text-white" onClick={() => navigate("/signup")}>{t("GO")}</button>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center" data-aos="fade-right">
                        <img src="/assets/images/home/home/GDenti_Home_Outstanding.jpg" alt="" height="384px" width="390px" className="doctor-image" />
                    </div> */}
                    {/* <img src="/assets/images/home/home/dentist.png" alt="" height="384px" width="357px" className="doctor-image" /> */}
                </div>
            </section>
            <section className="p-5" style={{ background: "#16558F" }}>
                <div className="container">
                    <div className="text-center">
                        <h1 className="text-info bold" data-aos="fade-up">
                            {t("A Dentist Approved")}
                        </h1>
                        <h3 className="text-white" data-aos="fade-down">
                            {t("Hybrid Dental Membership Plan for Patients of all ages.")}
                        </h3>
                    </div>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
                            <div className="card" style={{ width: "25rem" }}>
                                <div className="card-body">
                                    <div className="text-center">
                                        <div className="d-flex justify-content-center align-items-baseline">
                                            <h1 className="bold m-1 text-decoration-line-through text-warning">$60</h1>
                                            <p className="text-warning m-1"> /mo</p>
                                        </div>
                                        <h5 className="m-0">{t("Traditional Insurance")}</h5>
                                        <div className="d-flex justify-content-center align-items-baseline">
                                            <h1 className="text-primary bold m-1">$11.99</h1>
                                            <p className="text-black m-1">/{t("Per Month")}</p>
                                        </div>
                                        <h5 className="m-0 bold" style={{ color: "#3A9BDC" }}>
                                            {t("GDenti Plans")}
                                        </h5>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <hr className="my-2" style={{ border: "1px solid #3A9BDC", width: "200px" }} />
                                    </div>
                                    <div className="d-md-flex justify-content-between">
                                        <div>
                                            {treatmentGroup1 &&
                                                treatmentGroup1.map((item, i) => {
                                                    return (
                                                        <div className="mb-3" key={i}>
                                                            <i className="fa fa-circle text-green-custom mt-1 me-3" aria-hidden="true"></i>
                                                            <span className="text-number-color-custom">{item.name}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div>
                                            {treatmentGroup2 &&
                                                treatmentGroup2.map((item, i) => {
                                                    return (
                                                        <div className="mb-3" key={i}>
                                                            <i className="fa fa-circle text-green-custom mt-1 me-3" aria-hidden="true"></i>
                                                            <span className="text-number-color-custom">{item.name}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                            <h1 className="text-blue bold">
                                {t("No Limits, No Stress,")} <br />
                                {t("Just Good Coverage!")}
                            </h1>
                            <p className="mt-30" style={{ lineHeight: "1.5rem" }}>
                                {t("GDenti offers patients a reliable,")} <br />
                                {t("comprehensive dental membership plan that")} <br />
                                {t("won't hurt your bank account. At a fraction of")}
                                <br />
                                {t("the cost and without the many restrictions that")} <br />
                                {t("traditional coverage brings, you can")} <br />
                                {t("confidently pick a plan that fits your budget!")}
                            </p>
                            <button className="btn btn-blue text-white input-radius-custom bold mt-30 p-2 px-4">
                                <Link to="/services#whyGDentiCare" className="text-white">
                                    {t("More Plans & Services")}
                                </Link>
                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-5" style={{ background: "#F3ECE7" }}>
                <div className="container ">
                    <h1 className="bold text-center" data-aos="fade-up">
                        {t("Learn More About")} <span className="text-info">GDenti</span>
                    </h1>

                    <div className="p-5" data-aos="fade-right">
                        <img src="/assets/images/home/home/ceo-image2.png" alt="" className="w-100 h-100" />
                    </div>
                    <div className="text-center">
                        <h3 className="bold quality-text-custom">{t("Choose your language")}</h3>
                    </div>
                    <div className="d-md-flex justify-content-evenly mt-4" data-aos="fade-left">
                        <div className="text-center cursor-pointer" onClick={() => handleClick("Learn More About GDenti", "/assets/images/G_Denti_CEO_Eng.mp4", 100)}>
                            <img src="/assets/images/home/home/english.png" alt="english" className="mx-2" style={{ height: "120px", width: "120px" }} />
                            <h4 className="text-black mt-2">English</h4>
                        </div>
                        <div className="text-center cursor-pointer" onClick={() => handleClick("Learn More About GDenti", "/assets/images/G_Denti_CEO_Spa.mp4", 100)}>
                            <img src="/assets/images/home/home/spanish.png" alt="spanish" className="mx-2" style={{ height: "120px", width: "120px" }} />
                            <h4 className="text-black mt-2">Spanish</h4>
                        </div>
                        {/* <div className="text-center cursor-pointer" onClick={() => handleClick("Learn More About GDenti", "/assets/images/G_Denti_CEO_Eng.mp4", 100)}>
                            <img src="/assets/images/home/home/chinese.png" alt="chinese" className="mx-2" style={{ height: "120px", width: "120px" }} />
                            <h4 className="text-black mt-2">Chinese</h4>
                        </div> */}
                        <div className="text-center cursor-pointer" onClick={() => handleClick("Learn More About GDenti", "/assets/images/G_Denti_CEO_Kor.mp4", 100)}>
                            <img src="/assets/images/home/home/korean.png" alt="korean" className="mx-2" style={{ height: "120px", width: "120px" }} />
                            <h4 className="text-black mt-2">Korean</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div
                    ref={divRef}
                    className="hero-get-in-toch-custom"
                    style={{ cursor: "grab", position: "relative", width: "100%", height: `${lineHeight}px` }}
                    onMouseMove={handleMouseMove}
                    onTouchStart={handleTouchMove}
                    onTouchMove={handleTouchMove}
                >
                    <img src="/assets/images/home/home_main_left.png" alt="before" style={{ position: "absolute", left: 0, top: 0, zIndex: 2, width: "100%", height: "auto", clipPath }} />
                    <img src="/assets/images/home/home_main_right.png" alt="after" style={{ position: "absolute", left: 0, top: 0, zIndex: 1, width: "100%", height: "auto" }} />
                    <img
                        src="/assets/images/home/circle_line_icon.png"
                        alt="Vertical Line"
                        style={{ position: "absolute", top: 0, left: `${linePosition}px`, transform: "translateX(-50%)", width: `${lineWidth}px`, height: `${lineHeight}px`, zIndex: 3 }}
                        onLoad={handleVerticalImageLoad}
                    />
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <div className="text-center">
                        <h1 className="bold" data-aos="fade-right">
                            {languageShortName == "ko" ? (
                                <>
                                    {t("What Patients")} {t("About Us")}
                                    <span className="text-blue"> {t("Say")} </span>
                                </>
                            ) : (
                                <>
                                    {t("What Patients")} <span className="text-blue">{t("Say")} </span>
                                    {t("About Us")}
                                </>
                            )}
                        </h1>
                        <div className="carousel-height mt-3">
                            <p className="mx-lg-5 px-lg-5" data-aos="fade-left">
                                {imageData[activeIndex].text}
                                <br />
                                <span className="bold">- {imageData[activeIndex].author} </span>({imageData[activeIndex].city})
                            </p>
                        </div>
                        <Slider {...settings} ref={sliderRef}>
                            {imageData.map((img, i) => (
                                <div
                                    key={i}
                                    className={i === activeIndex ? "slide activeSlide" : "slide"}
                                    onClick={i === activeIndex ? () => handleClick("What Patients Say", img.video, img.height) : () => {}}
                                >
                                    <img src={img.url} alt={img.alt} onClick={() => handleSlideClick(i)} className="cursor-poiner" height="400px" width="300px" />
                                </div>
                            ))}
                        </Slider>

                        <div className="d-flex justify-content-center mt-25">
                            {dotsarray.map((_, i) => {
                                return (
                                    <i
                                        className="fa fa-circle mt-1 me-1"
                                        aria-hidden="true"
                                        style={{ fontSize: "0.7rem", color: i === activeIndex || i === activeIndex - 3 ? "#145073" : "#A9D5EE" }}
                                    ></i>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-5 pt-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
                            <Slider {...doctorSettings}>
                                {doctorCarousel.map((img, i) => (
                                    <div key={i} className="d-flex justify-content-center" onClick={i === doctorActiveIndex ? () => handleClick("What Doctors Say", img.video, img.height) : () => {}}>
                                        <img src={img.url} alt={img.alt} className="cursor-poiner doctor-image" height="484px" width="417px" />
                                    </div>
                                ))}
                            </Slider>
                            {/* <img src="/assets/images/home/home/dentist.png" alt="" height="484px" width="417px" className="doctor-image" /> */}
                            <div className="d-flex justify-content-center mt-25">
                                {doctorCarousel.map((img, i) => {
                                    return <i className="fa fa-circle mt-1 me-1" aria-hidden="true" style={{ fontSize: "0.7rem", color: i === doctorActiveIndex ? "#145073" : "#A9D5EE" }}></i>;
                                })}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-left">
                            <h1 className="text-blue bold">{t("GDenti For Dentists")}</h1>
                            <h3 className="bold">{t("Optimizing Dental Care one Smile at a time.")}</h3>
                            <h4 style={{ lineHeight: "1.8rem" }}>
                                {t("With GDenti Hybrid Memberships, you can offer and affordable...")} <br />
                                <ul>
                                    <li>{t("Bypass tedious claim paperwork")}</li>
                                    <li>{t("Benefit from cross promotional marketing")}</li>
                                    <li>{t("Be at the center of targeted campaigns within your radius.")}</li>
                                    <li>{t("Receive content production")}</li>
                                    <li>{t("Obtain dedicated Support from our representatives and specialists & More")}</li>
                                </ul>
                            </h4>
                            <button className="btn btn-primary input-radius-custom bold mt-30 p-3 px-4" onClick={() => navigate("/authDoctor/signup")}>
                                {t("Enroll As Dentist")}
                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
                            <h1 className="bold">
                                {languageShortName == "ko" ? (
                                    <>
                                        <span className="text-blue">{t("GDenti agent")} </span>
                                        {t("Interested in becoming a")}? <br />
                                    </>
                                ) : (
                                    <>
                                        {t("Interested in becoming a")} <br />
                                        <span className="text-blue">{t("GDenti agent")}?</span>
                                    </>
                                )}
                            </h1>
                            <h4 style={{ lineHeight: "1.8rem" }}>
                                {t("Our lucrative membership program empowers agents to onboard new patients and grow earning potential.")} <br />
                                {t("Uncapped commissions and an easy enrollment process are just two of the many...")} <br />
                                {t("Enrollment is free and best of all, our team is readily available to offer training and support.")}
                            </h4>
                            <button className="btn btn-info text-white input-radius-custom bold mt-30 p-3 px-4" onClick={() => navigate("/authAgent/signup")}>
                                {t("Become An Agent")}
                                <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mt-10" data-aos="fade-left">
                            <Slider {...agentSettings}>
                                {agentCarousel.map((img, i) => (
                                    <div key={i} className="d-flex justify-content-center">
                                        <img src={img.url} alt={img.alt} className="cursor-poiner doctor-image" height="484px" width="417px" />
                                    </div>
                                ))}
                            </Slider>
                            {/*  <img src="/assets/images/home/home/agent.png" alt="" height="484px" width="417px" className="doctor-image" />*/}
                            <div className="d-flex justify-content-center mt-25">
                                {agentCarousel.map((img, i) => {
                                    return <i className="fa fa-circle mt-1 me-1" aria-hidden="true" style={{ fontSize: "0.7rem", color: i === agentActiveIndex ? "#145073" : "#A9D5EE" }}></i>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p-5">
                <div className="container">
                    <h1 className="text-center bold">{t("Tutorials")}</h1>
                    <div className="row d-flex justify-content-center pt-5 px-lg-5 mx-lg-5">
                        <div className="col-lg-4 col-md-4 col-sm-12 d-sm-flex justify-content-sm-center d-lg-flex justify-content-end" data-aos="fade-up">
                            <div className="card" style={{ width: "250px" }}>
                                <div className="card-body">
                                    <div className="text-center">
                                        <img className="position-relative" src="/assets/images/home/home/tutorial1.png" alt="" height="45px" width="45px" />
                                        <img className="position-absolute" src="/assets/images/home/home/Ellipse 1.png" alt="" height="45px" width="45px" style={{ left: "81px", top: "30px" }} />
                                        <h3 className="text-blue bold uppercase">{t("Patient")}</h3>
                                        <p className="text-black">{t("guide")}</p>
                                        <button
                                            className="btn p-26-20 input-radius-custom mt-4"
                                            style={{ border: "1px solid #3A9BDC", background: "transparent", boxShadow: "0px 1px 2px 1px #3a9bdc57" }}
                                            onClick={() => handleClick(t("Patient"), "/assets/images/G_Denti_patient_guide.mp4", 100)}
                                        >
                                            {t("Watch Now")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 d-sm-flex justify-content-sm-center d-lg-flex justify-content-center" data-aos="fade-down">
                            <div className="card" style={{ width: "250px" }}>
                                <div className="card-body">
                                    <div className="text-center">
                                        <img className="position-relative" src="/assets/images/home/home/tutorial2.png" alt="" height="45px" width="45px" />
                                        <img className="position-absolute" src="/assets/images/home/home/Ellipse 2.png" alt="" height="45px" width="45px" style={{ left: "81px", top: "30px" }} />

                                        <h3 className="text-blue bold uppercase">{t("Dentist")}</h3>
                                        <p className="text-black">{t("guide")}</p>
                                        <button
                                            className="btn p-26-20 input-radius-custom mt-4"
                                            style={{ border: "1px solid #3A9BDC", background: "transparent", boxShadow: "0px 1px 2px 1px #3a9bdc57" }}
                                            onClick={() => handleClick(t("Doctor"), "/assets/images/G_Denti_dentist_guide.mp4", 100)}
                                        >
                                            {t("Watch Now")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 d-sm-flex justify-content-sm-center d-lg-flex justify-content-start" data-aos="fade-up">
                            <div className="card" style={{ width: "250px" }}>
                                <div className="card-body">
                                    <div className="text-center">
                                        <img className="position-relative" src="/assets/images/home/home/tutorial3.png" alt="" height="45px" width="45px" />
                                        <img className="position-absolute" src="/assets/images/home/home/Ellipse 3.png" alt="" height="45px" width="45px" style={{ left: "81px", top: "30px" }} />

                                        <h3 className="text-blue bold uppercase">{t("Agent")}</h3>
                                        <p className="text-black">{t("guide")}</p>
                                        <button
                                            className="btn p-26-20 input-radius-custom mt-4"
                                            style={{ border: "1px solid #3A9BDC", background: "transparent", boxShadow: "0px 1px 2px 1px #3a9bdc57" }}
                                            onClick={() => handleClick(t("Agent"), "/assets/images/G_Denti_agent_agency_guide.mp4", 100)}
                                        >
                                            {t("Watch Now")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 bg-getintouch-custom">
                <div className="container">
                    <div className="col-lg-12 col-md-12 col-xs-12">
                        <div className="col-lg-6 col-md-6 col-xs-12 get-intouch-custom" data-aos="fade-left">
                            <h1 className="fw-bolder text-dark">
                                {languageShortName == "ko" ? (
                                    <>
                                        <span className="quality-text-custom">{t("Touch2")}</span>
                                        <span>{t("Get in2")}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>{t("Get in2")} </span>
                                        <span className="quality-text-custom">{t("Touch2")}</span>
                                    </>
                                )}
                            </h1>
                            <p>{t("Submit this form and a GDenti representative will get back to you promptly.")}</p>
                            <form className="mt-4">
                                <div className="form-group">
                                    <label>{t("Name")}</label>
                                    <input
                                        type="text"
                                        value={mailData && mailData.name}
                                        className="form-control info-input-custom"
                                        placeholder={t("Enter your name")}
                                        onChange={(e) => setMailData({ ...mailData, name: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t("Email")}</label>
                                    <input
                                        type="email"
                                        value={mailData && mailData.email}
                                        className="form-control info-input-custom"
                                        placeholder={t("Enter your email")}
                                        onChange={(e) => setMailData({ ...mailData, email: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t("Messages")}</label>
                                    <textarea
                                        value={mailData && mailData.message}
                                        className="form-control info-input-custom"
                                        placeholder={t("Enter your message")}
                                        rows="3"
                                        onChange={(e) => setMailData({ ...mailData, message: e.target.value })}
                                    ></textarea>
                                </div>
                                <ZoomMeetingModal show={zoomShow} inputValue={inputValue} setInputValue={setInputValue} handleClose={handleZoomClose} onChangeValue={onChangeValue} />
                                <div className="form-group">
                                    <button
                                        className="btn my-1 text-white btn-getintouch-custom"
                                        onClick={
                                            isLoading
                                                ? (e) => {
                                                      e.preventDefault();
                                                  }
                                                : async (e) => {
                                                      e.preventDefault();
                                                      if (!mailData.email) {
                                                          toast.error(`Please enter email`);
                                                      } else if (mailData.email && checkEmail(mailData.email) == false) {
                                                          toast.error(`Please enter valid email`);
                                                      } else if (!mailData.name) {
                                                          toast.error(`Please enter name`);
                                                      } else if (!mailData.message) {
                                                          toast.error(`Please enter message`);
                                                      } else {
                                                          setIsLoading(true);
                                                          await axios
                                                              .post(`${baseURL}/api/v1/gdental/getintouch`, {
                                                                  email: mailData.email,
                                                                  name: mailData.name,
                                                                  text: mailData.message && mailData.message.replaceAll("\n", "<br />"),
                                                              })
                                                              .then(async (res) => {
                                                                  setMailData({ email: "", name: "", message: "" });
                                                                  setIsLoading(false);
                                                                  toast.success(res.data.message);
                                                              })
                                                              .catch((err) => {
                                                                  setIsLoading(false);
                                                                  toast.error(err.response.data.message);
                                                              });
                                                      }
                                                  }
                                        }
                                    >
                                        <span className="p-3">
                                            {isLoading ? (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                ></i>
                                            ) : (
                                                t("Send")
                                            )}
                                        </span>
                                    </button>
                                    {/* <button
                                        className="btn my-1 text-white btn-getintouch-custom btn-primary mx-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setZoomShow(true);
                                        }}
                                    >
                                        <span className="p-3">Request Zoom-Meeting</span>
                                    </button> */}
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12" data-aos="fade-right">
                            <img src="/assets/images/home/dental-care/sydneyimg.png" alt="" height="100%" width="100%" className="" />
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <Footer />
        </>
    );
};

export default Home;
