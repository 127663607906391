import Modal from "react-bootstrap/Modal";
import DocumentsTable from "../../managePatient/DocumentsTable";

function DocumentModal(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Progress Documemt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocumentsTable isView={props.isView} type="Attachment" title="Attachment" isFromInvester={true} progressId={props.progressId} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DocumentModal;
