import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkGoogleLink, checkPhNo } from "../../../helper/Validator";
import { ROLE_ID, STATUS } from "../../../helper/Constant";
import Loader from "../../../commoncomponents/Loader";
import { dateAPIFormate } from "../../../helper/dateFormates";
import GoogleMapHelpImage from "../../../commoncomponents/GoogleMapHelpImage";
import { useTranslation } from "react-i18next";

function AddOffice(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [languageList, setLanguageList] = useState([]);
    const [officeList, setOfficeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showMapImage, setShowMapImage] = useState(false);

    const handleNext = async () => {
        if (!props.officeInputValue.email || props.officeInputValue.email === "" || props.officeInputValue.email === null) {
            toast.error(t(`Please enter email`));
        } else if (!props.officeInputValue.name || props.officeInputValue.name === "" || props.officeInputValue.name === null) {
            toast.error(t(`Please enter office name`));
        } else if (!props.officeInputValue.businessUsername || props.officeInputValue.businessUsername === "" || props.officeInputValue.businessUsername === null) {
            toast.error(t(`Please enter business username`));
        } else if (!props.officeInputValue.phone1 || props.officeInputValue.phone1 === "" || props.officeInputValue.phone1 === null) {
            toast.error(t(`Please enter phone number`));
        } else if (props.officeInputValue.phone1 && checkPhNo(props.officeInputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit phone number`));
        } else if (
            !props.checkedList ||
            props.checkedList === "" ||
            props.checkedList === null ||
            props.checkedList == [0] ||
            (props.checkedList && !props.checkedList.length) ||
            (props.checkedList && props.checkedList.length == 1 && props.checkedList[0] == 0)
        ) {
            toast.error(t(`Please select language`));
        } else if (!props.officeInputValue.googleMapLink || props.officeInputValue.googleMapLink === "" || props.officeInputValue.googleMapLink === null) {
            toast.error(t(`Please enter google map link`));
        } else if (props.officeInputValue.googleMapLink && checkGoogleLink(props.officeInputValue.googleMapLink) == false) {
            toast.error(t(`Please enter valid google map link`));
        } else if (!props.officeInputValue.address1 || props.officeInputValue.address1 === "" || props.officeInputValue.address1 === null) {
            toast.error(t(`Please enter address`));
        } else if (!props.officeInputValue.countryId || props.officeInputValue.countryId === "" || props.officeInputValue.countryId === null) {
            toast.error(t(`Please enter country`));
        } else if (!props.officeInputValue.stateId || props.officeInputValue.stateId === "" || props.officeInputValue.stateId === null) {
            toast.error(t(`Please enter state`));
        } else if (!props.officeInputValue.cityId || props.officeInputValue.cityId === "" || props.officeInputValue.cityId === null) {
            toast.error(t(`Please enter city`));
        } else if (!props.officeInputValue.zip || props.officeInputValue.zip === "" || props.officeInputValue.zip === null) {
            toast.error(t(`Please enter zip`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/check`,
                    {
                        step: 2,
                        businessUsername: props.officeInputValue.businessUsername,
                        officeEmail: props.officeInputValue.email,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.success == true) {
                        props.setStep(3);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    const fetchLanguagedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/language/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setLanguageList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const checkedchange = (e) => {
        const value = Number(e.target.value);
        let checkedListData = props.checkedList;
        const index = checkedListData.indexOf(value);
        if (index > -1) {
            checkedListData.splice(index, 1);
            if (index == 0) {
                props.setCheckedList([...props.checkedList]);
            } else {
                props.setCheckedList([...props.checkedList, ...props.checkedList.slice(index, 1)]);
            }
        } else {
            props.setCheckedList([...props.checkedList, value]);
        }
    };

    const fetchOfficeList = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/office/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name + " (" + item.businessUsername + ")",
            };
        });

    const onOfficeChange = (e) => {
        props.setOfficeId(e.value);
        props.setIsExistingOffice(true);
    };

    const handlePrevious = () => {
        props.setOfficeId("");
        props.setIsExistingOffice(false);
    };

    const fetchCountryList = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
                isId: true,
            })
            .then((res) => {
                setCountryList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const countryOptions =
        countryList &&
        countryList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCountryChange = (e) => {
        props.setOfficeInputValue({ ...props.officeInputValue, countryId: e.value, stateId: "", cityId: "" });
        setStateList([]);
        setCityList([]);
        fetchStateList(e.value);
    };

    const fetchStateList = async (countryId) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
                countryId: countryId,
                isId: true,
            })
            .then((res) => {
                setStateList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onStateChange = (e) => {
        props.setOfficeInputValue({ ...props.officeInputValue, stateId: e.value });
        fetchCityList(e.value);
    };

    const fetchCityList = async (stateId) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/city/list?isPagination=false`, {
                stateId: stateId,
                isId: true,
            })
            .then((res) => {
                setCityList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const cityOptions =
        cityList &&
        cityList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCityChange = (e) => {
        props.setOfficeInputValue({ ...props.officeInputValue, cityId: e.value });
    };

    const handleExistingAdd = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/doctor/add`,
                {
                    email: props.inputValue.email,
                    password: props.inputValue.password,
                    firstName: props.inputValue.firstName,
                    lastName: props.inputValue.lastName,
                    userName: props.inputValue.userName,
                    dob: dateAPIFormate(props.inputValue.dob),
                    phone1: props.inputValue.phone1,
                    address1: props.inputValue.address1,
                    statusId: STATUS.Active,

                    //role
                    roleId: ROLE_ID.DOCTOR,

                    //Office
                    officeId: props.officeId,
                    isSelectOffice: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                toast.success(res.data.message);
                props.handleClose();
                props.fetchDoctordata();
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        if (props.step == 2) {
            fetchLanguagedata();
            fetchOfficeList();
            fetchCountryList();
        }
    }, [props.step]);

    return (
        <>
            {isLoading ? (
                <div className={`${props.step == 2 ? "" : "d-none"}`}>
                    <Loader />
                </div>
            ) : (
                <>
                    <div className={`content-body my-4 border-0 ${props.step == 2 ? "" : "d-none"}`}>
                        <label className="control-label col-12">
                            {t("Office")} <span className="required"> * </span>
                        </label>
                        <Select
                            name="officeList"
                            value={officeOptions && officeOptions.filter(({ value }) => value == props.officeId)}
                            options={officeOptions}
                            onChange={onOfficeChange}
                            placeholder={t("Select")}
                        />
                        {props.isExistingOffice ? (
                            <div className="padding-bottom-30 d-flex justify-content-center mt-4">
                                <div className="text-left">
                                    <button type="button" className="btn mx-2 btn-primary gradient-blue mx-2" onClick={handleExistingAdd}>
                                        {t("Add")}
                                    </button>
                                    <button type="button" className="btn btn-warning" onClick={handlePrevious}>
                                        {t("Previous")}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {props.isExistingOffice ? (
                        ""
                    ) : (
                        <div className={`content-body my-4 border-0 ${props.step == 2 ? "" : "d-none"}`}>
                            {props.isExistingOffice ? "" : <span className="d-flex justify-content-center mt-4"> -- {t("OR")} --</span>}
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group row">
                                        <label className="control-label col-md-12">
                                            {t("E-mail")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12">
                                            <input type="email" className="form-control" name="email" value={props.officeInputValue.email} onChange={props.onChangeValue} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Office")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="name" value={props.officeInputValue.name} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Business Username")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="businessUsername"
                                                        value={props.officeInputValue.businessUsername}
                                                        onChange={props.onChangeValue}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Phone")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input type="number" className="form-control" name="phone1" value={props.officeInputValue.phone1} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12"> {t("NPI 1")} </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="npi1" value={props.officeInputValue.npi1} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12"> {t("License Number")} </label>

                                                <div className="col-md-12">
                                                    <input type="text" className="form-control" name="licenseNumber" value={props.officeInputValue.licenseNumber} onChange={props.onChangeValue} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">Business category</label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="businessCategoryId"
                                                        value={props.officeInputValue.businessCategoryId}
                                                        onChange={props.onChangeValue}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <GoogleMapHelpImage showMapImage={showMapImage} setShowMapImage={setShowMapImage} />
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Google Map Link")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12 d-flex">
                                                    <input
                                                        type="url"
                                                        className="form-control"
                                                        name="googleMapLink"
                                                        value={props.inputValue.googleMapLink}
                                                        onChange={props.onChangeValue}
                                                        disabled={props.view ? "Disabled" : ""}
                                                    />
                                                    {props.view ? (
                                                        ""
                                                    ) : (
                                                        <button className="btn mx-1" onClick={() => setShowMapImage(true)}>
                                                            ?
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Language")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <div className="row" style={{ paddingLeft: "5px" }}>
                                                        {languageList &&
                                                            languageList.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-lg-1 col-sm-1 col-xs-4" key={i}>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="checkBox "
                                                                                value={item.id}
                                                                                name={item.id}
                                                                                checked={props.checkedList && props.checkedList.includes(item.id) ? true : false}
                                                                                onChange={checkedchange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-3 col-sm-3 col-xs-6">{item.name}</div>
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group row">
                                        <label className="control-label col-md-12">
                                            {t("Address")} <span className="required"> * </span>
                                        </label>

                                        <div className="col-md-12">
                                            <textarea type="text" className="form-control" name="address1" rows={5} cols={12} value={props.officeInputValue.address1} onChange={props.onChangeValue} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Country")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <Select
                                                        name="countryList"
                                                        value={countryOptions && countryOptions.filter(({ value }) => value == props.officeInputValue.countryId)}
                                                        options={countryOptions}
                                                        onChange={onCountryChange}
                                                        placeholder={t("Select")}
                                                        isDisabled={props.view}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("State")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <Select
                                                        name="stateList"
                                                        value={stateOptions && stateOptions.filter(({ value }) => value == props.officeInputValue.stateId)}
                                                        options={stateOptions}
                                                        onChange={onStateChange}
                                                        placeholder={t("Select")}
                                                        isDisabled={props.view || !props.officeInputValue.countryId ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("City")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <Select
                                                        name="cityList"
                                                        value={cityOptions && cityOptions.filter(({ value }) => value == props.officeInputValue.cityId)}
                                                        options={cityOptions}
                                                        onChange={onCityChange}
                                                        placeholder={t("Select")}
                                                        isDisabled={props.view || !props.officeInputValue.stateId ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">
                                                    {t("Zip Code")} <span className="required"> * </span>
                                                </label>

                                                <div className="col-md-12">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="zip"
                                                        value={props.officeInputValue.zip}
                                                        onChange={props.onChangeValue}
                                                        disabled={props.view ? "Disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="padding-bottom-30 d-flex justify-content-center">
                                    <div className="text-left">
                                        <button type="button" className="btn btn-warning mx-2" onClick={() => props.setStep(1)}>
                                            {t("Previous")}
                                        </button>
                                        <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                            {t("Close")}
                                        </button>
                                        <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleNext}>
                                            {t("Next")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default AddOffice;
