import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../helper/Config";
import { checkPwd } from "../helper/Validator";
import Loader from "./Loader";

const ResetPassword = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);

    const toggleNewPassword = () => {
        setNewPasswordShown(!newPasswordShown);
    };

    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };

    const onchangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };
    const [isLoading, setIsLoading] = useState(false);
    const [showData, setShowdata] = useState(false);
    const [message, setMessage] = useState("");

    const checkResetLink = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/check-link`, {
                link: params.link,
            })
            .then(() => {
                setShowdata(true);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        if (err.response.data.message) {
                            setMessage(err.response.data.message);
                            setShowdata(false);
                        }
                    }
                    setIsLoading(false);
                }
            });
    };
    const handleSend = async () => {
        if (!inputValue.newPassword || inputValue.newPassword === "" || inputValue.newPassword === null) {
            toast.error(t(`Please enter new password`));
        } else if (checkPwd(inputValue.newPassword) == false) {
            toast.error(t(`Enter Valid New Password`));
        } else if (!inputValue.confirmPassword || inputValue.confirmPassword === "" || inputValue.confirmPassword === null) {
            toast.error(t(`Please confirm your new password`));
        } else if (inputValue.newPassword != inputValue.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
        } else {
            await axios
                .post(`${baseURL}/api/v1/reset-user-password`, {
                    password: inputValue.newPassword,
                    link: params.link,
                })
                .then((res) => {
                    toast.success(res.data.message);
                    setInputValue({
                        newPassword: "",
                        confirmPassword: "",
                    });
                    navigate("/login");
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        checkResetLink();
    }, []);

    return (
        <>
            <div className="container-fluid login-style">
                <div className="login-wrapper row  d-flex align-items-center">
                    <div className="login col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4">
                        <div className="card">
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <>
                                    {showData && !isLoading ? (
                                        <>
                                            <div className="card-body">
                                                <h3 className="card-text bold text-center" style={{ color: "rgb(17 82 120)" }}>
                                                    Reset your password
                                                </h3>
                                                <div className="card-body ">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12 mx-2">
                                                            New Password <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12 d-flex">
                                                            <input
                                                                type={newPasswordShown ? "text" : "password"}
                                                                className="form-control mx-2"
                                                                name="newPassword"
                                                                placeholder="Enter new password"
                                                                value={inputValue.newPassword}
                                                                onChange={onchangeValue}
                                                            />
                                                            <i
                                                                className={`${newPasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                id="basic-addon1"
                                                                onClick={toggleNewPassword}
                                                            ></i>
                                                        </div>
                                                        <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                            *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                                        </p>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12 mx-2">
                                                            Confirm New Password <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12 d-flex">
                                                            <input
                                                                type={cfmpasswordShown ? "text" : "password"}
                                                                className="form-control mx-2"
                                                                name="confirmPassword"
                                                                placeholder="Enter confirm password"
                                                                value={inputValue.confirmPassword}
                                                                onChange={onchangeValue}
                                                            />
                                                            <i
                                                                className={`${cfmpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                id="basic-addon1"
                                                                onClick={toggleCfmPassword}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pull-right">
                                                    <button href="/login" className="btn mt-10 btn-info btn-corner signup login-btn mr-10" onClick={isLoading ? "" : handleSend}>
                                                        {isLoading ? (
                                                            <i
                                                                className="fa fa-spinner fa-spin"
                                                                style={{
                                                                    position: "relative",
                                                                }}
                                                            ></i>
                                                        ) : (
                                                            "Change"
                                                        )}
                                                    </button>
                                                    <button
                                                        href="/login"
                                                        className="btn mt-10 btn-corner signup login-btn mr-10"
                                                        onClick={() => {
                                                            sessionStorage.removeItem("token");
                                                            navigate("/login");
                                                        }}
                                                    >
                                                        {t("Close")}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <a href="">
                                                    <Link to="/login">
                                                        <u className="text-info">Login?</u>
                                                    </Link>
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="card-body">
                                                <h3 className="card-text bold text-center text-danger">{message}</h3>
                                                <div className="pull-right">
                                                    <a href="/" className="btn mt-10 btn-corner signup login-btn mr-10">
                                                        {t("Close")}
                                                    </a>
                                                    <a href="/login" className="btn mt-10 btn-corner sign-up-btn right-15 text-white mx-3" style={{ background: "#115278" }}>
                                                        Log in
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mt-4">
                                                <a href="" style={{ color: "#ddd" }}>
                                                    Expired or Invalid link? &nbsp;
                                                    <Link to="/forgot-password">
                                                        <u className="text-info">Click here.</u>
                                                    </Link>
                                                </a>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
