import React from "react";

function NoDataFound() {
    return (
        <tr className="text-center" style={{ color: "red" }}>
            <td colSpan={15} className="text-center mt-5" style={{ color: "red" }}>
                {/* No data Found */}
                <img alt="" src="assets/images/no_data_found.png" style={{ height: "200px", width: "200px" }} />
            </td>
        </tr>
    );
}

export default NoDataFound;
