import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { REGION, ROLE_ID, AGENT_STATUS } from "../../helper/Constant";
import { checkEmail, checkPhNo, checkPwd, checkUserName } from "../../helper/Validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, defaultTimeZone } from "../../helper/dateFormates";
import Select from "react-select";
import GPointWalletUserVerify from "../../commoncomponents/GPointWalletUserVerify";
import { useTranslation } from "react-i18next";

const AgentSignUp = () => {
    const search = useLocation().search;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [inputGroup, setInputGroup] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        address: "",
        agencyId: "",
        gPointUserName: "",
        membership: "",
    });

    const [checkedList, setCheckedList] = useState([]);
    const [agencyList, setAgencyList] = useState([]);
    const [show, setShow] = useState(false);

    const handleSyncWallet = async () => {
        setShow(true);
    };

    const onchangeValue = (e) => {
        let value1 = e.target.value;

        if (value1 && parseFloat(value1) < 0) {
            e.target.value = 0;
            return;
        }

        const { name, value } = e.target;

        setInputGroup({ ...inputGroup, [name]: value });
    };

    const checkedchange = (e) => {
        const value = Number(e.target.value);
        let checkedListData = checkedList;
        const index = checkedListData.indexOf(value);
        if (index > -1) {
            checkedListData.splice(index, 1);
            if (index == 0) {
                setCheckedList([...checkedList]);
            } else {
                setCheckedList([...checkedList, ...checkedList.slice(index, 1)]);
            }
        } else {
            setCheckedList([...checkedList, value]);
        }
    };

    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPwdShown, setConfirmPwdShown] = useState(false);

    const showPwd = () => {
        if (passwordShown) {
            setPasswordShown(false);
        } else {
            setPasswordShown(true);
        }
    };

    const showConfirmPwd = () => {
        if (confirmPwdShown) {
            setConfirmPwdShown(false);
        } else {
            setConfirmPwdShown(true);
        }
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!inputGroup.firstName) {
            toast.error(t(`Please enter first name`));
            return false;
        }

        if (!inputGroup.lastName) {
            toast.error(t(`Enter Last Name`));
            return false;
        }

        if (!inputGroup.userName) {
            toast.error(t(`Enter User Name`));
            return false;
        }

        if (checkUserName(inputGroup.userName) === false) {
            toast.error(t(`Enter Valid User Name`));
            return false;
        }

        if (!inputGroup.email) {
            toast.error(t(`Enter Email`));
            return false;
        }

        if (checkEmail(inputGroup.email) === false) {
            toast.error(t(`Enter Valid Email`));
            return false;
        }

        if (!inputGroup.password) {
            toast.error(t(`Enter Password`));
            return false;
        }

        if (checkPwd(inputGroup.password) === false) {
            toast.error("Enter Valid Password");
            return false;
        }

        if (!inputGroup.confirmPassword) {
            toast.error("Enter Confirm Password");
            return false;
        }

        if (checkPwd(inputGroup.confirmPassword) === false) {
            toast.error("Enter Valid Confirm Password");
            return false;
        }

        if (inputGroup.password !== inputGroup.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
            return false;
        }

        if (!inputGroup.phone) {
            toast.error(t(`Enter Phone Number`));
            return false;
        }

        if (checkPhNo(inputGroup.phone) === false) {
            toast.error(t(`Enter Valid Phone Number`));
            return false;
        }

        if (
            !checkedList ||
            checkedList === "" ||
            checkedList === null ||
            // checkedList === [] ||
            (checkedList && !checkedList.length) ||
            (checkedList && checkedList.length == 1 && checkedList[0] == 0)
        ) {
            toast.error("Please select region");
            return false;
        }

        await axios
            .post(`${baseURL}/api/v1/requestAgent`, {
                firstName: inputGroup.firstName,
                lastName: inputGroup.lastName,
                roleId: ROLE_ID.AGENT,
                userName: inputGroup.userName,
                email: inputGroup.email,
                password: inputGroup.password,
                confirmPassword: inputGroup.confirmPassword,
                phone1: inputGroup.phone,
                address1: inputGroup.address,
                agencyId: inputGroup.agencyId,
                gPointUserName: inputGroup.gPointUserName,
                region: checkedList && checkedList.length ? String(checkedList).replaceAll(",", "~") : "0",
                statusId: AGENT_STATUS.Requested,
            })
            .then((res) => {
                toast.success(res.data.message);
                navigate("/login");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    let agencyBlankList = [
        {
            agencyId: "",
            fullName: t("Select Blank Agency"),
        },
    ];

    const fetchAgencyList = async () => {
        await axios
            .post(`${baseURL}/api/v1/agencyList`)
            .then((res) => {
                setAgencyList(agencyBlankList.concat(res.data.data));
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const agencyOptions =
        agencyList &&
        agencyList.map((item, i) => {
            return {
                value: item.agencyId,
                label: item.fullName,
            };
        });

    const onAgencyChange = (e) => {
        setInputGroup({ ...inputGroup, agencyId: e.value });
    };

    useEffect(() => {
        fetchAgencyList();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="login-wrapper row">
                    <div id="login" className="login loginpage login-style col-lg-offset-2 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-8" style={{ marginBottom: "3%" }}>
                        <div className="login-form-header" style={{ marginTop: "3%" }}>
                            <img
                                src="../data/icons/signup.png"
                                alt="login-icon"
                                style={{
                                    maxWidth: "64px",
                                    filter: "invert(0%) sepia(0%) saturate(1522%) hue-rotate(95deg) brightness(5%) contrast(38%)",
                                }}
                            />
                            <div className="login-header">
                                <h4 className="bold text-break">Request Agent Now!</h4>
                                <h4 className="">
                                    <small className="text-dark">Please enter your data to request.</small>
                                </h4>
                            </div>
                        </div>

                        <div className="box login">
                            <div className="content-body" style={{ paddingTop: "30px" }}>
                                <form id="msg_validate" action="#" noValidate="novalidate" className="no-mb no-mt">
                                    <div className="row mx-1">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("First name")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input type="text" className="form-control" name="firstName" value={inputGroup && inputGroup.firstName} onChange={(e) => onchangeValue(e)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Last name")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="lastName"
                                                        placeholder="Enter Last Name"
                                                        value={inputGroup && inputGroup.lastName}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    User name <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="text"
                                                        className="form-control my-0"
                                                        name="userName"
                                                        placeholder="Enter User Name"
                                                        value={inputGroup && inputGroup.userName}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                                <p className="mb-0 pb-0 mx-1" style={{ fontSize: "10px", color: "gray" }}>
                                                    *{t("At least 5 characters : start and end with character or number, can have special character(-,_)")}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Email <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        value={inputGroup && inputGroup.email}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Password")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls d-flex">
                                                    <input
                                                        type={passwordShown ? "text" : "password"}
                                                        className="form-control my-0"
                                                        name="password"
                                                        placeholder="Enter Password"
                                                        value={inputGroup && inputGroup.password}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                    <span className="input-group-text bg-transparent cursor-pointer" onClick={showPwd}>
                                                        <i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                                </div>
                                                <p className="mb-0 pb-0" style={{ fontSize: "10px", color: "gray" }}>
                                                    *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    Confirm Password <span className="required"> * </span>
                                                </label>

                                                <div className="controls d-flex">
                                                    <input
                                                        type={confirmPwdShown ? "text" : "password"}
                                                        className="form-control my-0"
                                                        name="confirmPassword"
                                                        value={inputGroup && inputGroup.confirmPassword}
                                                        placeholder="Enter Confirm Password"
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                    <span className="input-group-text bg-transparent cursor-pointer" onClick={showConfirmPwd}>
                                                        <i className={confirmPwdShown ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label className="control-label">
                                                    {t("Phone")} <span className="required"> * </span>
                                                </label>

                                                <div className="controls">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="phone"
                                                        placeholder="Enter Phone Number"
                                                        maxLength="10"
                                                        value={inputGroup && inputGroup.phone}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <label className="form-label">Address</label>
                                                    </div>
                                                </div>

                                                <div className="controls">
                                                    <textarea
                                                        type="text"
                                                        rows="3"
                                                        className="form-control"
                                                        name="address"
                                                        placeholder="Enter Address"
                                                        value={inputGroup && inputGroup.address}
                                                        onChange={(e) => onchangeValue(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Agency")}</label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="agencyId"
                                                                value={agencyOptions && agencyOptions.filter(({ value }) => value == inputGroup.agencyId)}
                                                                options={agencyOptions}
                                                                onChange={onAgencyChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">
                                                            {t("Region")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-md-12">
                                                            <div className="row" style={{ paddingLeft: "5px" }}>
                                                                {REGION &&
                                                                    REGION.map((item, i) => {
                                                                        return (
                                                                            <Fragment key={i}>
                                                                                <div className="col-lg-1 col-sm-1 col-xs-4">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="checkBox "
                                                                                        value={item.id}
                                                                                        name={item.id}
                                                                                        checked={checkedList && checkedList.includes(item.id) ? true : false}
                                                                                        onChange={checkedchange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-3 col-sm-3 col-xs-6">{t(item.name)}</div>
                                                                            </Fragment>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <GPointWalletUserVerify show={show} setShow={setShow} inputGroup={inputGroup} setInputGroup={setInputGroup} />
                                        <div className="col-lg-6">
                                            <div className="form-group row">
                                                <label className="control-label col-md-12">{t("GPoint Wallet Username")}</label>

                                                <div className="col-xl-11 col-md-8 col-sm-8 col-12">
                                                    <input
                                                        // type="text"
                                                        className="form-control"
                                                        name="gPointUserName"
                                                        value={inputGroup.gPointUserName}
                                                        onChange={(e) => onchangeValue(e)}
                                                        disabled="disabled"
                                                    />
                                                </div>
                                                <div className="col-xl-1 col-md-4 col-sm-4 col-12">
                                                    <button type="button" className="form-control btn btn-info" onClick={handleSyncWallet}>
                                                        {t("SYNC Wallet")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">&nbsp;</div>
                                    </div>
                                    <div className="pull-right">
                                        <a href="/" className="btn mt-10 btn-corner signup login-btn mr-10">
                                            {t("Cancel")}
                                        </a>
                                        <button className="btn mt-10 btn-corner sign-up-btn right-15 text-white" style={{ background: "#115278" }} onClick={(e) => handleSignUp(e)}>
                                            Request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentSignUp;
