// import i18next from "i18next";
// import HttpBackend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// const apiKey = "FbHMJqhGcxYGtdj4wG-pRQ";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
// const languageShortName = localStorage.getItem("languageShortName");

// i18next
//     .use(HttpBackend)
//     .use(LanguageDetector)
//     .use(initReactI18next)
//     .init({
//         fallbackLng: "en",
//         lng: [languageShortName], // language's short-name choosen by user
//         ns: ["default"],
//         defaultNS: "default",

//         supportedLngs: ["en", "ja", "ko", "hi", "gu"],

//         backend: {
//             loadPath: loadPath,
//         },
//     });
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationGU from "./locales/gu.json";
import translationKO from "./locales/ko.json";
import translationZH from "./locales/zh.json";
import translationES from "./locales/es.json";
const languageShortName = localStorage.getItem("languageShortName");

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    gu: {
        translation: translationGU,
    },
    ko: {
        translation: translationKO,
    },
    zh: {
        translation: translationZH,
    },
    es: {
        translation: translationES,
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: [languageShortName],
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
