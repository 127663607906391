import axios from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../commoncomponents/Loader";
import { baseURL } from "../../../helper/Config";
import { ROLE_ID, VERIFIER_COMMENT_STATUS } from "../../../helper/Constant";

function UpsertComment(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const { profileData } = useOutletContext();

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const handleAdd = async (closeClick) => {
        if (props.oldNoteValues.verifierNote != props.inputValue.verifierNote && (profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.PATIENT)) {
            toast.error(t(`Only admin can change verifier's comment`));
        } else if (props.oldNoteValues.finalResult != props.inputValue.finalResult && (profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.PATIENT)) {
            toast.error(t(`Only admin can change final result`));
        } else if (props.oldNoteValues.doctorNote != props.inputValue.doctorNote && (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT)) {
            toast.error(t(`Only doctor can change doctor's note`));
        } else if (
            !closeClick &&
            profileData.roleId == ROLE_ID.ADMIN &&
            (props.inputValue.finalResult == 0 || props.inputValue.finalResult == VERIFIER_COMMENT_STATUS["Pre verified"] || props.inputValue.finalResult == "" || !props.inputValue.finalResult)
        ) {
            toast.error(t(`Please select final result`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/claim/comment/upsert`,
                    {
                        verifierNote: props.inputValue.verifierNote,
                        doctorNote: props.inputValue.doctorNote,
                        finalResult: props.inputValue.finalResult,
                        claimId: props.claimId,
                        isVerifierNoteChanged: props.oldNoteValues.verifierNote != props.inputValue.verifierNote && !closeClick ? 1 : 0,
                        isFinalResultChanged: props.oldNoteValues.finalResult != props.inputValue.finalResult && !closeClick ? 1 : 0,
                        isDoctorNoteChanged: props.oldNoteValues.doctorNote != props.inputValue.doctorNote && !closeClick ? 1 : 0,
                        isCloseClick: closeClick,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    if (!closeClick) {
                        toast.success(res.data.message);
                    }
                    props.fetchClaimdata();
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <div>
            <Modal show={props.show} onHide={profileData.roleId == ROLE_ID.PATIENT ? props.handleClose : () => handleAdd(true)} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Add Comment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.editLoader ? (
                        <Loader />
                    ) : (
                        <div className="justify-content-center row my-3">
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Verifier's comment")}
                                    {profileData.roleId == ROLE_ID.DOCTOR && props.inputValue.isVerifierNoteChanged ? (
                                        <img src="assets/images/new.gif" style={{ height: "20px", width: "30px", paddingright: "10px" }} />
                                    ) : (
                                        ""
                                    )}
                                </label>

                                <div className="col-md-12">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="verifierNote"
                                        value={props.inputValue.verifierNote}
                                        rows={3}
                                        onChange={props.onChangeValue}
                                        disabled={profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.PATIENT ? "disabled" : ""}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Doctor's opinion")}
                                    {profileData.roleId == ROLE_ID.ADMIN && props.inputValue.isDoctorNoteChanged ? (
                                        <img src="assets/images/new.gif" style={{ height: "20px", width: "30px", paddingright: "10px" }} />
                                    ) : (
                                        ""
                                    )}
                                </label>

                                <div className="col-md-12">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        name="doctorNote"
                                        value={props.inputValue.doctorNote}
                                        rows={3}
                                        onChange={props.onChangeValue}
                                        disabled={profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.VERIFY || profileData.roleId == ROLE_ID.PATIENT ? "disabled" : ""}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-md-12">
                                    {t("Final result")} <span className="required"> * </span>
                                    {profileData.roleId == ROLE_ID.DOCTOR && props.inputValue.isFinalResultChanged ? (
                                        <img src="assets/images/new.gif" style={{ height: "20px", width: "30px", paddingright: "10px" }} />
                                    ) : (
                                        ""
                                    )}
                                </label>
                                <div className="col-md-12">
                                    <input
                                        className="my-2 mx-3"
                                        type="radio"
                                        name="finalResult"
                                        value="1"
                                        checked={props.inputValue.finalResult == 1 ? true : false}
                                        onChange={props.onChangeValue}
                                        disabled={profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.PATIENT ? "disabled" : ""}
                                    />
                                    <div className="my-2 text-light  font-weight-bold btn btn-xs btn-success">{t("Good")}</div>
                                    <input
                                        className="my-2 mx-3"
                                        type="radio"
                                        name="finalResult"
                                        value="2"
                                        checked={props.inputValue.finalResult == 2 ? true : false}
                                        onChange={props.onChangeValue}
                                        disabled={profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.PATIENT ? "disabled" : ""}
                                    />
                                    <div className="my-2 text-light  font-weight-bold btn btn-xs btn-warning">{t("Normal")}</div>
                                    <input
                                        className="my-2 mx-3"
                                        type="radio"
                                        name="finalResult"
                                        value="3"
                                        checked={props.inputValue.finalResult == 3 ? true : false}
                                        onChange={props.onChangeValue}
                                        disabled={profileData.roleId == ROLE_ID.DOCTOR || profileData.roleId == ROLE_ID.PATIENT ? "disabled" : ""}
                                    />
                                    <div className="my-2 text-light  font-weight-bold btn btn-xs btn-danger">{t("Not Good")}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {profileData.roleId == ROLE_ID.PATIENT ? (
                        <Button variant="secondary" onClick={props.handleClose}>
                            {t("Close")}
                        </Button>
                    ) : (
                        <>
                            <Button variant="secondary" onClick={() => handleAdd(true)}>
                                {t("Close")}
                            </Button>
                            <Button variant="primary" onClick={() => handleAdd(false)}>
                                {t("Save")}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UpsertComment;
