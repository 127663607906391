import React, { Fragment, useEffect, useState } from "react";
import { ROLE_ID, STATUS, DFLT_BSN_CTGR_ID } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate } from "../../../helper/dateFormates";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import _ from "lodash";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function AddServiceAndCost(props) {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");

  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [inputValueObj, setInputValueObj] = useState([]);
  let InputObj = inputValueObj;
  const [specialityPartList, setSpecialityPartList] = useState([]);
  const { profileData } = useOutletContext();
  let apiRouteName =
    profileData.roleId == ROLE_ID.ADMIN
      ? "admin"
      : profileData.roleId == ROLE_ID.DOCTOR
      ? "doctor"
      : "";

  const [trmtGrpOpen, setTrmtGrpOpen] = useState([true, true, true, true]);

  const handleOpenClose = (e, i) => {
    setTrmtGrpOpen({ ...trmtGrpOpen, [i]: !trmtGrpOpen[i] });
  };

  const handleFeeChange = async (e, fid, GName) => {
    let value = Number(e.target.value);
    let id = fid;
    let oldgropudata = data;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate =
      value == 0
        ? 0
        : (
            (Math.round(value - Number(treatmentId.discountFee)) / value) *
            100
          ).toFixed(2);
    if (value >= Number(treatmentId.discountFee) && value >= 0) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(discountRate),
        discountFee: 0,
        originalFee: value,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };
      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;
      setData([...oldgropudata]);

      let newObj = {
        treatmentId: Number(id),
        originalFee: value,
        discountFee: 0,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };
      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      toast.error(
        t(
          `Original fee should not be less than discount fee or can not be less than 0`
        )
      );
    }
  };

  const handleDiscountFeeChange = async (e, fid, GName) => {
    let id = fid;

    let value = Number(e.target.value);
    let oldgropudata = data;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );
    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let discountRate =
      value == 0
        ? 0
        : (
            (Math.round(treatmentId.originalFee - value) /
              treatmentId.originalFee) *
            100
          ).toFixed(2);

    if (value <= Number(treatmentId.originalFee) && value >= 0) {
      let newobj = {
        code: treatmentId.code,
        description: treatmentId.description,
        id: Number(treatmentId.id),
        name: treatmentId.name,
        treatmentType: treatmentId.treatmentType,
        treatmentTypeGroup: treatmentId.treatmentTypeGroup,
        discountRate: Number(treatmentId.discountRate),
        discountFee: value,
        originalFee: Number(treatmentId.originalFee),
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
        unit: Number(treatmentId.unit),
      };
      objTreatmentdata[treatmentId_index] = newobj;
      oldgropudata[filterddata_index].data = objTreatmentdata;

      setData([...oldgropudata]);
      let newObj = {
        treatmentId: Number(id),
        originalFee: treatmentId.originalFee,
        discountFee: value,
        discountRate: discountRate,
        isFreeOnceYear: treatmentId.isFreeOnceYear,
      };
      let index = InputObj.findIndex((p) => p.treatmentId == id);
      if (index != -1) {
        InputObj[index] = newObj;
      } else {
        InputObj.push(newObj);
      }
      setInputValueObj(InputObj);
    } else {
      toast.error(
        t(
          `Discount fee should not be more than original fee or can not be less than 0`
        )
      );
    }
  };

  const handleCheckChange = async (e, fid, GName) => {
    let id = fid;
    let oldgropudata = data;
    let filterddata = await _.find(oldgropudata, { treatmentTypeGroup: GName });
    let filterddata_index = oldgropudata.findIndex(
      (p) => p.treatmentTypeGroup === GName
    );

    let objTreatmentdata = filterddata.data;
    let treatmentId = await _.find(objTreatmentdata, { id: parseInt(fid) });
    let treatmentId_index = objTreatmentdata.findIndex(
      (p) => p.id === parseInt(fid)
    );
    let newobj = {
      code: treatmentId.code,
      description: treatmentId.description,
      id: Number(treatmentId.id),
      name: treatmentId.name,
      treatmentType: treatmentId.treatmentType,
      treatmentTypeGroup: treatmentId.treatmentTypeGroup,
      discountRate: Number(treatmentId.discountRate),
      discountFee: Number(treatmentId.discountFee),
      originalFee: Number(treatmentId.originalFee),
      isFreeOnceYear: !e.target.checked ? 1 : 0,
      unit: Number(treatmentId.unit),
    };
    objTreatmentdata[treatmentId_index] = newobj;
    oldgropudata[filterddata_index].data = objTreatmentdata;
    setData([...oldgropudata]);
    let newObj = {
      treatmentId: Number(id),
      originalFee: treatmentId.originalFee,
      discountFee: treatmentId.discountFee,
      discountRate: treatmentId.discountRate,
      isFreeOnceYear: !e.target.checked ? 1 : 0,
    };
    let index = InputObj.findIndex((p) => p.treatmentId == id);
    if (index != -1) {
      InputObj[index] = newObj;
    } else {
      InputObj.push(newObj);
    }
    setInputValueObj(InputObj);
  };

  const fetchServiceAndCostData = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/treatment/list?isPagination=false`,
        {
          officeId: props.isEdit || props.view ? props.officeId : "",
          isListAllRecord: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
        let treatmentTypeList = res.data.data;
        const groupbydata = _.chain(treatmentTypeList)
          .groupBy("treatmentTypeGroup")
          .map(function (value, key) {
            return {
              treatmentTypeGroup: key,
              data: value,
            };
          })
          .value();
        setData(groupbydata);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const fetchSpecialityPartdata = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/speciality-part/list?isPagination=false`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setSpecialityPartList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleSpecialityCheckChange = (e) => {
    const value = Number(e.target.value);
    let specialityCheckedListData = props.specialityCheckedList;
    const index = specialityCheckedListData.indexOf(value);
    if (index > -1) {
      specialityCheckedListData.splice(index, 1);
      if (index == 0) {
        props.setSpecialityCheckedList([...props.specialityCheckedList]);
      } else {
        props.setSpecialityCheckedList([
          ...props.specialityCheckedList,
          ...props.specialityCheckedList.slice(index, 1),
        ]);
      }
    } else {
      props.setSpecialityCheckedList([...props.specialityCheckedList, value]);
    }
  };

  const handleAdd = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/office/assign`,
        {
          isExistOffice: false,
          doctorId:
            profileData.roleId == ROLE_ID.ADMIN
              ? props.doctorId
              : profileData.id,
          email: props.inputValue.email,
          name: props.inputValue.name,
          businessUsername: props.inputValue.businessUsername,
          phone1: props.inputValue.phone1,
          npi1: props.inputValue.npi1,
          licenseNumber: props.inputValue.licenseNumber,
          languageId:
            props.checkedList && props.checkedList.length
              ? String(props.checkedList).replaceAll(",", "~")
              : "0",
          businessCategoryId: DFLT_BSN_CTGR_ID,
          googleMapLink: props.inputValue.googleMapLink,
          address1: props.inputValue.address1,
          countryId: props.inputValue.countryId,
          stateId: props.inputValue.stateId,
          cityId: props.inputValue.cityId,
          zip: props.inputValue.zip,
          verifierApplied: props.inputValue.verifierApplied,
          statusId: profileData.roleId == ROLE_ID.DOCTOR ? "" : STATUS.Active,
          officeTreatmentFee: inputValueObj,
          specialtyPartId:
            props.specialityCheckedList && props.specialityCheckedList.length
              ? String(props.specialityCheckedList).replaceAll(",", "~")
              : "0",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        props.fetchOfficedata();
        toast.success(res.data.message);
        props.handleClose();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleRegister = async () => {
    let officeObj;
    if (props.isExistingOffice) {
      officeObj = {
        officeId: props.officeId,
        isSelectOffice: true,
      };
    } else {
      officeObj = {
        isSelectOffice: false,
        name: props.officeInputValue.name,
        officePhone1: props.officeInputValue.phone1,
        officeEmail: props.officeInputValue.email,
        businessUsername: props.officeInputValue.businessUsername,
        licenseNumber: props.officeInputValue.licenseNumber,
        npi1: props.officeInputValue.npi1,
        googleMapLink: props.officeInputValue.googleMapLink,
        address1: props.officeInputValue.address1,
        countryId: props.officeInputValue.countryId,
        stateId: props.officeInputValue.stateId,
        cityId: props.officeInputValue.cityId,
        zip: props.officeInputValue.zip,
        verifierApplied: props.officeInputValue.verifierApplied,
        businessCategoryId: DFLT_BSN_CTGR_ID,
        languageId:
          props.checkedList && props.checkedList.length
            ? String(props.checkedList).replaceAll(",", "~")
            : "0",
        officeTreatmentFee: inputValueObj,
        specialtyPartId:
          props.specialityCheckedList && props.specialityCheckedList.length
            ? String(props.specialityCheckedList).replaceAll(",", "~")
            : "0",
      };
    }
    await axios
      .post(
        `${baseURL}/api/v1/admin/doctor/add`,
        Object.assign(
          {
            email: props.inputValue.email,
            password: props.inputValue.password,
            firstName: props.inputValue.firstName,
            lastName: props.inputValue.lastName,
            userName: props.inputValue.userName,
            dob: dateAPIFormate(props.inputValue.dob),
            phone1: props.inputValue.phone1,
            address1: props.inputValue.address1,
            countryId: props.inputValue.countryId,
            stateId: props.inputValue.stateId,
            cityId: props.inputValue.cityId,
            zip: props.inputValue.zip,
            statusId: STATUS.Active,

            //role
            roleId: ROLE_ID.DOCTOR,
          },
          //Office
          officeObj
        ),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        props.handleClose();
        props.fetchOfficedata();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleUpdate = async () => {
    await axios
      .post(
        `${baseURL}/api/v1/${apiRouteName}/office/update`,
        {
          officeId: props.officeId,
          name: props.inputValue.name,
          businessUsername: props.inputValue.businessUsername,
          phone1: props.inputValue.phone1,
          npi1: props.inputValue.npi1,
          licenseNumber: props.inputValue.licenseNumber,
          languageId:
            props.checkedList && props.checkedList.length
              ? String(props.checkedList).replaceAll(",", "~")
              : "0",
          businessCategoryId: DFLT_BSN_CTGR_ID,
          googleMapLink: props.inputValue.googleMapLink,
          address1: props.inputValue.address1,
          countryId: props.inputValue.countryId,
          stateId: props.inputValue.stateId,
          cityId: props.inputValue.cityId,
          zip: props.inputValue.zip,
          verifierApplied: props.inputValue.verifierApplied,
          // statusId: profileData.roleId == ROLE_ID.DOCTOR ? "" : props.inputValue.statusId,
          statusId: props.inputValue.statusId,
          officeTreatmentFee: inputValueObj,
          specialtyPartId:
            props.specialityCheckedList && props.specialityCheckedList.length
              ? String(props.specialityCheckedList).replaceAll(",", "~")
              : "0",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        props.fetchOfficedata();
        toast.success(res.data.message);
        props.handleClose();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleReject = async (doctorId) => {
    Swal.fire({
      title: t(`Are you sure you want to reject this Doctor?`),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("Close"),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, reject it!"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .get(
              `${baseURL}/api/v1/admin/registration-request/rejected/${doctorId}?isDoctor=1`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                  lang: languageShortName,
                },
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              props.handleClose();
              props.fetchDoctorReqestdata();
              props.fetchDashboardData();
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500 || err.response.status == 401) {
                  localStorage.clear();
                  sessionStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };

  const handleApprove = async (doctorId) => {
    Swal.fire({
      title: t(`Are you sure you want to approve this Doctor?`),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("Close"),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, approve it!"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          await axios
            .get(
              `${baseURL}/api/v1/admin/registration-request/approved/${doctorId}?isDoctor=1`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                  lang: languageShortName,
                },
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              props.handleClose();
              props.fetchDoctorReqestdata();
              props.fetchDashboardData();
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status == 500 || err.response.status == 401) {
                  localStorage.clear();
                  sessionStorage.clear();
                  navigate("/login");
                } else {
                  toast.error(err.response.data.message);
                }
              }
            });
        }
      }
    });
  };

  useEffect(() => {
    if (props.step == 3) {
      fetchServiceAndCostData();
      fetchSpecialityPartdata();
    }
  }, [props.step]);

  return (
    <>
      <div
        className={`content-body my-4 border-0 ${
          props.step == 3 ? "" : "d-none"
        }`}
      >
        <div className="row">
          <div className="col-xs-12">
            {data &&
              data.map((item, index) => {
                return (
                  <Fragment>
                    <div
                      className="row align-items-center my-2 border"
                      key={index}
                    >
                      <div className="col-1">
                        <button
                          id={index}
                          className="btn transparent"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#treatmentGroup${index}`}
                          aria-expanded="false"
                          aria-controls={`treatmentGroup${index}`}
                          onClick={(e) => handleOpenClose(e, index)}
                        >
                          {!trmtGrpOpen[index] ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              // fill="currentColor"
                              className="bi bi-plus-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-dash-square-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
                            </svg>
                          )}
                        </button>
                      </div>
                      <div className="col-3">
                        <h5 className="mt-2 fw-bold fs-6">
                          {item.treatmentTypeGroup == "undefined" ||
                          item.treatmentTypeGroup == undefined ||
                          item.treatmentTypeGroup == "No-Group"
                            ? ""
                            : item.treatmentTypeGroup.toUpperCase()}
                        </h5>
                      </div>
                      <div className="col-2 justify-content-center text-center">
                        <h5 className="mt-2 fw-bold">{t("Original Fee")}</h5>
                      </div>
                      <div className="col-2 justify-content-center text-center">
                        <h5 className="mt-2 fw-bold">{t("Discount Fee")}</h5>
                      </div>
                      <div className="col-2 justify-content-center text-center">
                        <h5 className="mt-2 fw-bold">{t("Discount Rate")}</h5>
                      </div>
                      <div className="col-2"></div>
                    </div>
                    {item.data.map((item1, i) => {
                      return (
                        <div
                          className={`collapse ${index < 4 ? "show" : ""}`}
                          id={`treatmentGroup${index}`}
                        >
                          <div className="row mt-1 align-items-center">
                            <div className="col-lg-3 col-md-3 offset-lg-1 my-2">
                              {item1.name}
                            </div>
                            <div className="col-lg-2 col-md-2 d-flex justify-content-end">
                              <input
                                type="text"
                                className="form-control my-1"
                                name={item1.name}
                                value={item1.originalFee.toLocaleString(
                                  "en-US"
                                )}
                                min="0"
                                onChange={(e) =>
                                  handleFeeChange(
                                    e,
                                    item1.id,
                                    item1.treatmentTypeGroup
                                  )
                                }
                                disabled={props.view ? "disabled" : ""}
                              />
                            </div>
                            <div className="col-lg-2 col-md-2 d-flex justify-content-end">
                              <input
                                type="text"
                                className="form-control my-1 mx-2"
                                value={item1.discountFee.toLocaleString(
                                  "en-US"
                                )}
                                min="0"
                                onChange={(e) =>
                                  handleDiscountFeeChange(
                                    e,
                                    item1.id,
                                    item1.treatmentTypeGroup
                                  )
                                }
                                disabled={props.view ? "disabled" : ""}
                              />
                            </div>
                            <div className="col-lg-2 col-md-2 d-flex justify-content-end">
                              <input
                                type="text"
                                className="form-control my-1 mx-2"
                                id={item1.id}
                                value={`${item1.discountRate}%`}
                                disabled
                              />
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <div className="row">
                                <div className="col-lg-2 col-md-2 col-md-2 col-sm-3">
                                  <input
                                    type="checkbox"
                                    className="checkBox mt-2 mb-2"
                                    value={item1.id}
                                    onChange={(e) =>
                                      handleCheckChange(
                                        e,
                                        item1.id,
                                        item1.treatmentTypeGroup
                                      )
                                    }
                                    checked={
                                      item1.isFreeOnceYear == "1" ||
                                      item1.isFreeOnceYear == 1 ||
                                      item1.isFreeOnceYear == true
                                        ? true
                                        : false
                                    }
                                    disabled={props.view ? "disabled" : ""}
                                  />
                                </div>
                                <div className="col-lg-9 col-md-9 col-md-9 col-sm-8 my-1">
                                  {t("Free Once A Year")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                );
              })}
            <div className="col-12 my-4">
              <div className="col-12">
                <h5 className="mx-5 mt-2 fw-bold">
                  {"speciality parts in dentist".toUpperCase()}
                </h5>
              </div>

              <hr
                className="my-3"
                style={{ border: "1px solid", height: "0.1rem" }}
              />
              <div className="row">
                {specialityPartList &&
                  specialityPartList.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <div className="col-lg-1 col-md-1 col-md-2 col-sm-2 col-xs-4 offset-lg-1 d-md-flex d-sm-flex">
                          <input
                            type="checkbox"
                            className="checkBox "
                            value={item.id}
                            name={item.id}
                            checked={
                              props.specialityCheckedList &&
                              props.specialityCheckedList.includes(item.id)
                                ? true
                                : false
                            }
                            onChange={handleSpecialityCheckChange}
                            disabled={props.view ? "Disabled" : ""}
                          />
                        </div>
                        <div className="col-lg-2 col-md-2 col-md-3 col-sm-4 col-xs-8 my-1">
                          {item.name}
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="padding-bottom-30 d-flex justify-content-center mt-3">
            <div className="text-left">
              {props.isfromSearchDental ? (
                <button
                  type="button"
                  className="btn mx-2 btn-secondary"
                  onClick={props.handleClose}
                >
                  {t("Close")}
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-warning mx-2"
                    onClick={() =>
                      props.isFromDoctorList
                        ? props.setStep(2)
                        : props.setStep(1)
                    }
                  >
                    {t("Previous")}
                  </button>
                  {props.idPendingData ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-danger mx-2"
                        onClick={() => handleReject(props.doctorId)}
                      >
                        {t("Reject")}
                      </button>
                      <button
                        type="button"
                        className="btn mx-2 btn-success"
                        onClick={() => handleApprove(props.doctorId)}
                      >
                        {t("Approve")}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  <button
                    type="button"
                    className="btn mx-2 btn-secondary"
                    onClick={props.handleClose}
                  >
                    {t("Close")}
                  </button>
                  {props.isFromDoctorList ? (
                    <button
                      type="button"
                      className="btn btn-primary gradient-blue mx-2"
                      onClick={handleRegister}
                    >
                      {t("Add")}
                    </button>
                  ) : props.view ? (
                    ""
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary gradient-blue mx-2"
                      onClick={props.isUpdateOffice ? handleUpdate : handleAdd}
                    >
                      {props.isUpdateOffice ? t("Update") : t("Add")}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddServiceAndCost;
