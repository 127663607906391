var regUserName = /^(?!.*(?:\.-|-\.|\.\.|--))[a-zA-Z0-9._-]{5,20}$/;

var regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

var regPwd = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

var regPhNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

var regAddress = /^[a-zA-Z0-9\s,.'-]{15,}$/;

var regUserEmail = /([a-zA-Z0-9_\\-\\.]+)@((\\[a-z]{1,3}\\.[a-z]{1,3}\\.[a-z]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})/;

var regUserDob = /\s+(?:0[1-9]|[12][0-9]|3[01])[-/.](?:0[1-9]|1[012])[-/.](?:19\d{2}|20[01][0-9]|2020)\b/;

let googleMapLink = /(https|http):\/\/(www\.|)(goo.gl|google)(.[a-z]|)+\/maps/;

let SNSLink = /^https:\/\/\S+?([\w.-]+)\.([a-z]{2,})(:\d{1,5})?([/?#]\S*)?$/i;

let zoomLink = /https:\/\/(?:[a-z0-9-]+\.)*zoom\.us\/j\/\d+(?:\?pwd=[a-zA-Z0-9]+)?/;

export const checkZoomLink = (string) => {
    return zoomLink.test(string);
};

export const checkSNSLink = (string) => {
    return SNSLink.test(string);
};

export const checkGoogleLink = (string) => {
    return googleMapLink.test(string);
};

export const checkUserName = (string) => {
    return regUserName.test(string);
};

export const checkEmail = (string) => {
    return regEmail.test(string);
};

export const checkPwd = (string) => {
    return regPwd.test(string);
};

export const checkPhNo = (string) => {
    return regPhNo.test(string);
};

export const checkAddress = (string) => {
    return regAddress.test(string);
};

export const checkUserEmail = (string) => {
    return regUserEmail.test(string);
};

export const checkUserDob = (string) => {
    return regUserDob.test(string);
};
