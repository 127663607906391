import React, { useState } from "react";
import { STATUS } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkPhNo, checkUserName } from "../../../helper/Validator";
import Loader from "../../../commoncomponents/Loader";
import UpdateOffice from "../manageOffice/UpdateOffice";
import { useTranslation } from "react-i18next";

function UpdateDoctor(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [step, setStep] = useState(1);

    const handleUpdate = async () => {
        if (!props.inputValue.firstName || props.inputValue.firstName === "" || props.inputValue.firstName === null) {
            toast.error(t(`Please enter first name`));
        } else if (!props.inputValue.lastName || props.inputValue.lastName === "" || props.inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        } else if (!props.inputValue.userName || props.inputValue.userName === "" || props.inputValue.userName === null) {
            toast.error(t(`Please enter username`));
        } else if (props.inputValue.userName && checkUserName(props.inputValue.userName) == false) {
            toast.error(t(`Please enter valid username`));
            // } else if (!props.inputValue.dob || props.inputValue.dob === "" || props.inputValue.dob === null) {
            //     toast.error(t(`Please select date of birth`));
        } else if (!props.inputValue.phone1 || props.inputValue.phone1 === "" || props.inputValue.phone1 === null) {
            toast.error(t(`Please enter Mobile Number`));
        } else if (props.inputValue.phone1 && checkPhNo(props.inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit Mobile Number`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
            // } else if (!props.inputValue.address1 || props.inputValue.address1 === "" || props.inputValue.address1 === null) {
            //     toast.error(t(`Please enter address`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/doctor/update`,
                    {
                        doctorId: props.doctorId,
                        firstName: props.inputValue.firstName,
                        lastName: props.inputValue.lastName,
                        userName: props.inputValue.userName,
                        // dob: dateAPIFormate(props.inputValue.dob),
                        phone1: props.inputValue.phone1,
                        statusId: props.inputValue.statusId,
                        // address1: props.inputValue.address1,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchDoctordata();
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };
    return (
        <>
            {props.isView ? (
                ""
            ) : (
                <div className="col-xs-12">
                    <div className="page-title">
                        <div className="pull-left">
                            <h1 className="title">
                                {t("Update")} {t("Doctor")}
                            </h1>
                        </div>

                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li>
                                    <Link to="/dashboard">
                                        <i className="fa fa-home"></i>
                                        {t("Home")}
                                    </Link>
                                </li>
                                {props.isFromOfficeList ? (
                                    <li className="active cursor-pointer" onClick={props.backToDoctorList}>
                                        <strong>{t("Office List")}</strong>
                                    </li>
                                ) : (
                                    ""
                                )}
                                <li className="active cursor-pointer" onClick={props.handleClose}>
                                    <strong>{props.isFromOfficeList ? t("Doctor-Office List") : t("Doctor List")}</strong>
                                </li>
                                <li className="active cursor-pointer">
                                    <strong>
                                        {t("Update")} {t("Doctor")}
                                    </strong>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {step == 1 ? (
                <div className="col-xs-12 ">
                    <div className="add-header-wrapper gradient-blue curved-section text-center">
                        <h2 className="uppercase bold w-text">
                            {props.isView ? t("View") : t("Update")} {t("Doctor")}
                        </h2>
                        <div className="before-text">
                            {props.isView ? t("View") : t("Update")} {t("Doctor")}
                        </div>
                    </div>
                    <div className="bg-w">
                        <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                            {props.editLoader ? (
                                <Loader />
                            ) : (
                                <section className="box1">
                                    <div className={`content-body my-4 border-0`}>
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-12">
                                                                {t("E-mail")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-12">
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    name="email"
                                                                    value={props.inputValue.email}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.isView ? "disable" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("First name")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="firstName"
                                                                    value={props.inputValue.firstName}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.isView ? "disable" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Last name")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="lastName"
                                                                    value={props.inputValue.lastName}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.isView ? "disable" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Username")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="userName"
                                                                    value={props.inputValue.userName}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.isView ? "disable" : ""}
                                                                />
                                                                <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                    *{t("At least 5 characters : start and end with character or number, can have special character")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Phone")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="phone1"
                                                                    value={props.inputValue.phone1}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.isView ? "disable" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Status")} <span className="required"> * </span>
                                                            </label>

                                                            <div className="col-md-12">
                                                                <select
                                                                    className="form-control col-md-3"
                                                                    name="statusId"
                                                                    value={props.inputValue.statusId}
                                                                    onChange={props.onChangeValue}
                                                                    disabled={props.isView ? "disable" : ""}
                                                                >
                                                                    {Object.keys(STATUS).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={STATUS[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="padding-bottom-30 d-lg-flex justify-content-center">
                                                <div className="text-left">
                                                    <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                        {t("Close")}
                                                    </button>
                                                    {props.isView ? (
                                                        <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={() => setStep(2)}>
                                                            {t("Next")}
                                                        </button>
                                                    ) : (
                                                        <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleUpdate}>
                                                            {t("Update")}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <UpdateOffice
                    fetchDashboardData={props.fetchDashboardData}
                    doctorId={props.doctorId}
                    fetchDoctorReqestdata={props.fetchDoctorReqestdata}
                    setStep={setStep}
                    officeId={props.officeId}
                    isPendingData={true}
                    editLoader={props.editLoader}
                    checkedList={props.checkedList}
                    specialityCheckedList={props.specialityCheckedList}
                    languageList={props.languageList}
                    handleClose={props.handleClose}
                    inputValue={props.officeInputValue}
                    view={true}
                />
            )}
        </>
    );
}

export default UpdateDoctor;
