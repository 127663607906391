import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function SuccessPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="login-form-header">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ width: "100px", display: "block", margin: "5px auto 0" }}>
                <circle className="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <polyline className="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
            <h2 className="bold text-break text-success text-center my-3">{t("Congratulations")} !!</h2>
            <div className="my-4">
                <h4 className="text-dark text-center">{t("Transaction successful")} !</h4>
                <h5 className="text-center">{t("Your payment has been received, Please check your email for more details")}.</h5>
            </div>
            <div className="d-flex justify-content-center pb-4">
                <button
                    className="btn mt-10 btn-corner sign-up-btn right-15 text-white"
                    style={{ background: "#115278" }}
                    onClick={() => {
                        navigate("/dashboard");
                        window.location.reload();
                    }}
                >
                    {t("Back to home page")}
                </button>
            </div>
        </div>
    );
}

export default SuccessPage;
