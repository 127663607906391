import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import Cookies from "universal-cookie";
import Loader from "../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";

const SelectDental = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const cookies = new Cookies();
    const { t } = useTranslation();

    const [officeList, setOfficeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [officeId, setOfficeId] = useState("");

    const fetchProfiledata = async () => {
        setIsLoading(true);
        await axios
            .get(`${baseURL}/api/v1/doctor/profile`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                fetchOfficedata(res.data.data.id);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchOfficedata = async (doctorId) => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/doctor/doctor/office/list?isPagination=false`,
                {
                    doctorId: doctorId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleSelectDental = (officeId) => {
        setOfficeId(officeId);
    };

    const handleNext = async () => {
        if (!officeId) {
            toast.error(t(`Please select dental`));
        } else {
            cookies.set("office", officeId);
            navigate("/dashboard");
            window.location.reload();
        }
    };

    useEffect(() => {
        fetchProfiledata();
    }, []);

    return (
        <>
            <div className="container-fluid login-style">
                <div className="login-wrapper row  d-flex align-items-center">
                    <div className="login col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                {/* <h5 className="card-title">Special title treatment</h5> */}
                                <i className="fa fa-hospital-o d-flex justify-content-center" style={{ fontSize: "34px", color: "rgb(17 82 120)" }}></i>
                                <h3 className="card-text bold text-center" style={{ color: "rgb(17 82 120)" }}>
                                    Select Dental
                                </h3>
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <div className="card-body ">
                                        {officeList &&
                                            officeList.map((item, index) => {
                                                return (
                                                    <div className="form-check" key={index}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="selcetDental"
                                                            id={item.id}
                                                            value={item.id}
                                                            onChange={() => handleSelectDental(item.id)}
                                                        />
                                                        <label htmlFor={item.id}>
                                                            {item.name} ({item.businessUsername})
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                                <div className="pull-right">
                                    <button
                                        href="/login"
                                        className="btn mt-10 btn-corner signup login-btn mr-10"
                                        onClick={() => {
                                            sessionStorage.removeItem("token");
                                            navigate("/login");
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button className="btn mt-10 btn-corner sign-up-btn right-15 text-white" style={{ background: "#115278" }} onClick={handleNext}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectDental;
