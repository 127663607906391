import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, ROLE_ID } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

function DetailAgencyRevenueList(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();

    const { t } = useTranslation();

    const [agencyList, setAgencyList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.MANAGER ? "manager" : "";

    const fetchAgencydata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/agency/revenue-list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    id: props.agencyId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setAgencyList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        fetchAgencydata();
    }, [offset]);

    return (
        <>
            <section className="box1">
                <header className="panel_header">
                    <div className="mx-3">
                        <div className="row">
                            <div className="col-12 d-md-flex justify-content-end title">
                                {t("Total")} : ${props.agencyData && props.agencyData.totalRevenue}
                            </div>
                        </div>
                    </div>
                </header>
                <div className="content-body">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="table-responsive" data-pattern="priority-columns">
                                <table
                                    id="tech-companies-1"
                                    className={`table vm table-small-font no-mb table-bordered ${isLoading || !agencyList || (agencyList && !agencyList.length) ? "" : "table-striped"} table-hover`}
                                >
                                    <thead>
                                        <tr>
                                            <th>{t("Date")}</th>
                                            <th>{t("Agent")}</th>
                                            <th>{t("Patient")}</th>
                                            <th>{t("Membership Plan")}</th>
                                            <th>{t("Revenue")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!agencyList || (agencyList && !agencyList.length && !isLoading) ? (
                                            <NoDataFound />
                                        ) : isLoading ? (
                                            <Loader />
                                        ) : (
                                            agencyList &&
                                            agencyList.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{item.agentName ? item.agentName : "-"}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>{item.patient ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName) : "-"}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>${item.planName ? item.planName : 0}</h6>
                                                        </td>
                                                        <td>
                                                            <h6>${item.managerRevenue ? item.managerRevenue.toLocaleString("en-US") : 0}</h6>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-lg-flex justify-content-between ">
                                <div className="d-flex align-center">
                                    {t("Total")} {agencyList == null ? "0" : rowCount} {t("Revenue")}
                                    {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                </div>
                                <div className="pagination-center">
                                    <ReactPaginate
                                        initialPage={0}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={0}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        pageLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        previousLinkClassName="page-link"
                                        pageClassName="page-item"
                                        breakClassName="page-item"
                                        nextClassName="page-item"
                                        previousClassName="page-item"
                                        previousLabel={t("Previous")}
                                        nextLabel={t("Next")}
                                        disableInitialCallback={true}
                                        forcePage={currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DetailAgencyRevenueList;
