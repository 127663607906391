import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE, STATUS, STATUS_SEARCH } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import UpsertOfficeEmployee from "./UpsertOfficeEmployee";
import { useTranslation } from "react-i18next";
import Select from "react-select";

function OfficeEmployeeList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { officeData } = useOutletContext();
    const { t } = useTranslation();
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const [officeEmployeeList, setOfficeEmployeeList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        userName: "",
        statusId: 1,
        address1: "",
        officeId: profileData.roleId == ROLE_ID.ADMIN ? "" : officeData.id,
    });
    const [officeEmployeeId, setOfficeEmployeeId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
    });

    const [officeList, setOfficeList] = useState([]);
    let selectOffice = [
        {
            id: "",
            name: t("Select Office"),
        },
    ];
    const fetchOfficeList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/office/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                // setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onOfficeChange = (e) => {
        setFilterValue({ ...filterValue, officeId: e.value });
    };

    const fetchOfficeEmployeedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office-employee/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchOfficeEmployeeName: filterValue.searchOfficeEmployeeName,
                    searchOfficeEmployeeUsernameOrEmail: filterValue.searchOfficeEmployeeUsernameOrEmail,
                    statusId: filterValue.statusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeEmployeeList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setOfficeEmployeeId(id);
        officeEmployeeDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchOfficeEmployeedata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const officeEmployeeDetail = async (officeEmployeeId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/office-employee/detail/${officeEmployeeId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            userName: "",
            statusId: 1,
            address1: "",
            officeId: "",
        });
        setOfficeEmployeeId("");
        setIsEdit(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this office-employee ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/${apiRouteName}/office-employee/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchOfficeEmployeedata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchOfficeEmployeedata();
    }, [offset, filterValue.statusSearch, filterValue.officeId]);

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN) {
            fetchOfficeList();
        }
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Office-Employee")} </h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Office-Employee List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Office-Employee List")}</strong>
                                </li>
                            )}
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : t("Add")} {"Office-Employee"}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <UpsertOfficeEmployee
                            officeOptions={officeOptions}
                            editLoader={editLoader}
                            handleClose={handleClose}
                            fetchOfficeEmployeedata={fetchOfficeEmployeedata}
                            officeEmployeeId={officeEmployeeId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            isEdit={isEdit}
                            show={show}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-4 col-md-2 col-sm-12">
                                            <h2 className="title pull-left">
                                                <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                    <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                </button>
                                            </h2>
                                        </div>
                                        <div className="col-lg-8 col-md-10 col-sm-12 ">
                                            <div className="row mx-2">
                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                        <div className="my-4">
                                                            <Select
                                                                name="officeList"
                                                                value={officeOptions && officeOptions.filter(({ value }) => value == filterValue.officeId)}
                                                                options={officeOptions}
                                                                onChange={onOfficeChange}
                                                                placeholder={t("Select Office")}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-center">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchOfficeEmployeeUsernameOrEmail"
                                                            value={filterValue.searchOfficeEmployeeUsernameOrEmail}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search email or username")}
                                                            // style={{ width: "200px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex justify-content-end">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchOfficeEmployeeName"
                                                            value={filterValue.searchOfficeEmployeeName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("search name")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex justify-content-end">
                                                    <div className="title">
                                                        <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                            {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={STATUS_SEARCH[item]}>
                                                                        {t(item)}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !officeEmployeeList || (officeEmployeeList && !officeEmployeeList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Office")}</th> : ""}
                                                        <th>{t("E-mail")}</th>
                                                        <th>{t("Username")}</th>
                                                        <th>{t("Name")}</th>
                                                        <th>{t("Address")}</th>
                                                        <th>{t("Created Date")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!officeEmployeeList || (officeEmployeeList && !officeEmployeeList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        officeEmployeeList &&
                                                        officeEmployeeList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <td>
                                                                            <h6>{item.office ? item.office.name : "-"}</h6>
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.email ? item.email : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.userName ? item.userName : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.firstName ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.address1 ? item.address1 : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                            {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </button>
                                                                        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {officeEmployeeList == null ? "0" : rowCount} {t("Office-Employee")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default OfficeEmployeeList;
