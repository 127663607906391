import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../../helper/Config";
import { toast } from "react-toastify";
import Loader from "../../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";
import DocumentsTable from "../../managePatient/DocumentsTable";
import { STATUS } from "../../../../helper/Constant";
import { useState } from "react";

function UpsertProgress(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const [show, setShow] = useState(true);

    const handleAdd = async () => {
        if (!props.inputValue.title || props.inputValue.title === "" || props.inputValue.title === null) {
            toast.error(t(`Please enter title`));
        } else if (!props.inputValue.contents || props.inputValue.contents === "" || props.inputValue.contents === null) {
            toast.error(t(`Please enter contents`));
        } else if (!props.inputValue.statusId || props.inputValue.statusId === "" || props.inputValue.statusId === null) {
            toast.error(t(`Please select status`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/progress/upsert`,
                    {
                        progressId: props.isEdit ? props.progressId : "",
                        title: props.inputValue.title,
                        contents: props.inputValue.contents,
                        statusId: props.inputValue.statusId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    if (props.isEdit) {
                        setShow(true);
                        props.handleClose();
                    } else {
                        props.setProgressId(res.data.data.progressId);
                        setShow(false);
                    }
                    props.fetchProgressdata();
                    toast.success(res.data.message);
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    return (
        <>
            <div className="col-xs-12 ">
                <div className="add-header-wrapper gradient-blue curved-section text-center">
                    <h2 className="uppercase bold w-text">
                        {props.isEdit && !props.isView ? t("Update") : props.isView ? t("View") : t("Add")} {t("News")}
                    </h2>
                    <div className="before-text">
                        {props.isEdit && !props.isView ? t("Update") : props.isView ? t("View") : t("Add")} {t("News")}
                    </div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        {show ? (
                                            <>
                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Title")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="title"
                                                                    value={props.inputValue.title}
                                                                    onChange={props.isView ? () => {} : props.onChangeValue}
                                                                    disabled={props.isView ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Contents")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="contents"
                                                                    rows={5}
                                                                    cols={12}
                                                                    value={props.inputValue.contents}
                                                                    onChange={props.isView ? () => {} : props.onChangeValue}
                                                                    disabled={props.isView ? "disabled" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="form-group row">
                                                            <label className="control-label col-md-12">
                                                                {t("Status")} <span className="required"> * </span>
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select
                                                                    className="form-control col-md-3"
                                                                    name="statusId"
                                                                    value={props.inputValue.statusId}
                                                                    onChange={props.isView ? () => {} : props.onChangeValue}
                                                                    disabled={props.isView ? "disabled" : ""}
                                                                >
                                                                    {Object.keys(STATUS).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={STATUS[item]}>
                                                                                {item}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {props.isEdit ? (
                                                    ""
                                                ) : (
                                                    <div className="padding-bottom-30 d-flex justify-content-center">
                                                        <div className="text-left">
                                                            <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                                {t("Close")}
                                                            </button>
                                                            {props.isView ? (
                                                                ""
                                                            ) : (
                                                                <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleAdd}>
                                                                    {props.isEdit ? t("Update") : t("Add")}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {props.progressId ? (
                                            <>
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-center">
                                                        <DocumentsTable isView={props.isView} type="Attachment" title="Attachment" isFromInvester={true} progressId={props.progressId} />
                                                    </div>
                                                </div>
                                                {props.isEdit ? (
                                                    <div className="padding-bottom-30 d-flex justify-content-center">
                                                        <div className="text-left">
                                                            <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                                {t("Close")}
                                                            </button>
                                                            {props.isView ? (
                                                                ""
                                                            ) : (
                                                                <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleAdd}>
                                                                    {props.isEdit ? t("Update") : t("Add")}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="padding-bottom-30 d-flex justify-content-center">
                                                        <div className="text-left">
                                                            <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                                {t("Close")}
                                                            </button>
                                                            <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={() => props.handleClose()}>
                                                                {t("Finish")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertProgress;
