import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE } from "../../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, getPreviousYear, monthDateYear } from "../../../helper/dateFormates";
import UpsertEvaluation from "./UpsertEvaluation";
import { evaluationEmptyStars, evaluationRating, sliceString } from "../../../helper/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

function ManageEvaluationList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const cookies = new Cookies();

    const { t } = useTranslation();

    const { profileData } = useOutletContext();

    const [evaluationList, setEvaluationList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [isView, setIsView] = useState(false);
    const [inputValue, setInputValue] = useState({
        officeId: "",
        doctorId: "",
        dateOfService: "",
    });
    const [evaluationId, setEvaluationId] = useState("");
    const [filterValue, setFilterValue] = useState({
        // doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
        doctorId: "",
        officeId: profileData.roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "",
        searchPatientName: "",
        fromDate: getPreviousYear(new Date()),
        toDate: new Date(),
    });
    const [officeList, setOfficeList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const fetchEvaluationdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/dental-evaluation/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    doctorId: filterValue.doctorId,
                    officeId: filterValue.officeId,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : filterValue.patientId,
                    searchPatientName: profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.VERIFY ? filterValue.searchPatientName : "",
                    fromDate: dateAPIFormate(filterValue.fromDate),
                    toDate: dateAPIFormate(filterValue.toDate),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setEvaluationList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = (id) => {
        setEvaluationId(id);
        evaluationDetail(id);
        setShow(true);
    };

    const handleEdit = (id) => {
        setEvaluationId(id);
        evaluationDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const handleView = (id) => {
        setEvaluationId(id);
        evaluationDetail(id);
        setIsView(true);
        setIsEdit(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    let selectOffice = [
        {
            id: "",
            name: t("Office"),
        },
    ];

    const fetchOfficedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {
                    isClaimOffice: false,
                    isUserEvaluationOffice: true,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onOfficeChange = (e) => {
        setFilterValue({ ...filterValue, officeId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    let selectDoctor = [
        {
            id: "",
            firstName: t("Doctor"),
        },
    ];

    const fetchDoctorData = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    isClaimDoctor: false,
                    isInOffice: true,
                    isUserEvaluationDoctor: true,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data ? selectDoctor.concat(res.data.data) : selectDoctor);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorList &&
        doctorList.map((item) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const onFilterDoctorChange = (e) => {
        setFilterValue({ ...filterValue, doctorId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const evaluationDetail = async (evaluationId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/dental-evaluation/detail/${evaluationId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue("");
        setEvaluationId("");
        setIsEdit(false);
        setIsView(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this evaluation ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/${apiRouteName}/dental-evaluation/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchEvaluationdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        if (profileData.roleId != ROLE_ID.DOCTOR) {
            fetchDoctorData();
            fetchOfficedata();
        }
    }, []);

    useEffect(() => {
        fetchEvaluationdata();
    }, [offset, filterValue.doctorId, filterValue.officeId, filterValue.fromDate, filterValue.toDate]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage Evaluation")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Evaluation List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Evaluation List")}</strong>
                                </li>
                            )}
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : t("Add")} {t("Evaluation")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {show ? (
                        <UpsertEvaluation
                            editLoader={editLoader}
                            show={show}
                            isEdit={isEdit}
                            isView={isView}
                            handleClose={handleClose}
                            fetchEvaluationdata={fetchEvaluationdata}
                            fetchDoctorData={fetchDoctorData}
                            fetchOfficedata={fetchOfficedata}
                            evaluationId={evaluationId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box1">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        {/* {profileData.roleId == ROLE_ID.ADMIN ? (
                                            ""
                                        ) : (
                                            <div className="col-1">
                                                <h2 className="title pull-left">
                                                    <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                        <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                    </button>
                                                </h2>
                                            </div>
                                        )} */}
                                        <div className="col-lg-5 col-md-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg-3 col-md-3 col-sm-12">
                                                    <div className="title">{t("Date of service")}</div>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-7 d-flex justify-content-lg-center">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"Start date"}
                                                            maxDate={filterValue.toDate}
                                                            scrollableMonthDropdown={true}
                                                            selected={filterValue && defaultTimeZone(filterValue.fromDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, fromDate: date })}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                    <span className="title">{t("To")}</span>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-5">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"End date"}
                                                            maxDate={new Date()}
                                                            scrollableMonthDropdown={true}
                                                            minDate={defaultTimeZone(filterValue.fromDate)}
                                                            selected={filterValue && defaultTimeZone(filterValue.toDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, toDate: date })}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-12 col-sm-12 d-lg-flex justify-content-lg-center">
                                            <div className="row mx-2">
                                                {profileData.roleId == ROLE_ID.PATIENT ? (
                                                    ""
                                                ) : (
                                                    <div
                                                        className={`col-lg-${profileData.roleId == ROLE_ID.DOCTOR ? 12 : 4} col-md-${profileData.roleId == ROLE_ID.DOCTOR ? 12 : 4} col-sm-${
                                                            profileData.roleId == ROLE_ID.DOCTOR ? 12 : 4
                                                        } col-xs-${profileData.roleId == ROLE_ID.DOCTOR ? 12 : 12}`}
                                                    >
                                                        <div className="title">
                                                            <input
                                                                className="form-control"
                                                                name="searchPatientName"
                                                                value={filterValue.searchPatientName}
                                                                onChange={(e) => setFilterValue({ ...filterValue, searchPatientName: e.target.value })}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        fetchEvaluationdata();
                                                                        if (profileData.roleId != ROLE_ID.DOCTOR) {
                                                                            fetchDoctorData(1);
                                                                            fetchOfficedata(0);
                                                                        }
                                                                    }
                                                                }}
                                                                type="search"
                                                                placeholder={t("Patient")}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                    ""
                                                ) : (
                                                    <>
                                                        <div
                                                            className={`col-lg-${profileData.roleId == ROLE_ID.PATIENT ? 6 : 4} col-md-${profileData.roleId == ROLE_ID.PATIENT ? 6 : 4} col-sm-${
                                                                profileData.roleId == ROLE_ID.PATIENT ? 6 : 4
                                                            }  col-xs-${profileData.roleId == ROLE_ID.PATIENT ? 12 : 12}`}
                                                        >
                                                            <div className="my-4">
                                                                <Select
                                                                    name="officeList"
                                                                    value={officeOptions && officeOptions.filter(({ value }) => value == filterValue.officeId)}
                                                                    options={officeOptions}
                                                                    onChange={onOfficeChange}
                                                                    placeholder={t("Office")}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`col-lg-${profileData.roleId == ROLE_ID.PATIENT ? 6 : 4} col-md-${profileData.roleId == ROLE_ID.PATIENT ? 6 : 4} col-sm-${
                                                                profileData.roleId == ROLE_ID.PATIENT ? 6 : 4
                                                            } col-xs-${profileData.roleId == ROLE_ID.PATIENT ? 12 : 12}`}
                                                        >
                                                            <div className="my-4">
                                                                <Select
                                                                    name="doctorList"
                                                                    value={doctorOptions && doctorOptions.filter(({ value }) => value == filterValue.doctorId)}
                                                                    options={doctorOptions}
                                                                    onChange={onFilterDoctorChange}
                                                                    placeholder={t("Doctor")}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !evaluationList || (evaluationList && !evaluationList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        {profileData.roleId == ROLE_ID.DOCTOR ? "" : <th>{t("Office")}</th>}
                                                        <th>{t("Doctor")}</th>
                                                        {profileData.roleId == ROLE_ID.PATIENT ? "" : <th>{t("Patient")}</th>}
                                                        <th>{t("Date of service")}</th>
                                                        {profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT ? <th>{t("Benefit")}</th> : ""}
                                                        <th>{t("Evaluation")}</th>
                                                        <th>{t("Etc Opinion")}</th>
                                                        <th>{t("Created Date")}</th>
                                                        {profileData.roleId == ROLE_ID.PATIENT ? "" : <th>{t("Created by")}</th>}
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!evaluationList || (evaluationList && !evaluationList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        evaluationList &&
                                                        evaluationList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>{item.office && item.office.name ? item.office.name : "-"}</h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>
                                                                            {item.doctor
                                                                                ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>

                                                                    {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>
                                                                                {item.patient
                                                                                    ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName)
                                                                                    : "-"}
                                                                            </h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT ? (
                                                                        <td>${item.benefitAmount ? item.benefitAmount : 0}</td>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.evaluation ? evaluationRating(item.evaluation).concat(evaluationEmptyStars(item.evaluation)) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6 data-toggle="tooltip" title={item.etcOpinion && item.etcOpinion.length ? item.etcOpinion[0].value : ""}>
                                                                            {item.etcOpinion && item.etcOpinion.length ? item.etcOpinion[0] && sliceString(item.etcOpinion[0].value, 15) : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>
                                                                                {item.createdBy
                                                                                    ? (item.createdBy.firstName && item.createdBy.firstName) +
                                                                                      " " +
                                                                                      (item.createdBy.lastName && item.createdBy.lastName)
                                                                                    : "-"}
                                                                            </h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                            <button
                                                                                className={`btn btn-${item.evaluation ? "info" : "success"} btn-xs`}
                                                                                onClick={() => (item.evaluation ? handleEdit(item.id) : handleAdd(item.id))}
                                                                            >
                                                                                <i className={`fa fa-${item.evaluation ? "pencil" : "plus"} mt-1`}></i>
                                                                            </button>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {!item.evaluation ? (
                                                                            ""
                                                                        ) : (
                                                                            <button className="btn btn-warning btn-xs mx-2" onClick={() => handleView(item.id)}>
                                                                                <i className="fa fa-eye"></i>
                                                                            </button>
                                                                        )}
                                                                        {profileData.roleId == ROLE_ID.PATIENT && item.evaluation ? (
                                                                            <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                <i className="fa fa-trash-o "></i>
                                                                            </button>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {evaluationList == null ? "0" : rowCount} {t("Evaluation")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default ManageEvaluationList;
