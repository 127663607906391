import React, { useEffect, useState } from "react";
import { STATUS } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import { checkEmail, checkPhNo, checkPwd } from "../../../helper/Validator";
import AddServiceAndCost from "./AddServiceAndCost";
import Loader from "../../../commoncomponents/Loader";
import AddOffice from "../manageDoctor/AddOffice";
import { useTranslation } from "react-i18next";

function AssignDoctor(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        userName: "",
        // dob: "",
        phone1: "",
        // address1: "",
        statusId: 1,
    });
    const [officeInputValue, setOfficeInputValue] = useState({
        email: "",
        name: "",
        businessUsername: "",
        phone1: "",
        npi1: "",
        licenseNumber: "",
        languageId: "",
        businessCategoryId: "",
        statusId: "",
        address1: "",
        countryId: "",
        stateId: "",
        cityId: "",
        zip: "",
        googleMapLink: "",
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const [cfmpasswordShown, setCfmpasswordShown] = useState(false);
    const [step, setStep] = useState(1);
    const [doctorList, setDoctorList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [doctorId, setDoctorId] = useState("");
    const [isExisting, setIsExisting] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [officeId, setOfficeId] = useState("");
    const [isExistingOffice, setIsExistingOffice] = useState(false);
    const [specialityCheckedList, setSpecialityCheckedList] = useState([]);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const toggleCfmPassword = () => {
        setCfmpasswordShown(!cfmpasswordShown);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onChangeOfficeValue = (e) => {
        const { name, value } = e.target;
        setOfficeInputValue({ ...officeInputValue, [name]: value });
    };

    const fetchDoctorList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/doctor/list?isPagination=false`,
                {
                    officeId: props.officeId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorList &&
        doctorList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName + " (" + item.userName + ")",
            };
        });

    const onDoctorChange = (e) => {
        setDoctorId(e.value);
        setIsExisting(true);
    };

    const handlePrevious = () => {
        setDoctorId("");
        setIsExisting(false);
    };

    const handleExistingAdd = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/doctor/assign`,
                {
                    doctorId: doctorId,
                    officeId: props.officeId,
                    isExistDoctor: true,
                    statusId: STATUS.Active,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                props.fetchDoctordata();
                toast.success(res.data.message);
                props.handleClose();
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleAssign = async () => {
        if (!inputValue.email || inputValue.email === "" || inputValue.email === null) {
            toast.error(t(`Please enter email`));
        } else if (inputValue.email && checkEmail(inputValue.email) == false) {
            toast.error(t(`Please enter valid email`));
        } else if (!inputValue.password || inputValue.password === "" || inputValue.password === null) {
            toast.error(t(`Please enter password`));
        } else if (inputValue.password && checkPwd(inputValue.password) == false) {
            toast.error(t(`Please enter valid password`));
        } else if (!inputValue.confirmPassword || inputValue.confirmPassword === "" || inputValue.confirmPassword === null) {
            toast.error(t(`Please enter confirm password`));
        } else if (inputValue.password !== inputValue.confirmPassword) {
            toast.error(t(`Password and Confirm Password must be same`));
        } else if (!inputValue.firstName || inputValue.firstName === "" || inputValue.firstName === null) {
            toast.error(t(`Please enter first name`));
        } else if (!inputValue.lastName || inputValue.lastName === "" || inputValue.lastName === null) {
            toast.error(t(`Please enter last name`));
        } else if (!inputValue.userName || inputValue.userName === "" || inputValue.userName === null) {
            toast.error(t(`Please enter username`));
            // } else if (!inputValue.dob || inputValue.dob === "" || inputValue.dob === null) {
            //     toast.error(t(`Please select date of birth`));
        } else if (!inputValue.phone1 || inputValue.phone1 === "" || inputValue.phone1 === null) {
            toast.error(t(`Please enter Mobile Number`));
        } else if (inputValue.phone1 && checkPhNo(inputValue.phone1) == false) {
            toast.error(t(`Please enter 10 digit Mobile Number`));
        } else if ((props.isFromDoctorList == false || !props.isFromDoctorList) && (!inputValue.statusId || inputValue.statusId === "" || inputValue.statusId === null)) {
            toast.error(t(`Please select status`));
            // } else if (!inputValue.address1 || inputValue.address1 === "" || inputValue.address1 === null) {
            //     toast.error(t(`Please enter address`));
        } else if (props.isFromDoctorList) {
            await axios
                .post(
                    `${baseURL}/api/v1/check`,
                    {
                        step: 1,
                        userName: inputValue.userName,
                        email: inputValue.email,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    if (res.data.success == true) {
                        if (props.isFromDoctorList) {
                            setStep(2);
                        } else {
                            setStep(3);
                        }
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/admin/doctor/assign`,
                    {
                        officeId: props.officeId,
                        isExistDoctor: false,
                        email: inputValue.email,
                        password: inputValue.password,
                        firstName: inputValue.firstName,
                        lastName: inputValue.lastName,
                        userName: inputValue.userName,
                        // dob: dateAPIFormate(inputValue.dob),
                        phone1: inputValue.phone1,
                        // address1: inputValue.address1,
                        statusId: inputValue.statusId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    toast.success(res.data.message);
                    props.handleClose();
                    props.fetchDoctordata();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        fetchDoctorList();
    }, []);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{props.isFromDoctorList ? t("Register Doctor") : t("Assign Doctor")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer" onClick={props.backToDoctorList}>
                                <strong>{props.isFromOfficeList ? t("Office List") : t("Doctor list")}</strong>
                            </li>
                            {props.isFromDoctorList ? (
                                ""
                            ) : (
                                <li className="active cursor-pointer" onClick={props.handleClose}>
                                    <strong>{t("Doctor-Office List")}</strong>
                                </li>
                            )}
                            <li className="active cursor-pointer">
                                <strong>{props.isFromDoctorList ? t("Register Doctor") : t("Assign Doctor")}</strong>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 ">
                <div className="add-header-wrapper gradient-blue curved-section text-center">
                    <h2 className="uppercase bold w-text">{props.isFromDoctorList ? t("Register Doctor") : t("Assign Doctor")}</h2>
                    <div className="before-text">{props.isFromDoctorList ? t("Register Doctor") : t("Assign Doctor")}</div>
                    {props.isFromDoctorList ? (
                        <p className="g-text">
                            {step == 1 ? t("Doctor") : step == 2 ? t("Office") : step == 3 ? t("Service And Cost") : ""} {t("Information")}
                        </p>
                    ) : (
                        <p className="g-text">
                            {t("Office")}: {props.officeDetail && props.officeDetail.name}
                            <br />
                            {t("Business Username")}: {props.officeDetail && props.officeDetail.businessUsername}
                        </p>
                    )}
                </div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className={`bg-w${isExisting || isExistingOffice ? "1" : ""}`}>
                        <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                            <section className="box1">
                                {step == 1 && (!props.isFromDoctorList || props.isFromDoctorList == false) ? (
                                    <div className="content-body my-4 border-0">
                                        <label className="control-label col-12">
                                            {t("Doctor")} <span className="required"> * </span>
                                        </label>
                                        <Select
                                            name="doctorList"
                                            value={doctorOptions && doctorOptions.filter(({ value }) => value == doctorId)}
                                            options={doctorOptions}
                                            onChange={onDoctorChange}
                                            placeholder={t("Select")}
                                        />
                                        {isExisting ? (
                                            <div className="padding-bottom-30 d-flex justify-content-center mt-4">
                                                <div className="text-left">
                                                    <button type="button" className="btn mx-2 btn-primary gradient-blue mx-2" onClick={handleExistingAdd}>
                                                        {t("Add")}
                                                    </button>
                                                    <button type="button" className="btn btn-warning" onClick={handlePrevious}>
                                                        {t("Previous")}
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {isExisting ? (
                                    ""
                                ) : (
                                    <>
                                        {step == 1 && (!props.isFromDoctorList || props.isFromDoctorList == false) ? <span className="d-flex justify-content-center mt-4"> -- {t("OR")} --</span> : ""}
                                        <div className={`content-body my-4 border-0 ${step == 1 ? "" : "d-none"}`}>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-12">
                                                            {t("E-mail")} <span className="required"> * </span>
                                                        </label>

                                                        <div className="col-12">
                                                            <input type="email" className="form-control" name="email" value={inputValue.email} onChange={onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Password")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12 d-flex">
                                                                    <input
                                                                        type={passwordShown ? "text" : "password"}
                                                                        className="form-control"
                                                                        name="password"
                                                                        value={inputValue.password}
                                                                        onChange={onChangeValue}
                                                                    />
                                                                    <i
                                                                        className={`${passwordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                        id="basic-addon1"
                                                                        onClick={togglePassword}
                                                                    ></i>
                                                                </div>
                                                                <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                    *{t("At least 8 characters : uppercase, lowercase, numeric, or special characters")}.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Confirm Password")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12 d-flex">
                                                                    <input
                                                                        type={cfmpasswordShown ? "text" : "password"}
                                                                        className="form-control"
                                                                        name="confirmPassword"
                                                                        value={inputValue.confirmPassword}
                                                                        onChange={onChangeValue}
                                                                    />
                                                                    <i
                                                                        className={`${cfmpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"} input-group-text d-flex align-items-center`}
                                                                        id="basic-addon1"
                                                                        onClick={toggleCfmPassword}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("First name")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <input type="text" className="form-control" name="firstName" value={inputValue.firstName} onChange={onChangeValue} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Last name")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <input type="text" className="form-control" name="lastName" value={inputValue.lastName} onChange={onChangeValue} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xs-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Username")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <input type="text" className="form-control" name="userName" value={inputValue.userName} onChange={onChangeValue} />
                                                                    <p className="mb-0 pb-0 mx-3" style={{ fontSize: "10px", color: "grey" }}>
                                                                        *{t("At least 5 characters : start and end with character or number, can have special character")}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <div className="form-group row">
                                                                <label className="control-label col-md-12">
                                                                    {t("Phone")} <span className="required"> * </span>
                                                                </label>

                                                                <div className="col-md-12">
                                                                    <input type="number" className="form-control" name="phone1" value={inputValue.phone1} onChange={onChangeValue} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        {props.isFromDoctorList ? (
                                                            ""
                                                        ) : (
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-group row">
                                                                    <label className="control-label col-md-12">
                                                                        {t("Status")} <span className="required"> * </span>
                                                                    </label>

                                                                    <div className="col-md-12">
                                                                        <select className="form-control col-md-3" name="statusId" value={inputValue.statusId} onChange={onChangeValue}>
                                                                            {Object.keys(STATUS).map((item, i) => {
                                                                                return (
                                                                                    <option key={i} value={STATUS[item]}>
                                                                                        {item}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="padding-bottom-30 d-flex justify-content-center">
                                                    <div className="text-left">
                                                        <button type="button" className="btn mx-2 btn-secondary" onClick={() => props.setShow(false)}>
                                                            {t("Close")}
                                                        </button>
                                                        <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={handleAssign}>
                                                            {props.isFromDoctorList ? t("Next") : t("Assign")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {props.isFromDoctorList ? (
                                            <AddOffice
                                                isExistingOffice={isExistingOffice}
                                                setIsExistingOffice={setIsExistingOffice}
                                                officeId={officeId}
                                                setOfficeId={setOfficeId}
                                                checkedList={checkedList}
                                                setCheckedList={setCheckedList}
                                                isFromDoctorList={props.isFromDoctorList}
                                                handleClose={props.handleClose}
                                                step={step}
                                                setStep={setStep}
                                                fetchDoctordata={props.fetchDoctordata}
                                                inputValue={inputValue}
                                                officeInputValue={officeInputValue}
                                                setOfficeInputValue={setOfficeInputValue}
                                                onChangeValue={onChangeOfficeValue}
                                            />
                                        ) : (
                                            ""
                                        )}

                                        <AddServiceAndCost
                                            specialityCheckedList={specialityCheckedList}
                                            setSpecialityCheckedList={setSpecialityCheckedList}
                                            isExistingOffice={isExistingOffice}
                                            setIsExistingOffice={setIsExistingOffice}
                                            officeId={officeId}
                                            setOfficeId={setOfficeId}
                                            checkedList={checkedList}
                                            isFromDoctorList={props.isFromDoctorList}
                                            officeInputValue={officeInputValue}
                                            inputValue={inputValue}
                                            step={step}
                                            setStep={setStep}
                                            handleClose={props.handleClose}
                                            fetchDoctordata={props.fetchDoctordata}
                                        />
                                    </>
                                )}
                            </section>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default AssignDoctor;
