import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Loader from "../../../commoncomponents/Loader";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import { baseURL } from "../../../helper/Config";
import { ROLE_ID, ROWS_PER_PAGE } from "../../../helper/Constant";
import { displaySpecialityList, displayLanguageList } from "../../../helper/helper";
import AddServiceAndCost from "../../adminPanel/manageOffice/AddServiceAndCost";

function SearchDental() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [offset, setOffset] = useState(1);
    const [showOffice, setShowOffice] = useState(false);
    const [isOfficeDataLoading, setIsOfficeDataLoading] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [specialityList, setSpecialityList] = useState([]);
    const [show, setShow] = useState(false);
    const [officeId, setOfficeId] = useState("");
    const [officeName, setOfficeName] = useState("");
    const [specialityCheckedList, setSpecialityCheckedList] = useState([]);
    const [inputValue, setInputValue] = useState({
        countryId: "",
        stateId: "",
        languageId: "",
        specialityId: "",
        zip: "",
        officeName: "",
        doctorName: "",
    });
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
                ? "patient"
                : profileData.roleId == ROLE_ID.DOCTOR
                    ? "doctor"
                    : profileData.roleId == ROLE_ID.VERIFY
                        ? "verifier"
                        : "";

    const handleShowDetails = (officeId, officeName, specialtyPartId) => {
        setOfficeId(officeId);
        setOfficeName(officeName);
        setSpecialityCheckedList(specialtyPartId && specialtyPartId.split("~").map((item) => Number(item)));
        setShow(true);
    };

    const handleClose = () => {
        setOfficeId("");
        setShow(false);
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
        setOffset(selectedPage + 1);
    };

    let select = [
        {
            id: "",
            name: t("Select"),
        },
    ];

    const fetchSpecialityList = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/speciality-part/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSpecialityList(res.data.data ? select.concat(res.data.data) : select);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const specialityOptions =
        specialityList &&
        specialityList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onSpecialityChange = (e) => {
        setInputValue({ ...inputValue, specialityId: e.value });
    };

    const fetchLanguageList = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/language/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setLanguageList(res.data.data ? select.concat(res.data.data) : select);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const languageOptions =
        languageList &&
        languageList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onLanguageChange = (e) => {
        setInputValue({ ...inputValue, languageId: e.value });
    };

    const fetchCountryList = async () => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/country/list?isPagination=false`, {
                isId: true,
            })
            .then((res) => {
                setCountryList(res.data.data ? select.concat(res.data.data) : select);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const countryOptions =
        countryList &&
        countryList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onCountryChange = (e) => {
        setInputValue({ ...inputValue, countryId: e.value, stateId: "", cityId: "" });
        setStateList([]);
        fetchStateList(e.value);
    };

    const fetchStateList = async (countryId) => {
        setIsLoading(true);
        await axios
            .post(`${baseURL}/api/v1/state/list?isPagination=false`, {
                countryId: countryId,
                isId: true,
                isLimited: true,
            })
            .then((res) => {
                setStateList(res.data.data ? select.concat(res.data.data) : select);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const stateOptions =
        stateList &&
        stateList.map((item) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onStateChange = (e) => {
        setInputValue({ ...inputValue, stateId: e.value });
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const handleSearch = async (click) => {
        setIsOfficeDataLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/dental/search/list?isPagination=true`,
                {
                    rowsPerPage: ROWS_PER_PAGE,
                    page: click ? 1 : offset,
                    searchDoctorName: inputValue.doctorName,
                    searchOfficeName: inputValue.officeName,
                    searchLanguage: inputValue.languageId,
                    searchSpecialtyPart: inputValue.specialityId,
                    countryId: inputValue.countryId,
                    stateId: inputValue.stateId,
                    zip: inputValue.zip,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setIsOfficeDataLoading(false);
                if (!res.data.extraData) {
                    setPageCount(0);
                    setRowCount(0);
                } else {
                    setPageCount(res.data.extraData.pageCount);
                    setRowCount(res.data.extraData.rowCount);
                }
                setShowOffice(true);
            })
            .catch((err) => {
                setIsOfficeDataLoading(false);
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        fetchCountryList();
        fetchSpecialityList();
        fetchLanguageList();
    }, []);

    useEffect(() => {
        if (showOffice) {
            handleSearch();
        }
    }, [offset]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{show ? `service and cost information of ${officeName}` : t("Search Dental")}</h1>
                    </div>
                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className="active cursor-pointer" onClick={show ? handleClose : ""}>
                                <strong>{t("Search Dental")}</strong>
                            </li>
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>{t("Service-Cost Details")}</strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            {show ? (
                <div className="bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        <section className="box1">
                            <AddServiceAndCost
                                view={true}
                                isfromSearchDental={true}
                                specialityCheckedList={specialityCheckedList}
                                setSpecialityCheckedList={setSpecialityCheckedList}
                                officeId={officeId}
                                step={3}
                                handleClose={handleClose}
                            />
                        </section>
                    </div>
                </div>
            ) : (
                <div className="col-lg-12">
                    <section className="nobox marginBottom0" style={{ margin: "15px" }}>
                        <div className="content-body">
                            <div className="row">
                                <div className="col-lg-10 col-md-12 col-sm-12 offset-xl-1">
                                    {isLoading ? (
                                        <Loader />
                                    ) : (
                                        <section className={`gradient-${profileData.roleId == ROLE_ID.ADMIN ? "blue2" : "orange1"}`} style={{ padding: "20px", borderRadius: "16px" }}>
                                            <h2>{t("Find a dentist near you")}</h2>
                                            <h5>
                                                {t("Don't see your dentist? Nominate them to join the G-Denticare network")}&nbsp;
                                                <a href="https://gdenti.com/" target="_blank">
                                                    <u>{t("here")}</u>
                                                </a>
                                                .{" "}
                                            </h5>
                                            <hr className="my-3" style={{ border: "0.1rem solid #fff" }} />
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12 ">{t("Country")}</label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="countryList"
                                                                value={countryOptions && countryOptions.filter(({ value }) => value == inputValue.countryId)}
                                                                options={countryOptions}
                                                                onChange={onCountryChange}
                                                                placeholder={t("Select")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("State")}</label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="stateList"
                                                                value={stateOptions && stateOptions.filter(({ value }) => value == inputValue.stateId)}
                                                                options={stateOptions}
                                                                onChange={onStateChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={inputValue.countryId ? false : true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Zip Code")}</label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="zip" value={inputValue.zip} onChange={onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Dentist")}</label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="doctorName" value={inputValue.doctorName} onChange={onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Dental Office")}</label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" name="officeName" value={inputValue.officeName} onChange={onChangeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Language")}</label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="languageList"
                                                                value={languageOptions && languageOptions.filter(({ value }) => value == inputValue.languageId)}
                                                                options={languageOptions}
                                                                onChange={onLanguageChange}
                                                                placeholder={t("Select")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Specialty")}</label>

                                                        <div className="col-md-12">
                                                            <Select
                                                                name="specialityList"
                                                                value={specialityOptions && specialityOptions.filter(({ value }) => value == inputValue.specialityId)}
                                                                options={specialityOptions}
                                                                onChange={onSpecialityChange}
                                                                placeholder={t("Select")}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="padding-bottom-30 d-flex justify-content-center">
                                                <div className="text-left">
                                                    <button
                                                        type="button"
                                                        className={`btn mx-2 gradient-${profileData.roleId == ROLE_ID.ADMIN ? "violet" : "orange"}`}
                                                        onClick={() => handleSearch(true)}
                                                    >
                                                        <p className="g-text mb-0">
                                                            {isOfficeDataLoading ? (
                                                                <i
                                                                    className="fa fa-spinner fa-spin"
                                                                    style={{
                                                                        position: "relative",
                                                                    }}
                                                                ></i>
                                                            ) : (
                                                                t("Search")
                                                            )}
                                                        </p>
                                                    </button>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </div>
                            </div>
                            {showOffice && !isOfficeDataLoading ? (
                                <div className="row mt-4">
                                    <div className="col-lg-10 col-md-12 col-sm-12 offset-xl-1">
                                        <section className={`gradient-${profileData.roleId == ROLE_ID.ADMIN ? "blue2" : "orange1"}`} style={{ padding: "20px", borderRadius: "16px" }}>
                                            {!rowCount || rowCount == 0 || rowCount <= 0 ? (
                                                <div className="d-flex justify-content-center">
                                                    <NoDataFound />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="d-lg-flex justify-content-between ">
                                                        <div>
                                                            <h3 className="mt-4">
                                                                {t("All Result")}
                                                                {/* {rowCount && rowCount > 1 ? "s" : ""} ({rowCount}) */}
                                                            </h3>
                                                            <ComponentToPrintWrapper inputValue={inputValue} />
                                                        </div>
                                                        {rowCount > 0 ? (
                                                            <div className="pagination-center">
                                                                <ReactPaginate
                                                                    initialPage={0}
                                                                    pageCount={pageCount}
                                                                    marginPagesDisplayed={0}
                                                                    pageRangeDisplayed={3}
                                                                    onPageChange={handlePageClick}
                                                                    containerClassName="pagination"
                                                                    activeClassName="active"
                                                                    pageLinkClassName="page-link"
                                                                    breakLinkClassName="page-link"
                                                                    nextLinkClassName="page-link"
                                                                    previousLinkClassName="page-link"
                                                                    pageClassName="page-item"
                                                                    breakClassName="page-item"
                                                                    nextClassName="page-item"
                                                                    previousClassName="page-item"
                                                                    previousLabel={t("Previous")}
                                                                    nextLabel={t("Next")}
                                                                    disableInitialCallback={true}
                                                                    forcePage={currentPage}
                                                                />
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <hr className="mt-2" style={{ border: "0.1rem solid #fff" }} />
                                                    {officeList &&
                                                        officeList.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <div className="row" key={i}>
                                                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                                                            <div className="cursor-pointer" onClick={() => handleShowDetails(item.id, item.name, item.specialtyPartId)}>
                                                                                <i className="fa fa-briefcase mx-2" aria-hidden="true"></i>
                                                                                <u>{item.name}</u>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <h6 className="my-1">
                                                                                    <b>Speciality: </b>
                                                                                    {item.specialtyPartId && item.specialtyPartId != 0
                                                                                        ? displaySpecialityList(officeList && item.specialtyPartId, specialityList).toString()
                                                                                        : "--"}
                                                                                </h6>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <h6 className="my-1">
                                                                                    <b>Language: </b>
                                                                                    {item.languageId && item.languageId != 0
                                                                                        ? displayLanguageList(officeList && item.languageId, languageList).toString()
                                                                                        : "--"}
                                                                                </h6>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <h6 className="my-1">
                                                                                    <b>Doctors: </b>
                                                                                    {item.doctor && item.doctor.length
                                                                                        ? item.doctor.map(
                                                                                            (doctor, i) =>
                                                                                                doctor &&
                                                                                                (doctor.firstName && doctor.firstName) +
                                                                                                " " +
                                                                                                (doctor.lastName && doctor.lastName) +
                                                                                                (i == item.doctor.length - 1 ? "" : ",")
                                                                                        )
                                                                                        : "--"}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                                                            <i className="fa fa-phone mx-2" aria-hidden="true"></i>
                                                                            {item.phone1}
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                                                            <i className="fa fa-map-marker mx-2" aria-hidden="true"></i>
                                                                            <a href={item.googleMapLink} target="_blank">
                                                                                {item.address1}
                                                                                {item.city && item.city.name ? ", " + item.city.name : ""}
                                                                                {item.state && item.state.name ? ", " + item.state.name : ""}
                                                                                {item.zip ? ", " + item.zip : ""}
                                                                                {/* {item.country && item.country.name ? ", " + item.country.name : ""} */}
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-6 col-sm-12">
                                                                            <i className="fa fa-globe mx-2" aria-hidden="true"></i>
                                                                            <a href={item.googleMapLink} target="_blank">
                                                                                <u>View on Map</u>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-2" style={{ border: "0.1rem solid #fff" }} />
                                                                </>
                                                            );
                                                        })}
                                                </>
                                            )}
                                        </section>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}

export default SearchDental;

const ComponentToPrint = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { t } = useTranslation();
    const { profileData } = useOutletContext();
    const { inputValue } = props;
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
                ? "patient"
                : profileData.roleId == ROLE_ID.DOCTOR
                    ? "doctor"
                    : profileData.roleId == ROLE_ID.VERIFY
                        ? "verifier"
                        : "";

    const [officeList, setOfficeList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [specialityList, setSpecialityList] = useState([]);

    const fetchLanguageList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/language/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setLanguageList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchSpecialityList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/speciality-part/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSpecialityList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleSearch = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/dental/search/list?isPagination=false`,
                {
                    searchDoctorName: inputValue.doctorName,
                    searchOfficeName: inputValue.officeName,
                    searchLanguage: inputValue.languageId,
                    searchSpecialtyPart: inputValue.specialityId,
                    countryId: inputValue.countryId,
                    stateId: inputValue.stateId,
                    zip: inputValue.zip,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        handleSearch();
        fetchSpecialityList();
        fetchLanguageList();
    }, []);

    return (
        <div className="print-source" ref={ref}>
            <h3 className="my-4">{t("Dental list")}</h3>
            <hr className="my-2" style={{ border: "0.1rem solid black" }} />
            {officeList &&
                officeList.map((item, i) => {
                    return (
                        <>
                            <div className="row" key={i}>
                                <div className="col-4">
                                    <i className="fa fa-briefcase mx-2" aria-hidden="true"></i>
                                    {item.name}
                                    <br />
                                    <div className="d-flex">
                                        <h6 className="my-1 mx-1">
                                            <b>{t("Specialty")}: </b>
                                            {item.specialtyPartId && item.specialtyPartId != 0 ? displaySpecialityList(officeList && item.specialtyPartId, specialityList).toString() : "--"}
                                        </h6>
                                    </div>
                                    <div className="d-flex">
                                        <h6 className="my-1  mx-1">
                                            <b>{t("Language")}: </b>
                                            {item.languageId && item.languageId != 0 ? displayLanguageList(officeList && item.languageId, languageList).toString() : "--"}
                                        </h6>
                                    </div>
                                    <div className="d-flex">
                                        <h6 className="my-1  mx-1">
                                            <b>{t("Doctor")}: </b>
                                            {item.doctor && item.doctor.length
                                                ? item.doctor.map(
                                                    (doctor, i) =>
                                                        doctor && (doctor.firstName && doctor.firstName) + " " + (doctor.lastName && doctor.lastName) + (i == item.doctor.length - 1 ? "" : ",")
                                                )
                                                : "--"}
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <i className="fa fa-phone mx-2" aria-hidden="true"></i>
                                    {item.phone1}
                                </div>
                                <div className="col-4">
                                    <i className="fa fa-map-marker mx-2" aria-hidden="true"></i>
                                    <a href={item.googleMapLink} target="_blank">
                                        {item.address1}
                                        {item.city && item.city.name ? ", " + item.city.name : ""}
                                        {item.state && item.state.name ? ", " + item.state.name : ""}
                                        {item.zip ? "-" + item.zip : ""}
                                        {/* {item.country && item.country.name ? ", " + item.country.name : ""} */}
                                    </a>
                                </div>
                                <div className="col-2">
                                    <i className="fa fa-globe mx-2" aria-hidden="true"></i>
                                    <a href={item.googleMapLink} target="_blank">
                                        <u>{t("View Map")}</u>
                                    </a>
                                </div>
                            </div>
                            <hr className="my-2" style={{ border: "0.1rem solid black" }} />
                        </>
                    );
                })}
        </div>
    );
});

const ComponentToPrintWrapper = ({ inputValue }) => {
    const componentRef = useRef();

    return (
        <>
            <ReactToPrint
                trigger={() => (
                    <div className="cursor-pointer">
                        <u>{t("Print Result")}</u>
                    </div>
                )}
                content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
                <ComponentToPrint ref={componentRef} inputValue={inputValue} />
            </div>
        </>
    );
};
