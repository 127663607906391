import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, CLAIM_STATUS, CLAIM_STATUS_SEARCH, ROLE_ID } from "../../../helper/Constant";
import { dateAPIFormate, defaultTimeZone, getPreviousYear, monthDateYear } from "../../../helper/dateFormates";
import UpsertClaim from "./UpsertClaim";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

function ClaimList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const cookies = new Cookies();

    const { profileData, officeData } = useOutletContext();

    const { t } = useTranslation();

    const [claimList, setClaimList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [isView, setIsView] = useState(false);
    const [claimId, setClaimId] = useState("");
    const [filterValue, setFilterValue] = useState({
        // doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
        doctorId: "",
        code: "",
        searchPatientName: "",
        officeId: profileData.roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "",
        statusSearch: "",
        fromDate: getPreviousYear(new Date()),
        toDate: new Date(),
    });

    const [officeId, setOfficeId] = useState(profileData.roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "");
    const [doctorId, setDoctorId] = useState(profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "");
    // const [doctorId, setDoctorId] = useState("");
    const [patientId, setPatientId] = useState("");
    const [editPatient, setEditPatient] = useState("");
    const [isGDenticarePlan, setIsGDenticarePlan] = useState(false);
    const [doctorList, setDoctorList] = useState([]);
    const [dateOfService, setDateOfService] = useState("");
    const [sum, setSum] = useState([]);
    const [inputValueObj, setInputValueObj] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const [doctorFilterList, setDoctorFilterList] = useState([]);
    const [claimData, setClaimData] = useState([]);

    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const fetchClaimdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/claim/list`,
                {
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    doctorId: filterValue.doctorId,
                    code: filterValue.code,
                    searchPatientName: filterValue.searchPatientName,
                    officeId: filterValue.officeId,
                    fromDate: dateAPIFormate(filterValue.fromDate),
                    toDate: dateAPIFormate(filterValue.toDate),
                    isSubmitted: filterValue.statusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setClaimList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        if (profileData.roleId == ROLE_ID.DOCTOR) {
            fetchDoctorPatientdata(officeData.id);
        }
        setShow(true);
    };

    const handleEdit = (id, officeId, isSubmitted) => {
        setClaimId(id);
        claimDetail(id);
        fetchTreatmentTypeData(id, officeId);
        if (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT) {
            fetchDoctorList(officeId);
        }
        fetchDoctorPatientdata(officeId);
        setIsEdit(true);
        setShow(false);
        setIsSubmitted(isSubmitted == CLAIM_STATUS.Submitted ? true : false);
    };

    const handleView = (id, officeId) => {
        setClaimId(id);
        claimDetail(id);
        fetchTreatmentTypeData(id, officeId);
        if (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT) {
            fetchDoctorList(officeId);
        }
        setIsView(true);
    };

    let selectOffice = [
        {
            id: "",
            name: t("Office"),
        },
    ];

    const fetchOfficedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {
                    isClaimOffice: true,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onFilterOfficeChange = (e) => {
        setFilterValue({ ...filterValue, officeId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    let selectPatient = [
        {
            id: "",
            firstName: t("Select"),
            lastName: "",
            userName: "",
        },
    ];
    const [patientList, setPatientList] = useState([]);

    const fetchDoctorPatientdata = async (officeId) => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/patient/list?isPagination=false`,
                {
                    officeId: profileData.roleId == ROLE_ID.DOCTOR ? officeData.id : officeId,
                    isGDenticarePatient: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setPatientList(res.data.data ? selectPatient.concat(res.data.data) : selectPatient);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let selectDoctor = [
        {
            id: "",
            firstName: t("Doctor"),
        },
    ];

    const fetchDoctorData = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    isClaimDoctor: true,
                    isInOffice: true,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorFilterList(res.data.data ? selectDoctor.concat(res.data.data) : selectDoctor);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorFilterList &&
        doctorFilterList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const onFilterDoctorChange = (e) => {
        setFilterValue({ ...filterValue, doctorId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchClaimdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const claimDetail = async (claimId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/claim/detail/${claimId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                let response = res.data.data;
                setOfficeId(response.office.id);
                setDoctorId(response.doctor.id);
                setEditPatient(response.patient.id);
                setPatientId(response.patient.id);
                setIsGDenticarePlan(response.patient.isGDenticarePlan);
                setDateOfService(response.dateOfService);
                setClaimData(response);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const fetchTreatmentTypeData = async (claimId, officeId) => {
        axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/treatment/list?isPagination=false`,
                {
                    claimId: claimId,
                    officeId: officeId,
                    isListAllRecord: false,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                let obj = [];

                res.data.data &&
                    res.data.data.map((item) => {
                        obj.push({
                            treatmentTypeId: item.treatmentTypeId,
                            treatmentId: Number(item.id),
                            fee: Number(item.originalFee),
                            unit: item.unit,
                            totalUnitFee: item.totalUnitFee,
                            totalUnitFeeWithoutDiscount: item.totalUnitFeeWithoutDiscount,
                            discountFee: item.discountFee,
                            originalFee: item.originalFee,
                        });
                    });
                setInputValueObj(obj);
                let sum =
                    res.data.data &&
                    res.data.data.reduce((acc, obj) => {
                        if (!acc[obj.treatmentTypeId]) {
                            acc[obj.treatmentTypeId] = 0;
                        }
                        acc[obj.treatmentTypeId] += obj.totalUnitFee;
                        return acc;
                    }, {});

                setSum(sum);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setClaimId("");
        if (profileData.roleId == ROLE_ID.ADMIN) {
            setDoctorId("");
            setOfficeId("");
            setDoctorList([]);
        }
        setInputValueObj([]);
        setSum([]);
        setPatientId("");
        setEditPatient("");
        setPatientList([]);
        // setOfficeList([]);
        setIsGDenticarePlan(false);
        setDateOfService("");
        setIsEdit(false);
        setShow(false);
        setIsSubmitted(false);
        setIsView(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this claim ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/${apiRouteName}/claim/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchClaimdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const fetchDoctorList = async (officeId) => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    officeId: officeId,
                    isClaimDoctor: profileData.roleId == ROLE_ID.PATIENT || profileData.roleId == ROLE_ID.ADMIN ? false : true,
                    isInOffice: true,
                    isUserEvaluationDoctor: profileData.roleId == ROLE_ID.PATIENT ? true : false,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        // fetchClaimdata();
        if (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT) {
            fetchOfficedata();
            fetchDoctorData();
        }
    }, []);

    useEffect(() => {
        fetchClaimdata();
    }, [offset, filterValue.fromDate, filterValue.toDate, filterValue.statusSearch, filterValue.officeId, filterValue.doctorId]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage claim")}</h1>
                    </div>

                    <div className="pull-right ">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show || isEdit || isView ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Claim list")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Claim list")}</strong>
                                </li>
                            )}
                            {show || isEdit || isView ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : isView ? t("View") : t(t("Add"))} {t("Claim")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {((show || isEdit) && (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.DOCTOR)) || isView ? (
                        <UpsertClaim
                            fetchDoctorPatientdata={fetchDoctorPatientdata}
                            patientList={patientList}
                            setPatientList={setPatientList}
                            fetchOfficedata={fetchOfficedata}
                            fetchDoctorData={fetchDoctorData}
                            editLoader={editLoader}
                            sum={sum}
                            setSum={setSum}
                            inputValueObj={inputValueObj}
                            setInputValueObj={setInputValueObj}
                            setDoctorList={setDoctorList}
                            doctorList={doctorList}
                            fetchDoctorList={fetchDoctorList}
                            officeId={officeId}
                            setOfficeId={setOfficeId}
                            doctorId={doctorId}
                            setDoctorId={setDoctorId}
                            isGDenticarePlan={isGDenticarePlan}
                            setIsGDenticarePlan={setIsGDenticarePlan}
                            patientId={patientId}
                            editPatient={editPatient}
                            setPatientId={setPatientId}
                            dateOfService={dateOfService}
                            setDateOfService={setDateOfService}
                            handleClose={handleClose}
                            fetchClaimdata={fetchClaimdata}
                            claimData={claimData}
                            claimId={claimId}
                            show={show}
                            setShow={setShow}
                            isView={isView}
                            setIsView={setIsView}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            isSubmitted={isSubmitted}
                        />
                    ) : (
                        <section className="box1">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        {profileData.roleId == ROLE_ID.PATIENT ? (
                                            <div className="col-lg-1 col-md-1 col-sm-12 title">Service date</div>
                                        ) : (
                                            <div className="col-lg-1 col-md-1 col-sm-12">
                                                <h2 className="title pull-left">
                                                    <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                        <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                    </button>
                                                </h2>
                                            </div>
                                        )}
                                        <div className="col-lg-3 col-md-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-lg-7 col-md-7 col-sm-7 d-flex justify-content-center">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"Start date"}
                                                            maxDate={filterValue.toDate}
                                                            scrollableMonthDropdown={true}
                                                            selected={filterValue && defaultTimeZone(filterValue.fromDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, fromDate: date })}
                                                            // onKeyDown={onKeyDown}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                    <span className="title">{t("To")}</span>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-5">
                                                    <div className="title">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            placeholderText={"End date"}
                                                            maxDate={new Date()}
                                                            scrollableMonthDropdown={true}
                                                            minDate={defaultTimeZone(filterValue.fromDate)}
                                                            selected={filterValue && defaultTimeZone(filterValue.toDate)}
                                                            onChange={(date) => setFilterValue({ ...filterValue, toDate: date })}
                                                            // onKeyDown={onKeyDown}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-12 col-sm-12">
                                            <div className="row mx-2">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select
                                                                name="officeList"
                                                                value={officeOptions && officeOptions.filter(({ value }) => value == filterValue.officeId)}
                                                                options={officeOptions}
                                                                onChange={onFilterOfficeChange}
                                                                placeholder={t("Office")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select
                                                                name="doctorList"
                                                                value={doctorOptions && doctorOptions.filter(({ value }) => value == filterValue.doctorId)}
                                                                options={doctorOptions}
                                                                onChange={onFilterDoctorChange}
                                                                placeholder={t("Doctor")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-4 col-sm-6">
                                            <div className="title">
                                                <input
                                                    className="form-control"
                                                    name="code"
                                                    value={filterValue.code}
                                                    onChange={onFilterChangeValue}
                                                    onKeyDown={onKeyDown}
                                                    type="search"
                                                    placeholder={`${t("Claim")} #`}
                                                />
                                            </div>
                                        </div>
                                        {profileData.roleId == ROLE_ID.PATIENT ? (
                                            ""
                                        ) : (
                                            <div className="col-lg-1 col-md-4 col-sm-6">
                                                <div className="title">
                                                    <input
                                                        className="form-control"
                                                        name="searchPatientName"
                                                        value={filterValue.searchPatientName}
                                                        onChange={onFilterChangeValue}
                                                        onKeyDown={onKeyDown}
                                                        type="search"
                                                        placeholder={t("Patient")}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-lg-1 col-md-4 col-sm-12">
                                            <div className="title">
                                                <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onKeyDown={onKeyDown} onChange={onFilterChangeValue}>
                                                    {Object.keys(CLAIM_STATUS_SEARCH).map((item, i) => {
                                                        return (
                                                            <option key={i} value={CLAIM_STATUS_SEARCH[item]}>
                                                                {item}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !claimList || (claimList && !claimList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>{t("Claim")} #</th>
                                                        {profileData.roleId == ROLE_ID.DOCTOR ? "" : <th>{t("Office")}</th>}
                                                        <th>{t("Doctor")}</th>
                                                        {profileData.roleId == ROLE_ID.PATIENT ? "" : <th>{t("Patient")}</th>}
                                                        <th>{t("Date of service")}</th>
                                                        <th>{t("Amount")}</th>
                                                        <th>{t("Status")}</th>
                                                        <th>{t("Submitted date")}</th>
                                                        <th>{t("Created Date")}</th>
                                                        <th>{t("Created by")}</th>
                                                        <th>{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!claimList || (claimList && !claimList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        claimList &&
                                                        claimList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <h6>{item.code ? item.code : "-"}</h6>
                                                                    </td>

                                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>{item.office ? item.office.name : "-"}</h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>
                                                                            {item.doctor
                                                                                ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.PATIENT ? (
                                                                        ""
                                                                    ) : (
                                                                        <td>
                                                                            <h6>
                                                                                {item.patient
                                                                                    ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName)
                                                                                    : "-"}
                                                                            </h6>
                                                                        </td>
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>${item.amount ? item.amount.toLocaleString("en-US") : "0"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <span className={`badge badge-${item.isSubmitted == CLAIM_STATUS.Submitted ? "success" : "info"}`}>
                                                                            {Object.keys(CLAIM_STATUS).find((key) => CLAIM_STATUS[key] === item.isSubmitted)}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.submittedDate ? monthDateYear(item.submittedDate) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.createdBy
                                                                                ? (item.createdBy.firstName && item.createdBy.firstName) + " " + (item.createdBy.lastName && item.createdBy.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        {/* {(item.isSubmitted === 1 && item.isEdit == 0) || profileData.roleId == ROLE_ID.PATIENT ? ( */}
                                                                        {item.isSubmitted === 1 || profileData.roleId == ROLE_ID.PATIENT ? (
                                                                            ""
                                                                        ) : (
                                                                            <>
                                                                                <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.id, item.office.id, item.isSubmitted)}>
                                                                                    <i className="fa fa-pencil"></i>
                                                                                </button>
                                                                                <button className="btn btn-danger btn-xs mx-2" onClick={() => handleDelete(item.id)}>
                                                                                    <i className="fa fa-trash-o "></i>
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                        <button className="btn btn-warning btn-xs" onClick={() => handleView(item.id, item.office.id)}>
                                                                            <i className="fa fa-eye "></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-lg-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {claimList == null ? "0" : rowCount} {t("Claim")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default ClaimList;
