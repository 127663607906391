import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL, frontURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE, SNS_TYPE, STATUS, STATUS_SEARCH } from "../../../helper/Constant";
import UpsertSns from "./UpsertSns";
import { useTranslation } from "react-i18next";
import { sliceString } from "../../../helper/helper";

function GPCouponList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [snsList, setSnsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        SNSlink: "",
        lastName: "",
        title: "",
        statusId: 1,
        description: "",
    });
    const [snsId, setSnsId] = useState("");
    const [filterValue, setFilterValue] = useState({
        title: "",
        SNSlink: "",
        type: "",
        statusId: "",
    });
    const [agreementInfo, setAgreementInfo] = useState({ preview: "", data: "" });

    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.AGENT ? "agent" : "";

    const fetchSnsdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/snslink/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    title: filterValue.title,
                    SNSlink: filterValue.SNSlink,
                    type: filterValue.type,
                    statusId: filterValue.statusId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setSnsList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setSnsId(id);
        snsDetail(id);
        setIsEdit(true);
        setShow(true);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });

        if (e.target.name === "agreement") {
            const agreement = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            };

            const fileExtension = agreement.data.name.split(".").at(-1).toLowerCase();
            const allowedFileTypes = ["pdf", "jpg", "png", "gif"];
            if (!allowedFileTypes.includes(fileExtension)) {
                e.target.value = null;
                alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
            } else {
                setAgreementInfo(agreement);
            }
        }
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchSnsdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const snsDetail = async (snsId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/snslink/detail/${snsId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue({
            SNSlink: "",
            title: "",
            statusId: 1,
            description: "",
        });
        setSnsId("");
        setIsView(false);
        setIsEdit(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this sns ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/admin/snslink/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchSnsdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    const handleButtonClick = (SNSlink) => {
        const textToCopy = `${SNSlink}\n${frontURL}/signup?agent=${profileData.userName}`;
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                toast.success("Link for SNS copied!");
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
            });
    };

    useEffect(() => {
        fetchSnsdata();
    }, [offset, filterValue.type, filterValue.statusId]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage GPCoupon")} </h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("Manage GPCoupon")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("Manage GPCoupon")}</strong>
                                </li>
                            )}
                            {show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : isView ? t("View") : t("Add")} {"Sns"}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>

            <div className="pull-left">
                <h1 className="title">Will be developed. </h1>
            </div>
        </>
    );
}

export default GPCouponList;
