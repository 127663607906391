import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROWS_PER_PAGE, STATUS, STATUS_SEARCH } from "../../../helper/Constant";
import { monthDateYear } from "../../../helper/dateFormates";
import UpdateDoctor from "../manageDoctor/UpdateDoctor";
import AssignDoctor from "./AssignDoctor";
import { useTranslation } from "react-i18next";

function DoctorForOfficeList(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [doctorList, setDoctorList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [inputValue, setInputValue] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        dob: "",
        phone1: "",
        address1: "",
        statusId: "",
    });
    const [doctorId, setDoctorId] = useState("");
    const [filterValue, setFilterValue] = useState({
        nameSearch: "",
        statusSearch: "",
    });

    const fetchDoctordata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/admin/office/doctor/list?isPagination=true`,
                {
                    officeId: props.officeId,
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchDoctorName: filterValue.searchDoctorName,
                    searchDoctorUsername: filterValue.searchDoctorUsername,
                    statusId: filterValue.statusSearch,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleEdit = (id) => {
        setIsEdit(true);
        setDoctorId(id);
        doctorDetail(id);
    };

    const onChangeValue = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchDoctordata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const doctorDetail = async (doctorId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/admin/doctor/detail/${doctorId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleClose = () => {
        setInputValue("");
        setDoctorId("");
        setIsEdit(false);
        setShow(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this doctor ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .post(
                            `${baseURL}/api/v1/admin/office/delete`,
                            {
                                doctorId: id,
                                officeId: props.officeId,
                                isUserAssigned: true,
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                    lang: languageShortName,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchDoctordata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        fetchDoctordata();
    }, [offset, filterValue.statusSearch]);

    return (
        <>
            {show ? (
                <AssignDoctor
                    isFromOfficeList={true}
                    officeDetail={doctorList && doctorList[0] && doctorList[0].office}
                    setShow={setShow}
                    backToDoctorList={props.handleClose}
                    handleClose={handleClose}
                    fetchDoctordata={fetchDoctordata}
                    officeId={props.officeId}
                />
            ) : isEdit && (!isLoading || isLoading == false) ? (
                <UpdateDoctor
                    editLoader={editLoader}
                    isFromOfficeList={true}
                    backToDoctorList={props.handleClose}
                    handleClose={handleClose}
                    fetchDoctordata={fetchDoctordata}
                    doctorId={doctorId}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    setIsEdit={setIsEdit}
                    onChangeValue={onChangeValue}
                />
            ) : (
                <>
                    <div className="col-xs-12">
                        <div className="page-title">
                            <div className="pull-left">
                                <h1 className="title">{t("Manage Doctor For Office")} </h1>
                            </div>

                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard">
                                            <i className="fa fa-home"></i>
                                            {t("Home")}
                                        </Link>
                                    </li>
                                    <li className="active cursor-pointer" onClick={props.handleClose}>
                                        <strong>{t("Office List")}</strong>
                                    </li>
                                    <li className="active cursor-pointer">
                                        <strong>{t("Doctor-Office List")}</strong>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <section className="box">
                                <header className="panel_header">
                                    <div className="mx-3">
                                        <div className="row ">
                                            <div className="col-lg-6 col-md-2 col-sm-12">
                                                <h2 className="title pull-left">
                                                    <button className="btn btn-primary btn-corner" onClick={handleAdd}>
                                                        <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div className="col-lg-6 col-md-10 col-sm-12 d-flex justify-content-end">
                                                <div className="row mx-lg-2">
                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                        <div className="title">
                                                            <input
                                                                className="form-control"
                                                                name="searchDoctorUsername"
                                                                value={filterValue.searchDoctorUsername}
                                                                onChange={onFilterChangeValue}
                                                                onKeyDown={onKeyDown}
                                                                type="search"
                                                                placeholder={t("search email or username")}
                                                                // style={{ width: "200px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="title">
                                                            <input
                                                                className="form-control"
                                                                name="searchDoctorName"
                                                                value={filterValue.searchDoctorName}
                                                                onChange={onFilterChangeValue}
                                                                onKeyDown={onKeyDown}
                                                                type="search"
                                                                placeholder={t("search name")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="title">
                                                            <select className="form-control" name="statusSearch" value={filterValue.statusSearch} onChange={onFilterChangeValue}>
                                                                {Object.keys(STATUS_SEARCH).map((item, i) => {
                                                                    return (
                                                                        <option key={i} value={STATUS_SEARCH[item]}>
                                                                            {t(item)}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="content-body">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="table-responsive" data-pattern="priority-columns">
                                                <table
                                                    id="tech-companies-1"
                                                    className={`table vm table-small-font no-mb table-bordered ${
                                                        isLoading || !doctorList || (doctorList && !doctorList.length) ? "" : "table-striped"
                                                    } table-hover`}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>{t("E-mail")}</th>
                                                            <th>{t("Username")}</th>
                                                            <th>{t("Name")}</th>
                                                            <th>{t("Phone")}</th>
                                                            <th>{t("Created Date")}</th>
                                                            <th>{t("Status")}</th>
                                                            <th>{t("Action")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!doctorList || (doctorList && !doctorList.length && !isLoading && !isLoading) ? (
                                                            <NoDataFound />
                                                        ) : isLoading ? (
                                                            <Loader />
                                                        ) : (
                                                            doctorList &&
                                                            doctorList.map((item, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <h6>{item.email ? item.email : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.userName ? item.userName : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.firstName ? (item.firstName && item.firstName) + " " + (item.lastName && item.lastName) : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.phone1 ? item.phone1 : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                                        </td>
                                                                        <td>
                                                                            <span className={`badge badge-${item.statusId == STATUS.Active ? "success" : "danger"}`}>
                                                                                {Object.keys(STATUS).find((key) => STATUS[key] === item.statusId)}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <button className="btn btn-primary btn-xs mx-2" onClick={() => handleEdit(item.id)}>
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                            <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                <i className="fa fa-trash-o "></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-lg-flex justify-content-between ">
                                                <div className="d-flex align-center">
                                                    {t("Total")} {doctorList == null ? "0" : rowCount} {t("Doctor")}
                                                    {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                                </div>
                                                <div className="pagination-center">
                                                    <ReactPaginate
                                                        initialPage={0}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={0}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={handlePageClick}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageLinkClassName="page-link"
                                                        breakLinkClassName="page-link"
                                                        nextLinkClassName="page-link"
                                                        previousLinkClassName="page-link"
                                                        pageClassName="page-item"
                                                        breakClassName="page-item"
                                                        nextClassName="page-item"
                                                        previousClassName="page-item"
                                                        previousLabel={t("Previous")}
                                                        nextLabel={t("Next")}
                                                        disableInitialCallback={true}
                                                        forcePage={currentPage}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default DoctorForOfficeList;
