import React from "react";
import { useSearchParams } from "react-router-dom";
import FaildPage from "./FaildPage";
import SuccessPage from "./SuccessPage";

function TransactionPage() {
    const [searchParams] = useSearchParams();
    const submit = searchParams && searchParams.get("submit") == 1 ? 1 : 0;

    return (
        <div className="container-fluid login-style">
            <div className="login-wrapper row">
                <div id="login" className="login loginpage col-lg-offset-4 col-md-offset-3 col-sm-offset-3 col-xs-offset-0 col-xs-12 col-sm-6 col-lg-4">
                    <div className="box card" style={{ marginTop: "35%" }}>
                        <div className="content-body" style={{ paddingTop: "30px" }}>
                            {submit == 1 ? <SuccessPage /> : <FaildPage />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransactionPage;
