import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../../helper/Config";
import Select from "react-select";
import DocumentsTable from "../managePatient/DocumentsTable";
import { PATIENT_DOCUMENT_TYPE, ROLE_ID } from "../../../helper/Constant";
import Loader from "../../../commoncomponents/Loader";
import TreatmentTable from "./TreatmentTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, defaultTimeZone } from "../../../helper/dateFormates";
import _ from "lodash";
import UpsertComment from "../manageVerifyClaim/UpsertComment";
import { useTranslation } from "react-i18next";

function UpsertClaim(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const { profileData, officeData } = useOutletContext();
    const [officeList, setOfficeList] = useState([]);

    const [treatmentTypeList, setTreatmentTypeList] = useState([]);
    const [isPatientChange, setIsPatientChange] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";
    let InputObj = props.inputValueObj;

    const fetchOfficeList = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/office/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name + " (" + item.businessUsername + ")",
            };
        });

    const onOfficeChange = (e) => {
        props.setOfficeId(e.value);
        props.setDoctorList([]);
        props.setDoctorId("");
        props.setPatientList([]);
        props.setPatientId("");
        props.setIsGDenticarePlan(false);
        props.fetchDoctorList(e.value);
        props.fetchDoctorPatientdata(e.value);
    };

    const doctorOptions =
        props.doctorList &&
        props.doctorList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName + " (" + item.userName + ")",
            };
        });

    const onDoctorChange = (e) => {
        props.setDoctorId(e.value);
    };

    const patientOptions =
        props.patientList &&
        props.patientList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + item.lastName + (item.userName ? " (" + item.userName + ")" : ""),
                isGDenticarePatient: item.isGDenticarePlan,
            };
        });

    const onPatientChange = (e) => {
        if (e.value != props.editPatient) {
            setIsPatientChange(true);
        } else {
            setIsPatientChange(false);
        }
        props.setPatientId(e.value);
        props.setIsGDenticarePlan(e.isGDenticarePatient);
    };

    const fetchTreatmentTypedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/treatment-type/list?isPagination=false`,
                {
                    isId: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setTreatmentTypeList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handleTreatmentUnitChange = async () => {
        let sum =
            InputObj &&
            InputObj.reduce((acc, obj) => {
                if (!acc[obj.treatmentTypeId]) {
                    acc[obj.treatmentTypeId] = 0;
                }
                acc[obj.treatmentTypeId] += obj.totalUnitFee;
                return acc;
            }, {});
        props.setSum(sum);
        // props.setSum(Object.values(sum));a
    };

    const handleSave = async (isSubmitted) => {
        let totalSum = 0;
        for (let key in props.sum) {
            totalSum += props.sum[key];
        }

        if (!props.officeId || props.officeId === "" || props.officeId === null) {
            toast.error(t(`Please select office`));
        } else if (!props.doctorId || props.doctorId === "" || props.doctorId === null) {
            toast.error(t(`Please select doctor`));
        } else if (!props.patientId || props.patientId === "" || props.patientId === null) {
            toast.error(t(`Please select patient`));
        } else if (!props.dateOfService || props.dateOfService === "" || props.dateOfService === null) {
            toast.error(t(`Please select service date`));
        } else if (totalSum < 0) {
            toast.error(t(`Please enter treatment units grater than 0`));
        } else {
            await axios
                .post(
                    `${baseURL}/api/v1/${apiRouteName}/claim/upsert`,
                    {
                        claimId: props.isEdit ? props.claimId : "",
                        officeId: props.officeId,
                        doctorId: props.doctorId,
                        patientId: props.patientId,
                        dateOfService: dateAPIFormate(props.dateOfService),
                        isSubmitted: isSubmitted,
                        treatment: props.inputValueObj,
                        amount: props.inputValueObj.reduce((accumulator, object) => {
                            return accumulator + object.totalUnitFee;
                        }, 0),
                        benefitAmount:
                            props.inputValueObj.reduce((accumulator, object) => {
                                return accumulator + object.totalUnitFeeWithoutDiscount;
                            }, 0) -
                            props.inputValueObj.reduce((accumulator, object) => {
                                return accumulator + object.totalUnitFee;
                            }, 0),
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: token,
                            lang: languageShortName,
                        },
                    }
                )
                .then((res) => {
                    props.fetchClaimdata();
                    if (profileData.roleId == ROLE_ID.ADMIN) {
                        props.fetchOfficedata();
                        props.fetchDoctorData();
                    }
                    toast.success(res.data.message);
                    props.handleClose();
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status == 500) {
                            localStorage.clear();
                            sessionStorage.clear();
                            navigate("/login");
                        } else {
                            toast.error(err.response.data.message);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN) {
            fetchOfficeList();
        }
        // if (profileData.roleId == ROLE_ID.DOCTOR) {
        //     props.fetchDoctorPatientdata(props.officeId);
        // }
        fetchTreatmentTypedata();
    }, [profileData.roleId]);

    return (
        <div className="">
            <div className="col-xs-12">
                <div className="row">
                    {isLoading || props.editLoader ? (
                        <Loader />
                    ) : (
                        <section className="box1">
                            <div className="mt-3">
                                <p className="mx-3 title-fonts text-black"><span className="required"> * </span>: {t("Indicates required fields")} </p>
                            </div>
                            <div className="box gradient-blue py-3 d-flex justify-content-start title-box mt-2">
                                <h4 className="m-0 title-fonts mx-3">{t("Claim Information")} </h4>
                            </div>
                            <div className="content-body my-4 border-0">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Office")} <span className="required"> * </span>
                                                    </label>
                                                    <div className="col-md-12">
                                                        {props.isView ? (
                                                            <input className="form-control" value={props.claimData && props.claimData.office && props.claimData.office.name} disabled />
                                                        ) : profileData.roleId == ROLE_ID.DOCTOR ? (
                                                            <input className="form-control" value={officeData && officeData.name && officeData.name} disabled />
                                                        ) : (
                                                            <Select
                                                                name="officeList"
                                                                value={officeOptions && officeOptions.filter(({ value }) => value == props.officeId)}
                                                                options={officeOptions}
                                                                onChange={onOfficeChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={props.isEdit || props.isView ? true : false}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Doctor")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        {props.isView ? (
                                                            <input
                                                                className="form-control"
                                                                value={props.claimData && props.claimData.doctor && props.claimData.doctor.firstName + " " + props.claimData.doctor.lastName}
                                                                disabled
                                                            />
                                                        ) : profileData.roleId == ROLE_ID.DOCTOR ? (
                                                            <input
                                                                className="form-control"
                                                                value={profileData && (profileData.firstName && profileData.firstName) + " " + (profileData.lastName && profileData.lastName)}
                                                                disabled
                                                            />
                                                        ) : (
                                                            <Select
                                                                name="doctorList"
                                                                value={doctorOptions && doctorOptions.filter(({ value }) => value == props.doctorId)}
                                                                options={doctorOptions}
                                                                onChange={onDoctorChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={props.isView ? true : false}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Patient")}
                                                        <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        {props.isView ? (
                                                            <input
                                                                className="form-control"
                                                                value={props.claimData && props.claimData.patient && props.claimData.patient.firstName + " " + props.claimData.patient.lastName}
                                                                disabled
                                                            />
                                                        ) : (
                                                            <Select
                                                                name="patientList"
                                                                value={patientOptions && patientOptions.filter(({ value }) => value == props.patientId)}
                                                                options={patientOptions}
                                                                onChange={onPatientChange}
                                                                placeholder={t("Select")}
                                                                isDisabled={props.isView ? true : false}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group row">
                                                    <label className="control-label col-md-12">
                                                        {t("Date of service")} <span className="required"> * </span>
                                                    </label>

                                                    <div className="col-md-12">
                                                        <DatePicker
                                                            className="form-control input-height mr-sm-2"
                                                            selected={props.dateOfService && defaultTimeZone(props.dateOfService)}
                                                            placeholderText={"Please Select Date"}
                                                            maxDate={new Date()}
                                                            scrollableMonthDropdown={true}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            onChange={(date) => props.setDateOfService(date)}
                                                            disabled={props.isView ? "disabled" : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                {props.isView || props.isEdit ? (
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Claim code")}</label>
                                                        <div className="col-md-12">
                                                            <input className="form-control" value={props.claimData && props.claimData.code && props.claimData.code} disabled />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* ////////////////////////////// IMAGE SECTION ////////////////////////////// */}
                                    <>
                                        <div className="col-12">
                                            <div className="row">
                                                <section className="box py-3 d-flex justify-content-start title-box">
                                                    <h4 className="m-0 title-fonts">{t("Patient Documents")} </h4>
                                                </section>
                                            </div>
                                        </div>
                                        <DocumentsTable type={PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT} patientId={props.patientId} title="Insurance front" isView={props.isView} />
                                        <DocumentsTable type={PATIENT_DOCUMENT_TYPE.INSURANCE_BACK} patientId={props.patientId} title="Insurance back" isView={props.isView} />
                                        <DocumentsTable type={PATIENT_DOCUMENT_TYPE.X_RAY} patientId={props.patientId} title="X-ray" isView={props.isView} />
                                        <DocumentsTable type={PATIENT_DOCUMENT_TYPE.NOTE} patientId={props.patientId} title="Note" isView={props.isView} />
                                        <DocumentsTable type={PATIENT_DOCUMENT_TYPE.ID} patientId={props.patientId} title="Id" isView={props.isView} />
                                    </>

                                    {/* ////////////////////////////// TREATMENT-TABLES ////////////////////////////// */}
                                    <>
                                        <div className="col-12">
                                            <div className="row">
                                                {treatmentTypeList &&
                                                    treatmentTypeList.map((item, i) => {
                                                        return (
                                                            <TreatmentTable
                                                                isPatientChange={isPatientChange}
                                                                isGDenticarePlan={props.isGDenticarePlan}
                                                                patientId={props.patientId}
                                                                officeId={props.officeId}
                                                                isView={props.isView}
                                                                isEdit={props.isEdit}
                                                                setSum={props.setSum}
                                                                claimId={props.claimId}
                                                                sum={props.sum}
                                                                inputValueObj={props.inputValueObj}
                                                                InputObj={InputObj}
                                                                setInputValueObj={props.setInputValueObj}
                                                                handleTreatmentUnitChange={handleTreatmentUnitChange}
                                                                typeId={item.id}
                                                                treatmentTypeId={item.typeId}
                                                                name={t(item.name)}
                                                                index={i}
                                                                key={i}
                                                            />
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </>
                                    <div className="padding-bottom-30 d-flex justify-content-center">
                                        {props.isVerifyClaimList && props.show ? (
                                            <UpsertComment
                                                oldNoteValues={props.oldNoteValues}
                                                editLoader={props.editLoader}
                                                claimId={props.claimId}
                                                fetchClaimdata={props.fetchClaimdata}
                                                show={props.show}
                                                handleClose={() => {
                                                    props.setInputValue({
                                                        verifierNote: "",
                                                        doctorNote: "",
                                                        finalResult: "",
                                                    });
                                                    props.setShow(false);
                                                }}
                                                setShow={props.setShow}
                                                inputValue={props.inputValue}
                                                setInputValue={props.setInputValue}
                                                onChangeValue={props.onChangeValue}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        <div className="text-left">
                                            <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                {t("Close")}
                                            </button>
                                            {props.isVerifyClaimList ? (
                                                <button
                                                    type="button"
                                                    className="btn mx-2 btn-info"
                                                    onClick={() => {
                                                        props.claimNoteDetail(props.claimId);
                                                        props.setShow(true);
                                                    }}
                                                >
                                                    {t("Response")}
                                                </button>
                                            ) : (
                                                ""
                                            )}
                                            {props.isSubmitted || props.isView ? (
                                                ""
                                            ) : (
                                                <button type="button" className="btn btn-primary gradient-blue mx-2" onClick={() => handleSave(false)}>
                                                    {t("Save")}
                                                </button>
                                            )}
                                            {props.isView ? (
                                                ""
                                            ) : (
                                                <button type="button" className="btn btn-primary gradient-green mx-2" onClick={() => handleSave(true)}>
                                                    {t("Submit")}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpsertClaim;
