import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

export default function Practitioners() {
    const navigate = useNavigate();
    const divRef = useRef(null);
    const [clipPath, setClipPath] = useState("");

    const { t } = useTranslation();
    const languageShortName = localStorage.getItem("languageShortName");

    const [linePosition, setLinePosition] = useState([]); //Initial Line position
    const [lineHeight, setLineHeight] = useState([]); //Initial Line Height
    const [lineWidth, setLineWidth] = useState([]); //Initial Line Width

    let verticalHeight = null;
    let verticalWidth = null;
    let verticalPosition = null;

    function handleVerticalImageLoad() {
        const divWidth = divRef.current.offsetWidth;

        verticalHeight = divWidth * 0.235;
        verticalWidth = divWidth * 0.015;
        verticalPosition = divWidth * 0.5;

        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setLinePosition(verticalPosition);
        setClipPath(`polygon(0 0, ${verticalPosition}px 0, ${verticalPosition}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    }

    const handleMouseMove = (event) => {
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const x = event.clientX - rect.left;
        setLinePosition(x);
        verticalHeight = rect.width * 0.235;
        verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // prevent scrolling on touch devices
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const touch = event.touches[0];
        const x = touch.clientX - rect.left;
        setLinePosition(x);
        const verticalHeight = rect.width * 0.235;
        const verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    return (
        <>
            <Header />

            <section>
                <div
                    ref={divRef}
                    className="hero-get-in-toch-custom"
                    style={{ cursor: "grab", position: "relative", width: "100%", height: `${lineHeight}px` }}
                    onMouseMove={handleMouseMove}
                    onTouchStart={handleTouchMove}
                    onTouchMove={handleTouchMove}
                >
                    <img src="/assets/images/home/home_practitioners_left.png" alt="before" style={{ position: "absolute", left: 0, top: 0, zIndex: 2, width: "100%", height: "auto", clipPath }} />
                    <img src="/assets/images/home/home_practitioners_right.png" alt="after" style={{ position: "absolute", left: 0, top: 0, zIndex: 1, width: "100%", height: "auto" }} />
                    <img
                        src="/assets/images/home/circle_line_icon.png"
                        alt="Vertical Line"
                        style={{ position: "absolute", top: 0, left: `${linePosition}px`, transform: "translateX(-50%)", width: `${lineWidth}px`, height: `${lineHeight}px`, zIndex: 3 }}
                        onLoad={handleVerticalImageLoad}
                    />
                </div>
            </section>
            <br />

            {/* Practitioners */}
            <section className="pt-10 d-flex justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-7 text-title-sm-custom text-center" data-aos="zoom-in">
                    <h3 className="bold pb-2">
                        <span>{t("Dental")} </span>
                        <span className="quality-text-custom">{t("Practitioners")}</span>
                    </h3>
                    <button className="btn btn-outline text-white join-now-custom btn-arrow-width-custom" onClick={() => navigate("/authDoctor/signup")}>
                        {t("Join now")}
                        <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                    </button>
                </div>
            </section>

            {/* Affordable Coverage, Quality Services. */}
            <section className="pt-5 d-flex justify-content-center">
                <div className="container">
                    <div className="col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-md-6 col-xs-12 comprehensive-h1-text" data-aos="fade-left">
                            {languageShortName == "ko" ? (
                                <>
                                    <h1 className="fw-bolder text-pract-h1-custom quality-text-custom">
                                        {t("Comprehensive Dental")} {t("Coverage")}
                                    </h1>
                                    <h1 className="fw-bolder text-pract-h1-custom text-dark">{t("Your Partner in Providing")} </h1>
                                </>
                            ) : (
                                <>
                                    <h1 className="fw-bolder text-pract-h1-custom text-dark">{t("Your Partner in Providing")} </h1>
                                    <h1 className="fw-bolder text-pract-h1-custom quality-text-custom">{t("Comprehensive Dental")}</h1>
                                    <h1 className="fw-bolder text-pract-h1-custom quality-text-custom">{t("Coverage")}</h1>
                                </>
                            )}
                            <div>
                                <p className="mt-40 text-dentist-custom">{t("As a dentist, you know the importance of maintaining...")}</p>
                            </div>
                            <div className="d-flex">
                                <button className="btn my-1 text-white btn-enroll-doctor-custom btn-lg-display-custom" onClick={() => navigate("/authDoctor/signup")}>
                                    {t("Join now")}
                                    <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12" data-aos="fade-right">
                            <img src="/assets/images/home/img-group.png" alt="" height="430px" className="" />
                        </div>
                    </div>
                    <div className="btn-sm-display-custom">
                        <button className="btn my-1 text-white btn-enroll-doctor-custom">
                            {t("Join now")}
                            <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </section>

            {/*  */}
            <section className="pt-5 d-flex justify-content-center position-relative">
                <div className="practitioners-signup-custom" data-aos="fade-left">
                    <img src="/assets/images/home/dental-care/carousel-bg.png" alt="" />
                </div>
                <div className="container">
                    <div className="col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-md-6 col-xs-12 " data-aos="fade-left">
                            <img src="/assets/images/home/dental-care/comprehensive-img.png" alt="" height="250px" className="w-auto" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12" data-aos="fade-right">
                            <div>
                                <h1 className="practi-h1-text-custom">
                                    {languageShortName == "ko" ? (
                                        <>
                                            <span className="bold">{t("Today")} </span>
                                            <span className="bold quality-text-custom">{t("Sign Up")}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="bold quality-text-custom">{t("Sign Up")} </span>
                                            <span className="bold">{t("Today")}</span>
                                        </>
                                    )}
                                </h1>
                                <p className="text-black become-text-sm-custom">{t("Become a participating dentist and join our network ...")}</p>
                            </div>
                            <div className="compr-icons-marg-custom">
                                <div className="mb-3">
                                    <img src="/assets/images/home/comprehensive/comprehensive1.png" alt="" className="wd" />
                                    <span className="ms-3 text-black">{t("Competitive Reinbursement Rates")}</span>
                                </div>
                                <div className="mb-3">
                                    <img src="/assets/images/home/comprehensive/comprehensive2.png" alt="" className="wd" />
                                    <span className="ms-3 text-black">{t("Simple and Efficient Claims Process")}</span>
                                </div>
                                <div className="mb-3">
                                    <img src="/assets/images/home/comprehensive/comprehensive3.png" alt="" className="wd" />
                                    <span className="ms-3 text-black">{t("Access to our Network of Dental Providers")}</span>
                                </div>
                                <div className="mb-3">
                                    <img src="/assets/images/home/comprehensive/comprehensive4.png" alt="" className="wd" />
                                    <span className="ms-3 text-black">{t("Marketing & Promotional Support")}</span>
                                </div>
                            </div>
                            <div className="pt-4 btn-sm-center-custom">
                                <button className="btn text-white btn-enroll-doctor-custom" onClick={() => navigate("/authDoctor/signup")}>
                                    {t("Join now")}
                                    <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <Footer />
        </>
    );
}
