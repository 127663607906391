import React, { Fragment, useEffect, useState } from "react";
import { DENTAL_EVALUATION, FORM_TYPE, ROLE_ID } from "../../../helper/Constant";
import "react-datepicker/dist/react-datepicker.css";
import { dateAPIFormate, defaultTimeZone } from "../../../helper/dateFormates";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../helper/Config";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import Loader from "../../../commoncomponents/Loader";
import { useTranslation } from "react-i18next";

function UpsertEvaluation(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const [evaluationList, setEvaluationList] = useState([]);
    const [inputValueObj, setInputValueObj] = useState([]);
    let InputObj = inputValueObj;

    const { profileData } = useOutletContext();
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const handleUpdate = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/patient/dental-evaluation/upsert`,
                {
                    userEvaluationId: props.evaluationId,
                    officeId: props.inputValue.officeId,
                    doctorId: props.inputValue.doctorId,
                    patientId: profileData.id,
                    dateOfService: dateAPIFormate(props.inputValue.dateOfService),
                    evaluationDetail: inputValueObj,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                props.fetchEvaluationdata();
                props.fetchDoctorData();
                props.fetchOfficedata();
                toast.success(res.data.message);
                props.handleClose();
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
        // }
    };

    const handleEvaluationChange = async (e) => {
        const value = e.target.value;
        const id = Number(e.target.id);
        const formType = Number(e.target.dataset.formtype);

        let oldEvaluationList = evaluationList;
        let filterData = _.find(oldEvaluationList, { id: id });
        let filterData_index = oldEvaluationList.findIndex((p) => p.id === id);

        let newObj = {
            formType: filterData.formType,
            id: filterData.id,
            name: filterData.name,
            value: value,
        };

        oldEvaluationList[filterData_index] = newObj;

        setEvaluationList([...oldEvaluationList]);

        let obj2 = {
            evaluationId: id,
            value: value,
            formType: formType,
        };

        let index = InputObj.findIndex((p) => p.evaluationId == id);
        if (index > -1) {
            InputObj[index] = obj2;
        } else {
            InputObj.push(obj2);
        }
        setInputValueObj(InputObj);
    };

    const fetchEvaluationdata = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/evaluation/list?isPagination=false`,
                {
                    userEvaluationId: props.isEdit || props.isView ? props.evaluationId : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setEvaluationList(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500 || err.response.status == 401) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    useEffect(() => {
        if (props.show || props.isView) {
            fetchEvaluationdata();
        }
    }, [props.show, props.isEdit, props.isView]);

    return (
        <>
            <div className="col-xs-12 ">
                <div
                    className={`add-header-wrapper gradient-${
                        profileData.roleId == ROLE_ID.ADMIN
                            ? "blue"
                            : profileData.roleId == ROLE_ID.DOCTOR
                            ? "violet"
                            : profileData.roleId == ROLE_ID.PATIENT
                            ? "orange"
                            : profileData.roleId == ROLE_ID.VERIFY
                            ? "pink"
                            : "grep"
                    } curved-section text-center`}
                >
                    <h2 className="uppercase bold w-text">
                        {props.isEdit && !props.isView ? t("Update") : props.isView ? t("View") : t("Add")} {t("Evaluation")}
                    </h2>
                    <div className="before-text">
                        {props.isEdit && !props.isView ? t("Update") : props.isView ? t("View") : t("Add")} {t("Evaluation")}
                    </div>
                </div>

                <div className=" bg-w">
                    <div className="col-lg-10 col-lg-offset-1 col-xs-12">
                        {props.editLoader ? (
                            <Loader />
                        ) : (
                            <section className="box">
                                <div className="content-body my-4 border-0">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Office")}</label>

                                                        <div className="col-md-12">
                                                            <input type="text" className="form-control" value={props.inputValue.office && props.inputValue.office.name} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Doctor")}</label>

                                                        <div className="col-md-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={props.inputValue.doctor && props.inputValue.doctor.firstName + " " + props.inputValue.doctor.lastName}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="form-group row">
                                                        <label className="control-label col-md-12">{t("Date of service")}</label>

                                                        <div className="col-md-12">
                                                            <DatePicker
                                                                className="form-control input-height mr-sm-2"
                                                                selected={props.inputValue && props.inputValue.dateOfService && defaultTimeZone(props.inputValue.dateOfService)}
                                                                placeholderText={"Please Select Date"}
                                                                maxDate={new Date()}
                                                                scrollableMonthDropdown={true}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                onChange={(date) => props.setInputValue({ ...props.inputValue, dateOfService: date })}
                                                                disabled="disabled"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 mb-3">
                                            {evaluationList &&
                                                evaluationList.map((item, i) => {
                                                    return (
                                                        <div className="row" key={i}>
                                                            <div className="col-lg-4 col-md-4 col-sm-12 my-2">{item.name}</div>
                                                            {item.formType == FORM_TYPE.radiobutton ? (
                                                                <div className="col-lg-8 col-md-8 col-sm-12 d-flex">
                                                                    {Object.keys(DENTAL_EVALUATION).map((evaluation, i) => {
                                                                        return (
                                                                            <Fragment key={i}>
                                                                                {props.isView ? (
                                                                                    DENTAL_EVALUATION[evaluation] == item.value ? (
                                                                                        <i
                                                                                            className={`fa fa-circle text-${
                                                                                                profileData.roleId == ROLE_ID.PATIENT ? "warning" : "info"
                                                                                            } d-flex align-items-center mx-3`}
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                    ) : (
                                                                                        <i className="fa fa-circle-thin d-flex align-items-center mx-3" aria-hidden="true"></i>
                                                                                    )
                                                                                ) : (
                                                                                    <input
                                                                                        className="my-2 mx-3"
                                                                                        type="radio"
                                                                                        name={item.name}
                                                                                        id={item.id}
                                                                                        value={DENTAL_EVALUATION[evaluation]}
                                                                                        data-formtype={item.formType}
                                                                                        checked={DENTAL_EVALUATION[evaluation] == item.value ? true : false}
                                                                                        onChange={handleEvaluationChange}
                                                                                    />
                                                                                )}
                                                                                <div
                                                                                    className={`my-2 text-light  font-weight-bold btn btn-xs gradient-${
                                                                                        DENTAL_EVALUATION[evaluation] == DENTAL_EVALUATION.Excellent
                                                                                            ? "green"
                                                                                            : DENTAL_EVALUATION[evaluation] == DENTAL_EVALUATION.Good
                                                                                            ? "blue"
                                                                                            : DENTAL_EVALUATION[evaluation] == DENTAL_EVALUATION.Normal
                                                                                            ? "pink"
                                                                                            : DENTAL_EVALUATION[evaluation] == DENTAL_EVALUATION.Bad
                                                                                            ? "violet"
                                                                                            : DENTAL_EVALUATION[evaluation] == DENTAL_EVALUATION.Terrible
                                                                                            ? "orange"
                                                                                            : ""
                                                                                    }`}
                                                                                >
                                                                                    {evaluation}
                                                                                </div>
                                                                            </Fragment>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : (
                                                                <div className="col-8 d-flex">
                                                                    <input
                                                                        className="form-control my-2"
                                                                        type="text"
                                                                        data-formType={item.formType}
                                                                        name={item.name}
                                                                        id={item.id}
                                                                        value={item.value}
                                                                        onChange={handleEvaluationChange}
                                                                        disabled={`${props.isView ? "disabled" : ""}`}
                                                                    ></input>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>

                                        <div className="padding-bottom-30 d-flex justify-content-center">
                                            <div className="text-left">
                                                <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                                    {t("Close")}
                                                </button>
                                                {props.isView ? (
                                                    ""
                                                ) : (
                                                    <button type="button" className="btn btn-primary gradient-orange mx-2" onClick={handleUpdate}>
                                                        {props.isEdit ? t("Update") : "Save"}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpsertEvaluation;
