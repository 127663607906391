import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { baseURL } from "../../helper/Config";
import NoDataFound from "../../commoncomponents/NoDataFound";
import Loader from "../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE, STATUS_WITH_EXPIRY } from "../../helper/Constant";
import { monthDateYear } from "../../helper/dateFormates";
import CheckGDentalPatient from "./CheckGDentalPatient";
import UpsertPatient from "../adminPanel/managePatient/UpsertPatient";
import Cookies from "universal-cookie";
import Select from "react-select";
import { useTranslation } from "react-i18next";

function GdentalPatientList() {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const cookies = new Cookies();
    const { t } = useTranslation();

    const { profileData, officeData } = useOutletContext();
    let apiRouteName = profileData.roleId == ROLE_ID.ADMIN ? "admin" : profileData.roleId == ROLE_ID.PATIENT ? "patient" : profileData.roleId == ROLE_ID.DOCTOR ? "doctor" : "";

    const [patientList, setPatientList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isPatientAdd, setIsPatientAdd] = useState(false);
    const [view, setView] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [officeList, setOfficeList] = useState([]);
    const [doctorFilterList, setDoctorFilterList] = useState([]);
    const [recommenderList, setRecommenderList] = useState([]);
    const [inputValue, setInputValue] = useState({
        firstName: "",
        lastName: "",
        dob: "",
        dateOfService: "",
        officeId: profileData.roleId == ROLE_ID.DOCTOR ? officeData.id : "",
        doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
        // doctorId: "",
    });
    const [showDetails, setShowDetails] = useState(false);
    const [patientId, setPatientId] = useState("");
    const [filterValue, setFilterValue] = useState({
        searchPatientUsername: "",
        searchPatientName: "",
        searchPhone: "",
        // doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
        doctorId: "",
        officeId: profileData.roleId == ROLE_ID.DOCTOR ? cookies.get("office") : "",
    });
    const [patientInputValue, setPatientInputValue] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        userName: "",
        gender: "",
        dob: "",
        phone1: "",
        statusId: 1,
        address1: "",
        countryId: "",
        stateId: "",
        cityId: "",
        zip: "",
        ssn: "",
        insuranceNumber: "",
        referralAgenecyNumber: "",
        membership: "",
        recommenderId: "",
    });

    const fetchPatientdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/patient/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    searchPatientUsername: filterValue.searchPatientUsername,
                    searchPatientName: filterValue.searchPatientName,
                    searchPhone: filterValue.searchPhone,
                    doctorId: filterValue.doctorId,
                    officeId: filterValue.officeId,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setPatientList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    let selectOffice = [
        {
            id: "",
            name: t("Office"),
        },
    ];

    const fetchOfficedata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/office/list?isPagination=false`,
                {
                    isOfficePatient: true,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setOfficeList(res.data.data ? selectOffice.concat(res.data.data) : selectOffice);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onFilterOfficeChange = (e) => {
        setFilterValue({ ...filterValue, officeId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    let selectDoctor = [
        {
            id: "",
            firstName: t("Doctor"),
        },
    ];

    const fetchDoctorData = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/doctor/list?isPagination=false`,
                {
                    isInOffice: true,
                    isOfficePatient: true,
                    patientId: profileData.roleId == ROLE_ID.PATIENT ? profileData.id : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setDoctorFilterList(res.data.data ? selectDoctor.concat(res.data.data) : selectDoctor);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const doctorOptions =
        doctorFilterList &&
        doctorFilterList.map((item, i) => {
            return {
                value: item.id,
                label: item.firstName + " " + (item.lastName ? item.lastName : ""),
            };
        });

    const onFilterDoctorChange = (e) => {
        setFilterValue({ ...filterValue, doctorId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleAdd = () => {
        setShow(true);
    };

    const handleManualAdd = () => {
        setIsPatientAdd(true);
    };

    const handleEdit = (id) => {
        setPatientId(id);
        patientDetail(id);
        fetchRecommenderdata();
        setIsEdit(true);
    };

    const handleView = (id) => {
        setPatientId(id);
        patientDetail(id);
        fetchRecommenderdata();
        setIsEdit(true);
        setView(true);
    };

    const onChangeValue = (e) => {
        setShowDetails(false);
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onChangePatientValue = (e) => {
        const { name, value } = e.target;
        setPatientInputValue({ ...patientInputValue, [name]: value });
    };

    const onFilterChangeValue = (e) => {
        const { name, value } = e.target;
        setFilterValue({ ...filterValue, [name]: value });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            fetchPatientdata();
            setOffset(1);
            setCurrentPage(0);
        }
    };

    const patientDetail = async (patientId) => {
        setEditLoader(true);
        await axios
            .get(`${baseURL}/api/v1/${apiRouteName}/patient/detail/${patientId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                setPatientInputValue(res.data.data);
                setEditLoader(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    let selectRecommender = [
        {
            id: "",
            name: "-- Select Recommender --",
        },
    ];

    const fetchRecommenderdata = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/recommender/list?isPagination=false`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setRecommenderList(res.data.data ? selectRecommender.concat(res.data.data) : selectRecommender);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const recommenderOptions =
        recommenderList &&
        recommenderList.map((item, i) => {
            return {
                value: item.id,
                label: item.name,
            };
        });

    const onRecommenderChange = (e) => {
        setPatientInputValue({ ...patientInputValue, recommenderId: e.value });
        setOffset(1);
        setCurrentPage(0);
    };

    const handleClose = () => {
        setInputValue({
            firstName: "",
            lastName: "",
            dob: "",
            dateOfService: "",
            officeId: profileData.roleId == ROLE_ID.DOCTOR ? officeData.id : "",
            doctorId: profileData.roleId == ROLE_ID.DOCTOR ? profileData.id : "",
            // doctorId: "",
        });
        setPatientInputValue({
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            userName: "",
            gender: "",
            dob: "",
            phone1: "",
            statusId: 1,
            address1: "",
            countryId: "",
            stateId: "",
            cityId: "",
            zip: "",
            ssn: "",
            insuranceNumber: "",
            referralAgenecyNumber: "",
            membership: "",
            recommenderId: "",
        });
        setPatientId("");
        setView(false);
        setIsEdit(false);
        setShow(false);
        setIsPatientAdd(false);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: t("Are you sure you want to delete this patient ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    await axios
                        .delete(`${baseURL}/api/v1/${apiRouteName}/office/patient/${id}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: token,
                                lang: languageShortName,
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                Swal.fire(res.data.message, " ", "success");
                                fetchPatientdata();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                if (err.response.status == 500) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    navigate("/login");
                                } else {
                                    toast.error(err.response.data.message);
                                }
                            }
                        });
                }
            }
        });
    };

    useEffect(() => {
        if (profileData.roleId == ROLE_ID.ADMIN || profileData.roleId == ROLE_ID.PATIENT) {
            fetchOfficedata();
            fetchDoctorData();
        }
    }, []);

    useEffect(() => {
        fetchPatientdata();
    }, [offset, filterValue.doctorId, filterValue.officeId]);

    return (
        <>
            <div className="col-xs-12">
                <div className="page-title">
                    <div className="pull-left">
                        <h1 className="title">{t("Manage G-Dental Patient")}</h1>
                    </div>

                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <Link to="/dashboard">
                                    <i className="fa fa-home"></i>
                                    {t("Home")}
                                </Link>
                            </li>

                            {isEdit || show ? (
                                <li className="active cursor-pointer" onClick={handleClose}>
                                    <strong>{t("G-Dental Patient List")}</strong>
                                </li>
                            ) : (
                                <li className="active cursor-pointer">
                                    <strong>{t("G-Dental Patient List")}</strong>
                                </li>
                            )}
                            {isEdit || show ? (
                                <li className="active cursor-pointer">
                                    <strong>
                                        {isEdit ? t("Update") : t("G-Dental Check")} {t("Patient")}
                                    </strong>
                                </li>
                            ) : (
                                ""
                            )}
                        </ol>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {isEdit || isPatientAdd ? (
                        <UpsertPatient
                            isFromGDental={isPatientAdd}
                            view={view}
                            recommenderOptions={recommenderOptions}
                            onRecommenderChange={onRecommenderChange}
                            handleClose={handleClose}
                            fetchPatientdata={fetchPatientdata}
                            patientId={patientId}
                            setPatientId={setPatientId}
                            inputValue={patientInputValue}
                            setInputValue={setPatientInputValue}
                            show={isPatientAdd}
                            isEdit={isEdit}
                            onChangeValue={onChangePatientValue}
                            editLoader={editLoader}
                        />
                    ) : show ? (
                        <CheckGDentalPatient
                            showDetails={showDetails}
                            setShowDetails={setShowDetails}
                            handleClose={handleClose}
                            fetchPatientdata={fetchPatientdata}
                            patientId={patientId}
                            setPatientId={setPatientId}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            show={show}
                            isEdit={isEdit}
                            onChangeValue={onChangeValue}
                        />
                    ) : (
                        <section className="box">
                            <header className="panel_header">
                                <div className="mx-3">
                                    <div className="row ">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            {profileData.roleId == ROLE_ID.PATIENT ? (
                                                ""
                                            ) : (
                                                <h2 className="title pull-left">
                                                    <button className="btn btn-warning btn-corner" onClick={handleAdd}>
                                                        <i className="fa fa-plus"></i> &nbsp; <span>{t("G-Dental Check")}</span>
                                                    </button>
                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                        <button className="btn btn-primary btn-corner mx-3 my-1" onClick={handleManualAdd}>
                                                            <i className="fa fa-plus"></i> &nbsp; <span>{t("Add")}</span>
                                                        </button>
                                                    ) : (
                                                        ""
                                                    )}
                                                </h2>
                                            )}
                                        </div>
                                        <div className="col-lg-8 col-md-12 col-sm-12">
                                            <div className="row mx-lg-2">
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select
                                                                name="officeList"
                                                                value={officeOptions && officeOptions.filter(({ value }) => value == filterValue.officeId)}
                                                                options={officeOptions}
                                                                onChange={onFilterOfficeChange}
                                                                placeholder={t("Office")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6">
                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                        ""
                                                    ) : (
                                                        <div className="my-4">
                                                            <Select
                                                                name="doctorList"
                                                                value={doctorOptions && doctorOptions.filter(({ value }) => value == filterValue.doctorId)}
                                                                options={doctorOptions}
                                                                onChange={onFilterDoctorChange}
                                                                placeholder={t("Doctor")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchPatientUsername"
                                                            value={filterValue.searchPatientUsername}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("username")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchPatientName"
                                                            value={filterValue.searchPatientName}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("Patient")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-6 col-sm-6">
                                                    <div className="title">
                                                        <input
                                                            className="form-control"
                                                            name="searchPhone"
                                                            value={filterValue.searchPhone}
                                                            onChange={onFilterChangeValue}
                                                            onKeyDown={onKeyDown}
                                                            type="search"
                                                            placeholder={t("Phone")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="content-body">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="table-responsive" data-pattern="priority-columns">
                                            <table
                                                id="tech-companies-1"
                                                className={`table vm table-small-font no-mb table-bordered ${
                                                    isLoading || !patientList || (patientList && !patientList.length) ? "" : "table-striped"
                                                } table-hover`}
                                            >
                                                <thead>
                                                    <tr>
                                                        {profileData.roleId == ROLE_ID.DOCTOR ? "" : <th>{t("Office")}</th>}
                                                        {profileData.roleId == ROLE_ID.DOCTOR ? "" : <th>{t("Doctor")}</th>}
                                                        <th>{t("Username")}</th>
                                                        <th>{t("Name")}</th>
                                                        <th>{t("Birth Date")}</th>
                                                        <th>{t("Registered Date")}</th>
                                                        <th>{t("Claim")} #</th>
                                                        <th>{t("Membership Status")}</th>
                                                        <th>{t("Membership")} #</th>
                                                        <th>{t("Membership Plan")}</th>
                                                        <th>{t("Agent")}</th>
                                                        {/* <th>{t("Recommender")}</th> */}
                                                        <th>{t("Created by")}</th>
                                                        {profileData.roleId == ROLE_ID.ADMIN ? <th>{t("Action")}</th> : ""}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!patientList || (patientList && !patientList.length && !isLoading) ? (
                                                        <NoDataFound />
                                                    ) : isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        patientList &&
                                                        patientList.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    {profileData.roleId == ROLE_ID.DOCTOR ? (
                                                                        ""
                                                                    ) : (
                                                                        <>
                                                                            <td>
                                                                                <h6>{item.office && item.office.name ? item.office.name : "-"}</h6>
                                                                            </td>
                                                                            <td>
                                                                                <h6>
                                                                                    {item.doctor
                                                                                        ? (item.doctor.firstName && item.doctor.firstName) + " " + (item.doctor.lastName && item.doctor.lastName)
                                                                                        : "-"}
                                                                                </h6>
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                    <td>
                                                                        <h6>{item.patient && item.patient.userName ? item.patient.userName : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.patient
                                                                                ? (item.patient.firstName && item.patient.firstName) + " " + (item.patient.lastName && item.patient.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.patient && item.patient.dob ? monthDateYear(item.patient.dob) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.patient && item.patient.created_at ? monthDateYear(item.patient.created_at) : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.patient && item.totalClaim ? item.totalClaim : 0}</h6>
                                                                    </td>
                                                                    <td>
                                                                        {item.patient ? (
                                                                            <span
                                                                                className={`badge badge-${
                                                                                    item.patient.GDenticarePlanStatusId == STATUS_WITH_EXPIRY.Active
                                                                                        ? "success"
                                                                                        : item.patient.GDenticarePlanStatusId == STATUS_WITH_EXPIRY.Expiried
                                                                                        ? "dark"
                                                                                        : "danger"
                                                                                }`}
                                                                            >
                                                                                {Object.keys(STATUS_WITH_EXPIRY).find((key) => STATUS_WITH_EXPIRY[key] === item.patient.GDenticarePlanStatusId)}
                                                                            </span>
                                                                        ) : (
                                                                            "-"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.patient && item.patient.membership ? item.patient.membership : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>{item.patient && item.patient.planName && item.patient.planName.name ? item.patient.planName.name : "-"}</h6>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            {item.patient && item.patient.agent && item.patient.agent.firstName
                                                                                ? item.patient.agent.firstName + " " + item.patient.agent.lastName + " (" + item.patient.agent.userName + ")"
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    {/* <td>
                                                                        <h6>{item.patient && item.patient.recommender ? item.patient.recommender : "-"}</h6>
                                                                    </td> */}
                                                                    <td>
                                                                        <h6>
                                                                            {item.createdBy
                                                                                ? (item.createdBy.firstName && item.createdBy.firstName) + " " + (item.createdBy.lastName && item.createdBy.lastName)
                                                                                : "-"}
                                                                        </h6>
                                                                    </td>
                                                                    {profileData.roleId == ROLE_ID.ADMIN ? (
                                                                        <td>
                                                                            <button className="btn btn-primary btn-xs" onClick={() => handleEdit(item.patient.id)}>
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                            <button className="btn btn-warning btn-xs mx-2" onClick={() => handleView(item.patient.id)}>
                                                                                <i className="fa fa-eye"></i>
                                                                            </button>
                                                                            <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
                                                                                <i className="fa fa-trash-o "></i>
                                                                            </button>
                                                                        </td>
                                                                    ) : profileData.roleId == ROLE_ID.DOCTOR ? (
                                                                        <td>
                                                                            <button className="btn btn-warning btn-xs mx-2" onClick={() => handleView(item.patient.id)}>
                                                                                <i className="fa fa-eye"></i>
                                                                            </button>
                                                                        </td>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-md-flex justify-content-between ">
                                            <div className="d-flex align-center">
                                                {t("Total")} {patientList == null ? "0" : rowCount} {t("Patient")}
                                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                                            </div>
                                            <div className="pagination-center">
                                                <ReactPaginate
                                                    initialPage={0}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={0}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination"
                                                    activeClassName="active"
                                                    pageLinkClassName="page-link"
                                                    breakLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    previousLinkClassName="page-link"
                                                    pageClassName="page-item"
                                                    breakClassName="page-item"
                                                    nextClassName="page-item"
                                                    previousClassName="page-item"
                                                    previousLabel={t("Previous")}
                                                    nextLabel={t("Next")}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
}

export default GdentalPatientList;
