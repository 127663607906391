import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../commoncomponents/Loader";
import NoDataFound from "../../commoncomponents/NoDataFound";
import { baseURL } from "../../helper/Config";
import {
  CLAIM_STATUS,
  ROLE_ID,
  ROWS_PER_PAGE,
  VERIFIER_COMMENT_STATUS,
} from "../../helper/Constant";
import { monthDateYear } from "../../helper/dateFormates";
import UpsertClaim from "../../components/adminPanel/manageClaim/UpsertClaim";
import UpdateDoctor from "../../components/adminPanel/manageDoctor/UpdateDoctor";
import ApproveAgent from "../../components/adminPanel/manageAgent/ApproveAgent";
import { useTranslation } from "react-i18next";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const languageShortName = localStorage.getItem("languageShortName");

  const { t } = useTranslation();

  const [verifiedClaimList, setVerifiedClaimList] = useState([]);
  const [verifiedClaimCurrentPage, setVerifiedClaimCurrentPage] = useState(0);
  const [verifiedClaimOffset, setVerifiedClaimOffset] = useState(1);
  const [verifiedClaimPageCount, setVerifiedClaimPageCount] = useState(0);
  const [verifiedClaimRowCount, setVerifiedClaimRowCount] = useState(0);
  const [verifiedClaimIsLoading, setVerifiedClaimIsLoading] = useState(true);
  const [verifiedClaimData, setVerifiedClaimData] = useState([]);
  const [editLoader, setEditLoader] = useState(false);
  const [sum, setSum] = useState([]);
  const [inputValueObj, setInputValueObj] = useState([]);

  const [doctorReqestList, setDoctorReqestList] = useState([]);
  const [doctorCurrentPage, setDoctorCurrentPage] = useState(0);
  const [doctorOffset, setDoctorOffset] = useState(1);
  const [doctorPageCount, setDoctorPageCount] = useState(0);
  const [doctorRowCount, setDoctorRowCount] = useState(0);
  const [doctorIsLoading, setDoctorIsLoading] = useState(true);

  const [agentReqestList, setAgentReqestList] = useState([]);
  const [agentCurrentPage, setAgentCurrentPage] = useState(0);
  const [agentOffset, setAgentOffset] = useState(1);
  const [agentPageCount, setAgentPageCount] = useState(0);
  const [agentRowCount, setAgentRowCount] = useState(0);
  const [agentIsLoading, setAgentIsLoading] = useState(true);
  const [agencyList, setAgencyList] = useState([]);

  const [dashboardData, setDashboardData] = useState();
  const [show, setShow] = useState(false);
  const [showAgent, setShowAgent] = useState(false);

  const [languageList, setLangugaeList] = useState([]);
  const [id, setId] = useState("");

  const [claimList, setClaimList] = useState([]);
  const [claimCurrentPage, setClaimCurrentPage] = useState(0);
  const [claimOffset, setClaimOffset] = useState(1);
  const [claimPageCount, setClaimPageCount] = useState(0);
  const [claimRowCount, setClaimRowCount] = useState(0);
  const [claimIsLoading, setClaimIsLoading] = useState(true);

  const [officeId, setOfficeId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [agentId, setAgentId] = useState("");
  const [dateOfService, setDateOfService] = useState("");
  const [isView, setIsView] = useState(false);
  const [isVerifyClaimList, setIsVerifyClaimList] = useState(false);
  const [claimId, setClaimId] = useState("");
  const [claimInputValue, setClaimInputValue] = useState({
    verifierNote: "",
    doctorNote: "",
    finalResult: "",
    isVerifierNoteChanged: 0,
    isFinalResultChanged: 0,
    isDoctorNoteChanged: 0,
  });
  const [oldNoteValues, setOldNoteValues] = useState({
    verifierNote: "",
    doctorNote: "",
    finalResult: "",
  });

  const handleClaimView = (id, officeId) => {
    setClaimId(id);
    claimDetail(id);
    fetchTreatmentTypeData(id, officeId);
    setIsView(true);
  };

  const fetchLanguagedata = async (id) => {
    await axios
      .post(
        `${baseURL}/api/v1/admin/language/list?isPagination=false`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setLangugaeList(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleClose = () => {
    setClaimId("");
    setInputValueObj([]);
    setClaimInputValue("");
    setSum([]);
    setPatientId("");
    setDateOfService("");
    setIsView(false);
    setShow(false);
    setId("");
    setShowAgent(false);
  };

  //////////////////////// CLAIM //////////////////////////////////////////////////////////////
  const fetchTreatmentTypeData = async (claimId, officeId) => {
    axios
      .post(
        `${baseURL}/api/v1/admin/treatment/list?isPagination=false`,
        {
          claimId: claimId,
          officeId: officeId,
          isListAllRecord: false,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        let obj = [];

        res.data.data &&
          res.data.data.map((item) => {
            obj.push({
              treatmentTypeId: item.treatmentTypeId,
              treatmentId: Number(item.id),
              fee: Number(item.originalFee),
              unit: item.unit,
              totalUnitFee: item.totalUnitFee,
            });
          });
        setInputValueObj(obj);
        let sum =
          res.data.data &&
          res.data.data.reduce((acc, obj) => {
            if (!acc[obj.treatmentTypeId]) {
              acc[obj.treatmentTypeId] = 0;
            }
            acc[obj.treatmentTypeId] += obj.totalUnitFee;
            return acc;
          }, {});

        setSum(sum);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const claimDetail = async (claimId) => {
    setEditLoader(true);
    await axios
      .get(`${baseURL}/api/v1/admin/claim/detail/${claimId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then((res) => {
        let response = res.data.data;
        setOfficeId(response.office.id);
        setDoctorId(response.doctor.id);
        setPatientId(response.patient.id);
        setDateOfService(response.dateOfService);
        setVerifiedClaimData(response);
        setEditLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const claimNoteDetail = async (claimId) => {
    setEditLoader(true);
    await axios
      .get(`${baseURL}/api/v1/admin/claim/comment/detail/${claimId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then((res) => {
        setClaimInputValue(res.data.data);
        setOldNoteValues(res.data.data);
        setEditLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setClaimInputValue({ ...claimInputValue, [name]: value });
  };

  const handleClaimPageClick = (event) => {
    const selectedPage = event.selected;
    setClaimOffset(selectedPage + 1);
    setClaimCurrentPage(selectedPage);
  };

  const fetchClaimdata = async () => {
    setClaimIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/admin/claim/list`,
        {
          page: claimOffset,
          rowsPerPage: ROWS_PER_PAGE,
          isWeeklyVerifiedClaim: true,
          isSubmitted: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setClaimList(res.data.data);
        setClaimPageCount(res.data.extraData.pageCount);
        setClaimRowCount(res.data.extraData.rowCount);
        setClaimIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    fetchClaimdata();
  }, [claimOffset]);

  //////////////////////// VERIFIED - CLAIM //////////////////////////////////////////////////////////////

  const handleVerifiedClaimPageClick = (event) => {
    const selectedPage = event.selected;
    setVerifiedClaimOffset(selectedPage + 1);
    setVerifiedClaimCurrentPage(selectedPage);
  };

  const fetchVerifiedClaimdata = async () => {
    setVerifiedClaimIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/admin/claim/list`,
        {
          page: verifiedClaimOffset,
          rowsPerPage: ROWS_PER_PAGE,
          isSubmitted: CLAIM_STATUS.Submitted,
          isVerifierClaimList: true,
          isWeeklyVerifiedClaim: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setVerifiedClaimList(res.data.data);
        setVerifiedClaimPageCount(res.data.extraData.pageCount);
        setVerifiedClaimRowCount(res.data.extraData.rowCount);
        setVerifiedClaimIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    fetchVerifiedClaimdata();
  }, [verifiedClaimOffset]);

  /////////////////////////////////////// DASHBOARD-DATA /////////////////////////////

  const fetchDoctorReqestdata = async () => {
    setDoctorIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/admin/registration-request/list?isPagination=true`,
        {
          page: doctorOffset,
          rowsPerPage: ROWS_PER_PAGE,
          roleId: ROLE_ID.DOCTOR,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setDoctorIsLoading(false);
        setDoctorReqestList(res.data.data);
        setDoctorPageCount(res.data.extraData.pageCount);
        setDoctorRowCount(res.data.extraData.rowCount);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const handleDoctorPageClick = (event) => {
    const selectedPage = event.selected;
    setDoctorOffset(selectedPage + 1);
    setDoctorCurrentPage(selectedPage);
  };

  const fetchAgentReqestdata = async () => {
    setAgentIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/admin/registration-request/agentlist?isPagination=true`,
        {
          page: agentOffset,
          rowsPerPage: ROWS_PER_PAGE,
          roleId: ROLE_ID.AGENT,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setAgentIsLoading(false);
        setAgentReqestList(res.data.data);
        setAgentPageCount(res.data.extraData.pageCount);
        setAgentRowCount(res.data.extraData.rowCount);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  let agencyBlankList = [
    {
      agencyId: "",
      firstName: t("Select Blank Agency"),
    },
  ];

  const fetchAgencydata = async () => {
    setAgentIsLoading(true);
    await axios
      .post(
        `${baseURL}/api/v1/admin/agency/list?isPagination=false`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            lang: languageShortName,
          },
        }
      )
      .then((res) => {
        setAgencyList(agencyBlankList.concat(res.data.data));
        setAgentIsLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const agencyOptions =
    agencyList &&
    agencyList.map((item, i) => {
      return {
        value: item.id,
        label: item.firstName,
      };
    });

  const onAgencyChange = (e) => {
    setAgentValue({ ...agentValue, agencyId: e.value });
  };

  const handleAgentPageClick = (event) => {
    const selectedPage = event.selected;
    setAgentOffset(selectedPage + 1);
    setAgentCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchDoctorReqestdata();
    fetchAgentReqestdata();
    fetchAgencydata();
  }, [doctorOffset, agentOffset]);

  const fetchDashboardData = async () => {
    await axios
      .get(`${baseURL}/api/v1/admin/dashboard`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then((res) => {
        setDashboardData(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  ////////////////////////////////////// DOCTOR DATA /////////////////////////////////

  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    dob: "",
    phone1: "",
    address1: "",
    statusId: "",
  });
  const [officeInputValue, setOfficeInputValue] = useState({
    email: "",
    name: "",
    businessUsername: "",
    phone1: "",
    npi1: "",
    licenseNumber: "",
    languageId: "",
    businessCategoryId: "",
    statusId: "",
    address1: "",
    countryId: "",
    stateId: "",
    cityId: "",
    zip: "",
    googleMapLink: "",
  });
  const [specialityCheckedList, setSpecialityCheckedList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const [agentValue, setAgentValue] = useState({
    email: "",
    firstName: "",
    lastName: "",
    userName: "",
    gPointUserName: "",
    phone1: "",
    address1: "",
    statusId: "",
    agencyId: "",
  });
  const [agencyCheckedList, setAgencyCheckedList] = useState([]);

  const handleShow = (id, roleId, officeId) => {
    doctorDetail(id);
    officeDetail(officeId);
    setDoctorId(id);
    setId(officeId);
    fetchLanguagedata();
    setShow(true);
  };

  const handleShowAgent = (id, roleId) => {
    agentDetail(id);
    setAgentId(id);
    setShowAgent(true);
    setIsView(false);
  };

  const officeDetail = async (officeId) => {
    setEditLoader(true);
    await axios
      .get(`${baseURL}/api/v1/admin/office/detail/${officeId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then((res) => {
        setOfficeInputValue(res.data.data);
        setCheckedList(
          res.data.data &&
            res.data.data.languageId &&
            res.data.data.languageId.split("~").map((item) => Number(item))
        );
        setSpecialityCheckedList(
          res.data.data &&
            res.data.data.specialtyPartId &&
            res.data.data.specialtyPartId.split("~").map((item) => Number(item))
        );
        setEditLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const doctorDetail = async (doctorId) => {
    setEditLoader(true);
    await axios
      .get(`${baseURL}/api/v1/admin/doctor/detail/${doctorId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then((res) => {
        setInputValue(res.data.data);
        setEditLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  const onDoctorChangeValue = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const agentDetail = async (agentId) => {
    setEditLoader(true);
    await axios
      .get(`${baseURL}/api/v1/admin/agent/detail/${agentId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          lang: languageShortName,
        },
      })
      .then((res) => {
        setAgentValue(res.data.data);
        setAgencyCheckedList(
          res.data.data &&
            res.data.data.region &&
            res.data.data.region.split("~").map((item) => Number(item))
        );
        setEditLoader(false);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 500 || err.response.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/login");
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <>
      <div className="col-xs-12">
        <div className="page-title">
          <div className="pull-left">
            <h1 className="title">
              {isView && isVerifyClaimList
                ? t("Manage verified claim")
                : isView && !isVerifyClaimList
                ? t("Manage claim")
                : show
                ? t("View requested doctor's detail")
                : `${t("Admin")} ${t("Dashboard")}`}
            </h1>
          </div>
          {isView || show || showAgent ? (
            <div className="pull-right">
              <ol className="breadcrumb">
                <li className="cursor-pointer" onClick={handleClose}>
                  <i className="fa fa-home"></i>
                  {t("Home")}
                </li>

                <li className="active cursor-pointer">
                  <strong>
                    {t("View")}{" "}
                    {isView
                      ? t("Claim")
                      : show
                      ? t("Requested doctor's detail")
                      : showAgent
                      ? t("Requested Agent's detail")
                      : ""}
                  </strong>
                </li>
              </ol>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {isView ? (
        <UpsertClaim
          fetchClaimdata={fetchVerifiedClaimdata}
          oldNoteValues={oldNoteValues}
          inputValue={claimInputValue}
          setInputValue={setClaimInputValue}
          onChangeValue={onChangeValue}
          show={show}
          setShow={setShow}
          claimDetail={claimDetail}
          claimNoteDetail={claimNoteDetail}
          isVerifyClaimList={isVerifyClaimList}
          editLoader={editLoader}
          sum={sum}
          setSum={setSum}
          inputValueObj={inputValueObj}
          setInputValueObj={setInputValueObj}
          officeId={officeId}
          doctorId={doctorId}
          patientId={patientId}
          dateOfService={dateOfService}
          handleClose={handleClose}
          claimData={verifiedClaimData}
          claimId={claimId}
          isView={isView}
        />
      ) : show ? (
        <UpdateDoctor
          fetchDashboardData={fetchDashboardData}
          officeId={id}
          languageList={languageList}
          officeInputValue={officeInputValue}
          checkedList={checkedList}
          specialityCheckedList={specialityCheckedList}
          editLoader={editLoader}
          isSteps={true}
          isView={true}
          handleClose={handleClose}
          doctorId={doctorId}
          inputValue={inputValue}
          setInputValue={setInputValue}
          onChangeValue={onDoctorChangeValue}
          fetchDoctorReqestdata={fetchDoctorReqestdata}
        />
      ) : showAgent ? (
        <ApproveAgent
          agencyCheckedList={agencyCheckedList}
          setAgencyCheckedList={setAgencyCheckedList}
          editLoader={editLoader}
          handleClose={handleClose}
          fetchAgentReqestdata={fetchAgentReqestdata}
          agentId={agentId}
          agencyOptions={agencyOptions}
          onAgencyChange={onAgencyChange}
          agentValue={agentValue}
          setAgentValue={setAgentValue}
          isView={isView}
        />
      ) : (
        <>
          <div className="col-lg-12">
            <section className="box nobox marginBottom0">
              <div className="content-body">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-doctor")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-user-md"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        />
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalDoctors}{" "}
                          {t("Doctor")}
                          {/* {dashboardData && dashboardData.totalDoctors > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Doctor")}
                          {/* {dashboardData && dashboardData.totalDoctors > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-office")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-hospital-o"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        ></i>
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalOffice}{" "}
                          {t("Office")}
                          {/* {dashboardData && dashboardData.totalOffice > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Office")}
                          {/* {dashboardData && dashboardData.totalOffice > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-patient")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-user-plus"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        />
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalPatient}{" "}
                          {t("Patient")}
                          {/* {dashboardData && dashboardData.totalPatient > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Patient")}
                          {/* {dashboardData && dashboardData.totalPatient > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/dashboard")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <img
                          src="./data/icons/hos-icon-so4.png"
                          className="ico-icon-o"
                          alt=""
                        />
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalPendingRequest}{" "}
                          {t("Request")}
                          {/* {dashboardData && dashboardData.totalPendingRequest > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Registration")} {t("Request")}
                          {/* {dashboardData && dashboardData.totalPendingRequest > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-claim")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa-regular fa-address-card"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        ></i>
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalClaim}{" "}
                          {t("Claim")}
                          {/* {dashboardData && dashboardData.totalClaim > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Claim")}
                          {/* {dashboardData && dashboardData.totalClaim > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-claim-verify")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-envelope-o"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        ></i>
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalVerifiedClaim}{" "}
                          {t("Verified")} {t("Claim")}
                          {/* {dashboardData && dashboardData.totalVerifiedClaim > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Verified")} {t("Claim")}
                          {/* {dashboardData && dashboardData.totalVerifiedClaim > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-gdental-patient")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-wheelchair"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        ></i>
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalOfficePatients}{" "}
                          {t("G-Dental")} {t("Patient")}
                          {/* {dashboardData && dashboardData.totalOfficePatients > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("G-Dental")} {t("Patient")}
                          {/* {dashboardData && dashboardData.totalOfficePatients > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-employee")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa-solid fa-users"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        ></i>
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalEmployees}{" "}
                          {t("Employee")}
                          {/* {dashboardData && dashboardData.totalEmployees > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {" "}
                          {t("Total")} {t("Employee")}
                          {/* {dashboardData && dashboardData.totalEmployees > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-agency")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-building"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        ></i>
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalAgency}{" "}
                          {t("Agency")}
                          {/* {dashboardData && dashboardData.totalAgency > 1 ? "ies" : "y"} */}
                        </h3>
                        <span>
                          {" "}
                          {t("Total")} {t("Agency")}
                          {/* {dashboardData && dashboardData.totalAgency > 1 ? "ies" : "y"} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div
                      className="r4_counter db_box"
                      onClick={() => navigate("/manage-agent")}
                    >
                      <i className="pull-left ico-icon icon-md icon-primary mt-10">
                        <i
                          className="fa fa-user-secret"
                          style={{ fontSize: "34px", color: "rgb(77 108 143)" }}
                        />
                      </i>
                      <div className="stats">
                        <h3 className="mb-5">
                          {dashboardData && dashboardData.totalAgent}{" "}
                          {t("Agent")}
                          {/* {dashboardData && dashboardData.totalAgent > 1 ? "s" : ""} */}
                        </h3>
                        <span>
                          {t("Total")} {t("Agent")}
                          {/* {dashboardData && dashboardData.totalAgent > 1 ? "s" : ""} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="clearfix"></div>
          <div className="clearfix"></div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title pull-left">
                      {t("Last week's claim list")}
                    </h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table vm table-small-font no-mb table-bordered table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th>{t("Created Date")}</th>
                                <th>{t("Date of service")}</th>
                                <th>{t("Doctor")}</th>
                                <th>{t("Office")}</th>
                                <th>{t("Action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!claimList ||
                              (claimList && !claimList.length) ? (
                                <NoDataFound />
                              ) : claimIsLoading ? (
                                <Loader />
                              ) : (
                                claimList &&
                                claimList.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <h6>
                                          {item.created_at
                                            ? monthDateYear(item.created_at)
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {item.dateOfService
                                            ? monthDateYear(item.dateOfService)
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {item.doctor
                                            ? (item.doctor.firstName &&
                                                item.doctor.firstName) +
                                              " " +
                                              (item.doctor.lastName &&
                                                item.doctor.lastName)
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {item.office && item.office.name
                                            ? item.office.name
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-warning btn-xs"
                                          onClick={() => {
                                            setIsVerifyClaimList(false);
                                            handleClaimView(
                                              item.id,
                                              item.office.id
                                            );
                                          }}
                                        >
                                          <i className="fa fa-eye "></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-md-flex justify-content-between ">
                          <div className="d-flex align-center">
                            {t("Total")}{" "}
                            {claimList == null ? "0" : claimRowCount}{" "}
                            {t("Claim")}
                            {/* {claimRowCount && claimRowCount > 1 ? "s" : ""} */}
                          </div>
                          <div className="pagination-center">
                            <ReactPaginate
                              initialPage={0}
                              pageCount={claimPageCount}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={3}
                              onPageChange={handleClaimPageClick}
                              containerClassName="pagination"
                              activeClassName="active"
                              pageLinkClassName="page-link"
                              breakLinkClassName="page-link"
                              nextLinkClassName="page-link"
                              previousLinkClassName="page-link"
                              pageClassName="page-item"
                              breakClassName="page-item"
                              nextClassName="page-item"
                              previousClassName="page-item"
                              previousLabel={t("Previous")}
                              nextLabel={t("Next")}
                              disableInitialCallback={true}
                              forcePage={claimCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title pull-left">
                      {" "}
                      {t("Last week's verified claim list")}
                    </h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table vm table-small-font no-mb table-bordered table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th>{t("Claim")} #</th>
                                <th>{t("Doctor")}</th>
                                <th>{t("Office")}</th>
                                <th>{t("Date of service")}</th>
                                <th>{t("Result")}</th>
                                <th>{t("Action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!verifiedClaimList ||
                              (verifiedClaimList &&
                                !verifiedClaimList.length) ? (
                                <NoDataFound />
                              ) : verifiedClaimIsLoading ? (
                                <Loader />
                              ) : (
                                verifiedClaimList &&
                                verifiedClaimList.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <h6>{item.code ? item.code : "-"}</h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {item.doctor
                                            ? (item.doctor.firstName &&
                                                item.doctor.firstName) +
                                              " " +
                                              (item.doctor.lastName &&
                                                item.doctor.lastName)
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {item.office && item.office.name
                                            ? item.office.name
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {item.dateOfService
                                            ? monthDateYear(item.dateOfService)
                                            : "-"}
                                        </h6>
                                      </td>
                                      <td>
                                        <span
                                          className={`badge badge-${
                                            item.finalResult ==
                                            VERIFIER_COMMENT_STATUS.Good
                                              ? "success"
                                              : item.finalResult ==
                                                VERIFIER_COMMENT_STATUS.Normal
                                              ? "warning"
                                              : item.finalResult ==
                                                VERIFIER_COMMENT_STATUS[
                                                  "Not Good"
                                                ]
                                              ? "danger"
                                              : "info"
                                          }`}
                                        >
                                          {Object.keys(
                                            VERIFIER_COMMENT_STATUS
                                          ).find(
                                            (key) =>
                                              VERIFIER_COMMENT_STATUS[key] ===
                                              item.finalResult
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-warning btn-xs"
                                          onClick={() => {
                                            setIsVerifyClaimList(true);
                                            handleClaimView(
                                              item.id,
                                              item.office.id
                                            );
                                          }}
                                        >
                                          <i className="fa fa-eye "></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-md-flex  justify-content-between ">
                          <div className="d-flex align-center">
                            {t("Total")}{" "}
                            {verifiedClaimList == null
                              ? "0"
                              : verifiedClaimRowCount}{" "}
                            {t("Claim")}
                            {/* {verifiedClaimRowCount && verifiedClaimRowCount > 1 ? "s" : ""} */}
                          </div>
                          <div className="pagination-center">
                            <ReactPaginate
                              initialPage={0}
                              pageCount={verifiedClaimPageCount}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={3}
                              onPageChange={handleVerifiedClaimPageClick}
                              containerClassName="pagination"
                              activeClassName="active"
                              pageLinkClassName="page-link"
                              breakLinkClassName="page-link"
                              nextLinkClassName="page-link"
                              previousLinkClassName="page-link"
                              pageClassName="page-item"
                              breakClassName="page-item"
                              nextClassName="page-item"
                              previousClassName="page-item"
                              previousLabel={t("Previous")}
                              nextLabel={t("Next")}
                              disableInitialCallback={true}
                              forcePage={verifiedClaimCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title pull-left">
                      {t("Pending doctor's list")}
                    </h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table vm table-small-font no-mb table-bordered table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th>{t("E-mail")}</th>
                                <th>{t("Username")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Created Date")}</th>
                                <th>{t("Action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!doctorReqestList ||
                              (doctorReqestList && !doctorReqestList.length) ? (
                                <NoDataFound />
                              ) : doctorIsLoading ? (
                                <Loader />
                              ) : (
                                doctorReqestList &&
                                doctorReqestList.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <h6>{item.email}</h6>
                                      </td>
                                      <td>
                                        <h6>{item.userName}</h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {(item.firstName && item.firstName) +
                                            " " +
                                            (item.lastName && item.lastName)}
                                        </h6>
                                      </td>
                                      <td>
                                        {item.created_at &&
                                          monthDateYear(item.created_at)}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-warning btn-xs mx-2"
                                          onClick={() =>
                                            handleShow(
                                              item.id,
                                              ROLE_ID.DOCTOR,
                                              item.officeId
                                            )
                                          }
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-md-flex  justify-content-between ">
                          <div className="d-flex align-center">
                            {t("Total")}{" "}
                            {doctorReqestList == null ? "0" : doctorRowCount}{" "}
                            {t("Doctor")}- {t("Request")}
                            {/* {doctorRowCount && doctorRowCount > 1 ? "s" : ""} */}
                          </div>
                          <div className="pagination-center">
                            <ReactPaginate
                              initialPage={0}
                              pageCount={doctorPageCount}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={3}
                              onPageChange={handleDoctorPageClick}
                              containerClassName="pagination"
                              activeClassName="active"
                              pageLinkClassName="page-link"
                              breakLinkClassName="page-link"
                              nextLinkClassName="page-link"
                              previousLinkClassName="page-link"
                              pageClassName="page-item"
                              breakClassName="page-item"
                              nextClassName="page-item"
                              previousClassName="page-item"
                              previousLabel={t("Previous")}
                              nextLabel={t("Next")}
                              disableInitialCallback={true}
                              forcePage={doctorCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <section className="box">
                  <header className="panel_header">
                    <h2 className="title pull-left">
                      {t("Pending Agent's list")}
                    </h2>
                  </header>
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="table-responsive"
                          data-pattern="priority-columns"
                        >
                          <table
                            id="tech-companies-1"
                            className="table vm table-small-font no-mb table-bordered table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th>{t("E-mail")}</th>
                                <th>{t("Username")}</th>
                                <th>{t("Name")}</th>
                                <th>{t("Created Date")}</th>
                                <th>{t("Action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!agentReqestList ||
                              (agentReqestList && !agentReqestList.length) ? (
                                <NoDataFound />
                              ) : agentIsLoading ? (
                                <Loader />
                              ) : (
                                agentReqestList &&
                                agentReqestList.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <h6>{item.email}</h6>
                                      </td>
                                      <td>
                                        <h6>{item.userName}</h6>
                                      </td>
                                      <td>
                                        <h6>
                                          {(item.firstName && item.firstName) +
                                            " " +
                                            (item.lastName && item.lastName)}
                                        </h6>
                                      </td>
                                      <td>
                                        {item.created_at &&
                                          monthDateYear(item.created_at)}
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-warning btn-xs mx-2"
                                          onClick={() =>
                                            handleShowAgent(
                                              item.id,
                                              ROLE_ID.AGENT
                                            )
                                          }
                                        >
                                          <i className="fa fa-eye"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-md-flex  justify-content-between ">
                          <div className="d-flex align-center">
                            {t("Total")}{" "}
                            {agentReqestList == null ? "0" : agentRowCount}{" "}
                            {t("Agent")}- {t("Request")}
                          </div>
                          <div className="pagination-center">
                            <ReactPaginate
                              initialPage={0}
                              pageCount={agentPageCount}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={3}
                              onPageChange={handleAgentPageClick}
                              containerClassName="pagination"
                              activeClassName="active"
                              pageLinkClassName="page-link"
                              breakLinkClassName="page-link"
                              nextLinkClassName="page-link"
                              previousLinkClassName="page-link"
                              pageClassName="page-item"
                              breakClassName="page-item"
                              nextClassName="page-item"
                              previousClassName="page-item"
                              previousLabel={t("Previous")}
                              nextLabel={t("Next")}
                              disableInitialCallback={true}
                              forcePage={agentCurrentPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
