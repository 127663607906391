import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

export default function NearMe() {
    const navigate = useNavigate();
    const divRef = useRef(null);
    const [clipPath, setClipPath] = useState("");

    const { t } = useTranslation();
    const languageShortName = localStorage.getItem("languageShortName");

    const [linePosition, setLinePosition] = useState([]); //Initial Line position
    const [lineHeight, setLineHeight] = useState([]); //Initial Line Height
    const [lineWidth, setLineWidth] = useState([]); //Initial Line Width

    let verticalHeight = null;
    let verticalWidth = null;
    let verticalPosition = null;

    function handleVerticalImageLoad() {
        const divWidth = divRef.current.offsetWidth;

        verticalHeight = divWidth * 0.235;
        verticalWidth = divWidth * 0.015;
        verticalPosition = divWidth * 0.5;

        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setLinePosition(verticalPosition);
        setClipPath(`polygon(0 0, ${verticalPosition}px 0, ${verticalPosition}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    }

    const handleMouseMove = (event) => {
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const x = event.clientX - rect.left;
        setLinePosition(x);
        verticalHeight = rect.width * 0.235;
        verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
    };

    const handleTouchMove = (event) => {
        event.preventDefault(); // prevent scrolling on touch devices
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const touch = event.touches[0];
        const x = touch.clientX - rect.left;
        setLinePosition(x);
        const verticalHeight = rect.width * 0.235;
        const verticalWidth = rect.width * 0.015;
        setLineHeight(verticalHeight);
        setLineWidth(verticalWidth);
        setClipPath(`polygon(0 0, ${x}px 0, ${x}px ${verticalHeight}px, 0 ${verticalHeight}px)`);
      };

    return (
        <>
            <Header />

            <section>
            <div ref={divRef} className="hero-get-in-toch-custom" style={{ cursor: "grab", position: "relative", width: "100%", height: `${lineHeight}px` }} onMouseMove={handleMouseMove} onTouchStart={handleTouchMove} onTouchMove={handleTouchMove}>
                    <img src="/assets/images/home/home_find_left.png" alt="" style={{ position: "absolute", left: 0, top: 0, zIndex: 2, width: "100%", height: "auto", clipPath }} />
                    <img src="/assets/images/home/home_find_right.png" alt="" style={{ position: "absolute", left: 0, top: 0, zIndex: 1, width: "100%", height: "auto" }} />
                    <img
                        src="/assets/images/home/circle_line_icon.png"
                        alt="Vertical Line"
                        style={{ position: "absolute", top: 0, left: `${linePosition}px`, transform: "translateX(-50%)", width: `${lineWidth}px`, height: `${lineHeight}px`, zIndex: 3 }}
                        onLoad={handleVerticalImageLoad}
                    />
                </div>
            </section>
            <br />

            {/* Find Dentist */}
            <section className="pt-10 d-flex justify-content-center">
                <div className="col-lg-6 col-md-6 col-sm-7 text-title-sm-custom text-center" data-aos="zoom-in">
                    <h3 className="bold pb-2">{t("Practitioners")}</h3>
                    <button className="btn btn-outline text-white join-now-custom btn-arrow-width-custom" onClick={() => navigate("/get-in-touch")}>
                        {t("Contact Me")}
                        <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                    </button>
                </div>
            </section>

            {/*  */}
            {/* <section className="pt-5 d-flex justify-content-center" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                <div className="card col-lg-5 contact-info-box-custom">
                    <div className="row d-flex p-3">
                        <div className="col-lg-5 col-md-12 col-sm-12 text-black near-me-card-custom">
                            <div className="mask-group-near-me-img-custom">
                                <img src="/assets/images/home/mask-group.png" alt="" />
                            </div>
                            {languageShortName == "ko" ? (
                                <>
                                <h3 className="bold quality-text-custom practitioner-text-mb-custom">{t("a Practitioner")}</h3>
                                <h3 className="bold text-dark">{t("Search for")}</h3>
                                </>
                            ) : (
                                <>
                                <h3 className="bold text-dark">{t("Search for")}</h3>
                                <h3 className="bold quality-text-custom practitioner-text-mb-custom">{t("a Practitioner")}</h3>
                                </>
                            )}
                        </div>
                        <div className="col card-sm-bg-custom">
                            <form>
                                <div className="form-group">
                                    <label for="exampleFormControlInput1">Service</label>
                                    <input type="email" className="form-control info-input-custom input-radius-custom" id="exampleFormControlInput1" />
                                </div>
                                <div className="form-group">
                                    <label for="exampleFormControlInput1">Zip Code</label>
                                    <input type="email" className="form-control info-input-custom input-radius-custom" id="exampleFormControlInput1" />
                                </div>
                                <div className="form-group">
                                    <button className="btn quality-text-custom btn-contact-me-custom btn-sm-full-custom">
                                        Find A Dentist Near Me
                                        <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}
            {/*  */}
            <section className="pt-5 d-flex justify-content-center">
                <div className="container">
                    <div className="col-lg-12 col-sm-12">
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-left">
                            <div className="card dentist-near-me-custom">
                                <div className="text-center">
                                    <p>
                                        <img src="/assets/images/home/dental-care/doctor_Alex_Musheyev.jpg" alt="" className="card-img-width-custom" width="334" height="269" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom mb-1">Dr. Alex Musheyev</p>
                                    {/* <p className="d-flex justify-content-center">New York</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="zoom-in">
                            <div className="card dentist-near-me-custom">
                                <div className="text-center">
                                    <p>
                                        <img src="/assets/images/home/dental-care/doctor_Geraldo_Polanco.jpg" alt="" className="card-img-width-custom" width="334" height="269" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom mb-1">Dr. Geraldo Polanco</p>
                                    {/* <p className="d-flex justify-content-center">New York</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-right">
                            <div className="card dentist-near-me-custom">
                                <div className="text-center">
                                    <p>
                                        <img src="/assets/images/home/dental-care/doctor_HR_Lee.jpg" alt="" className="card-img-width-custom" width="334" height="269" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom mb-1">Dr. H R Lee</p>
                                    {/* <p className="d-flex justify-content-center">New York</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-left">
                            <div className="card dentist-near-me-custom">
                                <div className="text-center">
                                    <p>
                                        <img src="/assets/images/home/dental-care/doctor_Daniel_Kim.png" alt="" className="card-img-width-custom" width="334" height="269" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom mb-1">Dr. Daniel Kim</p>
                                    {/* <p className="d-flex justify-content-center">New York</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="zoom-in">
                            <div className="card dentist-near-me-custom">
                                <div className="text-center">
                                    <p>
                                        <img src="/assets/images/home/doctor5.png" alt="" className="card-img-width-custom" width="334" height="269" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom mb-1">Dr. Ava Anderson</p>
                                    {/* <p className="d-flex justify-content-center">Cosmetic Dentistry</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12" data-aos="fade-right">
                            <div className="card dentist-near-me-custom">
                                <div className="text-center">
                                    <p>
                                        <img src="/assets/images/home/doctor6.png" alt="" className="card-img-width-custom" width="334" height="269" />
                                    </p>
                                    <p className="pt-2 bold text-number-color-custom mb-1">Dr. Olivia Wilson</p>
                                    {/* <p className="d-flex justify-content-center">Oral Pathology</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="form-group text-center">
                        <button className="btn btn-outline text-white btn-dentists-doctor-custom">
                            Dentists Near Me
                            <i className="fa fa-long-arrow-right ms-2 arrow-rotate-custom" aria-hidden="true"></i>
                        </button>
                    </div> */}
                </div>
            </section>
            {/*  */}
            <Footer />
        </>
    );
}
