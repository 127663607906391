import React, { useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { LANGUAGE_LIST } from "../../helper/Constant";

import Select from "react-select";
import { useTranslation } from "react-i18next";

export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [languageId, setLanguageId] = useState(localStorage.getItem("languageShortName") ? localStorage.getItem("languageShortName") : "");
    const [windowSize] = useState({ width: window.innerWidth });

    const btnLogin = () => {
        navigate("/login");
    };

    const signUpWithDoc = () => {
        navigate("/authDoctor/signup");
    };

    const signUpWithPatient = () => {
        navigate("/signup");
    };

    const requestAgent = () => {
        navigate("/authAgent/signup");
    };

    const languageOptions =
        LANGUAGE_LIST &&
        LANGUAGE_LIST.map((item) => {
            return {
                value: item.shortName,
                label: windowSize.width > 768 ? item.flag + "   " + item.name : item.flag,
            };
        });

    const onLanguageChange = async (e) => {
        setLanguageId(e.value);
        localStorage.setItem("languageShortName", e.value);
        window.location.reload();
    };

    return (
        <>
            <style jsx>
                {`
                    .container-sm {
                        max-width: 1425px;
                    }
                    .nav-link:hover {
                        color: #ffffff !important;
                        background-color: #34a8eb !important;
                    }
                `}
            </style>
            <nav className="navbar navbar-expand-lg navbar-light py-4 blur-opacity-header-custom shrink mb-0">
                <div className="container-sm d-lg-flex">
                    <Link to="/">
                        <a role="button" className="p-0">
                            <img src="/assets/images/home/logo.png" alt="" className="header-img-iconchange-custom" width="100%" height="100%" />
                        </a>
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end nav-direct-custom" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto text-center">
                            <li className={`nav-item`}>
                                <Link to="/">
                                    <a role="button" className="nav-link text-black">
                                        {t("Home")}
                                    </a>
                                </Link>
                            </li>
                            <li className={`nav-item ${location.pathname == "/services" ? "active" : ""}`}>
                                <Link to="/services">
                                    <a role="button" className="nav-link text-black">
                                        {t("Services")}
                                    </a>
                                </Link>
                            </li>
                            <li className={`nav-item ${location.pathname == "/near-me" ? "active" : ""}`}>
                                <Link to="/near-me">
                                    <a role="button" className="nav-link text-black">
                                        {t("Find a Dentist")}
                                    </a>
                                </Link>
                            </li>
                            <li className={`nav-item ${location.pathname == "/practitioners" ? "active" : ""}`}>
                                <Link to="/practitioners">
                                    <a role="button" className="nav-link text-black">
                                        {t("For Practitioners")}
                                    </a>
                                </Link>
                            </li>
                            <li className={`nav-item ${location.pathname == "/get-in-touch" ? "active" : ""}`}>
                                <Link to="/get-in-touch">
                                    <a role="button" className="nav-link text-black">
                                        {t("Contact Us")}
                                    </a>
                                </Link>
                            </li>
                        </ul>
                        <form className="form-inline my-2 my-lg-0 text-center d-lg-flex">
                            <button
                                className={`${
                                    location && location.pathname == "/"
                                        ? "btn btn-outline-success my-2 my-sm-0 ms-2 me-3 btn-login-outline-custom"
                                        : "btn btn-outline-success my-2 my-sm-0 ms-2 me-3 btn-login-custom"
                                }`}
                                type="submit"
                                onClick={btnLogin}
                            >
                                {t("Login")}
                            </button>
                            <button
                                className={`${
                                    location && location.pathname == "/"
                                        ? "btn btn-outline-success my-2 my-sm-0 me-3 text-white enroll-patient-custom"
                                        : "btn btn-outline-success my-2 my-sm-0 me-3 text-white enroll-patient-custom"
                                }`}
                                type="submit"
                                onClick={signUpWithPatient}
                            >
                                {t("Member Enrollment")}
                            </button>
                            <button className="btn btn-outline-success my-2 my-sm-0 me-3 text-white btn-enrolldoctor-custom" type="submit" onClick={signUpWithDoc}>
                                {t("Dentist Enrollment")}
                            </button>
                            <button className="btn btn-outline-success my-2 my-sm-0 me-3 text-white btn-requestagent-custom" type="submit" onClick={requestAgent}>
                                {t("Agent Requestment")}
                            </button>
                            <div className="d-flex justify-content-center">
                                <div className="mx-3 mt-5 " style={{ width: "120px" }}>
                                    <Select
                                        name="languageList"
                                        value={languageOptions && languageOptions.filter(({ value }) => value == languageId)}
                                        options={languageOptions}
                                        onChange={onLanguageChange}
                                        placeholder={t("Select")}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </>
    );
}
