import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../../helper/Config";
import NoDataFound from "../../../commoncomponents/NoDataFound";
import Loader from "../../../commoncomponents/Loader";
import { ROLE_ID, ROWS_PER_PAGE } from "../../../helper/Constant";
import { evaluationEmptyStars, evaluationRating } from "../../../helper/helper";
import "react-datepicker/dist/react-datepicker.css";
import { monthDateYear } from "../../../helper/dateFormates";
import { useTranslation } from "react-i18next";

function DetailDentalCompareList(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { profileData } = useOutletContext();
    const { t } = useTranslation();

    const [evaluationList, setEvaluationList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    let apiRouteName =
        profileData.roleId == ROLE_ID.ADMIN
            ? "admin"
            : profileData.roleId == ROLE_ID.PATIENT
            ? "patient"
            : profileData.roleId == ROLE_ID.DOCTOR
            ? "doctor"
            : profileData.roleId == ROLE_ID.VERIFY
            ? "verifier"
            : "";

    const fetchEvaluationdata = async () => {
        setIsLoading(true);
        await axios
            .post(
                `${baseURL}/api/v1/${apiRouteName}/dental-evaluation/compare/list?isPagination=true`,
                {
                    page: offset,
                    rowsPerPage: ROWS_PER_PAGE,
                    doctorId: props.doctor.id,
                    officeId: props.office.id,
                    isCompareDetail: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                setEvaluationList(res.data.data);
                setPageCount(res.data.extraData.pageCount);
                setRowCount(res.data.extraData.rowCount);
                setIsLoading(false);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/login");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1);
        setCurrentPage(selectedPage);
    };

    useEffect(() => {
        fetchEvaluationdata();
    }, [offset]);

    return (
        <section className="box1">
            <header className="panel_header">
                <div className="mx-3">
                    <div className="row ">
                        <div className="col-9 d-flex justify-content-start">
                            <div className="row mx-2">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4 title">{t("Office")}: </div>
                                        <div className="col-8 title">
                                            <input className="form-control fw-bold" value={props.office.name} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-4 title">{t("Doctor")}: </div>
                                        <div className="col-8 title">
                                            <input className="form-control fw-bold" value={props.doctor.name} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="content-body">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="table-responsive" data-pattern="priority-columns">
                            <table
                                id="tech-companies-1"
                                className={`table vm table-small-font no-mb table-bordered ${
                                    isLoading || !evaluationList || (evaluationList && !evaluationList.length) ? "" : "table-striped"
                                } table-hover`}
                            >
                                <thead>
                                    <tr>
                                        <th>{t("Date of service")}</th>
                                        {evaluationList &&
                                            evaluationList.length &&
                                            evaluationList[0] &&
                                            evaluationList[0].evluation &&
                                            Object.keys(evaluationList[0].evluation).map((item, i) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <th>{item}</th>
                                                    </Fragment>
                                                );
                                            })}
                                        <th>{t("Created Date")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!evaluationList || (evaluationList && !evaluationList.length && !isLoading) ? (
                                        <NoDataFound />
                                    ) : isLoading ? (
                                        <Loader />
                                    ) : (
                                        evaluationList &&
                                        evaluationList.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <h6>{item.dateOfService ? monthDateYear(item.dateOfService) : "-"}</h6>
                                                    </td>
                                                    {item.evluation &&
                                                        Object.values(item.evluation).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <td>
                                                                        <h6>{evaluationRating(item).concat(evaluationEmptyStars(item))}</h6>
                                                                    </td>
                                                                </Fragment>
                                                            );
                                                        })}
                                                    <td>
                                                        <h6>{item.created_at ? monthDateYear(item.created_at) : "-"}</h6>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-between ">
                            <div className="d-flex align-center">
                                {t("Total")} {evaluationList == null ? "0" : rowCount} {t("Dental Comparison")}
                                {/* {rowCount && rowCount > 1 ? "s" : ""} */}
                            </div>
                            <div className="d-flex align-center">
                                <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                                    {t("Close")}
                                </button>
                            </div>
                            <div className="pagination-center">
                                <ReactPaginate
                                    initialPage={0}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={0}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    pageClassName="page-item"
                                    breakClassName="page-item"
                                    nextClassName="page-item"
                                    previousClassName="page-item"
                                    previousLabel={t("Previous")}
                                    nextLabel={t("Next")}
                                    disableInitialCallback={true}
                                    forcePage={currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DetailDentalCompareList;
