import React from "react";
import { useTranslation } from "react-i18next";
import { PATIENT_DOCUMENT_TYPE } from "../../../helper/Constant";
import DocumentsTable from "./DocumentsTable";

function ManagePatientDocuments(props) {
    const { t } = useTranslation();

    return (
        <>
            <div className="content-body my-4 border-0">
                <div className="row">
                    <DocumentsTable type={PATIENT_DOCUMENT_TYPE.INSURANCE_FRONT} patientId={props.patientId} title={"Insurance front"} isView={props.view} />
                    <DocumentsTable type={PATIENT_DOCUMENT_TYPE.INSURANCE_BACK} patientId={props.patientId} title={"Insurance back"} isView={props.view} />
                    <DocumentsTable type={PATIENT_DOCUMENT_TYPE.X_RAY} patientId={props.patientId} title={"X-ray"} isView={props.view} />
                    <DocumentsTable type={PATIENT_DOCUMENT_TYPE.NOTE} patientId={props.patientId} title={"Note"} isView={props.view} />
                    <DocumentsTable type={PATIENT_DOCUMENT_TYPE.ID} patientId={props.patientId} title={"Id"} isView={props.view} />
                </div>
                <div className="padding-bottom-30 d-flex justify-content-center">
                    <div className="text-left">
                        <button type="button" className="btn mx-2 btn-secondary" onClick={props.handleClose}>
                            {t("Close")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagePatientDocuments;
