import React from "react";
import { ROLE_ID } from "../../helper/Constant";
import AdminSidebar from "./AdminSideBar";
import AgencySidebar from "./AgencySidebar";
import AgentSidebar from "./AgentSidebar";
import DoctorSideBar from "./DoctorSideBar";
import ManagerSidebar from "./ManagerSidebar";
import PatientSideBar from "./PatientSideBar";
import VerifierSidebar from "./VerifierSidebar";

function Sidebar(props) {
    const { profileData, pageAccess } = props;
    return (
        <>
            {profileData.roleId == ROLE_ID.ADMIN ? (
                <AdminSidebar pageAccess={pageAccess} />
            ) : profileData.roleId == ROLE_ID.PATIENT ? (
                <PatientSideBar />
            ) : profileData.roleId == ROLE_ID.DOCTOR ? (
                <DoctorSideBar />
            ) : profileData.roleId == ROLE_ID.VERIFY ? (
                <VerifierSidebar />
            ) : profileData.roleId == ROLE_ID.AGENCY ? (
                <AgencySidebar />
            ) : profileData.roleId == ROLE_ID.AGENT ? (
                <AgentSidebar />
            ) : profileData.roleId == ROLE_ID.MANAGER ? (
                <ManagerSidebar />
            ) : (
                ""
            )}
        </>
    );
}

export default Sidebar;
