import axios from "axios";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../commoncomponents/Loader";
import { baseURL } from "../../../../helper/Config";

function UpsertRolePage(props) {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");

    const { t } = useTranslation();

    const handlePageAdd = async () => {
        await axios
            .post(
                `${baseURL}/api/v1/admin/page-authority/upsert`,
                {
                    pageAuthority: props.pageList,
                    roleId: props.roleId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                        lang: languageShortName,
                    },
                }
            )
            .then((res) => {
                props.handlePageClose();
                toast.success(res.data.message);
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status == 500) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/");
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };

    return (
        <>
            <Modal show={props.isPageShow} onHide={props.handlePageClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.pageTitle ? t(props.pageTitle) : t("All Pages")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.editLoader ? (
                        <Loader />
                    ) : (
                        <div>
                            <div className="row">
                                <label className="mt-2 col-12 ">{t("Select Pages")}</label>
                            </div>
                            <div className="row">
                                {props.pageList &&
                                    props.pageList.map((item, i) => {
                                        return (
                                            <>
                                                <div className="col-lg-1 col-sm-1 col-xs-4">
                                                    <input
                                                        type="checkbox"
                                                        className="checkBox mt-2 mb-2"
                                                        key={i}
                                                        value={item.id}
                                                        onChange={props.handleCheck}
                                                        checked={item.isPageAuthority == 1 ? true : false}
                                                        disabled={item.name === "Dashboard" ? "disabled" : ""}
                                                    />
                                                </div>
                                                <div className="offset-lg-1 offset-sm-1 col-lg-4 col-sm-4 col-xs-6">{item.name}</div>
                                            </>
                                        );
                                    })}
                            </div>
                            <div id="checkAdd" className="my-3 text-end">
                                <button className="btn btn-info mx-2" onClick={handlePageAdd}>
                                    {t("Add")}
                                </button>
                                <button className="btn btn-default" onClick={props.handlePageClose}>
                                    {t("Close")}
                                </button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UpsertRolePage;
