import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURL } from "../../helper/Config";
import { LANGUAGE_LIST, ROLE_ID } from "../../helper/Constant";
import Cookies from "universal-cookie";
import Select from "react-select";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Header = (props) => {
    const cookies = new Cookies();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const languageShortName = localStorage.getItem("languageShortName");
    const { profileData, fetchProfiledata, officeList, officeId, setOfficeId } = props;
    const [languageId, setLanguageId] = useState(localStorage.getItem("languageShortName") ? localStorage.getItem("languageShortName") : "");
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const languageOptions =
        LANGUAGE_LIST &&
        LANGUAGE_LIST.map((item) => {
            return {
                value: item.shortName,
                label: windowSize.width > 768 ? item.flag + "   " + item.name : item.flag,
            };
        });

    const onLanguageChange = async (e) => {
        setLanguageId(e.value);
        await axios
            .post(
                `${baseURL}/api/v1/change/language`,
                { lang: e.value },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
            .then(() => {
                localStorage.setItem("languageShortName", e.value);
                window.location.reload();
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const handleLogout = async () => {
        await axios
            .get(`${baseURL}/api/v1/logout`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                    lang: languageShortName,
                },
            })
            .then((res) => {
                toast.success(res.data.message);
                localStorage.clear();
                sessionStorage.clear();
                cookies.remove("pageAccess");
                cookies.remove("office");
                navigate("/login");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    };

    const officeOptions =
        officeList &&
        officeList.map((item, i) => {
            return {
                value: item.id,
                label: item.name + " (" + item.businessUsername + ")",
            };
        });

    const onOfficeChange = (e) => {
        setOfficeId(e.value);
    };

    const handleSwitch = () => {
        Swal.fire({
            title: t("Are you sure you want switch this office ?"),
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: t("Close"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, switch it!"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (result.isConfirmed) {
                    cookies.set("office", officeId);
                    window.location.reload();
                }
            }
        });
    };

    return (
        <div
            className={`page-topbar  gradient-${
                profileData.roleId == ROLE_ID.ADMIN
                    ? "blue1"
                    : profileData.roleId == ROLE_ID.DOCTOR
                    ? "violet"
                    : profileData.roleId == ROLE_ID.PATIENT
                    ? "orange"
                    : profileData.roleId == ROLE_ID.VERIFY
                    ? "pink"
                    : profileData.roleId == ROLE_ID.AGENCY
                    ? "green"
                    : profileData.roleId == ROLE_ID.AGENT
                    ? "green1"
                    : profileData.roleId == ROLE_ID.MANAGER
                    ? "blue3"
                    : "grep"
            }`}
        >
            <Link to="/">
                <div className="logo-area crypto toggle_chat"></div>
            </Link>
            <div className="quick-area">
                <div className="pull-left">
                    <ul className="info-menu left-links list-inline list-unstyled">
                        <li className="sidebar-toggle-wrap">
                            <a data-toggle="sidebar" className="sidebar_toggle">
                                <i className="fa fa-bars" style={{ cursor: "pointer" }}></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="d-flex float-end">
                    <div className={`row ${windowSize.width > 768 ? "" : "pull-right"}`}>
                        {profileData.roleId == ROLE_ID.DOCTOR ? (
                            <div className="col-xl-6 col-lg-6 col-md-4 hidden-sm">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-11 col-sm-11 d-flex" style={{ marginTop: "10px" }}>
                                        <div>
                                            <Select
                                                name="officeList"
                                                value={officeOptions && officeOptions.filter(({ value }) => value == officeId)}
                                                options={officeOptions}
                                                onChange={onOfficeChange}
                                                placeholder={t("Select")}
                                            />
                                        </div>
                                        <button type="button" className="btn btn-primary gradient-green mx-2" onClick={handleSwitch}>
                                            {t("Switch")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className={`${profileData.roleId == ROLE_ID.DOCTOR ? "col-lg-6 col-md-8  col-xl-6 col-sm-12 col-xs-12" : "col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12"} `}>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-8" style={{ marginTop: "8px" }}>
                                    <div className=" mx-3 d-flex  mt-5">
                                        <Select
                                            name="languageList"
                                            value={languageOptions && languageOptions.filter(({ value }) => value == languageId)}
                                            options={languageOptions}
                                            onChange={onLanguageChange}
                                            placeholder={t("Select")}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-xs-4">
                                    <ul className="info-menu right-links list-inline list-unstyled d-flex">
                                        <li className="profile">
                                            <a href="#" data-toggle="dropdown" className="toggle">
                                                {profileData && profileData.profilePic ? (
                                                    <img src={baseURL + profileData.profilePic} className="img-circle img-inline" alt="User Image" />
                                                ) : (
                                                    <img src="/data/profile/profile.jpg" alt="user-image" className="img-circle img-inline" />
                                                )}
                                                {windowSize.width > 1440 ? (
                                                    <span>
                                                        {profileData && profileData.userName}
                                                        <i className="fa fa-angle-down"></i>
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </a>
                                            <ul className="dropdown-menu profile animated fadeIn">
                                                {profileData.roleId == ROLE_ID.ADMIN ? (
                                                    <li>
                                                        <Link to="/view-profile">
                                                            <i className="fa fa-user"></i> {t("Profile")}
                                                        </Link>
                                                    </li>
                                                ) : profileData.roleId == ROLE_ID.DOCTOR ||
                                                  profileData.roleId == ROLE_ID.PATIENT ||
                                                  profileData.roleId == ROLE_ID.AGENCY ||
                                                  profileData.roleId == ROLE_ID.AGENT ||
                                                  profileData.roleId == ROLE_ID.MANAGER ? (
                                                    <li>
                                                        <Link to="/update-profile" onClick={() => fetchProfiledata()}>
                                                            <i className="fa fa-user"></i> {t("Edit Profile")}
                                                        </Link>
                                                    </li>
                                                ) : (
                                                    ""
                                                )}
                                                <li>
                                                    <Link to="/change-password">
                                                        <i className="fa fa-key"></i> {t("Change Password")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <a className="cursor-pointer" onClick={handleLogout}>
                                                        <i className="fa fa-lock"></i> {t("Logout")}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
