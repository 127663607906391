import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import $ from "jquery";

const VerifierSidebar = () => {
    const { t } = useTranslation();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleCollapse = () => {
        if (windowSize.width < 777) {
            $(".page-sidebar").addClass("collapseit").removeClass("expandit");
        }
    };

    return (
        <>
            <div className="page-sidebar fixedscroll">
                <div className="page-sidebar-wrapper" id="main-menu-wrapper">
                    <ul className="wraplist">
                        <li className="menusection">{t("Verifier")}</li>
                        <li className="open" onClick={handleCollapse}>
                            <Link to="/dashboard">
                                <i
                                    className="fa fa-dashcube"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(205 79 79)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Dashboard")}</span>
                            </Link>
                        </li>
                        <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-envelope-o"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(205 79 79)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Claim Verify")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-claim-verify">{t("All Claim Verify")}</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-star-o"
                                    style={{
                                        fontSize: "18px",
                                        color: "rgb(205 79 79)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Evaluation")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-evaluation">{t("All Evaluation")}</Link>
                                </li>
                            </ul>
                        </li>
                        {/* <li className="cursor-pointer">
                            <a>
                                <i
                                    className="fa fa-video-camera"
                                    style={{
                                        fontSize: "15px",
                                        color: "rgb(205 79 79)",
                                        top: "2px",
                                    }}
                                ></i>
                                <span className="title">{t("Zoom-Meeting")}</span>
                                <span className="arrow "></span>
                            </a>
                            <ul className="sub-menu">
                                <li onClick={handleCollapse}>
                                    <Link to="/manage-zoom-meeting">{t("Manage Zoom-Meeting")}</Link>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default VerifierSidebar;
